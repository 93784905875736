import React, { useState, useEffect } from 'react';
import { useGetAccountantSelect } from 'hooks/seller';
import { USER_PERMISSIONS } from 'config/constants';

function useForm() {
  const [params, setParams] = useState({
    filters: {
      query: undefined,
      // permission: USER_PERMISSIONS.ACCOUNTANT_BANK,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const { hasMoreData, loadMoreDataAccountantName, options } = useGetAccountantSelect(params);

  const onSearch = (value) => {
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };


  return { hasMoreData, loadMoreDataAccountantName, options, onSearch };
}

export default useForm;

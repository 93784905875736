import { t } from "i18next";
import { useEffect, useState } from "react";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import ListByExportSerial from "./ListByExportSerial";
import ListBySerial from "./ListBySerial";

function useList() {
  const GET_QUERY = getQuery();
  const [activeKeyMain, setActiveKeyMain] = useState(GET_QUERY.activeKey || "listByExportSerial");
  const [activeKeyStatus, setActiveKeyStatus] = useState(GET_QUERY.key);
  const handleChangeTab = (activeKey) => {
    setActiveKeyMain(activeKey);
    setActiveKeyStatus("listByExportSerial");
    buildQueryString({
      params:
        activeKey === "listByExportSerial" ? { activeKey: activeKey } : { activeKey: activeKey, key: "listBySerial" },
    });
  };

  const handleChangeTabStatus = (value) => {
    setActiveKeyStatus(value);
  };

  const tabsMain = [
    {
      title: t("serialIE.ListByExportSerial"),
      children: <ListByExportSerial />,
      key: "listByExportSerial",
    },
    {
      title: t("serialIE.ListBySerial"),
      children: <ListBySerial />,
      key: "listBySerial",
    },
  ];

  useEffect(() => {
    buildQueryString({
      params: { activeKey: GET_QUERY.activeKey },
    });
  }, [GET_QUERY.activeKey]);

  return { tabsMain, handleChangeTab, handleChangeTabStatus, activeKeyMain, activeKeyStatus };
}

export default useList;

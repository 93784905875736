import {
  useGetDetailBrand,
  useGetListProductBrand,
  useUpdateStatusBrand,
} from 'hooks/product/product';
import useDebounce from 'hooks/useDebounce';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { buildQueryString, getQuery, notify } from 'utils/helperFuncs';
import { omit } from 'utils/object';
import { isNil } from 'lodash';

const useList = () => {
  const GET_QUERY = getQuery();
  const [params, setParams] = useState(() => {
    return {
      filters: {
        query: '',
        isActive: undefined,
      },
      pagination: {
        offset: GET_QUERY.offset || 0,
        limit: GET_QUERY.limit || 10,
      },
      sort: [],
    };
  });
  const { data, paginationData, loading } = useGetListProductBrand(omit(params));

  const statusBrand = {
    true: t('productBrand.active'),
    false: t('productBrand.unactive'),
  };

  const VALUE_OPTION_STATUS_BRAND = [
    {
      label: t('productBrand.active'),
      value: true,
    },
    {
      label: t('productBrand.unactive'),
      value: false,
    },
  ];

  const checkNullValue = (value) => {
    if (value === null || value === '' || value === undefined) {
      return '- -';
    } else {
      return value;
    }
  };

  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
      },
    });
  };
  const handleRemoveFilterTag = (key) => {
    setParams({
      pagination: { offset: 0, limit: 10 },
      filters: {
        ...omit({ ...params.filters }, [`${key}`]),
      },
      sort: params.sort,
    });
    buildQueryString({
      params: {
        ...omit({ ...params?.filters }, [`${key}`]),
        ...params?.pagination,
      },
    });
  };

  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedValue = useDebounce(searchTerm);

  const { handleUpdateStatusBrand } = useUpdateStatusBrand();
  const { handlegetDetailBrand } = useGetDetailBrand();
  const handleChangeStatus = async (id, checked) => {
    const data = await handlegetDetailBrand({ id });
    const dataGetDetailBrand = data?.data?.brand?.get;
    const params = {
      name: dataGetDetailBrand.name,
      logo: dataGetDetailBrand?.logoURL,
      catalogs: dataGetDetailBrand?.catalogURLs,
      description: dataGetDetailBrand.description,
      isActive: checked,
      id: id,
    };
    await handleUpdateStatusBrand(params)
      .then(() => {
        notify.success({ message: t('productBrand.changeStatusSuccess') });
      })
      .catch((error) => {
        notify.error({
          message: error.message,
        });
      });
  };

  const onSearchProductBrand = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      filters: {
        ...params.filters,
      },
      sort: [],
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
      },
    });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      buildQueryString({
        params: {
          ...newParams?.filters,
          ...newParams?.pagination,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return {
    data,
    paginationData,
    loading,
    statusBrand,
    checkNullValue,
    VALUE_OPTION_STATUS_BRAND,
    handleChangeTable,
    onSearchProductBrand,
    handleFilter,
    params,
    handleRemoveFilterTag,
    handleChangeStatus,
  };
};

export default useList;

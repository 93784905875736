import React from 'react';
import Card from 'features/Cart/components/Card';
import { Col, Image, Row, Space, Typography } from 'antd';
import { t } from 'i18next';
import { renderItemsStatus, TOTAL_ITEMS_STATUS } from 'features/WarrantyRequest/constant';
import styles from './index.module.scss';
import CustomStep from './CustomStep';
import { SHIPPING_TYPE } from 'config/constants';
import { useGetInfoPlatform } from 'hooks/info-platform';

function FullEvents({ data }) {
  const { platformName } = useGetInfoPlatform();

  const SHIPPING_METHOD = {
    [`${SHIPPING_TYPE.BUS}`]: t('serial.bus'),
    [`${SHIPPING_TYPE.DELIVER_3PARTY}`]: t('serial.deliver3Party'),
    [`${SHIPPING_TYPE.PICK_UP_COUNTER}`]: t('serial.pickUpCounter'),
    [`${SHIPPING_TYPE.URBAN_COD}`]: t('serial.urbanCOD', {
      platformName,
    }),
    null: '--',
  };

  const imagesDeli = (
    <Col span={12}>
      <Space direction="vertical">
        <Col span={24}>
          <Typography.Title level={5} style={{ color: '#052987' }}>
            {t('serial.shippedImages')}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Image.PreviewGroup>
            {data?.deliverFileURLs?.map((item, index) => {
              return <Image src={item} key={index} className={styles['imageSize']} />;
            }) || <Typography.Text italic>{t('common.noImages')}</Typography.Text>}
          </Image.PreviewGroup>
        </Col>
      </Space>
    </Col>
  );

  const shippingConfig = (
    <Col span={12}>
      <Col span={24} xl={12}>
        <Typography.Title level={5} style={{ color: '#052987' }}>
          {t('serial.distributorDeliverMethod')}
        </Typography.Title>
      </Col>
      <Col span={24} xl={12}>
        <Typography.Text>{SHIPPING_METHOD?.[data?.shippingType]}</Typography.Text>
        <br />
        <Typography.Text>
          {data?.shippingConfig?.busConfig
            ? data?.shippingConfig?.busConfig?.busName +
              ' - ' +
              data?.shippingConfig?.busConfig?.telephone
            : data?.shippingConfig?.deliver3PartyConfig?.provider?.name}
        </Typography.Text>
      </Col>
    </Col>
  );

  const renderUI = {
    [TOTAL_ITEMS_STATUS.CANCELED]: (
      <>
        {imagesDeli}
        {shippingConfig}
      </>
    ),
    [TOTAL_ITEMS_STATUS.COMPLETED]: (
      <>
        {imagesDeli}
        {shippingConfig}
      </>
    ),
    [TOTAL_ITEMS_STATUS.DELIVERING]: shippingConfig,
  };

  return (
    <Card
      title={
        <Row>
          <Col span={12}>
            <Space>
              <Typography.Text strong>{t('serial.serialNumber')}</Typography.Text>
              <Typography.Link strong>{data?.serial}</Typography.Link>
              <Typography.Text strong>{t('serial.belongToWarranty')}</Typography.Text>
              <Typography.Link
                strong
                href={`/warranty-request/detail/${data?.warrantyRequest?.id}`}
              >
                #{data?.warrantyRequest?.code}
              </Typography.Link>
            </Space>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography.Text strong>{renderItemsStatus(data?.status)}</Typography.Text>
          </Col>
        </Row>
      }
    >
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <Typography.Title level={5} style={{ color: '#052987' }}>
            {t('serial.warrantyStatus')}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <CustomStep dataSteps={data?.fullEvents} isCancel={data?.isCancel} />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>{renderUI?.[data?.status]}</Row>
    </Card>
  );
}

export default FullEvents;

import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Switch, Tag } from 'antd';
import { FontAwesomeIcon, Icon } from 'assets/icons';
import { buildQueryString, getQuery, notify, debounce } from 'utils/helperFuncs';
import { Link } from 'react-router-dom';
import Img from 'assets/images';
import TableSelection from 'components/TableSelection';
import CustomModal from 'components/CustomModal';
import CustomTable from 'components/CustomTable';
import { useGetUsers, useUpdateUserStatus } from 'hooks/user/user';
import { useGetRoles } from 'hooks/role';
import 'styles/custom_component.scss';
import './index.scss';
import { useStaffPermissions } from 'hooks/user/user';
import FilterMenu from 'components/CustomFilterMenu/FilterMenu';
import { useGetBranchQuery } from 'hooks/branch';
import { t } from 'i18next';
import { FiltersTag } from '../FiltersTag';
import { omit } from 'utils/object';
import useDebounce from 'hooks/useDebounce';
import { isNil } from 'lodash';

const AcountList = () => {
  const GET_QUERY = getQuery();
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [currentUser, setCurrentUser] = useState({
    id: '',
    status: '',
  });
  const [params, setParams] = useState(() => {
    return {
      filters: {
        query: GET_QUERY.query || null,
        status: GET_QUERY.status || null,
        roleIDs: GET_QUERY.roleIDs || null,
        branchIDs: GET_QUERY.branchIDs || null,
      },
      pagination: {
        offset: GET_QUERY.offset || 0,
        limit: GET_QUERY.limit || 10,
      },
    };
  });
  const { users, paginationData, loading, refetch } = useGetUsers(params);
  const { roles } = useGetRoles({
    offset: null,
    query: null,
  });

  const { canCreate, canUpdate, canDeactivateStaff } = useStaffPermissions();
  const { hanldeUpdateUserStatus } = useUpdateUserStatus();
  const { data: dataBranch } = useGetBranchQuery();
  const USER_STATUS = {
    ACTIVE: 'ACTIVE',
    DISABLED: 'DISABLED',
    DELETED: 'DELETED',
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys: selectedRowKeys,
  };

  const statusOptions = [
    {
      label: t('account.active'),
      value: USER_STATUS.ACTIVE,
    },
    {
      label: t('account.unactive'),
      value: USER_STATUS.DISABLED,
    },
  ];
  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
      },
    });
  };

  const handleRemoveFilterTag = (key) => {
    setParams({
      pagination: { offset: 0, limit: 10 },
      filters: {
        ...omit({ ...params.filters }, [`${key}`]),
      },
    });
    buildQueryString({
      params: {
        ...omit({ ...params?.filters }, [`${key}`]),
        ...params?.pagination,
      },
    });
  };
  const columns = [
    {
      title: 'STT',
      fixed: 'left',
      align: 'center',
      width: 60,
      render: (text, record, index) => <b>{index + 1}</b>,
    },
    {
      title: t('account.FULLNAME'),
      dataIndex: 'fullname',
      width: '230px',
      render: (_, record) =>
        canUpdate ? (
          <Link to={`/account/detail/${record?.id}`} className="account-id">
            {record?.fullname}
          </Link>
        ) : (
          <b>{record?.fullname}</b>
        ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
    },
    {
      title: t('account.BRANCH'),
      render: (_, record, index) => {
        return (
          <div>
            {record?.branches?.length > 0
              ? record?.branches?.map(({ name }, index) => <Tag key={index}>{name}</Tag>)
              : '- -'}
          </div>
        );
      },
      filterDropdown: (
        <FilterMenu
          options={dataBranch}
          type="checkbox"
          onFilter={(value) => handleFilter({ branchIDs: value })}
        />
      ),
    },
    {
      title: t('account.DEPARTMENT'),
      width: '180px',
      dataIndex: 'departmentId',
      render: (_, record) => {
        return <span>{record.department.name}</span>;
      },
    },
    {
      title: t('account.ROLE'),
      dataIndex: 'roleId',
      render: (_, record) => {
        return (
          <div>
            {record?.roles?.slice(0, 2)?.map(({ name }, index) => (
              <Tag key={index}>{name}</Tag>
            ))}
          </div>
        );
      },
      filterDropdown: (
        <FilterMenu
          options={roles?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))}
          type="checkbox"
          onFilter={(value) => handleFilter({ roleIDs: value })}
        />
      ),
    },
    {
      title: t('account.ACTION'),
      dataIndex: 'action',
      width: '140px',
      align: 'center',
      filterDropdown: (
        <FilterMenu
          options={statusOptions}
          type="radio"
          onFilter={(value) => handleFilter({ status: value })}
        />
      ),
      render: (_, record) =>
        canDeactivateStaff ? (
          <CustomModal
            message={
              <p className="user-name">
                Bạn có chắc chắn muốn {record.status === USER_STATUS.ACTIVE ? 'khóa' : 'mở khóa'}{' '}
                tài khoản <span className="user-name">{record?.fullName}</span> không ?
              </p>
            }
            centered={true}
            footer={false}
            onOke={changeUserStatus}
            icon={record.status === USER_STATUS.ACTIVE ? Img.LockedIcon : Img.UnlockedIcon}
            customComponent={
              <div style={{ margin: '0 auto' }}>
                <Switch
                  onChange={(value) => onUserStatusChange(record, value)}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  checked={record?.status === USER_STATUS.ACTIVE}
                />
              </div>
            }
          />
        ) : (
          <></>
        ),
    },
  ];

  function onUserStatusChange({ id }, value) {
    setCurrentUser({
      id,
      status: value ? USER_STATUS.ACTIVE : USER_STATUS.DISABLED,
    });
  }

  async function changeUserStatus() {
    const { status } = currentUser;
    try {
      await hanldeUpdateUserStatus(currentUser);
      notify.success({
        message: `${status === USER_STATUS.ACTIVE ? 'Mở khóa' : 'Khóa'} tài khoản thành công !`,
      });
      refetch();
    } catch (err) {
      notify.error({
        message: `${status === USER_STATUS.ACTIVE ? 'Mở khóa' : 'Khóa'} tài khoản thất bại !`,
      });
    }
  }

  async function removeUsers() {
    try {
      await Promise.all(
        selectedRowKeys?.map((id) =>
          hanldeUpdateUserStatus({
            id,
            status: USER_STATUS.DELETED,
          })
        )
      );
      notify.error({
        message: 'Xóa tài khoản thành công !',
      });
      unSelectAllUser();
      refetch();
    } catch (err) {
      notify.success({
        message: 'Xóa tài khoản thất bại !',
      });
    }
  }

  function selectAllUser() {
    setSelectedRowKeys(users?.map((item) => item?.id));
  }

  function toggleSeclectAllUser() {
    if (selectedRowKeys?.length !== users?.length) {
      selectAllUser();
      return;
    }
    unSelectAllUser();
  }

  function unSelectAllUser() {
    setSelectedRowKeys([]);
  }

  function filterUserByStatus(status) {
    let newParams = {
      ...params,
      offset: 0,
      status,
    };
    setParams(newParams);
    buildQueryString({
      params: newParams,
    });
  }

  function filterUserByRole(roleId) {
    let newParams = {
      ...params,
      offset: 0,
      roleIDs: roleId ? [roleId] : null,
    };
    setParams(newParams);
    buildQueryString({
      params: newParams,
    });
  }

  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedValue = useDebounce(searchTerm);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      buildQueryString({
        params: {
          ...newParams?.filters,
          ...newParams?.pagination,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  function onTableChange(pagination, filters, sorter) {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      filters: {
        ...params.filters,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
      },
    });
  }

  return (
    <div className="account-container">
      <div className="filter-box">
        <div className="search-input">
          <Input
            className="custom-input"
            allowClear={true}
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder="Tìm kiếm tài khoản theo ID, họ tên hoặc email"
            onChange={onSearch}
            defaultValue={getQuery()?.query || ''}
          />
        </div>
        {canCreate && (
          <Link to="/account/create">
            <Button className="create-btn" type="primary">
              Tạo tài khoản
            </Button>
          </Link>
        )}
      </div>

      {selectedRowKeys?.length > 0 && (
        <TableSelection
          name="tài khoản"
          selectedNumber={selectedRowKeys?.length}
          totalSelectedNumber={users?.length}
          onSelectAll={selectAllUser}
          toggleSelectAll={toggleSeclectAllUser}
          isSelectAll={selectedRowKeys?.length === users?.length}
          modalProps={{
            buttonLabel: t('account.deleteusert'),
            centered: true,
            footer: false,
            message: t('account.areyousureDelete'),
            icon: Img.DeleteIcon,
            onOke: removeUsers,
          }}
        />
      )}
      <div style={{ display: 'flex', gap: '10px', margin: '10px 24px -6px 24px' }}>
        <FiltersTag
          params={params}
          dataBranch={dataBranch}
          handleRemoveFilterTag={handleRemoveFilterTag}
          dataRoles={[
            {
              name: 'Tất cả vai trò',
              id: null,
            },
          ]
            .concat(roles || [])
            ?.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
          statusOptions={statusOptions}
        />
      </div>
      <div className="table-list">
        <CustomTable
          rowKey={(record) => record?.id}
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={users}
          scroll={{ x: 800, y: null }}
          pagination={{
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }}
          onChange={onTableChange}
        />
      </div>
    </div>
  );
};

export default AcountList;

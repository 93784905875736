import React from 'react';
import { Button, Typography } from 'antd';
import { t } from 'i18next';
import { SvgIcon } from 'assets/icons';

function PopoverExport({ handleExport }) {
  return (
    <Button type="text" className="button" onClick={handleExport}>
      <SvgIcon.ArrowCircleDownIcon />
      <Typography.Text>{t('warrantyRequest.detail.export')}</Typography.Text>
    </Button>
  );
}

export default PopoverExport;

import React from 'react';
import { Tooltip } from 'antd';
import { SvgIcon } from 'assets/icons';
import { t } from 'i18next';

function EditIcon({ status }) {
  return (
    <Tooltip title={status ? t('warrantyRequest.detail.edit') : ''}>
      <SvgIcon.EditIcon style={status ? {} : { cursor: 'not-allowed', opacity: '.5' }} />
    </Tooltip>
  );
}

export default EditIcon;

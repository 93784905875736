import { DIMENSION_UNIT, WEIGHT_UNIT } from "config/constants";
import { t } from "i18next";

export const SELLING_STATUS = {
  AVAILABLE: "AVAILABLE",
  CONTACT: "CONTACT",
  UNAVAILABLE: "UNAVAILABLE",
};

export const sellingStatusLabel = {
  [SELLING_STATUS.AVAILABLE]: "Đang kinh doanh",
  [SELLING_STATUS.CONTACT]: "Liên hệ",
  [SELLING_STATUS.UNAVAILABLE]: "Ngừng kinh doanh",
};

export const PRODUCT_TYPE = {
  MATERIAL: "MATERIAL",
  SERVICE: "SERVICE",
  KEY_LICENSE: "KEY_LICENSE",
  ACCOUNT: "ACCOUNT",
};

export const UNIT_DATE = {
  DAY: "DAY",
  MONTH: "MONTH",
  YEAR: "YEAR",
  FOREVER: "FOREVER",
};

export const convertUnit = (unit) => {
  switch (unit) {
    case UNIT_DATE.DAY:
      return t("v2.keyManagement.day");
    case UNIT_DATE.MONTH:
      return t("v2.keyManagement.month");
    case UNIT_DATE.YEAR:
      return t("v2.keyManagement.year");
    case UNIT_DATE.FOREVER:
      return t("v2.keyManagement.forever");
    default:
      return "--";
  }
};

export const keyOnlineTypeLabel = {
  [PRODUCT_TYPE.KEY_LICENSE]: "Key license",
  [PRODUCT_TYPE.ACCOUNT]: "Account",
};

export const dimensionUnitLabel = {
  [DIMENSION_UNIT.CENTIMETER]: "cm",
  [DIMENSION_UNIT.METER]: "m",
  [DIMENSION_UNIT.MILLIMETER]: "mm",
};

export const weightLabel = {
  [WEIGHT_UNIT.GRAM]: "g",
  [WEIGHT_UNIT.KILOGRAM]: "kg",
  [WEIGHT_UNIT.QUINTAL]: "tạ",
  [WEIGHT_UNIT.TON]: "tấn",
};

export const VAT_TYPE = {
  REQUIRED: "REQUIRED",
  NOT_REQUIRED: "NOT_REQUIRED",
  OPTIONAL: "OPTIONAL",
};

export const TAXABLE = {
  ALL: "ALL",
  TAXABLE: "TAXABLE",
  NOT_TAXABLE: "NOT_TAXABLE",
};

export const SELLING_STATUS_OPTIONS = [
  {
    value: SELLING_STATUS.AVAILABLE,
    label: "Đang kinh doanh",
  },
  {
    value: SELLING_STATUS.CONTACT,
    label: "Liên hệ",
  },
  {
    value: SELLING_STATUS.UNAVAILABLE,
    label: "Ngừng kinh doanh",
  },
];

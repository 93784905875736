import React from 'react';
import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

function InfoProvider({ sellerInfo }) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24} className="form-info-provider">
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">
              {t('warehouse.create.providerrecommend')}
            </Typography.Text>
          </Col>
          <Col span={18}>
            <Link to={`/supplier/detail/${sellerInfo?.id}`}>
              <Typography.Link strong>{sellerInfo?.code || '---'}</Typography.Link>
            </Link>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}></Col>
          <Col span={18}>
            <Typography.Text>{sellerInfo?.fullName || '---'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{'SĐT'}</Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>{sellerInfo?.telephone || '---'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{t('common.address')}</Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>
              {`${sellerInfo?.address || '---'} - ${sellerInfo?.ward?.name || '---'} - ${
                sellerInfo?.district?.name || '---'
              } - ${sellerInfo?.city?.name || '---'}`}
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">
              {t('warehouse.create.infoAccountBank')}
            </Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>
              {sellerInfo?.bankAccounts?.map((item) => (
                <div>
                  <Typography.Text>
                    {`${item?.bank?.name} - ${item?.bankAccountNumber} - ${item?.name} - 
                    ${t('warranty.warrantyDetail.branch')} ${item?.bankBranch}` || '---'}
                  </Typography.Text>
                </div>
              ))}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default InfoProvider;

import { useQuery, useMutation } from '@apollo/client';
import { ATTRIBUTE_LIST, GET_ATTRIBUTE, PROD_ATTRIBUTE } from 'graphql/attribute/query';
import { convertGetAttributeList } from './converter';
import {
  CREATE_ATTRIBUTE_MUTATION,
  UPDATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE_STATUS,
} from 'graphql/attribute/mutation';

export const useGetAttributeList = (params) => {
  const { loading, data, refetch } = useQuery(ATTRIBUTE_LIST, {
    variables: params,
  });
  return {
    loading,
    data: convertGetAttributeList(data?.attribute?.list),
    refetch,
  };
};

export const useGetAttribute = (params) => {
  const { loading, data } = useQuery(GET_ATTRIBUTE, {
    variables: params,
    skip: !params.id,
  });
  return {
    loading,
    data: data?.attribute?.get,
  };
};

export const useCreateAttribute = () => {
  const [createAttribute, { loading, error, data }] = useMutation(CREATE_ATTRIBUTE_MUTATION);

  const handleCreateAttribute = async (params) => {
    return await createAttribute({
      variables: params,
    });
  };
  return {
    handleCreateAttribute,
    loading,
    error,
    data,
  };
};

export const useUpdateAttribute = () => {
  const [updateAttribute, { loading, error, data }] = useMutation(UPDATE_ATTRIBUTE);

  const handleUpdateAttribute = async (params) => {
    return await updateAttribute({
      variables: params,
    });
  };
  return {
    handleUpdateAttribute,
    loading,
    error,
    data,
  };
};

export const useUpdateAttributeStatus = () => {
  const [updateAttributeStatus, { loading, error, data }] = useMutation(UPDATE_ATTRIBUTE_STATUS);

  const handleUpdateAttributeStatus = async (params) => {
    return await updateAttributeStatus({
      variables: params,
    });
  };
  return {
    handleUpdateAttributeStatus,
    loading,
    error,
    data,
  };
};

export const useGetProdAttribute = (categoryIDs) => {
  const { loading, data } = useQuery(PROD_ATTRIBUTE, {
    variables: {
      categoryIDs: categoryIDs,
      attributeType: 'PREDEFINED',
    },
    skip: !categoryIDs || categoryIDs.length <= 0,
  });
  return {
    loading,
    data: data?.attribute?.list,
  };
};

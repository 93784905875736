import { useMutation, useQuery } from '@apollo/client';
import { MARK_ALL_READ, UPDATE_NOTIFY_STATUS } from 'graphql/notification/mutation';
import { GET_NOTIFICATION_PAGING, GET_UNREAD_NOTIFICATIONS } from 'graphql/notification/query';
import { convertNotification } from './converter';

export const useGetNotifyPaging = (params) => {
  const { loading, data, refetch } = useQuery(GET_NOTIFICATION_PAGING, {
    variables: params,
    skip: params === undefined,
  });
  return {
    loading,
    data: data?.notification?.pagination?.notification,
    paginationData: data?.notification?.pagination?.paginationData,
    convertData: convertNotification(data?.notification?.pagination?.notification),
    refetch,
  };
};

export const useUpdateNotifyStatus = () => {
  const [updateNotifyStatus, { loading, error }] = useMutation(UPDATE_NOTIFY_STATUS);
  const handleUpdateNotifyStatus = async (params) => {
    return await updateNotifyStatus({
      variables: params,
      refetchQueries: [GET_UNREAD_NOTIFICATIONS, GET_NOTIFICATION_PAGING],
      skip: !params?.ids || params?.ids?.length === 0,
    });
  };
  return {
    handleUpdateNotifyStatus,
    loading,
    error,
  };
};

export const useMarkAllRead = () => {
  const [markAllRead, { loading, error }] = useMutation(MARK_ALL_READ);
  const handleMarkAllRead = async () => {
    return await markAllRead({
      refetchQueries: [GET_UNREAD_NOTIFICATIONS, GET_NOTIFICATION_PAGING],
    });
  };
  return {
    handleMarkAllRead,
    loading,
    error,
  };
};

export const useGetUnreadNotifications = () => {
  const { loading, data, refetch } = useQuery(GET_UNREAD_NOTIFICATIONS, {
    pollInterval: 60000,
  });
  return {
    loading,
    data: data?.notification?.totalUnreadNotifications,
    refetch,
  };
};

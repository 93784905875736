import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Space, Tag } from "antd";
import { SvgIcon } from "assets/icons";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { formatBuildQueryString } from "features/Agent/constants";
import { useGetSeller, useInviteSellers, useSellerPermissions, useUpdateSellerStatus } from "hooks/seller";
import { useSupplierPermissions } from "hooks/supplier";
import useDebounce from "hooks/useDebounce";
import { t } from "i18next";
import { compact, isNil, omit } from "lodash";
import ActiveSellerModal from "pages/App/Agent/List/components/ActiveSellerModal";
import {
  FilterGroupCustomer,
  FilterInvited,
  FilterLevel,
  FilterStatus,
  FilterSupplier,
  FilterWarehouse,
} from "pages/App/Agent/List/components/FilterSeller";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency, getQuery, getTimeStamp, notify } from "utils/helperFuncs";

const renderInvitedStatus = (isUsed, invitedCount = 0, sellerStatus) => {
  if (isUsed === true && sellerStatus === true) {
    return <SvgIcon.SuccessIcon />;
  } else if (isUsed === false && sellerStatus === true) {
    if (invitedCount > 0)
      return (
        <>
          <div>{`${t("seller.invited")}`}</div>
          <div>{`(${invitedCount} ${t("seller.times")})`}</div>
        </>
      );
    else return t("seller.uninvited");
  } else return;
};

function useAgentTab() {
  const GET_QUERY = getQuery();

  const [params, setParams] = useState({
    filters: {
      statuses: GET_QUERY.statuses || undefined,
      query: GET_QUERY.query || undefined,
      sellerLevelIDs: GET_QUERY.sellerLevelIDs || undefined,
      exportedWarehouseIDs: GET_QUERY.exportedWarehouseIDs || undefined,
      isActive: GET_QUERY.isActive || undefined,
      isSupplier: GET_QUERY.isSupplier || undefined,
      inviteStatus: GET_QUERY.inviteStatus || undefined,
      sellerGroupIDs: GET_QUERY.sellerGroupIDs || undefined,
      timeRange: GET_QUERY.from
        ? {
            from: GET_QUERY.from,
            to: GET_QUERY.to,
          }
        : undefined,
      periodSelected: GET_QUERY.periodSelected,
    },
    pagination: {
      offset: GET_QUERY.offset || 0,
      limit: GET_QUERY.limit || 10,
    },
  });

  const { loading, data, total, refetch, paginationData } = useGetSeller({
    ...params,
    filters: {
      ...omit(params?.filters, ["periodSelected", "statuses"]),
      approvalStatus: "APPROVED",
    },
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const valueDebounce = useDebounce(searchValue?.trim()?.toString());

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const {
    canCreate,
    canDeactivate,
    canView,
    canSellerAccountApproval,
    loading: loadingSellerPermissions,
  } = useSellerPermissions();

  const {
    canCreateSupplier,
    canDeactivateSupplier,
    canViewListSupplier,
    loading: loadingSupplierPermissions,
  } = useSupplierPermissions();

  const { handleInviteSellers } = useInviteSellers();
  const { handleUpdateSellerStatus, loading: loadingUpdateSellerStatus } = useUpdateSellerStatus();

  const handleFilterSellerExtra = (values) => {
    const newParams = { ...params, filters: { ...params.filters, ...values } };
    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  function handleFilterSellerByTimeRange(value) {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        timeRange: { from: getTimeStamp(value)?.from, to: getTimeStamp(value)?.to },
        periodSelected: value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    formatBuildQueryString(newParams);
  }

  const handleTableChange = (pagination) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
    };
    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  const countSellerInvited = () => {
    let countUninvited = 0;
    let isAllInvited = false;
    selectedRow.forEach((seller) => {
      if (seller.isActive === true && !seller.isLoggedIn) {
        countUninvited++;
      }
    });
    if (countUninvited === selectedRow.length) {
      isAllInvited = true;
    }
    return {
      countUninvited,
      isAllInvited,
    };
  };

  const handleInvitingSellers = async () => {
    if (countSellerInvited().countUninvited !== 0) {
      const activeRows = selectedRow.filter((item) => item.isActive === true) || [];
      const activeKeys = activeRows.map((item) => item.id) || [];

      await handleInviteSellers({ sellerIDs: activeKeys })
        .then((res) => {
          if (res.data.seller.inviteSeller)
            notify.success({
              message: t("seller.successSendInvitation"),
            });
          else
            notify.error({
              message: t("common.error"),
            });
        })
        .catch((error) => {
          notify.error({
            message: t("common.error"),
            description: error.message,
          });
        })
        .finally(() => {
          setSelectedRow([]);
          setSelectedRowKeys([]);
          refetch();
        });
    } else {
      notify.warning({
        message: t("seller.warningEmptyInvitation"),
      });
    }
  };

  const handleCloseTag = (key) => {
    const newParams = { ...params, filters: omit(params.filters, key) };
    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  const columns = [
    {
      title: t("seller.sellerCode"),
      width: 250,
      dataIndex: "code",
      fixed: "left",
      render: (text, record, index) => (
        <Link to={`seller/detail/${record.id}`} strong>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: t("seller.sellerName"),
      width: 250,
      fixed: "left",
      dataIndex: "fullName",
      render: (text, record, index) => {
        return <CustomTextHiddenLine line={2} text={record?.fullName} />;
      },
    },
    {
      title: t("common.address"),
      width: 250,
      render: (text, record, index) => {
        return (
          <CustomTextHiddenLine
            line={2}
            text={compact([record?.address, record?.ward?.name, record?.district?.name, record?.city?.name]).join(", ")}
          />
        );
      },
    },
    {
      title: t("common.level"),
      align: "center",
      dataIndex: ["sellerLevel", "level"],
      filterDropdown: <FilterLevel onFilter={handleFilterSellerExtra} />,
    },
    {
      title: t("seller.sellerDebtLimit"),
      dataIndex: "debtLimit",
      align: "right",
      render: (text) => formatCurrency(text),
    },
    {
      title: t("seller.sellerDebtAge"),
      dataIndex: "debtAge",
      align: "center",
      render: (_, record) => record?.debtAge || "--",
    },
    {
      title: t("seller.warehouse"),
      width: 320,
      render: (text, record, index) => (
        <Space wrap={true}>
          {record.warehouses?.map(({ name }, index) => (
            <Tag color="blue" key={index}>
              {name}
            </Tag>
          ))}
        </Space>
      ),
      filterDropdown: <FilterWarehouse onFilter={handleFilterSellerExtra} />,
    },
    ...(canDeactivate || canDeactivateSupplier
      ? [
          {
            title: t("common.status"),
            align: "center",
            dataIndex: "isActive",
            render: (text, record, index) => (
              <Space style={{ width: "100%", justifyContent: "center" }}>
                <ActiveSellerModal
                  isActive={record.isActive}
                  loading={loadingUpdateSellerStatus}
                  onOke={(value) => {
                    handleUpdateSellerStatus({
                      id: record.id,
                      isActive: !record.isActive,
                      reason: !record.isActive ? undefined : value,
                    });
                  }}
                />
              </Space>
            ),
            filterDropdown: <FilterStatus onFilter={handleFilterSellerExtra} />,
          },
        ]
      : []),
    {
      title: t("seller.groupCustomer"),
      dataIndex: ["sellerGroup", "name"],
      filterDropdown: <FilterGroupCustomer onFilter={handleFilterSellerExtra} />,
    },
    {
      title: t("seller.isSupplier"),
      align: "center",
      render: (text, record, index) =>
        record?.isSupplier && record?.isSeller ? (
          <CheckOutlined style={{ color: "#2246DC", fontSize: "24px" }} />
        ) : (
          <CloseOutlined style={{ fontSize: "24px" }} />
        ),
      filterDropdown: <FilterSupplier onFilter={handleFilterSellerExtra} />,
    },
    {
      title: t("seller.usedApp"),
      align: "center",
      render: (text, record, index) => renderInvitedStatus(record?.isLoggedIn, record?.countInvite, record?.isActive),
      filterDropdown: <FilterInvited onFilter={handleFilterSellerExtra} />,
    },
  ];

  useEffect(() => {
    if (!isNil(valueDebounce)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: valueDebounce.trim() || undefined },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      formatBuildQueryString(newParams);
    }
  }, [valueDebounce]);

  useEffect(() => {
    let newParams = {
      ...params,
      filters: {
        ...params.filters,
        isSupplier: GET_QUERY.isSupplier,
        isActive: GET_QUERY.isActive,
      },
    };
    setParams(newParams);
    formatBuildQueryString(newParams);
  }, []);

  return {
    loading,
    data,
    total,
    canCreate,
    canView,
    canSellerAccountApproval,
    loadingSellerPermissions,
    canCreateSupplier,
    canViewListSupplier,
    loadingSupplierPermissions,
    columns,
    params,
    searchValue,
    selectedRowKeys,
    paginationData,
    countSellerInvited,
    handleTableChange,
    handleFilterSellerByTimeRange,
    handleInvitingSellers,
    handleCloseTag,
    handleSearch,
    setSelectedRowKeys,
    setSelectedRow,
  };
}

export default useAgentTab;

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_STAFFS } from "graphql/user/query";
import {
  COMPLETE,
  CONFIRM_CHECK,
  CONFIRM_DISTRIBUTE,
  CONFIRM_RETURN,
  CONFIRM_SOLUTION,
  EXPORT_FILE,
  RECEIVE_RESULT,
  RETURN_WAITING_FOR_CHECK,
  UPDATE_SERIAL,
  WARRANTY_REQUEST_CREATE,
  WARRANTY_REQUEST_UPDATE,
} from "graphql/warranty/mutation";
import {
  GET_SERIAL_DETAIL,
  GET_SERIAL_REPLACE,
  GET_SHIPPING_PROVIDER,
  GET_SUMMARY_TABS,
  GET_TOTAL_ITEM_STATUS,
  GET_WARRANTY,
  GET_WARRANTY_DETAIL,
  GET_WARRANTY_HISTORY,
  GET_WARRANTY_LOAD_MORE,
  GET_WARRANTY_REQUEST,
  GET_WARRANTY_REQUEST_DETAIL,
  GET_WARRANTY_REQUEST_POPUP,
  GET_WARRANTY_SERIAL,
  GET_WARRANTY_TABLE_PENDING,
} from "graphql/warranty/query";
import { useUpload } from "hooks/upload";
import { t } from "i18next";
import { isEqual } from "lodash";
import { notify } from "utils/helperFuncs";
import { convertWarrantyDetail, convertWarrantyRequestDetail } from "./converter";

const refetchQueries = [
  "GetWarrantySerial",
  "GetWarrantyRequestDetail",
  "GetWarrantyRequestPopup",
  "GetSummaryTabs",
  "GetSerialDetail",
];

export const useGetWarranty = (params) => {
  const { loading, data, refetch } = useQuery(GET_WARRANTY, {
    variables: params,
  });

  return {
    loading,
    data: data?.warranty?.pagination?.noteWarranty,
    paginationData: data?.warranty?.pagination?.paginationData,
    refetch,
  };
};

export const useGetWarrantyRequestDetail = (params) => {
  const { data, loading, refetch, error } = useQuery(GET_WARRANTY_REQUEST_DETAIL, {
    variables: params,
    refetchQueries: [GET_WARRANTY_SERIAL, GET_WARRANTY_REQUEST_DETAIL, GET_WARRANTY_REQUEST_POPUP],
  });

  const newData = convertWarrantyRequestDetail(data?.warrantyRequest?.get);
  return {
    loading,
    data: newData || [],
    totalItemStatus: newData?.totalItemsByStatus,
    refetch,
    error,
  };
};

export const useGetWarrantyRequestPopup = (params) => {
  const { data, loading, refetch, error } = useQuery(GET_WARRANTY_REQUEST_POPUP, {
    variables: params,
    refetchQueries: [GET_WARRANTY_SERIAL, GET_WARRANTY_REQUEST_DETAIL, GET_WARRANTY_REQUEST_POPUP],
  });

  return {
    loading,
    data: data?.warrantyRequest?.get || [],
    refetch,
    error,
  };
};

export const useGetWarrantyRequestCreate = () => {
  const [createWarrantyRequest, { loading, error, data }] = useMutation(WARRANTY_REQUEST_CREATE);

  const handleCreateWarrantyRequest = async (params) => {
    return await createWarrantyRequest({
      variables: params,
    });
  };
  return {
    handleCreateWarrantyRequest,
    loading,
    error,
    data,
  };
};

export const useGetWarrantyRequest = (params) => {
  const { loading, data, refetch } = useQuery(GET_WARRANTY_REQUEST, {
    variables: params,
  });
  return {
    loading,
    data: data?.warrantyRequest?.pagination?.warrantyRequests,
    paginationData: data?.warrantyRequest?.pagination?.paginationData,
    refetch,
  };
};

export const useGetStaff = (params, fetchPolicy) => {
  const { data, loading, refetch, fetchMore } = useQuery(GET_STAFFS, {
    variables: {
      filters: {
        ...params?.filters,
      },
      pagination: params?.pagination,
    },
    fetchPolicy: "cache-first",
  });

  const paginationData = data?.user?.pagination?.paginationData;
  const currentTotal = data?.user?.pagination?.users?.length;
  const staffs = data?.user?.pagination?.users;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: currentTotal, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        const isSamePaginationData = isEqual(
          pv?.user?.pagination?.paginationData,
          fetchMoreResult?.user?.pagination?.paginationData
        );
        if (isSamePaginationData || fetchMoreResult?.user?.pagination?.users.length === 0) return pv;
        const newData = {
          user: {
            pagination: {
              paginationData: { ...fetchMoreResult?.user?.pagination?.paginationData },
              users: [...pv?.user?.pagination?.users, ...fetchMoreResult?.user?.pagination?.users],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotal === paginationData?.total);
  return {
    loading,
    paginationData,
    data: staffs?.map(({ id, fullname, telephone }) => ({
      label: fullname,
      value: id,
      telephone: telephone,
      labelStaff: fullname,
    })),
    total: paginationData?.total,
    refetch,
    fetchMore,
    hasMoreData,
    onLoadMoreData,
    dataOptions: staffs,
  };
};

export const useGetWarrantySerial = (params) => {
  const { data, loading, refetch } = useQuery(GET_WARRANTY_SERIAL, { variables: params });

  return {
    loading,
    data: data?.warrantyRequest?.paginationSerial?.warrantyRequestItems,
    paginationData: data?.warrantyRequest?.paginationSerial?.paginationData,
    refetch,
    totalItems: data?.warrantyRequest?.paginationSerial?.totalItemByStatus,
  };
};

export const useGetSummaryTabs = () => {
  const { data, refetch } = useQuery(GET_SUMMARY_TABS);
  return {
    totalItems: data?.warrantyRequest?.paginationSerial?.totalItemByStatus,
    refetch,
  };
};

export const useGetWarrantyDetail = (params) => {
  const { data, error, loading, refetch } = useQuery(GET_WARRANTY_DETAIL, {
    variables: params,
  });
  return {
    data: convertWarrantyDetail(data?.warranty?.get) || {},
    error,
    loading,
    refetch,
  };
};

export const useGetTotalItemStatus = (params) => {
  const { data, refetch, loading } = useQuery(GET_TOTAL_ITEM_STATUS, {
    variables: params,
  });
  return {
    refetch,
    loading,
    totalItemStatus: data?.warrantyRequest?.totalItemStatus,
  };
};

export const useGetWarrantyLoadMore = (params, fetchPolicy = "no-cache") => {
  const { data, loading, fetchMore, refetch } = useQuery(GET_WARRANTY_LOAD_MORE, {
    variables: params,
    fetchPolicy: fetchPolicy,
  });

  const noteWarranty = data?.warranty?.pagination?.noteWarranty || [];
  const currentTotalNoteWarranty = noteWarranty?.length;
  const paginationData = data?.warranty?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotalNoteWarranty, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.warranty?.pagination?.noteWarranty) return pv;
        const newData = {
          warranty: {
            pagination: {
              paginationData: { ...fetchMoreResult?.warranty?.pagination?.paginationData },
              noteWarranty: [
                ...pv?.warranty?.pagination?.noteWarranty,
                ...fetchMoreResult?.warranty?.pagination?.noteWarranty,
              ],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotalNoteWarranty === paginationData?.total);

  return {
    loading,
    paginationData,
    data: noteWarranty,
    total: paginationData?.total,
    refetch,
    fetchMore,
    hasMoreData,
    onLoadMoreData,
  };
};

export const useGetWarrantyRequestUpdate = () => {
  const [updateWarrantyRequest, { loading, error }] = useMutation(WARRANTY_REQUEST_UPDATE);

  const handleUpdateWarrantyRequest = async (params) => {
    return await updateWarrantyRequest({
      variables: params,
      refetchQueries: ["GetWarrantyRequestDetail", "GetWarrantyRequestPopup"],
    });
  };

  return {
    handleUpdateWarrantyRequest,
    loading,
    error,
  };
};

export const useConfirmCheck = () => {
  const [confirmCheck, { loading, error }] = useMutation(CONFIRM_CHECK);

  const handleConfirmCheck = async (params) => {
    return await confirmCheck({
      variables: params,
      refetchQueries: refetchQueries,
    });
  };

  return {
    handleConfirmCheck,
    loading,
    error,
  };
};

export const useConfirmSolution = () => {
  const [confirmSolution, { loading, error }] = useMutation(CONFIRM_SOLUTION);
  const { handleUpload, loading: loadingUpload } = useUpload();

  const handleConfirmSolution = async (params) => {
    const files = params.solutionRequest.reimportFileURLs;

    const fileURLs = files
      ? await Promise.all(
          files?.map(
            async (item) =>
              await handleUpload({ file: item?.originFileObj }).catch((error) => {
                notify.error({
                  message: t("uploadDocument.uploadImageError"),
                  description: error?.message,
                });
              })
          )
        )
      : [];

    return await confirmSolution({
      variables: {
        ...params,
        solutionRequest: {
          ...params.solutionRequest,
          reimportFileURLs: fileURLs,
        },
      },
      refetchQueries: refetchQueries,
    });
  };

  return {
    handleConfirmSolution,
    loading: loadingUpload || loading,
    error,
  };
};

export const useConfirmDistribute = () => {
  const [confirmDistribute, { loading, error }] = useMutation(CONFIRM_DISTRIBUTE);

  const handleConfirmDistribute = async (params) => {
    return await confirmDistribute({
      variables: params,
      refetchQueries: refetchQueries,
    });
  };

  return {
    handleConfirmDistribute,
    loading,
    error,
  };
};

export const useReceiveResult = () => {
  const [receiveResult, { loading, error }] = useMutation(RECEIVE_RESULT);

  const handleReceiveResult = async (params) => {
    return await receiveResult({
      variables: params,
      refetchQueries: refetchQueries,
    });
  };

  return {
    handleReceiveResult,
    loading,
    error,
  };
};

export const useComplete = () => {
  const [complete, { loading, error }] = useMutation(COMPLETE);

  const handleComplete = async (params) => {
    return await complete({
      variables: params,
      refetchQueries: refetchQueries,
    });
  };

  return {
    handleComplete,
    loading,
    error,
  };
};

export const useGetWarrantyHistory = (params) => {
  const { data, loading, error } = useQuery(GET_WARRANTY_HISTORY, {
    variables: params,
  });

  return {
    data: data?.warranty?.histories,
    loading,
    error,
  };
};

export const useConfirmReturn = () => {
  const [confirmReturn, { loading, error }] = useMutation(CONFIRM_RETURN);

  const handleConfirmReturn = async (params) => {
    return await confirmReturn({
      variables: params,
      refetchQueries: refetchQueries,
    });
  };

  return {
    handleConfirmReturn,
    loading,
    error,
  };
};

export const useSerialDetail = (params) => {
  const { data, loading, error, refetch } = useQuery(GET_SERIAL_DETAIL, {
    variables: params,
  });

  return {
    data: data?.warrantyRequest?.getSerial,
    warrantyNote: convertWarrantyDetail(data?.warrantyRequest?.getSerial?.warrantyNote),
    loading,
    error,
    refetch,
  };
};

export const useExportFile = () => {
  const [exportFile, { loading, error }] = useMutation(EXPORT_FILE);

  const handleExportFile = async (params) => {
    return await exportFile({
      variables: params,
    });
  };

  return {
    handleExportFile,
    loading,
    error,
  };
};

export const useUpdateSerial = () => {
  const [updateSerial, { loading, error }] = useMutation(UPDATE_SERIAL);

  const handleUpdateSerial = async (params) => {
    return await updateSerial({
      variables: params,
      refetchQueries: refetchQueries,
    });
  };

  return {
    handleUpdateSerial,
    loading,
    error,
  };
};

export const useGetWarrantyTablePending = (params) => {
  const filters = params.filters;
  const skip = !filters.serials || filters.serials.length === 0;
  const { loading, data, refetch } = useQuery(GET_WARRANTY_TABLE_PENDING, {
    variables: params,
    skip: skip,
  });

  return {
    loading,
    data: data?.warranty?.pagination?.noteWarranty || [],
    paginationData: data?.warranty?.pagination?.paginationData,
    refetch,
  };
};

export const useReturnWaitingForCheck = () => {
  const [returnWaitingForCheck, { loading }] = useMutation(RETURN_WAITING_FOR_CHECK);

  const handleReturnWaitingForCheck = async (params) => {
    return await returnWaitingForCheck({
      variables: params,
      refetchQueries: refetchQueries,
    });
  };
  return {
    loading,
    handleReturnWaitingForCheck,
  };
};

export const useGetWarrantyExportSerial = () => {
  const [pagination, { loading, refetch }] = useLazyQuery(GET_WARRANTY_TABLE_PENDING);
  const handleGetWarrantyExportSerial = async (params) => {
    return pagination({
      variables: params,
    });
  };
  return {
    loading,
    handleGetWarrantyExportSerial,
    refetch,
  };
};

export const useGetShippingProvider = () => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_SHIPPING_PROVIDER);

  return {
    loading,
    data: data?.shippingProvider?.list,
    refetch,
    fetchMore,
  };
};

export const useGetSerialReplaceLoadMore = ({ filter, pagination }) => {
  const { loading, data, fetchMore } = useQuery(GET_SERIAL_REPLACE, {
    variables: {
      filter,
      pagination,
    },
    fetchPolicy: "cache-first",
  });

  const serials = data?.serial?.pagination?.Serials || [];
  const currentTotalSellers = serials.length;
  const paginationData = data?.seller?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotalSellers, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.serial?.pagination?.Serials) return pv;
        const newData = {
          seller: {
            pagination: {
              paginationData: { ...fetchMoreResult?.serial?.pagination?.paginationData },
              serials: [...pv?.serial?.pagination?.Serials, ...fetchMoreResult?.serial?.pagination?.Serials],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotalSellers === paginationData?.total);

  return {
    loading,
    serials,
    onLoadMoreData,
    hasMoreData,
    data: data?.serial?.pagination?.Serials,
  };
};

import { gql } from '@apollo/client';

export const GET_NOTIFICATION_PAGING = gql`
  query GetNotifyPaging($filters: NotificationsFilter, $pagination: PaginationRequest!) {
    notification {
      pagination(filters: $filters, pagination: $pagination) {
        notification {
          id
          title
          body
          createdAt
          imageURL
          status
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

export const GET_UNREAD_NOTIFICATIONS = gql`
  {
    notification {
      totalUnreadNotifications
    }
  }
`;

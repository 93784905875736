import React, { useState } from 'react';
import { Form } from 'antd';
import { DATE_ISO_8601_FORMAT } from 'config/constants';
import { WARRANTY_PRODUCT_TYPE, WARRANTY_REQUEST_TYPE } from 'features/WarrantyRequest/constant';
import { useGetAllCategories, useGetProducts } from 'hooks/product/product';
import { formatDateTime } from 'utils/dateTime';
import { useUploadDocument } from 'hooks/upload-document';
import { notify } from 'utils/helperFuncs';
import { t } from 'i18next';

function useFormEditNotOwner({ onSubmit }) {
  const [form] = Form.useForm();
  const [switchClose, setSwitchClose] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const { handleUpload } = useUploadDocument();

  const [params, setParams] = useState({
    filters: {
      query: undefined,
    },
    pagination: { offset: 0, limit: 10 },
  });

  const [product, setProduct] = useState();
  const [category, setCategory] = useState();
  const { data: dataAllCategories } = useGetAllCategories();
  const { data, onLoadMoreData, hasMoreData, loading } = useGetProducts(params, 'cache-first');

  const initialValues = {
    serialImei: null,
    productName: null,
    warrantyProductType: WARRANTY_PRODUCT_TYPE.NOT_OWNER,
    warrantyRequestType: WARRANTY_REQUEST_TYPE.BEHALF,
    productID: null,
    categoryID: null,
    promisedReturnDate: null,
    accessoryAttachment: null,
    itemAmount: null,
    customerRequest: null,
    defectDescription: null,
    imageURLs: [],
  };

  const options = data?.map((item) => {
    return {
      value: item.id,
      label: item.code,
      item: item,
    };
  });

  const onSearch = (value) =>
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const handleChange = (value, option) => {
    setProduct(option?.item);
    setParams({
      ...params,
      filters: { ...params.filters, query: undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleSearchTreeSelect = (search, option) => {
    return option.label.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  };

  const handleChangeTreeSelect = (value, option) => {
    setCategory(option.at(0));
  };

  const handleOpen = () => {
    setSwitchClose(true);
  };

  const handleCancel = () => {
    setSwitchClose(false);
  };

  const uploadImage = async (values) => {
    const newImages = values?.filter((item) => !item?.url);
    const oldImageURLs = values?.filter((item) => item?.url)?.map((item) => item?.url);
    const newImageURLs = await handleUpload({
      files: newImages?.map((item) => item?.originFileObj),
    }).catch((error) => {
      notify.error({
        message: t('uploadDocument.uploadImageError'),
        description: error?.message,
      });
    });
    const allImageURLs = [...oldImageURLs, ...newImageURLs?.map(({ url }) => url)];

    return allImageURLs;
  };

  const addProduct = async ({ isClose }) => {
    try {
      const values = await form.validateFields();
      setLoadingAdd(true);
      const imageURLs = await uploadImage(values?.imageURLs);

      const newData = {
        ...values,
        productCode: product?.code,
        categoryName: category,
        warrantyProductType: WARRANTY_PRODUCT_TYPE?.NOT_OWNER,
        promisedReturnDate: formatDateTime(values?.promisedReturnDate, DATE_ISO_8601_FORMAT),
        imageURLs: imageURLs,
      };

      onSubmit(newData);
      setSwitchClose(isClose ? true : false);
      form.resetFields();
    } catch (e) {
      console.info('Validate Failed:', e);
    } finally {
      setLoadingAdd(false);
    }
  };

  return {
    form,
    options,
    loading,
    onLoadMoreData,
    hasMoreData,
    dataAllCategories,
    handleSearchTreeSelect,
    initialValues,
    addProduct,
    switchClose,
    onSearch,
    handleChange,
    handleChangeTreeSelect,
    loadingAdd,
    handleOpen,
    handleCancel,
    product,
    category,
  };
}

export default useFormEditNotOwner;

import React from 'react';
import { Col, Row, Spin, Tooltip, Typography } from 'antd';
import { SelectLoadMore } from 'components/CustomSelect';
import { t } from 'i18next';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import CustomModal from 'components/CustomModal';
import { WarningOutlined } from '@ant-design/icons';
import { checkOptionDuplicate } from 'utils/helperFuncs';

function InfoOrder({
  orders,
  orderInfo,
  handleChangeInfoOrder,
  onSearchOrder,
  hasMoreOrder,
  loadMoreOrder,
  loadingOrders,
  visiblePopupChangeOrder,
  handleClosePopupChangeOrder,
  handleOkPopupChangeOrder,
  orderEdit,
  orderMemory,
}) {
  const combinedArray = (orders || []).concat(orderEdit || []).concat(orderMemory || []);

  const ordersOptions = checkOptionDuplicate(combinedArray)?.map((item) => ({
    value: item?.id,
    label: (
      <Row gutter={[16, 8]} style={{ alignItems: 'center' }}>
        <Col
          span={6}
          className={styles['item']}
          style={{ padding: '0px 0 0 20px', textAlign: 'left' }}
        >
          <Typography.Link strong>{item?.number}</Typography.Link>
        </Col>
        <Col span={10} className={styles['item']} style={{ textAlign: 'left', padding: '5px 0' }}>
          <Tooltip title={item?.seller?.fullName}>
            <Typography.Text ellipsis>{item?.seller?.fullName}</Typography.Text>
          </Tooltip>
        </Col>
        <Col span={5} style={{ textAlign: 'center', padding: '5px 0' }}>
          <Typography.Text>{item?.exportedWarehouse?.name}</Typography.Text>
        </Col>
        <Col span={3} style={{ textAlign: 'center', padding: '5px 0' }}>
          <Typography.Text ellipsis>
            {item?.items?.reduce((acc, currentItem) => {
              return acc + currentItem?.quantity;
            }, 0)}
          </Typography.Text>
        </Col>
      </Row>
    ),
    order: item,
    labelOrder: <Typography.Link strong>{item?.number}</Typography.Link>,
  }));
  return (
    <Row gutter={[16, 8]}>
      <CustomModal
        footer={false}
        width="600px"
        title={<WarningOutlined style={{ color: '#EF9351', fontSize: '50px' }} />}
        okeButtonLabel={t('common.confirm')}
        centered
        onOke={handleOkPopupChangeOrder}
        onCancel={handleClosePopupChangeOrder}
        visible={visiblePopupChangeOrder}
      >
        <div style={{ textAlign: 'center', margin: '0px 0 20px 0' }}>
          {t('warehouse.salesReturn.questionChangeOrder')}
        </div>
      </CustomModal>
      <Col span={24}>
        <Row gutter={[16, 8]}>
          <Col span={4} style={{ paddingTop: '6px' }}>
            <Typography.Text strong>{t('warehouse.salesReturn.codeOrder')}</Typography.Text>
          </Col>
          <Col span={20}>
            <div>
              <SelectLoadMore
                formItemOptions={{
                  className: 'form-seller-select',
                  name: 'orderID',
                  rules: [{ required: true, message: t('warehouse.salesReturn.chooseProduct') }],
                }}
                inputOptions={{
                  placeholder: t('warehouse.salesReturn.searchOrder'),
                  options: ordersOptions,
                  optionLabelProp: 'labelOrder',
                  onSelect: (value, options) => handleChangeInfoOrder(value, options),
                  dropdownMatchSelectWidth: 1000,
                  dropdownRender: (originNode) => (
                    <>
                      <Row
                        gutter={[16, 8]}
                        className={styles['custom-dropdown']}
                        style={{ padding: '0 12px' }}
                      >
                        <Col
                          span={6}
                          className={styles['item']}
                          style={{
                            paddingTop: '10px',
                            textAlign: 'center',
                          }}
                        >
                          <Typography.Text>{t('serialIE.providerInfo.orderCodeB')}</Typography.Text>
                        </Col>
                        <Col
                          span={10}
                          className={styles['item']}
                          style={{ paddingTop: '10px', textAlign: 'center' }}
                        >
                          <Typography.Text>
                            {t('campaign.ranking.columns.nameDealer')}
                          </Typography.Text>
                        </Col>
                        <Col
                          span={5}
                          className={styles['item']}
                          style={{ paddingTop: '10px', textAlign: 'center' }}
                        >
                          <Typography.Text>{t('serialIE.providerInfo.warehouseB')}</Typography.Text>
                        </Col>
                        <Col span={3} className={styles['item']} style={{ textAlign: 'center' }}>
                          <Typography.Text>{t('serialIE.providerInfo.quantity')}</Typography.Text>
                        </Col>
                      </Row>
                      <Spin spinning={loadingOrders}>{originNode}</Spin>
                    </>
                  ),
                }}
                onLoadMore={loadMoreOrder}
                hasMoreData={hasMoreOrder}
                onSearch={onSearchOrder}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="form-info-provider">
        <Row gutter={[16, 8]}>
          <Col span={4}>
            <Typography.Text className="sub-title">
              {t('warehouse.salesReturn.codeSeller')}
            </Typography.Text>
          </Col>
          <Col span={20}>
            <Link to={`/seller/detail/${orderInfo?.seller?.id}`} target="_blank">
              <Typography.Link strong>{orderInfo?.seller?.code || '---'}</Typography.Link>
            </Link>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={4}>
            <Typography.Text className="sub-title">
              {t('warehouse.salesReturn.nameSeller')}
            </Typography.Text>
          </Col>
          <Col span={20}>
            <Typography.Text>{orderInfo?.seller?.fullName || '---'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={4}>
            <Typography.Text className="sub-title">{t('common.address')}</Typography.Text>
          </Col>
          <Col span={20}>
            <Typography.Text>
              {`${orderInfo?.seller?.address || '---'} - ${
                orderInfo?.seller?.ward?.name || '---'
              } - ${orderInfo?.seller?.district?.name || '---'} - ${
                orderInfo?.seller?.city?.name || '---'
              }`}
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={4}>
            <Typography.Text className="sub-title">{t('accountant.warehouse')}</Typography.Text>
          </Col>
          <Col span={20}>
            <Typography.Text>{orderInfo?.exportedWarehouse?.name || '---'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24} style={{ textAlign: 'center', width: '100%' }}>
            {!orderInfo && (
              <Typography.Link strong>
                {t('warehouse.salesReturn.pleaseSearchOrder')}
              </Typography.Link>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default InfoOrder;

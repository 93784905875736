import { t } from 'i18next';
import { Typography } from 'antd';
import { formatDateTime } from 'utils/dateTime';
import { SHIPPING_TYPE } from 'config/constants';
import { useGetInfoPlatform } from 'hooks/info-platform';

export const WarrantyReceiveMethod = {
  BUS: 'BUS', //
  SHIPPING_PROVIDER: 'SHIPPING_PROVIDER', // nhà cung cấp giao hàng
  SHIPPER: 'SHIPPER', // shipper
  CUSTOMER_BRING: 'CUSTOMER_BRING', // khách mang đến
};

export const WarrantyRequestStatus = {
  WAITING_FOR_PROCESS: 'WAITING_FOR_PROCESS', // chờ xử lý
  PROCESSING: 'PROCESSING', // đang xử lý
  COMPLETED: 'COMPLETED', // hoàn thành
  CANCELED: 'CANCELED', // hủy bỏ
};

export const TOTAL_ITEMS_STATUS = {
  ALL: 'ALL',
  WAITING_FOR_CHECK: 'WAITING_FOR_CHECK', // chờ kiểm tra
  CHECKING: 'CHECKING', // kiểm tra
  WAITING_FOR_DISTRIBUTE: 'WAITING_FOR_DISTRIBUTE', // chờ phân phối
  REPAIRING: 'REPAIRING', // sửa chữa
  WAITING_FOR_RETURN: 'WAITING_FOR_RETURN', // chờ phản hồi
  DELIVERING: 'DELIVERING', // đang giao hàng
  COMPLETED: 'COMPLETED', // hoàn thành
  CANCELED: 'CANCELED', // hủy bỏ
};

export const WARRANTY_REQUEST_TYPE = {
  WARRANTY: 'WARRANTY',
  HOUSEHOLD_WARRANTY: 'HOUSEHOLD_WARRANTY',
  SELF: 'SELF',
  BEHALF: 'BEHALF',
};

export const WARRANTY_PRODUCT_TYPE = {
  OWNER: 'OWNER',
  NOT_OWNER: 'NOT_OWNER',
};

export const optionsRequestType = [
  {
    value: WARRANTY_REQUEST_TYPE.SELF,
    label: t('warrantyRequest.detail.requestType.warranty'),
  },
  {
    value: WARRANTY_REQUEST_TYPE.BEHALF,
    label: t('warrantyRequest.detail.requestType.houseoldWarranty'),
  },
];

export const optionsItemStatus = [
  // {
  //   value: TOTAL_ITEMS_STATUS.ALL,
  //   label: t('warrantyRequest.itemsStatus.all'),
  // },
  {
    value: TOTAL_ITEMS_STATUS.WAITING_FOR_CHECK,
    label: t('warrantyRequest.itemsStatus.waitingForCheck'),
  },
  {
    value: TOTAL_ITEMS_STATUS.CHECKING,
    label: t('warrantyRequest.itemsStatus.checking'),
  },
  {
    value: TOTAL_ITEMS_STATUS.WAITING_FOR_DISTRIBUTE,
    label: t('warrantyRequest.itemsStatus.waitinForDistribute'),
  },
  {
    value: TOTAL_ITEMS_STATUS.REPAIRING,
    label: t('warrantyRequest.itemsStatus.repairing'),
  },
  {
    value: TOTAL_ITEMS_STATUS.WAITING_FOR_RETURN,
    label: t('warrantyRequest.itemsStatus.waitingForReturn'),
  },
  {
    value: TOTAL_ITEMS_STATUS.DELIVERING,
    label: t('warrantyRequest.itemsStatus.delivering'),
  },
  {
    value: TOTAL_ITEMS_STATUS.COMPLETED,
    label: t('warrantyRequest.itemsStatus.completed'),
  },
  {
    value: TOTAL_ITEMS_STATUS.CANCELED,
    label: t('warrantyRequest.itemsStatus.canceled'),
  },
];

export const renderItemsStatus = (status) => {
  switch (status) {
    case TOTAL_ITEMS_STATUS.ALL:
      return t('warrantyRequest.itemsStatus.all');
    case TOTAL_ITEMS_STATUS.WAITING_FOR_CHECK:
      return t('warrantyRequest.itemsStatus.waitingForCheck');
    case TOTAL_ITEMS_STATUS.CHECKING:
      return t('warrantyRequest.itemsStatus.checking');
    case TOTAL_ITEMS_STATUS.WAITING_FOR_DISTRIBUTE:
      return t('warrantyRequest.itemsStatus.waitinForDistribute');
    case TOTAL_ITEMS_STATUS.REPAIRING:
      return t('warrantyRequest.itemsStatus.repairing');
    case TOTAL_ITEMS_STATUS.WAITING_FOR_RETURN:
      return t('warrantyRequest.itemsStatus.waitingForReturn');
    case TOTAL_ITEMS_STATUS.DELIVERING:
      return t('warrantyRequest.itemsStatus.delivering');
    case TOTAL_ITEMS_STATUS.COMPLETED:
      return (
        <Typography.Text style={{ color: '#00AB78' }}>
          {t('warrantyRequest.itemsStatus.completed')}
        </Typography.Text>
      );
    case TOTAL_ITEMS_STATUS.CANCELED:
      return (
        <Typography.Text style={{ color: '#D2434D' }}>
          {t('warrantyRequest.itemsStatus.canceled')}
        </Typography.Text>
      );
    default:
      return;
  }
};

export const warrantyRequestStatusLabel = {
  [`${WarrantyRequestStatus.WAITING_FOR_PROCESS}`]: (
    <Typography.Text style={{ color: '#EF9351' }}>
      {t('warranty.warrantyRequestList.waitingForProcess')}
    </Typography.Text>
  ),
  [`${WarrantyRequestStatus.PROCESSING}`]: (
    <Typography.Text style={{ color: '#052987' }}>
      {t('warranty.warrantyRequestList.processing')}
    </Typography.Text>
  ),
  [`${WarrantyRequestStatus.COMPLETED}`]: (
    <Typography.Text style={{ color: '#00AB78' }}>
      {t('warranty.warrantyRequestList.completed')}
    </Typography.Text>
  ),
  [`${WarrantyRequestStatus.CANCELED}`]: (
    <Typography.Text style={{ color: '#D2434D' }}>
      {t('warranty.warrantyRequestList.canceled')}
    </Typography.Text>
  ),
};

export const WRequestSolutionMethod = {
  PROVIDER: 'PROVIDER', //Bảo hành trong nước
  THIRD_PARTY: 'THIRD_PARTY', // Sửa chữa dịch vụ
  SELF: 'SELF', // HA tự bảo hành
  FOREIGN_WARRANTY: 'FOREIGN_WARRANTY', // Bảo hành nước ngoài
};

export const optionsSolution = [
  {
    value: WRequestSolutionMethod.PROVIDER,
    label: t('warrantyRequest.detail.provider'),
  },
  {
    value: WRequestSolutionMethod.FOREIGN_WARRANTY,
    label: t('warrantyRequest.detail.foreignWarranty'),
  },
  {
    value: WRequestSolutionMethod.THIRD_PARTY,
    label: t('warrantyRequest.detail.thirdParty'),
  },
  {
    value: WRequestSolutionMethod.SELF,
    label: t('warrantyRequest.detail.self'),
  },
];

export const solutionLabel = {
  [WRequestSolutionMethod.PROVIDER]: t('warrantyRequest.detail.provider'),
  [WRequestSolutionMethod.FOREIGN_WARRANTY]: t('warrantyRequest.detail.foreignWarranty'),
  [WRequestSolutionMethod.THIRD_PARTY]: t('warrantyRequest.detail.thirdParty'),
  [WRequestSolutionMethod.SELF]: t('warrantyRequest.detail.self'),
  null: <Typography.Text italic>{t('warrantyRequest.detail.notYet')}</Typography.Text>,
};

export const renderSolutionLabel = (solution, platformName) => {
  switch (solution) {
    case WRequestSolutionMethod.PROVIDER:
      return t('warrantyRequest.detail.provider');
    case WRequestSolutionMethod.FOREIGN_WARRANTY:
      return t('warrantyRequest.detail.foreignWarranty');
    case WRequestSolutionMethod.THIRD_PARTY:
      return t('warrantyRequest.detail.thirdParty');
    case WRequestSolutionMethod.SELF:
      return t('warrantyRequest.detail.self', { platformName });
    default:
      return <Typography.Text italic>{t('warrantyRequest.detail.notYet')}</Typography.Text>;
  }
};

export const renderWarrantyRequestType = (type) => {
  switch (type) {
    case WARRANTY_REQUEST_TYPE.SELF:
      return t('warrantyRequest.detail.requestType.warranty');
    case WARRANTY_REQUEST_TYPE.BEHALF:
      return t('warrantyRequest.detail.requestType.houseoldWarranty');
    default:
      return;
  }
};

export const totalItemStatusMethod = {
  all: 'all',
  waitingForCheck: 'waitingForCheck',
  checking: 'checking',
  waitingForDistribute: 'waitingForDistribute',
  repairing: 'repairing',
  waitingForReturn: 'waitingForReturn',
  delivering: 'delivering',
  completed: 'completed',
  canceled: 'canceled',
};

export const REASON_CANCEL = {
  EXPIRED: 'EXPIRED',
  NOT_BELONG_HA: 'NOT_BELONG_HA',
  USER_FAULT: 'USER_FAULT',
  OTHER: null,
};

export const DistributeMethod = {
  PROVIDER: 'PROVIDER',
  THIRD_PARTY: 'THIRD_PARTY',
  TECHNICAL_STAFF: 'TECHNICAL_STAFF',
};

// todo namtc07 check constant !== api
export const ResultMethod = {
  EXCHANGE_SIMILAR_PRODUCT: 'EXCHANGE_SIMILAR_PRODUCT',
  RENEW_SAME_PRODUCT: 'RENEW_SAME_PRODUCT',
  DONE: 'DONE',
  CANCEL: 'CANCEL',
  REIMPORT: 'REIMPORT',
};

export const ResultOptions = [
  {
    label: t('warrantyRequest.popup.changeSameType'),
    value: ResultMethod.EXCHANGE_SIMILAR_PRODUCT,
  },
  {
    label: t('warrantyRequest.popup.changeEquivalent'),
    value: ResultMethod.RENEW_SAME_PRODUCT,
  },
  {
    label: t('warrantyRequest.popup.warrantyDone'),
    value: ResultMethod.DONE,
  },
  {
    label: t('warrantyRequest.popup.warrantyCancel'),
    value: ResultMethod.CANCEL,
  },
  {
    label: t('warrantyRequest.popup.retype'),
    value: ResultMethod.REIMPORT,
  },
];

export const resultLabel = {
  [ResultMethod.EXCHANGE_SIMILAR_PRODUCT]: t('warrantyRequest.popup.changeSameType'),
  [ResultMethod.RENEW_SAME_PRODUCT]: t('warrantyRequest.popup.changeEquivalent'),
  [ResultMethod.DONE]: t('warrantyRequest.popup.warrantyDone'),
  [ResultMethod.CANCEL]: t('warrantyRequest.popup.warrantyCancel'),
  [ResultMethod.REIMPORT]: t('warrantyRequest.popup.retype'),
  null: <Typography.Text italic>{t('warrantyRequest.detail.notYet')}</Typography.Text>,
};

export const titlePopupSolution = {
  [WRequestSolutionMethod.PROVIDER]: t('warrantyRequest.popup.provider'),
  [WRequestSolutionMethod.THIRD_PARTY]: t('warrantyRequest.popup.thirdRepair'),
  [WRequestSolutionMethod.SELF]: t('warrantyRequest.popup.technicalStaff'),
};

export const warrantyResultLabel = {
  [ResultMethod.EXCHANGE_SIMILAR_PRODUCT]: t('warrantyRequest.popup.changeSameType'),
  [ResultMethod.RENEW_SAME_PRODUCT]: t('warrantyRequest.popup.changeEquivalent'),
  [ResultMethod.DONE]: t('warrantyRequest.popup.warrantyDone'),
  [ResultMethod.CANCEL]: t('warrantyRequest.popup.warrantyCancel'),
  [ResultMethod.REIMPORT]: t('warrantyRequest.popup.retype'),
  undefined: '--',
};

export const renderPercentBar = (status, canceled, completed, process, waitingForProcessing) => {
  const totalPercent = [
    { percent: `${process}%`, color: '#EF9351' },
    { percent: `${waitingForProcessing}%`, color: '#052987' },
    { percent: `${completed}%`, color: '#00AB78' },
    { percent: `${canceled}%`, color: '#D2434D' },
  ];

  switch (status) {
    case status?.waitingForCheck >= 1:
      return totalPercent;
    case status?.waitingForCheck === 0:
    case status?.checking >= 1:
    case status?.waitingForDistribute >= 1:
    case status?.repairing >= 1:
    case status?.waitingForReturn >= 1:
    case status?.delivering >= 1:
      return totalPercent;
    case status?.completed > status?.canceled:
    case status?.completed === status?.all:
      return totalPercent;
    case status?.canceled > status?.completed:
    case status?.canceled === status?.all:
      return totalPercent;
    default:
      return totalPercent;
  }
};

export const STEPS_STATE = {
  PAST: 'PAST',
  CURRENT: 'CURRENT',
  FUTURE: 'FUTURE',
};

export const STEPS_STATE_VALUE = {
  [`${STEPS_STATE.PAST}`]: 'finish',
  [`${STEPS_STATE.CURRENT}`]: 'process',
  [`${STEPS_STATE.FUTURE}`]: 'wait',
  null: undefined,
};

export const convertTimeDate = (value, format) => {
  if (value !== null) {
    return formatDateTime(value, format);
  } else {
    return;
  }
};

export const checkingFullEvents = (value) => {
  if (value === [null]) {
    return null;
  } else {
    return value?.fullEvents;
  }
};

export const SERIAL_DETAIL_TABS = {
  REQUEST_INFO: 'REQUEST_INFO',
  DEMAND: 'DEMAND',
  SOLUTION: 'SOLUTION',
  RESULTS: 'RESULTS',
};

export const NOTE_TYPE = {
  RECEIVE_NOTE: 'RECEIVE_NOTE',
  RETURN_NOTE: 'RETURN_NOTE',
};

export const EXPORT_WARRANTY_TYPE = {
  PDF: 'PDF',
  XLSX: 'XLSX',
};

export const isEditIcon = (status) => {
  switch (status) {
    case TOTAL_ITEMS_STATUS.DELIVERING:
    case TOTAL_ITEMS_STATUS.COMPLETED:
    case TOTAL_ITEMS_STATUS.CANCELED:
      return false;
    default:
      return true;
  }
};

export const checkIcon = (isStatus) => {
  if (isStatus === true) {
    return false;
  } else {
    return true;
  }
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Input, Radio, InputNumber, Space } from 'antd';
import { Icon } from 'assets/icons';
import './index.scss';
import useComboCheckbox from 'hooks/useComboCheckbox';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { REGEX } from 'config/constants';
import FilterMenu from 'components/CustomFilterMenu/FilterMenu';

const MIN_PRICE = 0;
const MAX_PRICE = 999999999;
const MIN_STOCK = 0;
const MIN_VAT_PERCENT = 0;
const MAX_VAT_PERCENT = 100;
const MENU_LAYOUT = {
  width: '300px',
};

const FilterManufacture = ({ onFilter, dataManufactures, defaultValue }) => {
  const [manufacturers, setManufacturers] = useState([...dataManufactures]);
  const { t } = useTranslation();

  const manufacturersCheckBox = useComboCheckbox(manufacturers, defaultValue);

  function resetFilter() {
    manufacturersCheckBox.onResetFilter();
  }

  function applyFilter() {
    onFilter({ manufacturerIDs: manufacturersCheckBox.checkedList });
  }

  const handleSearchManufacturers = (text) =>
    setManufacturers(
      dataManufactures.filter((item) => item.label.toLowerCase().includes(text.toLowerCase()))
    );

  return (
    <menu className="menu" style={MENU_LAYOUT}>
      <div className="menu-header">
        <p className="title">{t('common.filter')}</p>
        <p className="setting">
          <span onClick={resetFilter}>{t('common.reset')}</span>&nbsp;&nbsp;
          <span className="apply" onClick={applyFilter}>
            {t('common.apply')}
          </span>
        </p>
      </div>
      <div className="panel-content">
        <div className="search-input">
          <Input
            className="custom-input"
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={t('product.placeholder.searchFilter')}
            onChange={(e) => handleSearchManufacturers(e.target.value)}
            allowClear={true}
          />
        </div>
        <div className="options">
          <Checkbox
            className="check-box"
            indeterminate={manufacturersCheckBox.indeterminate}
            checked={manufacturersCheckBox.checkAll}
            onChange={manufacturersCheckBox.onCheckAllChange}
          >
            {t('common.all')}
          </Checkbox>
          <Checkbox.Group
            className="check-box-group"
            options={manufacturersCheckBox.options}
            value={manufacturersCheckBox.checkedList}
            onChange={manufacturersCheckBox.onChange}
          />
        </div>
      </div>
    </menu>
  );
};

const FilterLevelPrice = ({ onFilter, defaultValue }) => {
  let defaultValueString = JSON.stringify(defaultValue);

  const [isSpecified, setIsSpecified] = useState(defaultValue?.isSpecified === undefined);
  const [rangeFrom, setRangeFrom] = useState(defaultValue?.range?.from || '');
  const [rangeTo, setRangeTo] = useState(defaultValue?.range?.to || '');
  const { t } = useTranslation();

  const priceRadio = [
    { value: undefined, label: t('common.all') },
    { value: false, label: t('product.priceRadio.haveNotPrice') },
    { value: true, label: t('product.priceRadio.havePrice') },
  ];

  function resetFilter() {
    setIsSpecified(undefined);
    setRangeFrom('');
    setRangeTo('');
  }

  const handleChangeRange = (inputValue, setRange) => {
    if (inputValue > 0) {
      setRange(inputValue);
    } else {
      setRange('');
    }
  };

  function handleSetRange(from, to) {
    if (!from && to) return { to: +to };
    if (from && !to) return { from: +from };
    if (from && to) return { from: +from, to: +to };
    return {};
  }

  function applyFilter() {
    onFilter({
      isSpecified: isSpecified,
      range: handleSetRange(rangeFrom, rangeTo),
    });
  }

  function isDisabledInput(isSpecified) {
    return isSpecified === true || isSpecified === null ? false : true;
  }

  useEffect(() => {
    if (isDisabledInput(isSpecified)) {
      setRangeFrom('');
      setRangeTo('');
    }
  }, [isSpecified]);

  useEffect(() => {
    setIsSpecified(defaultValue?.isSpecified);
    setRangeFrom(defaultValue?.range?.from || '');
    setRangeTo(defaultValue?.range?.to || '');
  }, [defaultValueString]);

  return (
    <menu className="menu" style={MENU_LAYOUT}>
      <div className="menu-header">
        <p className="title">{t('common.filter')}</p>
        <p className="setting">
          <span onClick={resetFilter}>{t('common.reset')}</span>&nbsp;&nbsp;
          <span className="apply" onClick={applyFilter}>
            {t('common.apply')}
          </span>
        </p>
      </div>
      <div className="panel-content">
        <Radio.Group
          className="radio-group"
          onChange={(e) => setIsSpecified(e.target.value)}
          value={isSpecified}
        >
          {priceRadio.map(({ value, label }, index) => (
            <Radio value={value} key={index}>
              {label}
            </Radio>
          ))}
        </Radio.Group>
        <div className="input-range">
          {t('product.inputRange.from')}&nbsp;&nbsp;
          <InputNumber
            className="input-range-number"
            controls={false}
            value={rangeFrom}
            min={MIN_PRICE}
            max={MAX_PRICE}
            disabled={isDisabledInput(isSpecified)}
            onChange={(value) => handleChangeRange(value, setRangeFrom)}
            formatter={(value) => value.replace(REGEX.CURRENCY, '.')}
            parser={(value) => value.replace(REGEX.CURRENCY_PARSER, '')}
          />
          &nbsp;-&nbsp;
          <InputNumber
            className="input-range-number"
            controls={false}
            value={rangeTo}
            min={MIN_PRICE}
            max={MAX_PRICE}
            disabled={isDisabledInput(isSpecified)}
            onChange={(value) => handleChangeRange(value, setRangeTo)}
            formatter={(value) => value.replace(REGEX.CURRENCY, '.')}
            parser={(value) => value.replace(REGEX.CURRENCY_PARSER, '')}
          />
        </div>
      </div>
    </menu>
  );
};

const FilterRealStocks = ({ onFilter, warehouseID, defaultValue }) => {
  let defaultValueString = JSON.stringify(defaultValue);

  const defaultValueRangeByWarehouseID = () => {
    if (defaultValue?.warehouseID === warehouseID) {
      return { from: defaultValue?.range?.from, to: defaultValue?.range?.to };
    } else {
      return undefined;
    }
  };

  const [rangeFrom, setRangeFrom] = useState(defaultValueRangeByWarehouseID()?.from || null);
  const [rangeTo, setRangeTo] = useState(defaultValueRangeByWarehouseID()?.to || null);
  const { t } = useTranslation();
  function resetFilter() {
    setRangeFrom(null);
    setRangeTo(null);
  }

  const handleChangeRange = (inputValue, setRange) => {
    if (inputValue > 0) {
      setRange(inputValue);
    } else {
      setRange(null);
    }
  };

  function handleSetRange(from, to) {
    if (!from && to) return { to: +to };
    if (from && !to) return { from: +from };
    if (from && to) return { from: +from, to: +to };
    return;
  }

  function applyFilter() {
    if (rangeFrom === null && rangeTo === null) {
      onFilter({
        warehouseID: warehouseID,
      });
    } else {
      onFilter({
        warehouseID: warehouseID,
        range: handleSetRange(rangeFrom, rangeTo),
      });
    }
  }

  useEffect(() => {
    setRangeFrom(defaultValueRangeByWarehouseID()?.from);
    setRangeTo(defaultValueRangeByWarehouseID()?.to);
  }, [defaultValueString]);

  return (
    <menu className="menu" style={MENU_LAYOUT}>
      <div className="menu-header">
        <p className="title">{t('common.filter')}</p>
        <p className="setting">
          <span onClick={resetFilter}>{t('common.reset')}</span>&nbsp;&nbsp;
          <span className="apply" onClick={applyFilter}>
            {t('common.apply')}
          </span>
        </p>
      </div>
      <div className="panel-content">
        <form className="input-range">
          {t('product.inputRange.from')}&nbsp;&nbsp;
          <InputNumber
            className="input-range-number"
            controls={false}
            value={rangeFrom}
            onChange={(value) => handleChangeRange(value, setRangeFrom)}
            min={MIN_STOCK}
          />
          &nbsp;-&nbsp;
          <InputNumber
            className="input-range-number"
            controls={false}
            value={rangeTo}
            onChange={(value) => handleChangeRange(value, setRangeTo)}
            min={MIN_STOCK}
          />
        </form>
      </div>
    </menu>
  );
};

const FilterVAT = ({ onFilter, defaultValue }) => {
  const { t } = useTranslation();

  const defaultVatValue = (value) => {
    switch (value) {
      default:
        return undefined;
      case null:
        return [true, false];
      case true:
        return [true];
      case false:
        return [false];
    }
  };

  const vatCheckbox = useComboCheckbox(
    [
      { label: t('product.vatCheckbox.yes'), value: true },
      { label: t('product.vatCheckbox.no'), value: false },
    ],
    defaultVatValue(defaultValue)
  );

  function resetFilter() {
    vatCheckbox.onResetFilter();
  }

  function applyFilter() {
    onFilter({
      vat: vatCheckbox.checkedList?.length === 2 ? null : vatCheckbox.checkedList?.at(0),
    });
  }

  return (
    <menu className="menu" style={MENU_LAYOUT}>
      <div className="menu-header">
        <p className="title">{t('common.filter')}</p>
        <p className="setting">
          <span onClick={resetFilter}>{t('common.reset')}</span>&nbsp;&nbsp;
          <span className="apply" onClick={applyFilter}>
            {t('common.apply')}
          </span>
        </p>
      </div>
      <div className="panel-content">
        <Checkbox
          className="check-box"
          indeterminate={vatCheckbox.indeterminate}
          checked={vatCheckbox.checkAll}
          onChange={vatCheckbox.onCheckAllChange}
        >
          {t('common.all')}
        </Checkbox>
        <Checkbox.Group
          className="check-box-group"
          options={vatCheckbox.options}
          value={vatCheckbox.checkedList}
          onChange={vatCheckbox.onChange}
        />
      </div>
    </menu>
  );
};

const FilterVatPercent = ({ onFilter, defaultValue }) => {
  let defaultValueString = JSON.stringify(defaultValue);
  const [rangeFrom, setRangeFrom] = useState(defaultValue?.from || null);
  const [rangeTo, setRangeTo] = useState(defaultValue?.to || null);
  const { t } = useTranslation();
  function resetFilter() {
    setRangeFrom(null);
    setRangeTo(null);
  }

  const handleChangeRange = (inputValue, setRange) => {
    if (inputValue >= 0) {
      setRange(inputValue);
    } else {
      setRange(null);
    }
  };

  function handleSetRange(from, to) {
    if (typeof from !== 'number' && to) return { to: +to };
    if (from && typeof to !== 'number') return { from: +from };
    if (typeof from === 'number' && typeof to === 'number') return { from: +from, to: +to };
    return;
  }

  function applyFilter() {
    onFilter({
      vatPercent: handleSetRange(rangeFrom, rangeTo),
    });
  }

  useEffect(() => {
    setRangeFrom(defaultValue?.from);
    setRangeTo(defaultValue?.to);
  }, [defaultValueString]);

  return (
    <menu className="menu" style={MENU_LAYOUT}>
      <div className="menu-header">
        <p className="title">{t('common.filter')}</p>
        <p className="setting">
          <span onClick={resetFilter}>{t('common.reset')}</span>&nbsp;&nbsp;
          <span className="apply" onClick={applyFilter}>
            {t('common.apply')}
          </span>
        </p>
      </div>
      <div className="panel-content">
        <form className="input-range">
          {t('product.inputRange.from')}&nbsp;&nbsp;
          <InputNumber
            className="input-range-number"
            controls={false}
            value={rangeFrom}
            min={MIN_VAT_PERCENT}
            max={MAX_VAT_PERCENT}
            onChange={(value) => handleChangeRange(value, setRangeFrom)}
          />
          &nbsp;-&nbsp;
          <InputNumber
            className="input-range-number"
            controls={false}
            value={rangeTo}
            min={MIN_VAT_PERCENT}
            max={MAX_VAT_PERCENT}
            onChange={(value) => handleChangeRange(value, setRangeTo)}
          />
        </form>
      </div>
    </menu>
  );
};

const FilterAttributes = ({ onFilter, attributeId, attributeList = [], defaultValue }) => {
  const handleFilter = (checkedList) => {
    onFilter({ attributeID: attributeId, valueIDs: checkedList });
  };

  const convertedOptions = attributeList?.map((item) => ({ label: item.value, value: item.id }));

  return (
    <FilterMenu
      options={convertedOptions}
      type="checkbox"
      onFilter={handleFilter}
      defaultValue={defaultValue}
      searchable
    />
  );
};

export {
  FilterManufacture,
  FilterLevelPrice,
  FilterRealStocks,
  FilterVAT,
  FilterVatPercent,
  FilterAttributes,
};

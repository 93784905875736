import React from 'react';
import { useSerialDetail } from 'hooks/warranty';
import { useParams } from 'react-router-dom';

function useDetail() {
  const { id } = useParams();

  const { data, loading, refetch, warrantyNote } = useSerialDetail({ id: id });
  return { loading, refetch, data, warrantyNote };
}

export default useDetail;

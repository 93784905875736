import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useGetDetailSellerCustomer, useUpdateSellerGroup } from 'hooks/seller/index';
import { Form } from 'antd';
import { notify } from 'utils/helperFuncs';
import InfoDetail from './CustomerInfo';
import AgentList from './AgentList';
import { CUSTOMER_DETAIL_TABS } from '../constant';

function useCustomerDetail() {
  const [activeTabKey, setActiveTabKey] = useState(CUSTOMER_DETAIL_TABS.CUSTOMER_INFO);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [switchClose, setSwitchClose] = useState(true);
  const history = useHistory();
  const {
    data: customerInfo,
    loading: loadingData,
    refetch,
  } = useGetDetailSellerCustomer({ id: id });
  const { handleUpdateSellerGroup, loading } = useUpdateSellerGroup();

  const tabs = [
    {
      title: <span className="tab-title">{t('customer.detail.infoCustomer')}</span>,
      key: CUSTOMER_DETAIL_TABS.CUSTOMER_INFO,
      children: <InfoDetail manager={customerInfo?.manager} form={form} />,
    },
    {
      title: <div className="tab-title">{t('customer.detail.listAgent')}</div>,
      key: CUSTOMER_DETAIL_TABS.AGENT_LIST,
      children: <AgentList id={customerInfo?.id} />,
    },
  ];
  const handleChangeTab = (activeKey) => {
    setActiveTabKey(activeKey);
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    const { code, name, managerID, description, isActive } = values;
    const payload = {
      sellerGroupInfo: {
        code,
        name,
        managerID:
          customerInfo?.manager?.fullname === values?.managerID
            ? customerInfo?.manager?.id
            : values?.managerID,
        description,
        isActive,
      },
    };

    await form
      .validateFields()
      .then(async (values) => {
        try {
          await handleUpdateSellerGroup({
            id: id,
            ...payload,
          });
          notify.success({
            message: t('customer.updateSuccess'),
          });
          setSwitchClose(!switchClose);
          history.push('/seller-group');
          refetch();
        } catch (err) {
          notify.error({
            message: err?.message,
          });
        }
      })
      .catch(() => setActiveTabKey(CUSTOMER_DETAIL_TABS.CUSTOMER_INFO));
  };

  useEffect(() => {
    form.setFieldsValue({
      ...customerInfo,
      managerID: customerInfo?.manager?.id ? customerInfo?.manager?.fullname : undefined,
    });
  }, [loadingData]);

  return {
    tabs,
    activeTabKey,
    handleChangeTab,
    customerInfo,
    handleSave,
    loading,
    refetch,
    form,
    id,
  };
}

export default useCustomerDetail;

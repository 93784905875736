import React from 'react';
import styles from './index.module.scss';

const NotiCard = ({ status, children }) => {
  const STATUS = {
    UNREAD: 'active',
    READ: '',
  };

  return <div className={`${styles['noti-card']} ${styles[STATUS[`${status}`]]}`}>{children}</div>;
};

export default NotiCard;

import React from 'react';

function useTable() {
  const renderLack = ({ quantity, scanned }) => {
    if (scanned?.length) {
      return quantity - scanned?.length;
    } else {
      return quantity || 0;
    }
  };

  return { renderLack };
}

export default useTable;

import React from 'react';
import { Typography } from 'antd';
import FormInput from 'components/FormInput';
import { INPUT_TYPE } from 'config/constants';
import { useGetAllCategories } from 'hooks/product/product';
import { t } from 'i18next';

function SelectCategory() {
  const { data: dataAllCategories } = useGetAllCategories();
  const handleSearchTreeSelect = (search, option) => {
    return option.label.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  };

  return (
    <FormInput
      type={INPUT_TYPE.TREE_SELECT}
      formItemOptions={{
        placeholder: t('warrantyRequest.create.placeHolder.attributeLV3'),
        label: <Typography.Text strong>{t('warrantyRequest.popup.category')}</Typography.Text>,
        name: 'alternativeProductCategoryID',
      }}
      inputOptions={{
        placeholder: t('warrantyRequest.create.placeHolder.attributeLV3'),
        treeData: dataAllCategories,
        showSearch: true,
        disabled: true,
        filterTreeNode: (search, item) => handleSearchTreeSelect(search, item),
      }}
    />
  );
}

export default SelectCategory;

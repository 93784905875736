import { gql } from '@apollo/client';

export const ATTRIBUTE_LIST = gql`
  query AttributeList($filters: ListAttributeRequest) {
    attribute {
      list(req: $filters) {
        id
        name
        attributeType
        isActive
        isCommon
        totalUsedCategory
        values {
          id
          value
        }
      }
    }
  }
`;

export const GET_ATTRIBUTE = gql`
  query GetAttribute($id: ID!) {
    attribute {
      get(id: $id) {
        id
        name
        attributeType
        isActive
        isCommon
        totalUsedCategory
        values {
          id
          value
        }
      }
    }
  }
`;

export const PROD_ATTRIBUTE = gql`
  query GetProdAttribute($categoryIDs: [ID!], $attributeType: AttributeType) {
    attribute {
      list(req: { categoryIDs: $categoryIDs, attributeType: $attributeType }) {
        id
        name
        values {
          id
          value
        }
      }
    }
  }
`;

import { useMutation, useQuery } from '@apollo/client';
import { USER_PERMISSIONS } from 'config/constants';
import {
  CONFIRM_CANCEL_SALES_RETURN,
  CONFIRM_COMPLETE_SALES_RETURN,
  CONFIRM_REJECT_IMPORT_REQUEST,
  MANAGER_APPROVE_SALES_RETURN,
  MANAGER_REJECT_SALES_RETURN,
  TECHNICAL_APPROVE_SALES_RETURN,
  TECHNICAL_REJECT_SALES_RETURN,
  UPDATE_STATUS_IMPORT_REQUEST,
} from 'graphql/recommendimport/mutation';
import {
  COUNT_STATUS_REQUET_IMPORT,
  GET_DETAIL_IMPORT_REQUEST,
  GET_LIST_IMPORT_RECOMMMEND,
} from 'graphql/recommendimport/query';
import { useGetUserPermissions } from 'hooks/user/user';
import { hasPermission } from 'utils/helperFuncs';

export const useGetListRecommendImport = (params) => {
  const { data, loading, error } = useQuery(GET_LIST_IMPORT_RECOMMMEND, {
    variables: params,
  });

  return {
    data: data?.importRequests?.pagination?.importRequests,
    paginationData: data?.importRequests?.pagination?.paginationData,
    loading,
    error,
  };
};

export const useConfirmRejectImportRequest = () => {
  const [confirmReject, { loading }] = useMutation(CONFIRM_REJECT_IMPORT_REQUEST);

  const handleConfirmRejectImportRequest = async ({ id, request }) => {
    return await confirmReject({
      variables: {
        id,
        request,
      },
      refetchQueries: [
        'GetListImportRequests',
        'CountStatusRequestImport',
        'GetDetailImportRequest',
      ],
    });
  };
  return {
    handleConfirmRejectImportRequest,
    loading,
  };
};

export const useUpdateStatusImportRequest = () => {
  const [updateStatus, { loading }] = useMutation(UPDATE_STATUS_IMPORT_REQUEST);

  const handleUpdateStatusImportRequest = async ({ id, actionType }) => {
    return await updateStatus({
      variables: {
        id,
        actionType,
      },
      refetchQueries: [
        'GetListImportRequests',
        'CountStatusRequestImport',
        'GetDetailImportRequest',
      ],
    });
  };
  return {
    handleUpdateStatusImportRequest,
    loading,
  };
};

export const useGetCountStatusRequestImport = (params) => {
  const { data, loading, error } = useQuery(COUNT_STATUS_REQUET_IMPORT, {
    variables: params,
  });

  return {
    data: data?.importRequests?.count,
    loading,
    error,
  };
};

export const useImportRequestPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();
  return {
    canImportRequestView: hasPermission(permissions, [USER_PERMISSIONS.IMPORT_REQUEST_VIEW]),
    canImportRequestCreate: hasPermission(permissions, [USER_PERMISSIONS.IMPORT_REQUEST_CREATE]),
    canImportRequestUpdate: hasPermission(permissions, [USER_PERMISSIONS.IMPORT_REQUEST_UPDATE]),
    canImportRequestApprove: hasPermission(permissions, [USER_PERMISSIONS.IMPORT_REQUEST_APPROVE]),
    canImportRequestReject: hasPermission(permissions, [USER_PERMISSIONS.IMPORT_REQUEST_REJECT]),
    canImportRequestCancel: hasPermission(permissions, [USER_PERMISSIONS.IMPORT_REQUEST_CANCEL]),
    canImportRequestComplete: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_REQUEST_COMPLETE,
    ]),
    canImportRequestViewCreatedByMe: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_REQUEST_VIEW_CREATED_BY_ME,
    ]),

    loading,
  };
};

export const useGetDetailImportRequest = (params) => {
  const { data, loading, error, refetch } = useQuery(GET_DETAIL_IMPORT_REQUEST, {
    variables: params,
  });

  return {
    data: data?.importRequests?.get,
    loading,
    error,
    refetch,
  };
};

export const useConfirmCancelSalesReturn = () => {
  const [confirmCancel, { loading }] = useMutation(CONFIRM_CANCEL_SALES_RETURN);

  const handleConfirmCancelSalesReturn = async ({ id }) => {
    return await confirmCancel({
      variables: {
        id,
      },
      refetchQueries: [
        'GetListImportRequests',
        'CountStatusRequestImport',
        'GetDetailImportRequest',
      ],
    });
  };
  return {
    handleConfirmCancelSalesReturn,
    loading,
  };
};

export const useManagerApproveSalesReturn = () => {
  const [managerApprove, { loading }] = useMutation(MANAGER_APPROVE_SALES_RETURN);

  const handleManagerApproveSalesReturn = async ({ id }) => {
    return await managerApprove({
      variables: {
        id,
      },
      refetchQueries: [
        'GetListImportRequests',
        'CountStatusRequestImport',
        'GetDetailImportRequest',
      ],
    });
  };
  return {
    handleManagerApproveSalesReturn,
    loading,
  };
};

export const useTechnicalApproveSalesReturn = () => {
  const [technicalApprove, { loading }] = useMutation(TECHNICAL_APPROVE_SALES_RETURN);

  const handleTechnicalApproveSalesReturn = async ({ id }) => {
    return await technicalApprove({
      variables: {
        id,
      },
      refetchQueries: [
        'GetListImportRequests',
        'CountStatusRequestImport',
        'GetDetailImportRequest',
      ],
    });
  };
  return {
    handleTechnicalApproveSalesReturn,
    loading,
  };
};

export const useManagerRejectSalesReturn = () => {
  const [managerReject, { loading }] = useMutation(MANAGER_REJECT_SALES_RETURN);

  const handleManagerRejectSalesReturn = async (params) => {
    return await managerReject({
      variables: params,
      refetchQueries: [
        'GetListImportRequests',
        'CountStatusRequestImport',
        'GetDetailImportRequest',
      ],
    });
  };
  return {
    handleManagerRejectSalesReturn,
    loading,
  };
};

export const useTechnicalRejectSalesReturn = () => {
  const [technicalReject, { loading }] = useMutation(TECHNICAL_REJECT_SALES_RETURN);

  const handleTechnicalRejectSalesReturn = async (params) => {
    return await technicalReject({
      variables: params,
      refetchQueries: [
        'GetListImportRequests',
        'CountStatusRequestImport',
        'GetDetailImportRequest',
      ],
    });
  };
  return {
    handleTechnicalRejectSalesReturn,
    loading,
  };
};

export const useSalesReturnPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();
  return {
    canImportSalesReuturnView: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_VIEW,
    ]),
    canImportSalesReuturnCreate: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_CREATE,
    ]),
    canImportSalesReturnUpdate: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_UPDATE,
    ]),
    canImportSalesReturnManagerApprove: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_MANAGER_APPROVE,
    ]),
    canImportSalesReturnTechnicalApprove: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_TECHNICAL_APPROVE,
    ]),
    canImportSalesReturnApproveAfterCheckingQuanlity: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_APPROVE_AFTER_CHECKING_QUALITY,
    ]),
    canImportSalesReturnManagerReject: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_MANAGER_REJECT,
    ]),
    canImportSalesReturnTechnicalReject: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_TECHNICAL_REJECT,
    ]),
    canImportSalesReturnComplete: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_COMPLETE,
    ]),
    canImportSalesReturnBackToEntry: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_BACK_TO_ENTRY,
    ]),
    canImportSalesReturnCancel: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_CANCEL,
    ]),
    canImportSalesReturnViewCreatesByMe: hasPermission(permissions, [
      USER_PERMISSIONS.IMPORT_SALES_RETURN_VIEW_CREATED_BY_ME,
    ]),
    loading,
  };
};


export const useConfirmCompleteSalesReturn = () => {
  const [confirmComplete, { loading }] = useMutation(CONFIRM_COMPLETE_SALES_RETURN);

  const handleConfirmCompleteSalesReturn = async (params) => {
    return await confirmComplete({
      variables: params,
      refetchQueries: ["GetListImportRequests", "CountStatusRequestImport", "GetDetailImportRequest"],
    });
  };
  return {
    handleConfirmCompleteSalesReturn,
    loading,
  };
};

import React from 'react';
import FormProduct from '../components/FormProduct';

// import FormProduct from '../components/FormProduct';
import { ACTION } from '../conts';

const ProductGroupCreate = () => {
  return <FormProduct action={ACTION.CREATE} />;
};

export default ProductGroupCreate;

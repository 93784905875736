import React from 'react';
import { Input, List, Pagination, Tag } from 'antd';
import { FontAwesomeIcon, Icon, SvgIcon } from 'assets/icons';
import CustomModal from 'components/CustomModal';
import { t } from 'i18next';
import useModalChangeAddress from './useModalChangeAddress';

import './index.scss';

const ModalChangeAddress = ({
  title,
  list,
  id,
  onSubmit,
  loadingUpdateCart,
  addMoreButton,
  paginationData,
  params,
  onTableChange,
  loadingContactList,
  isReceiver,
  handleChangeInput,
}) => {
  const { selected, handleClick, handleCancel } = useModalChangeAddress({ id, list });

  return (
    <CustomModal
      title={<b>{title}</b>}
      customComponent={<SvgIcon.EditCartIcon />}
      onCancel={handleCancel}
      onOke={() => onSubmit(selected)}
      footer={false}
      centered={true}
      buttonLoading={loadingUpdateCart}
      addMoreButton={addMoreButton}
    >
      <div style={{ margin: '16px 0 ', width: '100%' }}>
        <Input
          onChange={(e) => handleChangeInput(e.target.value)}
          placeholder={t('cart.searchContactAll')}
          prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
        />
      </div>
      <List
        className="list"
        dataSource={list}
        loading={loadingContactList}
        renderItem={(item, index) => (
          <List.Item key={index} onClick={() => handleClick(item?.id)}>
            <div className={`item-info-shipping ${item?.id === selected && 'selected'}`}>
              <div className="info-user">
                <div>
                  <b>{item?.fullName}</b> | {item?.telephone}
                </div>
                {item?.isDefault && <Tag>{t('cart.changeAddress.default')}</Tag>}
              </div>
              <p className="address">{item?.address}</p>
            </div>
          </List.Item>
        )}
      ></List>
      {isReceiver && (
        <Pagination
          style={{ margin: '16px 0', float: 'right' }}
          showSizeChanger={false}
          total={paginationData?.total}
          pageSize={params?.pagination?.limit}
          current={params?.pagination?.offset / params?.pagination?.limit + 1 || 1}
          onChange={(current, pageSize) => onTableChange({ current, pageSize })}
          size={'small'}
        />
      )}
    </CustomModal>
  );
};

export default ModalChangeAddress;

import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { ResultMethod, warrantyResultLabel } from 'features/WarrantyRequest/constant';
import { t } from 'i18next';
import { DATE_FORMAT } from 'config/constants';
import { convertTimeDate } from 'utils/dateTime';
import { formatCurrency } from 'utils/helperFuncs';
import CustomViewDocumentFiles from 'components/CustomViewDocumentFiles';
import { useGetInfoPlatform } from 'hooks/info-platform';

const RenderUIbyResult = ({ data }) => {
  const { platformName } = useGetInfoPlatform();

  const fieldShared = (
    <>
      <Col span={7}>
        <Typography.Text strong>{t('warrantyRequest.popup.serial')}</Typography.Text>
      </Col>
      <Col span={17}>
        <Typography>{data?.alternativeProductSerial || '--'}</Typography>
      </Col>
      <Col span={7}>
        <Typography.Text strong>{t('warrantyRequest.popup.productCode')}</Typography.Text>
      </Col>
      <Col span={17}>{data?.alternativeProductCode || '--'}</Col>
      <Col span={7}>
        <Typography.Text strong>{t('warrantyRequest.popup.productName')}</Typography.Text>
      </Col>
      <Col span={17}>{data?.alternativeProductName || '--'}</Col>
      <Col span={7}>
        <Typography.Text strong>{t('warrantyRequest.popup.category')}</Typography.Text>
      </Col>
      <Col span={17}>{data?.alternativeProductCategory?.name || '--'}</Col>
    </>
  );
  const renderUI = {
    [ResultMethod.EXCHANGE_SIMILAR_PRODUCT]: fieldShared,
    [ResultMethod.RENEW_SAME_PRODUCT]: fieldShared,
    [ResultMethod.DONE]: <></>,
    [ResultMethod.CANCEL]: (
      <>
        <Col span={7}>
          <Typography.Text strong>{t('warrantyRequest.popup.cancel')}</Typography.Text>
        </Col>
        <Col span={17}>{data?.declineReason || '--'}</Col>
      </>
    ),
    [ResultMethod.REIMPORT]: (
      <>
        <Col span={8}>
          <Typography.Text strong>
            {t('warrantyRequest.popup.ownerGetMoney', { platformName })}
          </Typography.Text>
        </Col>
        <Col span={16}>
          <Typography.Link>{formatCurrency(data?.receiveAmount) || '--'}</Typography.Link>
        </Col>
        <Col span={8}>
          <Typography.Text strong>
            {t('warrantyRequest.popup.ownerPayMoney', { platformName })}
          </Typography.Text>
        </Col>
        <Col span={16}>
          <Typography.Link>{formatCurrency(data?.returnAmount) || '--'}</Typography.Link>
        </Col>
      </>
    ),
  };

  return renderUI?.[data?.result];
};

function TabResults({ data }) {
  const fileURLs = data?.isCancel ? data?.declineFileURLs : data?.resultFileURLs;
  const notREIMPORT = data?.result === ResultMethod.REIMPORT;
  const spanFirstCol = !notREIMPORT ? 7 : 8;
  const span2ndCol = !notREIMPORT ? 17 : 16;
  const { platformName } = useGetInfoPlatform();

  return (
    <Row gutter={[16, 8]}>
      <Col span={24} xl={12}>
        <Row gutter={[16, 8]} style={{ borderRight: '1px dashed #9CADC4' }}>
          <Col span={spanFirstCol}>
            <Typography.Text strong>{t('serial.warrantyResult', { platformName })}</Typography.Text>
          </Col>
          <Col span={span2ndCol}>
            <Typography.Text>{warrantyResultLabel?.[data?.result]}</Typography.Text>
          </Col>
          <Col span={spanFirstCol}>
            <Typography.Text strong>{t('serial.warrantyResultNotowner')}</Typography.Text>
          </Col>
          <Col span={span2ndCol}>
            <Typography.Text>{warrantyResultLabel?.[data?.brandResult]}</Typography.Text>
          </Col>
          <RenderUIbyResult data={data} />
          <Col span={spanFirstCol}>
            <Typography.Text strong>{t('warrantyRequest.popup.note')}</Typography.Text>
          </Col>
          <Col span={span2ndCol}>{data?.resultNote || '--'}</Col>
        </Row>
      </Col>
      <Col span={24} xl={12} style={{}}>
        <Row gutter={[16, 8]}>
          {!data?.isCancel && (
            <>
              <Col span={spanFirstCol}>
                <Typography.Text strong>{t('serial.resultDate')}</Typography.Text>
              </Col>
              <Col span={span2ndCol}>
                <Typography.Text>
                  {convertTimeDate(data?.resultReceivedDate, DATE_FORMAT)}
                </Typography.Text>
              </Col>
            </>
          )}
          <Col span={24}>
            <Typography.Text strong>{t('serial.resultsFiles')}</Typography.Text>
          </Col>
          <Col span={24}>
            <Space style={{ flexWrap: 'wrap' }}>
              {fileURLs?.map((fileURL, index) => {
                return <CustomViewDocumentFiles file={fileURL} key={index} />;
              }) || <Typography.Text italic>{t('common.noFiles')}</Typography.Text>}
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default TabResults;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Dropdown, Input } from 'antd';
import { Icon } from 'assets/icons';
import CreateButton from 'components/CreateButton';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getQuery } from 'utils/helperFuncs';
import styles from './index.module.scss';

const FilterMenu = memo(
  ({
    attributeOptions,
    handleSearchAttributes,
    handleFilterAttributes,
    applyFilter,
    resetFilter,
    attributeIDs,
  }) => {
    const { t } = useTranslation();
    return (
      <menu className={styles.menu}>
        <div
          className={styles['menu-header']}
          // style={{ backgroundColor: '#ffffff', borderRadius: '8px 8px 0px 0px' }}
        >
          <p className={styles.title}>{t('common.filter')}</p>
          <p className={styles.setting}>
            <span onClick={resetFilter}>{t('common.reset')}</span>{' '}
            <a onClick={applyFilter}>{t('common.apply')}</a>
          </p>
        </div>
        <div className={styles['panel-content']}>
          <Input
            className={styles['custom-input']}
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={t('product.categoryTableTitle.nameAttribute')}
            allowClear={true}
            onChange={handleSearchAttributes}
          />

          <Checkbox.Group
            className={styles['checkbox-group']}
            options={attributeOptions}
            onChange={handleFilterAttributes}
            value={attributeIDs}
          />
        </div>
      </menu>
    );
  }
);

const FilterBox = ({
  attributeOptions,
  filterByName,
  handleSearchAttributes,
  handleFilterAttributes,
  applyFilter,
  resetFilter,
  attributeIDs,
  loadingCategories,
}) => {
  const GET_QUERY = getQuery();
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <div className={styles['filter-box']}>
      {' '}
      <div className={styles['search-input']}>
        <Input
          className={styles['custom-input']}
          prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
          placeholder={t('product.categoryTableTitle.searchInputPlaceholder')}
          allowClear={true}
          onChange={filterByName}
          value={GET_QUERY.query}
        />
      </div>
      <div className={styles.btns}>
        <Dropdown
          overlayClassName="dropdown-overlay"
          trigger="click"
          overlay={
            <FilterMenu
              attributeOptions={attributeOptions}
              handleSearchAttributes={handleSearchAttributes}
              handleFilterAttributes={handleFilterAttributes}
              applyFilter={applyFilter}
              resetFilter={resetFilter}
              attributeIDs={attributeIDs}
            />
          }
          placement="bottomRight"
        >
          <Button className="custom-button">
            <FontAwesomeIcon icon={Icon.faFilter} style={{ marginRight: '16px' }} />
            {t('product.categoryTableTitle.attributes')}
          </Button>
        </Dropdown>

        <CreateButton
          title={t('common.add')}
          loading={loadingCategories}
          onClick={() => {
            history.push('/product-category-create');
          }}
        />
      </div>
    </div>
  );
};

export default memo(FilterBox);

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Typography } from "antd";
import { REJECTED_REASON_CAUSE_LABEL } from "../../constants";
import SkeletonCustom from "components/_storybooks/Skeleton";

function ReasonBlock({ rejectedReason, fullEvents, isRejected, isCanceled, loading }) {
  const createdBy = fullEvents?.[fullEvents?.length - 1]?.createdBy;
  const renderTitle = () => {
    if (isCanceled) return "Lý do hủy:";
    if (isRejected) return "Lý do từ chối:";
    return "renderTitle: ";
  };

  const renderReason = () => {
    if (isCanceled) {
      if (createdBy?.type === "SELLER")
        return `Do đơn hàng bị tài khoản ${createdBy?.name} - ${createdBy?.email} hủy khi chưa hoàn thành`;
      return "Do đơn hàng bị Hồng Anh hủy khi chưa hoàn thành";
    }
    if (isRejected)
      return rejectedReason?.reason ? rejectedReason?.reason : REJECTED_REASON_CAUSE_LABEL[rejectedReason?.causes];
    return "renderReason";
  };

  return (
    <Alert
      message={
        <SkeletonCustom rows={0} loading={loading}>
          <span>
            <Typography.Text strong>{renderTitle()}</Typography.Text>
            <Typography.Text style={{ marginLeft: "4px" }}>{renderReason()}</Typography.Text>
          </span>
        </SkeletonCustom>
      }
      icon={<ExclamationCircleOutlined />}
      type="error"
      showIcon
      style={{ width: "100%", marginBottom: "10px" }}
    />
  );
}

export default ReasonBlock;

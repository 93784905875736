import { InputNumber, Space } from 'antd';
import { MenuLayout } from 'components/CustomFilterMenu';
import React from 'react';
import useFilterInStock from './useFilterInStock';

const FilterInStock = ({ warehouseIDs, onFilter }) => {
  const { handleChangeFrom, handleChangeTo, handleApplyFilter, handleResetFilter, range } =
    useFilterInStock({
      warehouseIDs,
      onFilter,
    });

  return (
    <MenuLayout
      filterContent={
        <Space>
          Từ
          <InputNumber
            style={{ width: '100px' }}
            controls={false}
            onChange={handleChangeFrom}
            value={range?.from}
            min={0}
            max={range?.to}
          />
          -
          <InputNumber
            style={{ width: '100px' }}
            controls={false}
            onChange={handleChangeTo}
            value={range?.to}
            min={range?.from}
          />
        </Space>
      }
      applyFilter={handleApplyFilter}
      resetFilter={handleResetFilter}
    />
  );
};

export default FilterInStock;

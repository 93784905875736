import React from 'react';
import { DeleteOutlined, EyeOutlined, PictureOutlined } from '@ant-design/icons';
import { Image as AntImage, Upload } from 'antd';
import './index.scss';
import styles from './index.module.scss';
import { t } from 'i18next';
import useUploadBannerButton from './useUploadBannerButton';
import { dummyRequest, notify, validateFile } from 'utils/helperFuncs';
import { ACCEPT_IMG_TYPES } from 'config/constants';

//don't manipulate value and onChange unless you know how
const UploadBannerButton = ({ value = [], onChange }) => {
  const {
    bannerFiles,
    bannerSize,
    handleChange,
    handlePreview,
    previewImage,
    previewOpen,
    handleTogglePreview,
    handleToggleButton,
  } = useUploadBannerButton({ value, onChange });

  const uploadButton = (
    <div className={`${styles['upload-field']} ${styles['upload-button']}`}>
      <PictureOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('common.chooseImage')}
      </div>
    </div>
  );

  return (
    <div className={`custom-upload-container`}>
      <Upload
        listType="picture-card"
        maxCount={1}
        accept={ACCEPT_IMG_TYPES.join(',')}
        customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
        onChange={handleChange}
        onPreview={handlePreview}
        fileList={bannerFiles}
        beforeUpload={(file) => {
          if (!validateFile(file, 10)) {
            notify.error({ message: t('uploadDocument.limit10ErrorMessage') });
            return Upload.LIST_IGNORE;
          }
          return validateFile(file, 10);
        }}
        itemRender={(originNode, file, fileList, actions) => {
          return (
            <div className={`${styles['upload-field']}`}>
              <img alt="preview-img" src={previewImage} className={`${styles['upload-preview']}`} />

              <div className={`${styles['upload-actions']}`}>
                <div className={`${styles['upload-actions-container']}`}>
                  <EyeOutlined onClick={actions.preview} className={`${styles['actions-img']}`} />
                  <DeleteOutlined onClick={actions.remove} className={`${styles['actions-img']}`} />
                </div>
              </div>
            </div>
          );
        }}
      >
        {handleToggleButton(bannerFiles.length === 1, uploadButton)}
      </Upload>
      <AntImage
        alt="preview-banner"
        src={previewImage}
        style={{ display: 'none' }}
        preview={{
          visible: previewOpen,
          onVisibleChange: handleTogglePreview,
        }}
      />
    </div>
  );
};

export default UploadBannerButton;

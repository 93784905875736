import { WEIGHT_UNIT } from 'config/constants';
import { useState } from 'react';

const useWeightInput = (value, onChange) => {
  const [groupValue, setGroupValue] = useState({
    weight: null,
    unit: null,
  });

  const weightOptions = [
    {
      value: WEIGHT_UNIT.GRAM,
      label: 'g',
    },
    {
      value: WEIGHT_UNIT.KILOGRAM,
      label: 'kg',
    },
    {
      value: WEIGHT_UNIT.QUINTAL,
      label: 'tạ',
    },
    {
      value: WEIGHT_UNIT.TON,
      label: 'tấn',
    },
  ];

  const triggerChange = (changedValue) => {
    onChange?.({
      ...groupValue,
      ...value,
      ...changedValue,
    });
  };

  const onNumberChange = (inputValue, key) => {
    const newNumber = inputValue >= 0 ? inputValue : null;
    if (Number.isNaN(groupValue[`${key}`])) {
      return;
    }
    if (!(key in value) || !newNumber) {
      setGroupValue({ ...groupValue, [`${key}`]: newNumber });
    }
    triggerChange({
      [`${key}`]: newNumber,
    });
  };

  const onUomChange = (newUom) => {
    if (!('unit' in value)) {
      setGroupValue({ ...groupValue, unit: newUom });
    }
    triggerChange({
      unit: newUom,
    });
  };

  return {
    onUomChange,
    onNumberChange,
    groupValue,
    weightOptions,
  };
};

export default useWeightInput;

import React, { useState } from 'react';
import { Form, Tooltip } from 'antd';
import { SvgIcon } from 'assets/icons';
import UploadDocument from 'components/UploadDocument';
import { INPUT_TYPE, REGEX } from 'config/constants';
import { formatCurrency, notify } from 'utils/helperFuncs';
import { useTranslation } from 'react-i18next';
import FormInput from 'components/FormInput';
import { useCurrentUser } from 'hooks/user/user';
import { ACTIVE_WALLET, TARGET_WALLET, WALLET_TYPE } from '../../constants';
import { useTransferMoney } from 'hooks/money-transfer';
import CustomModal from 'components/CustomModal';
import { convertActiveWallet } from 'hooks/seller/converter';

const SellerRechargeModal = ({ sellerName, record }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { currentUser: userInfo } = useCurrentUser();
  const { handleTransferMoney } = useTransferMoney();
  const [fileUrls, setFileUrls] = useState([]);
  const [switchClear, setSwitchClear] = useState(false);
  const [openRechargeModal, setOpenRechargeModal] = useState(false);

  const transactionOptions = [
    {
      label: t('accountant.transactionType.companyToCompany'),
      value: 'COMPANY_TO_COMPANY',
    },
    {
      label: t('accountant.transactionType.personalToCompany'),
      value: 'PERSONAL_TO_COMPANY',
    },
    {
      label: t('accountant.transactionType.companyToPersonal'),
      value: 'COMPANY_TO_PERSONAL',
    },
    {
      label: t('accountant.transactionType.personalToPersonal'),
      value: 'PERSONAL_TO_PERSONAL',
    },
  ];

  function handleUploadSuccess(urls) {
    setFileUrls(urls);
  }

  function resetFields() {
    form.resetFields();
    setSwitchClear(!switchClear);
  }

  function getSourceOptions() {
    let initialValue = WALLET_TYPE.BANK_ACCOUNT;
    let options = [];
    const { wallets = [] } = userInfo || {};
    options = wallets?.map((wallet) => {
      if (wallet.type === WALLET_TYPE.WAREHOUSE_ACCOUNTANT) {
        return {
          label: `${t('accountant.paymentWallet')} ( ${t(
            'accountant.currentBalance'
          )}: ${formatCurrency(wallet?.balance)} )`,
          value: WALLET_TYPE.WAREHOUSE_ACCOUNTANT,
        };
      }

      if (wallet.type === WALLET_TYPE.BANK_ACCOUNT) {
        return {
          label: t('accountant.activeWallet.sellerBankAccount'),
          value: WALLET_TYPE.BANK_ACCOUNT,
        };
      }

      return undefined;
    });

    let isOnlyBankAccount =
      options?.filter((item) => item?.value === WALLET_TYPE.BANK_ACCOUNT)?.length === 1;
    if (isOnlyBankAccount) {
      initialValue = WALLET_TYPE.BANK_ACCOUNT;
    } else {
      initialValue = WALLET_TYPE.WAREHOUSE_ACCOUNTANT;
    }

    return { options, initialValue };
  }

  function getTargetOptions() {
    let initialValue = TARGET_WALLET.TO_VIRTUAL_WALLET;
    let options = [];

    options = record?.activeWallets?.map((wallet) => {
      if (ACTIVE_WALLET.SELLER_PERSONAL || ACTIVE_WALLET.SELLER_COMPANY) {
        initialValue = TARGET_WALLET.TO_VIRTUAL_WALLET;
        return {
          label: t(convertActiveWallet(wallet.type)),
          value: TARGET_WALLET.TO_VIRTUAL_WALLET,
        };
      }

      if (wallet.type === ACTIVE_WALLET.SELLER_DEBT) {
        initialValue = TARGET_WALLET.TO_DEBT;
        return {
          label: t(convertActiveWallet(wallet.type)),
          value: TARGET_WALLET.TO_DEBT,
        };
      }

      if (wallet.type === ACTIVE_WALLET.WAREHOUSE_ACCOUNTANT) {
        initialValue = TARGET_WALLET.TO_WAREHOUSE_ACCOUNTANT;
        return {
          label: t(convertActiveWallet(wallet.type)),
          value: TARGET_WALLET.TO_WAREHOUSE_ACCOUNTANT,
        };
      }

      if (wallet.type === ACTIVE_WALLET.BANK_ACCOUNT) {
        initialValue = TARGET_WALLET.TO_BANK_ACCOUNT;
        return {
          label: t(convertActiveWallet(wallet.type)),
          value: TARGET_WALLET.TO_BANK_ACCOUNT,
        };
      }

      return undefined;
    });

    return { options, initialValue };
  }

  async function transferMoney({ activeWallets }) {
    const { amount, description, source, transferType } = await form.validateFields();
    if (source === WALLET_TYPE.BANK_ACCOUNT && !transferType) {
      notify.error({
        message: t('accountant.rechargeSellerError'),
        description: t('accountant.rechargeValidateTransferType'),
      });
      return;
    }
    try {
      await handleTransferMoney({
        source: source === WALLET_TYPE.WAREHOUSE_ACCOUNTANT ? userInfo?.wallet.id : null,
        target: activeWallets[0].id,
        amount,
        description,
        fileUrls,
        transferType,
      });
      notify.success({
        message: t('accountant.rechargeSellerSuccess'),
      });
      resetFields();
      setOpenRechargeModal(!openRechargeModal);
    } catch (err) {
      notify.error({
        message: t('accountant.rechargeSellerError'),
        description: err?.message,
      });
    }
  }

  const formFields = [
    {
      type: INPUT_TYPE.RADIO_GROUP,
      formItemOptions: {
        label: t('accountant.rechargeForm.sourceLabel'),
        name: 'source',
        initialValue: getSourceOptions().initialValue,
      },
      inputOptions: {
        options: getSourceOptions().options,
      },
    },
    {
      type: INPUT_TYPE.RADIO_GROUP,
      formItemOptions: {
        label: t('accountant.rechargeForm.targetLabel'),
        name: 'target',
        initialValue: getTargetOptions().initialValue,
      },
      inputOptions: {
        options: getTargetOptions().options,
      },
    },
    {
      type: INPUT_TYPE.NUMBER,
      formItemOptions: {
        label: t('accountant.rechargeForm.amountLabel'),
        name: 'amount',
        rules: [
          {
            required: true,
            message: t('accountant.rechargeForm.amountValidate'),
          },
        ],
      },
      inputOptions: {
        placeholder: t('accountant.rechargeForm.amountPlaceholder'),
        formatter: (value) => value.replace(REGEX.CURRENCY, '.'),
        parser: (value) => value.replace(REGEX.CURRENCY_PARSER, ''),
        min: 0,
        controls: false,
        maxLength: 12,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t('accountant.transferType'),
        name: 'transferType',
      },
      inputOptions: {
        placeholder: t('accountant.selectTransferType'),
        options: transactionOptions,
      },
    },
    {
      type: INPUT_TYPE.TEXT_AREA,
      formItemOptions: {
        label: t('common.description'),
        name: 'description',
      },
      inputOptions: {
        placeholder: t('accountant.rechargeForm.descriptionPlaceholder'),
        maxLength: 255,
        showCount: true,
      },
    },
  ];

  return (
    <CustomModal
      className="transfer-popup"
      closable={false}
      centered={true}
      footer={false}
      isBlockCloseOnOke
      switchClose={openRechargeModal}
      okeButtonLabel={t('common.save')}
      customComponent={
        <Tooltip title={t('accountant.recharge')}>
          <SvgIcon.RechargeIcon />
        </Tooltip>
      }
      onOke={() => transferMoney(record)}
      onCancel={() => {
        setOpenRechargeModal(false);
        resetFields();
      }}
    >
      <div className="recharge-title">
        <p>{t('accountant.rechargeTitle')}</p>
        <p className="seller-name">{sellerName}</p>
      </div>

      <Form form={form} labelCol={{ span: 6 }}>
        <div className="recharge-form">
          {formFields.map((field, index) => {
            return <FormInput key={index} {...field} />;
          })}

          <Form.Item label={t('accountant.attachment')} wrapperCol={{ span: 16 }}>
            <UploadDocument onUploadSuccess={handleUploadSuccess} switchClear={switchClear} />
            <p className="files-support">{t('accountant.filesSupport')}</p>
          </Form.Item>
          <p className="note">{t('accountant.note')} </p>
        </div>
      </Form>
    </CustomModal>
  );
};

export default SellerRechargeModal;

/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "antd/lib/form/Form";
import { ITEM_DATA_TYPE } from "features/Order/constants";
import { useConfirmExportOrder, useGetOrderDetail, useGetOrderLoadmore } from "hooks/order";
import { useGetSellersCreateExportSerial } from "hooks/seller";
import { useCreateSerialExport } from "hooks/serial";
import { useGetSupplierOnLoadMore } from "hooks/supplier";
import { useWarehouse } from "hooks/warehouse";
import { t } from "i18next";
import { sumBy, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { notify } from "utils/helperFuncs";
import { EXPORT_TYPE } from "../../constant";

function useCreate() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [orderId, setOrderId] = useState(JSON.parse(queryParams.get("orderId")));
  const [params] = useState({
    id: orderId,
    itemDataType: ITEM_DATA_TYPE.ORDER_ITEM,
  });
  const { order: orderInfoState, loading: loadingOrder } = useGetOrderDetail({ params });
  const [form] = useForm();
  const history = useHistory();
  const [warehouse, setWarehouse] = useState();
  const [exportType, setExportType] = useState(EXPORT_TYPE.ORDER);
  const [sellerInfo, setSellerInfo] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [supplierInfo, setSupplierInfo] = useState(null);
  const [supplierInfoMemory, setSupplierInfoMemory] = useState(null);

  const [dataTableOrder, setDataTableOrder] = useState([]);
  const [dataTableSupplier, setDataTableSupplier] = useState([]);
  const [dataTableTransferSerial, setDataTableTransferSerial] = useState([]);

  const [tags, setTags] = useState([]);
  const sumProductByOrder = dataTableOrder?.length;
  const sumSerialByOrder = sumBy(dataTableOrder, "quantity");
  const formData = form.getFieldsValue();
  const sumSerialScanned = sumBy(formData.items, "quantitySerialScanned");

  const [visiblePopconfirmWarehouse, setVisiblePopconfirmWarehouse] = useState(false);
  const [visiblePopconfirmProvider, setVisiblePopconfirmProvider] = useState(false);

  const [warehouseInfo, setWarehouseInfo] = useState();

  const handleGetTags = (values) => {
    setTags(values);
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues?.exportType) {
      setExportType(changedValues?.exportType);
      setDataTableSupplier([]);
      setSupplierInfo(null);
    }
    if (changedValues?.items) {
      const newData = dataTableSupplier?.map((item, index) => {
        return {
          ...item,
          quantity: (allValues?.items && allValues?.items[index]?.quantity) || 0,
          serialItems: (allValues?.items && allValues?.items[index]?.serialItems) || [],
          lack:
            ((allValues?.items && allValues?.items[index]?.quantity) || 0) -
            ((allValues?.items && allValues?.items[index]?.serialItems?.length) || 0),
          scanned:
            ((allValues?.items && allValues?.items[index]?.quantity) || 0) -
            (((allValues?.items && allValues?.items[index]?.quantity) || 0) -
              ((allValues?.items && allValues?.items[index]?.serialItems?.length) || 0)),
        };
      });
      const newDataTransferSerial = dataTableTransferSerial?.map((item, index) => {
        return {
          ...item,
          quantity: (allValues?.items && allValues?.items[index]?.quantity) || 0,
          serialItems: (allValues?.items && allValues?.items[index]?.serialItems) || [],
          lack:
            ((allValues?.items && allValues?.items[index]?.quantity) || 0) -
            ((allValues?.items && allValues?.items[index]?.serialItems?.length) || 0),
          scanned:
            ((allValues?.items && allValues?.items[index]?.quantity) || 0) -
            (((allValues?.items && allValues?.items[index]?.quantity) || 0) -
              ((allValues?.items && allValues?.items[index]?.serialItems?.length) || 0)),
        };
      });
      setDataTableSupplier(newData);
      setDataTableTransferSerial(newDataTransferSerial);
    }
  };

  const handleChangeInfo = (value, info) => {
    if (info?.seller) setSellerInfo(info?.seller);
    if (info?.order && exportType === EXPORT_TYPE.ORDER) {
      setOrderInfo(info?.order);
      setOrderId(info?.order?.id);
    }
    if (info?.supplier && exportType === EXPORT_TYPE.RETURN_SUPPLIER) {
      if (dataTableSupplier.some((e) => e?.serialItems.length > 0)) {
        setVisiblePopconfirmProvider(true);
        setSupplierInfoMemory(info?.supplier);
      } else {
        setSupplierInfo(info?.supplier);
      }
    }
  };

  const [paramsOrder, setParamsOrder] = useState({
    filters: {
      query: "",
      statuses: [],
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: [],
  });

  const [paramsSeller, setParamsSeller] = useState({
    filters: {
      query: null,
      isActive: true,
      isSeller: true,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: [],
  });

  const [paramsSupplier, setParamsSupplier] = useState({
    filters: {
      query: null,
      isSupplier: true,
      isActiveSupplier: true,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: [],
  });

  const handleChange = (value, option) => {
    setParamsOrder({
      ...paramsOrder,
      filters: { ...paramsOrder.filters, query: undefined },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsSeller({
      ...paramsSeller,
      filters: { ...paramsSeller.filters, query: undefined, isSeller: true },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsSupplier({
      ...paramsSupplier,
      filters: { ...paramsSupplier.filters, query: undefined, isSupplier: true, isActiveSupplier: true },
      pagination: { offset: 0, limit: 10 },
    });
    handleSetFieldOrder && handleSetFieldOrder({ ...option?.order });
  };

  const onSearch = (value) => {
    setParamsOrder({
      filters: { ...paramsOrder.filters, query: value.trim() || "" },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsSeller({
      filters: { ...paramsSeller.filters, query: value.trim() || null, isSeller: true },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsSupplier({
      filters: { ...paramsSupplier.filters, query: value.trim() || null, isSupplier: true, isActiveSupplier: true },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleSetFieldOrder = (data) => {
    const formData = form.getFieldsValue();

    const newData = data?.items?.map((item, index) => {
      return {
        ...item,
        productID: item?.productID,
        warrantyUnit: item?.warrantyDateUnit,
        warrantyOnSale: item?.productWarranty,
        serialItems: [],
      };
    });
    form.setFieldsValue({
      ...formData,
      items: newData,
    });
    setDataTableOrder(newData);
  };

  const handleSetFieldSupplier = (data) => {
    const formData = form.getFieldsValue();

    const newData = {
      ...data,
      productID: data?.productID,
      productName: data?.productName,
      productCode: data?.productCode,
      productUom: data?.productUom,
      serialItems: [],
    };

    const isDuplicate = dataTableSupplier?.some((item) => {
      const itemKey = `${item.productID}`;
      return itemKey === data?.productID;
    });
    if (!isDuplicate) {
      form.setFieldsValue({
        ...formData,
        items: uniqBy([...(formData?.items || []), newData], "productID"),
      });
      setDataTableSupplier(uniqBy([...dataTableSupplier, data], "productID"));
    } else {
      notify.error({
        message: t("warehouse.salesReturn.errorDuplicateProduct", {
          codeProduct: data?.productCode,
          nameProduct: data?.productName,
        }),
      });
    }
  };

  const handleSetFieldTransferSerial = (data) => {
    if (warehouseInfo) {
      const formData = form.getFieldsValue();
      const newData = {
        ...data,
        productID: data?.productID,
        productName: data?.productName,
        productCode: data?.productCode,
        productUom: data?.productUom,
        quantity: 0,
        warrantyUnit: "MONTH",
        warrantyPeriodSupplier: 0,
        serialItems: [],
      };

      const isDuplicate = dataTableTransferSerial?.some((item) => {
        const itemKey = `${item.productID}`;
        return itemKey === data?.productID;
      });

      if (!isDuplicate) {
        form.setFieldsValue({
          ...formData,
          items: uniqBy([...(formData?.items || []), newData], "productID"),
        });

        setDataTableTransferSerial(uniqBy([...dataTableTransferSerial, data], "productID"));
      } else {
        notify.error({
          message: t("warehouse.salesReturn.errorDuplicateProduct", {
            codeProduct: data?.productCode,
            nameProduct: data?.productName,
          }),
        });
      }
    } else {
      notify.warning({
        message: "Vui lòng chọn kho xuất!",
      });
    }
  };

  const handleDeleteAllSerial = (index, exportType) => {
    const formData = form.getFieldsValue();
    if (exportType === EXPORT_TYPE.TRANSFER_SERIAL) {
      const newFormItems = formData.items.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            serialItems: [],
          };
        }
        return item;
      });
      const newDataTable = dataTableTransferSerial.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            serialItems: [],
          };
        }
        return item;
      });

      form.setFieldsValue({
        ...formData,
        items: newFormItems,
      });
      setDataTableTransferSerial(newDataTable);
    }

    if (exportType === EXPORT_TYPE.RETURN_SUPPLIER) {
      const newFormItems = formData.items.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            serialItems: [],
          };
        }
        return item;
      });
      const newDataTable = dataTableSupplier.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            serialItems: [],
          };
        }
        return item;
      });

      form.setFieldsValue({
        ...formData,
        items: newFormItems,
      });
      setDataTableSupplier(newDataTable);
    }
  };

  const handleRemoveItemReturnSupplier = (productID, index) => {
    const formData = form.getFieldsValue();
    const dataFormDelete = formData?.items?.filter((item) => item?.productID !== productID);
    form.setFieldsValue({
      ...formData,
      items: dataFormDelete,
    });

    const dataTableDelete = dataTableSupplier?.filter((item) => item?.productID !== productID);
    setDataTableSupplier(dataTableDelete);
  };

  const handleRemoveItemTransferSerial = (productID, index) => {
    const formData = form.getFieldsValue();
    const dataFormDelete = formData?.items?.filter((item) => item?.productID !== productID);
    form.setFieldsValue({
      ...formData,
      items: dataFormDelete,
    });

    const dataTableDelete = dataTableTransferSerial?.filter((item) => item?.productID !== productID);
    setDataTableTransferSerial(dataTableDelete);
  };

  const handleGetTableOrder = (data) => {
    setDataTableOrder(data);
  };

  const {
    paginationData,
    orders,
    hasMoreData: hasMoreOrder,
    onLoadMoreData: loadMoreOrder,
    loading: loadingOrders,
  } = useGetOrderLoadmore(paramsOrder, "cache-first");

  const { loading: loadingSellers, data: sellers } = useGetSellersCreateExportSerial(paramsSeller, "cache-first");

  const {
    data: dataSupplier,
    onLoadMoreData: onLoadMoreDataSupplier,
    hasMoreData: hasMoreDataSupplier,
    loading: loadingSupplier,
  } = useGetSupplierOnLoadMore(paramsSupplier);

  const { warehouses, loading: loadingWarehouse } = useWarehouse();

  const warehouseOptions = warehouses?.map((item) => ({
    value: item?.id,
    label: item?.name,
    warehouse: item,
  }));

  const onCancelCreate = () => {
    history.push("/export-serial");
  };

  const handleChangeWarehouse = (value) => {
    if (dataTableSupplier.some((e) => e?.serialItems.length > 0)) {
      setVisiblePopconfirmWarehouse(true);
    } else {
      setWarehouse(value);
    }
  };

  const handleSelectWarehouse = (value, option) => {
    setWarehouseInfo(option?.warehouse);
    setDataTableTransferSerial([]);
    form.setFieldsValue({
      ...formData,
      warehouseID: value,
      items: [],
    });
  };

  const handleCancelPopconfirmWarehouse = () => {
    setWarehouse(warehouse);
    form.setFieldsValue({
      ...formData,
      warehouseID: warehouse,
    });
    setVisiblePopconfirmWarehouse(false);
  };

  const handleConfirmPopconfirmWarehouse = () => {
    setDataTableSupplier([]);
    form.setFieldsValue({
      ...formData,
      items: [],
    });
    setVisiblePopconfirmWarehouse(false);
  };

  const handleCancelPopconfirmProvider = () => {
    setSupplierInfo(supplierInfo);
    form.setFieldsValue({
      ...formData,
      sellerID: supplierInfo?.id,
    });
    setVisiblePopconfirmProvider(false);
  };

  const handleConfirmPopconfirmProvider = () => {
    setDataTableSupplier([]);
    setSupplierInfo(supplierInfoMemory);
    form.setFieldsValue({
      ...formData,
      items: [],
    });
    setVisiblePopconfirmProvider(false);
  };

  const [visibleCreateModal, setVisibleCreateModel] = useState(false);

  const showOnModalConfirmCreate = () => {
    setVisibleCreateModel(true);
  };

  const showOffModalConfirmCreate = () => {
    setVisibleCreateModel(false);
  };

  const handleFinish = async () => {
    await form.validateFields().then((values) => {
      if (exportType === EXPORT_TYPE.WARRANTY) {
        let shouldShowModal = true;
        const hasItems = Array.isArray(values?.items) && values.items.length > 0;
        const allHaveWarehouseID = hasItems && values.items.every((item) => item.warehouseID !== undefined);
        if (!allHaveWarehouseID) {
          notify.warning({ message: t("serialExportCreate.enterWarranty") });
          shouldShowModal = false;
        }
        const allHaveName = hasItems && values.items.every((item) => item.replaceSerialID !== undefined);
        if (!allHaveName) {
          notify.warning({ message: t("serialExportCreate.enterNewSerial") });
          shouldShowModal = false;
        }
        if (shouldShowModal) {
          showOnModalConfirmCreate();
        }
      } else {
        if (values?.items?.length > 0) {
          showOnModalConfirmCreate();
        } else {
          notify.error({
            message: t("warehouse.create.PleaseChooseProduct"),
          });
        }
      }
    });
  };

  const { handleCreateSerialExport } = useCreateSerialExport();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const { handleConfirmExportOrder } = useConfirmExportOrder();

  const handleSubmitCreateExportNotes = async () => {
    setLoadingCreate(true);
    const values = await form.getFieldsValue();
    try {
      if (values?.items?.length) {
        await form.validateFields();
        const hasEmptySerialOrNoSerial = values?.items?.some((item) => item?.serial?.length > 0);
        if (exportType === EXPORT_TYPE.ORDER) {
          const paramsOrder = {
            type: values?.exportType,
            orderID: values?.orderID,
            sellerID: orderInfo?.seller?.id || orderInfoState?.sellerID,
            note: values?.note,
            fileURLs: values?.fileURLs,
            items: values?.items?.map((item, i) => {
              return {
                productID: item?.product?.id || item?.productID,
                warrantyOnSale: item?.warrantyOnSale,
                warrantyUnit: item?.warrantyDateUnit,
                soldDate: item?.soldDate,
                serialIDs: item?.serial?.map((i, index) => {
                  return i?.id;
                }),
                warrantyItem: {
                  warehouseID: orderInfo?.exportedWarehouse?.id,
                },
              };
            }),
          };
          if (hasEmptySerialOrNoSerial) {
            const dataCreate = await handleCreateSerialExport({ request: paramsOrder });
            const idParams = dataCreate?.data?.serialExportNotes?.create?.id;
            notify.success({ message: t("serialIE.notify.successCreate") });
            history.push(`/serial-export/detail/${idParams}`);
          } else {
            history.push(`/order/${orderId}`);
          }

          await handleConfirmExportOrder({
            orderIDs: orderId,
          }).then(() => {
            notify.success({
              message: t("order.orderList.exportingSuccess"),
            });
          });
          var checkReload = "checkReload";
          localStorage.setItem("checkReload", JSON.stringify(checkReload));
        }
        if (exportType === EXPORT_TYPE.WARRANTY) {
          const paramsSeller = {
            type: values?.exportType,
            sellerID: values?.sellerID,
            note: values?.note,
            fileURLs: values?.fileURLs,
            items: values?.items?.map((item, i) => {
              return {
                productID: item?.productIDNewSerial,
                warrantyOnSale: item?.warrantyOnSale,
                warrantyUnit: item?.warrantyDateUnit,
                soldDate: item?.soldDate,
                serialIDs: item?.serial?.map((i, index) => {
                  return i?.id;
                }),
                warrantyItem: {
                  warehouseID: item?.warehouseID,
                  warrantyNoteID: item?.id,
                  serialID: item?.replaceSerialID[0]?.id, // replaced replaceSerialID, will be imported
                  replaceSerialID: item?.serialID, // replaced serialID, will be imported
                },
              };
            }),
          };
          const dataCreate = await handleCreateSerialExport({ request: paramsSeller });
          const idParams = dataCreate?.data?.serialExportNotes?.create?.id;
          notify.success({ message: t("serialIE.notify.successCreate") });
          history.push(`/serial-export/detail/${idParams}`);
        }
        if (exportType === EXPORT_TYPE.RETURN_SUPPLIER) {
          const paramsSupplier = {
            type: values?.exportType,
            sellerID: values?.sellerID,
            note: values?.note,
            fileURLs: values?.fileURLs,
            items: values?.items?.map((item, i) => {
              return {
                productID: item?.product?.id || item?.productID,
                serialIDs: item?.serialItems?.map((i, index) => {
                  return i?.id;
                }),
                quantity: item?.quantity,
                expectedQuantity: item?.quantity,
                warrantyItem: {
                  warehouseID: values?.warehouseID,
                },
              };
            }),
          };
          const dataCreate = await handleCreateSerialExport({ request: paramsSupplier });
          const idParams = dataCreate?.data?.serialExportNotes?.create?.id;
          notify.success({ message: t("serialIE.notify.successCreate") });
          history.push(`/serial-export/detail/${idParams}`);
        }
        if (exportType === EXPORT_TYPE.TRANSFER_SERIAL || exportType === EXPORT_TYPE.OTHER) {
          const paramsTransferSerial = {
            type: values?.exportType,
            note: values?.note,
            fileURLs: values?.fileURLs,
            items: values?.items?.map((item, i) => {
              return {
                productID: item?.product?.id || item?.productID,
                warrantyOnSale: item?.warrantyPeriodSupplier,
                warrantyUnit: item?.warrantyUnit,
                serialIDs: item?.serialItems?.map((i, index) => {
                  return i?.id;
                }),
                expectedQuantity: item?.quantity,
                warrantyItem: {
                  warehouseID: values?.warehouseID,
                },
              };
            }),
          };
          const dataCreate = await handleCreateSerialExport({ request: paramsTransferSerial });
          const idParams = dataCreate?.data?.serialExportNotes?.create?.id;
          notify.success({ message: t("serialIE.notify.successCreate") });
          history.push(`/serial-export/detail/${idParams}`);
        }
      }
    } catch (error) {
      notify.error({
        message: error.message,
      });
    } finally {
      setLoadingCreate(false);
      setVisibleCreateModel(false);
    }
  };
  const initialValues = {
    exportType: exportType,
    sellerID: null,
    orderID: JSON.parse(queryParams.get("orderId")),
    providerID: null,
    note: null,
    items: [],
    warehouseID: null,
    warrantyItem: null,
  };

  const checkTypeTitleInfo = () => {
    switch (exportType) {
      case EXPORT_TYPE.ORDER:
        return t("serialIE.infoOrder");
      case EXPORT_TYPE.WARRANTY:
        return t("serialIE.infoSeller");
      case EXPORT_TYPE.RETURN_SUPPLIER:
        return t("warranty.warrantyDetail.infoNCC");
      case EXPORT_TYPE.TRANSFER_SERIAL:
        return "Thông tin kho xuất";
      case EXPORT_TYPE.OTHER:
        return "Thông tin kho xuất";
      default:
        return [];
    }
  };

  useEffect(() => {
    setOrderInfo(null);
    setSellerInfo(null);
    setWarehouseInfo(null);
    setDataTableTransferSerial([]);
    setDataTableOrder([]);
    form.setFieldsValue({ ...initialValues, exportType: exportType });
  }, [exportType]);

  useEffect(() => {
    setOrderInfo(orderInfoState);

    const dataOrderTable = orderInfoState?.items?.map((item) => (item?.orderItems ? item?.orderItems : [item]))?.flat();

    const convertFillterData = dataOrderTable?.reduce((acc, item) => {
      const existingItem = acc.find(
        (entry) =>
          entry.productID === item.productID &&
          entry.price === item.price &&
          entry.productWarranty === item.productWarranty
      );
      if (existingItem) {
        existingItem.quantity += item.quantity;
        existingItem.total += item.total;
      } else {
        acc.push({ ...item });
      }
      return acc;
    }, []);
    const formData = form.getFieldsValue();
    form.setFieldsValue({
      ...formData,
      orderID: orderId,
    });
    const newData = convertFillterData?.map((item, index) => {
      return {
        ...item,
        productID: item?.productID,
        warrantyUnit: item?.warrantyDateUnit,
        warrantyOnSale: item?.productWarranty,
        serialItems: [],
      };
    });
    form.setFieldsValue({
      ...formData,
      items: newData,
    });
    setDataTableOrder(newData);
  }, [orderInfoState]);

  return {
    form,
    warehouseOptions,
    paginationData,
    hasMoreOrder,
    loadMoreOrder,
    loadingOrders,
    orders,
    initialValues,
    sellerInfo,
    exportType,
    orderInfo,
    dataTableOrder,
    handleValuesChange,
    handleChangeInfo,
    handleGetTags,
    tags,
    onCancelCreate,
    handleChange,
    visibleCreateModal,
    showOnModalConfirmCreate,
    showOffModalConfirmCreate,
    sellers,
    loadingSellers,
    handleGetTableOrder,
    onSearch,
    sumProductByOrder,
    sumSerialByOrder,
    sumSerialScanned,
    handleFinish,
    handleSubmitCreateExportNotes,
    loadingCreate,
    isStateLocation: orderInfoState,
    idOrderStateLocation: orderId,
    loadingOrder,
    checkTypeTitleInfo,
    dataSupplier,
    onLoadMoreDataSupplier,
    hasMoreDataSupplier,
    loadingSupplier,
    supplierInfo,
    dataTableSupplier,
    handleSetFieldSupplier,
    handleRemoveItemReturnSupplier,
    formData,
    handleChangeWarehouse,
    warehouse,
    visiblePopconfirmWarehouse,
    handleCancelPopconfirmWarehouse,
    handleConfirmPopconfirmWarehouse,
    visiblePopconfirmProvider,
    handleCancelPopconfirmProvider,
    handleConfirmPopconfirmProvider,
    handleSelectWarehouse,
    warehouseInfo,
    loadingWarehouse,
    handleSetFieldTransferSerial,
    dataTableTransferSerial,
    handleRemoveItemTransferSerial,
    handleDeleteAllSerial,
  };
}

export default useCreate;

import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import AccordingSerial from '../components/AccordingSerial';
import AccordingWarrantyRequest from '../components/AccordingWarrantyRequest';
import { buildQueryString, getQuery } from 'utils/helperFuncs';

const useList = () => {
  const GET_QUERY = getQuery();
  const [activeKeyMain, setActiveKeyMain] = useState(
    GET_QUERY.activeKey || 'accordingWarrantyRequest'
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeKeyStatus, setActiveKeyStatus] = useState(GET_QUERY.key);
  const handleChangeTab = (activeKey) => {
    setActiveKeyMain(activeKey);
    setActiveKeyStatus('ALL');
    buildQueryString({
      params:
        activeKey === 'accordingWarrantyRequest'
          ? { activeKey: activeKey }
          : { activeKey: activeKey, key: 'ALL' },
    });
  };
  const handleChangeTabStatus = (value) => {
    setActiveKeyStatus(value);
  };

  const tabsMain = [
    {
      title: t('warranty.warrantyRequestList.accordingWarrantyRequest'),
      children: <AccordingWarrantyRequest />,
      key: 'accordingWarrantyRequest',
    },
    {
      title: t('warranty.warrantyRequestList.accordingSerial'),
      children: (
        <AccordingSerial
          onChangeTabStatus={handleChangeTabStatus}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        />
      ),
      key: 'accordingSerial',
    },
  ];

  useEffect(() => {
    buildQueryString({
      params: {
        activeKey: GET_QUERY.activeKey,
        key: GET_QUERY.key,
      },
    });
  }, [GET_QUERY.activeKey]);

  return {
    tabsMain,
    activeKeyMain,
    handleChangeTabStatus,
    activeKeyStatus,
    selectedRowKeys,
    handleChangeTab,
    selectedRows,
    setSelectedRowKeys,
  };
};

export default useList;

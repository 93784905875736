import React from 'react';
import { Typography } from 'antd';
import CustomModal from 'components/CustomModal';
import { useRejectBid } from 'hooks/priceBid/mutation';
import { t } from 'i18next';
import { formatCurrency, notify } from 'utils/helperFuncs';

const PopupRejectBid = ({ record }) => {
  const { handleReject, loading } = useRejectBid();

  const onReject = async () => {
    try {
      await handleReject({ priceBidSessionID: record?.id });
      notify.success({ message: t('product.priceBid.rejectRequestPriceBid') });
    } catch (error) {
      notify.error({ message: error?.message });
    }
  };
  return (
    <CustomModal
      footer={false}
      customComponent={<Typography.Text>{t('product.priceBid.Cancel')}</Typography.Text>}
      onOke={onReject}
      buttonLoading={loading}
      message={
        <Typography.Text>
          {t('product.priceBid.QuestionReject') +
            formatCurrency(record?.currentSuggestedPrice) +
            `đ/` +
            record?.product?.uom?.name +
            t('product.priceBid.give') +
            ' ' +
            record?.currentSuggestedQuantity +
            t('product.priceBid.Product') +
            ' : ' +
            record?.product?.name +
            t('product.priceBid.no') +
            '?'}
        </Typography.Text>
      }
    ></CustomModal>
  );
};

export default PopupRejectBid;

/* eslint-disable no-prototype-builtins */
import { useTranslation } from "react-i18next";
import { Tag, Tooltip } from "antd";

import { DATE_FORMAT, STATUS_ORDER } from "config/constants";
import { SELLER_CANCEL_REASON_LABEL, STAFF_CANCEL_REASON_LABEL } from "pages/App/Order/conts";
import { useOrder } from "pages/App/Order/hooks";
import { formatDateTime } from "utils/dateTime";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";

import "./index.scss";

const TagCustom = ({ label, filterText, handleOnClose }) => {
  return (
    <Tag closable onClose={handleOnClose} style={{ marginBottom: "2px" }}>
      <span className="label">{label}:</span>
      <span>&nbsp;{filterText}</span>
    </Tag>
  );
};

const TagsList = ({ params, setParams, statusOrder, filterTags }) => {
  const { t } = useTranslation();
  const { stockStatusOption } = useOrder();

  const isRenderTag = () => (!statusOrder ? true : false);
  const renderTag = (keyParam, label, isRender = true) => {
    if (isRender && params?.filters?.hasOwnProperty([`${keyParam}`])) {
      const dataFilter = filterTags[`${keyParam}`];

      //Array filter
      if (Array.isArray(filterTags[`${keyParam}`]) && filterTags[`${keyParam}`].length) {
        const text = dataFilter?.map((item) => item?.label)?.join(", ");
        return <TagCustom label={label} filterText={text} handleOnClose={() => handleCloseTag(keyParam)} />;
      }
      //Single filter
      if (!Array.isArray(params?.filters[`${keyParam}`]) && params?.filters[`${keyParam}`] !== undefined) {
        if (keyParam === "stockStatus") {
          return (
            <TagCustom
              label={label}
              filterText={stockStatusOption.find((item) => item.value === dataFilter)?.label}
              handleOnClose={() => handleCloseTag(keyParam)}
            />
          );
        }
        if (keyParam === "timeRange") {
          if (dataFilter?.from) {
            if (dataFilter?.to) {
              return (
                <TagCustom
                  label={label}
                  filterText={`${t("order.orderTag.from")} ${formatDateTime(dataFilter?.from, DATE_FORMAT)} ${t(
                    "order.orderTag.to"
                  )} ${formatDateTime(dataFilter?.to, DATE_FORMAT)}`}
                  handleOnClose={() => handleCloseTag(keyParam)}
                />
              );
            }
            return (
              <TagCustom
                label={label}
                filterText={`${t("common.day")} ${formatDateTime(dataFilter?.from, DATE_FORMAT)}`}
                handleOnClose={() => handleCloseTag(keyParam)}
              />
            );
          } else {
            return null;
          }
        }
        if (keyParam === "cancelReasons") {
          const cancelReasons = params?.filters?.cancelReasons;
          const staffLength = cancelReasons?.staff?.length;
          const sellerLength = cancelReasons?.seller?.length;

          if (staffLength || sellerLength) {
            return (
              <div>
                <Tag
                  style={{
                    display: "inline-flex",
                    height: "max-content",
                  }}
                  closable
                  onClose={() => handleCloseTag(keyParam)}
                >
                  <div style={{ width: "100%" }}>
                    {staffLength > 0 && (
                      <>
                        <span className="label">{t(STAFF_CANCEL_REASON_LABEL.BY_STAFF)}:</span>
                        <Tooltip
                          title={
                            cancelReasons?.staff?.length > 6 &&
                            cancelReasons?.staff?.map((item) => t(STAFF_CANCEL_REASON_LABEL?.[item])).join(", ")
                          }
                        >
                          &nbsp;
                          {truncateAddDots(
                            cancelReasons?.staff?.map((item) => t(STAFF_CANCEL_REASON_LABEL?.[item])).join(", "),
                            cancelReasons?.staff?.length > 6 ? 200 : 1000
                          )}
                        </Tooltip>
                      </>
                    )}
                    {staffLength && sellerLength ? <br /> : null}
                    {sellerLength > 0 && (
                      <>
                        <span className="label">{t(SELLER_CANCEL_REASON_LABEL.BY_SELLER)}:</span>
                        <span>
                          &nbsp;
                          {truncateAddDots(
                            cancelReasons?.seller?.map((item) => t(SELLER_CANCEL_REASON_LABEL?.[item])).join(", "),
                            190
                          )}
                        </span>
                      </>
                    )}
                  </div>
                </Tag>
              </div>
            );
          } else {
            return null;
          }
        }

        if (keyParam === "preOrder") {
          return (
            <TagCustom
              label={label}
              filterText={dataFilter ? t("order.approvalOrder.preOrder") : t("order.approvalOrder.regularOrder")}
              handleOnClose={() => handleCloseTag(keyParam)}
            />
          );
        }
        return (
          <TagCustom
            label={label}
            filterText={dataFilter ? t("common.yes") : t("common.no")}
            handleOnClose={() => handleCloseTag(keyParam)}
          />
        );
      }
    } else {
      return null;
    }
  };

  const handleCloseTag = (keyParam) => {
    const cloneParams = { ...params };
    delete cloneParams.filters[`${keyParam}`];
    setParams({ ...cloneParams });
  };

  const allTags = {
    exportedWarehouseIDs: {
      keyParam: "exportedWarehouseIDs",
      label: t("order.orderTag.exportWarehouse"),
    },
    priorities: {
      keyParam: "priorities",
      label: t("order.orderTag.priorities"),
    },
    shippingTypes: {
      keyParam: "shippingTypes",
      label: t("order.orderTag.shippingTypes"),
    },
    paymentMethods: {
      keyParam: "paymentMethods",
      label: t("order.orderTag.paymentMethods"),
    },
    statuses: {
      keyParam: "statuses",
      label: t("order.orderTag.statuses"),
      isRender: isRenderTag(),
    },
    fullStockRequired: {
      keyParam: "fullStockRequired",
      label: t("order.orderTag.fullStockRequired"),
    },
    vat: {
      keyParam: "vat",
      label: t("order.orderTag.vat"),
    },
    createdByIDs: {
      keyParam: "createdByIDs",
      label: t("order.orderTag.createdBy"),
    },
    stockStatus: {
      keyParam: "stockStatus",
      label: t("order.orderTag.stockStatus"),
    },
    timeRange: {
      keyParam: "timeRange",
      label: t("order.orderTag.timeRange"),
    },
    preOrder: {
      keyParam: "preOrder",
      label: t("order.orderTag.orderType"),
    },
    paymentStatuses: {
      keyParam: "paymentStatuses",
      label: t("order.orderList.statusPayment"),
    },
    salesChannel: {
      keyParam: "saleChannelIDs",
      label: "Kênh bán",
    },
    cancelReason: {
      keyParam: "cancelReasons",
      label: "Lý do huỷ đơn",
    },
  };

  const {
    exportedWarehouseIDs,
    priorities,
    shippingTypes,
    paymentMethods,
    statuses,
    fullStockRequired,
    vat,
    createdByIDs,
    stockStatus,
    timeRange,
    preOrder,
    paymentStatuses,
    salesChannel,
    cancelReason,
  } = allTags;

  const tagsRender = () => {
    switch (statusOrder) {
      case STATUS_ORDER.ALL:
        return [
          preOrder,
          exportedWarehouseIDs,
          priorities,
          shippingTypes,
          paymentMethods,
          statuses,
          createdByIDs,
          timeRange,
          paymentStatuses,
          salesChannel,
        ];
      case STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER:
        return [
          preOrder,
          stockStatus,
          priorities,
          exportedWarehouseIDs,
          shippingTypes,
          vat,
          paymentMethods,
          paymentStatuses,
          salesChannel,
        ];
      case STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK:
        return [
          preOrder,
          exportedWarehouseIDs,
          priorities,
          shippingTypes,
          paymentMethods,
          fullStockRequired,
          vat,
          stockStatus,
          timeRange,
          paymentStatuses,
          salesChannel,
        ];
      case STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK:
        return [
          preOrder,
          exportedWarehouseIDs,
          priorities,
          fullStockRequired,
          shippingTypes,
          vat,
          paymentMethods,
          createdByIDs,
          stockStatus,
          timeRange,
          paymentStatuses,
          salesChannel,
        ];
      case STATUS_ORDER.WAITING_FOR_PAYMENT:
        return [
          preOrder,
          exportedWarehouseIDs,
          priorities,
          fullStockRequired,
          shippingTypes,
          vat,
          paymentMethods,
          createdByIDs,
          stockStatus,
          timeRange,
          paymentStatuses,
          salesChannel,
        ];
      case STATUS_ORDER.WAITING_FOR_EXPORTING:
        return [
          preOrder,
          exportedWarehouseIDs,
          priorities,
          shippingTypes,
          createdByIDs,
          timeRange,
          paymentStatuses,
          salesChannel,
        ];
      case STATUS_ORDER.WAITING_FOR_ASSEMBLY:
        return [
          preOrder,
          exportedWarehouseIDs,
          priorities,
          shippingTypes,
          createdByIDs,
          timeRange,
          paymentStatuses,
          salesChannel,
        ];
      case "WAITING_FOR_SHIPPING_NOT_SET":
        return [
          preOrder,
          priorities,
          paymentMethods,
          shippingTypes,
          createdByIDs,
          timeRange,
          paymentStatuses,
          salesChannel,
        ];
      case "WAITING_FOR_SHIPPING_WAITING":
        return [
          preOrder,
          priorities,
          paymentMethods,
          shippingTypes,
          createdByIDs,
          timeRange,
          paymentStatuses,
          salesChannel,
        ];
      case STATUS_ORDER.DELIVERING:
        return [preOrder, priorities, paymentMethods, createdByIDs, timeRange, paymentStatuses, salesChannel];
      case STATUS_ORDER.COMPLETED:
        return [
          preOrder,
          exportedWarehouseIDs,
          shippingTypes,
          vat,
          createdByIDs,
          timeRange,
          paymentStatuses,
          salesChannel,
        ];
      case STATUS_ORDER.CANCELED:
        return [
          preOrder,
          exportedWarehouseIDs,
          priorities,
          fullStockRequired,
          shippingTypes,
          vat,
          paymentMethods,
          createdByIDs,
          stockStatus,
          timeRange,
          paymentStatuses,
          salesChannel,
          cancelReason,
        ];
      default:
        return [];
    }
  };

  return <div className="tags-list">{tagsRender().map((tag) => renderTag(tag.keyParam, tag.label))}</div>;
};

export default TagsList;

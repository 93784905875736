import React from 'react';
import { Button, Col, Form, Row, Typography } from 'antd';
import CustomModal from 'components/CustomModal';
import { t } from 'i18next';
import FormInput from 'components/FormInput';
import useFormEditCustomerRequest from './useFormEditCustomerRequest';
import EditIcon from '../EditIcon';
import { checkIcon } from 'features/WarrantyRequest/constant';

function FormEditCustomerInfoRequest({ data }) {
  const {
    formFields,
    form,
    handleOk,
    loading,
    isModalOpen,
    showModal,
    handleCancel,
    initialValues,
    isCompleted,
    isCanceled,
    isDelivering,
  } = useFormEditCustomerRequest({ data });

  return (
    <CustomModal
      {...{
        okeButtonLabel: t('common.save'),
        customComponent: <EditIcon status={checkIcon(isCompleted || isCanceled || isDelivering)} />,
        addMoreButton: (
          <Button type="primary" onClick={handleOk} loading={loading}>
            {t('common.save')}
          </Button>
        ),
        title: (
          <Typography.Text strong style={{ fontSize: '20px' }}>
            {t('warrantyRequest.detail.customerInfoRequest')}
          </Typography.Text>
        ),
        hideConfirmButton: true,
        width: 'fit-content',
        centered: true,
        footer: false,
        visible: isModalOpen,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={16}>
          {formFields.map((field, index) => {
            return (
              <Col span={12} key={index}>
                <FormInput {...field} />
              </Col>
            );
          })}
        </Row>
      </Form>
    </CustomModal>
  );
}

export default FormEditCustomerInfoRequest;

import CustomModal from 'components/CustomModal';
import { t } from 'i18next';
import React from 'react';

const ModalRemoveCart = ({ open, onCancel, onSubmit, buttonLoading }) => {
  return (
    <CustomModal
      title={<b>{t('cart.removeCartConfirmationMessage')}</b>}
      footer={false}
      centered={true}
      visible={open}
      onCancel={onCancel}
      selfVisibleControlled={false}
      onOke={onSubmit}
      buttonLoading={buttonLoading}
    ></CustomModal>
  );
};

export default ModalRemoveCart;

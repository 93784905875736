import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import PageHeader from 'components/PageHeader';
import { FontAwesomeIcon, Icon } from 'assets/icons';
import ProdAttTable from './components/ProdAttTable';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetAttributeList, useUpdateAttributeStatus } from 'hooks/attribute';
import { buildQueryString, debounce, getQuery } from 'utils/helperFuncs';
import TagsList from './components/TagList';

import './index.scss';

const ProductAttribute = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const GET_QUERY = getQuery();

  const defaultParams = {
    filters: {
      query: GET_QUERY.query || undefined,
      isCommon: GET_QUERY.isCommon,
      isActive: GET_QUERY.isActive,
    },
  };

  const [params, setParams] = useState(defaultParams);

  const { loading, data, refetch } = useGetAttributeList(params);
  const { handleUpdateAttributeStatus } = useUpdateAttributeStatus();

  const handleCreateProdAtt = () => {
    history.push('/product-attribute/create');
  };

  const handleChangeStatus = async (id, status) => {
    await handleUpdateAttributeStatus({
      ids: [id],
      isActive: status,
    });
  };

  //Filter attribute by text
  function filterAttByText([text]) {
    if (text[0] === ' ') {
      return;
    }

    let newParams = {
      ...params,
      filters: {
        ...params.filters,
        query: text.length > 0 ? text : undefined,
      },
    };
    setParams(newParams);
  }
  const filterAttByTextDebounced = debounce(filterAttByText, 800);

  function filterAttributeExtra(values) {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...values,
      },
    };

    setParams(newParams);
  }

  useEffect(() => {
    refetch();

    const paramsFilter = params.filters;
    const queryString = {
      query: paramsFilter.query || undefined,
      isCommon: paramsFilter.isCommon,
      isActive: paramsFilter.isActive,
    };
    buildQueryString({ params: { ...queryString } });
  }, [params]);

  return (
    <>
      <div className="product-attribute-container">
        <PageHeader
          pageTitle={t('common.categoryAttribute')}
          routes={[
            {
              path: '/product',
              name: t('common.product'),
            },
            {
              path: '',
              name: t('common.categoryAttribute'),
            },
          ]}
        />

        <div className="filter-box">
          <div className="search-input">
            <Input
              className="custom-input"
              prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
              placeholder={t('product.categoryAttribute.placeholder')}
              allowClear={true}
              onChange={(e) => {
                filterAttByTextDebounced(e.target.value);
              }}
            />
          </div>

          <Button type="primary" onClick={handleCreateProdAtt}>
            {t('product.categoryAttribute.createNew')}
          </Button>
        </div>

        <TagsList listFilter={params.filters} params={params} setParams={setParams} />

        <ProdAttTable
          tableData={data}
          loading={loading}
          onFilter={filterAttributeExtra}
          defaultParams={defaultParams}
          onChangeStatus={handleChangeStatus}
        />
      </div>
    </>
  );
};

export default ProductAttribute;

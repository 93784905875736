import React from 'react';
import { t } from 'i18next';
import CustomTags from 'components/CustomTags';

const useFiltersTag = ({ params, handleRemoveFilterTag, staffOptions }) => {
  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    if (
      typeof arrValue !== 'undefined' &&
      arrValue !== null &&
      typeof arrValue[Symbol.iterator] === 'function'
    ) {
      for (const element of arrValue) {
        const item = arrTags?.find(({ value }) => element === value);
        result.push(item);
      }
    }
    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(', ')}
      </div>
    );
  };

  const tagsCreatedByID = [
    {
      title: t('product.PEOPLE_CHANGE'),
      options: staffOptions,
      selected: params?.filters?.createdByIDs,
      key: 'createdByIDs',
    },
  ];

  const renderTagsCreatedByID = tagsCreatedByID.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });

  return {
    renderTagsCreatedByID,
  };
};

export default useFiltersTag;

import { gql } from '@apollo/client';

export const GET_UOM_QUERY = gql`
  query GET_UOM_QUERY {
    uom {
      list {
        id
        name
      }
    }
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, Select } from "antd";
import { CustomPaginationPrevNext } from "components/CustomPaginationPrevNext";
import CustomTable from "components/CustomTable";
import { t } from "i18next";
import { FiltersTag } from "../../../components/FiltersTag";
import { VALUE_TAGS_IMPORT_NOTE_TYPE } from "../../../constant";
import "./index.scss";
import useList from "./useList";

function ListByImportSerial() {
  const {
    onChangeBranchesId,
    valueFormSelectCity,
    columns,
    loading,
    paginationData,
    data,
    onSearch,
    handleChangeTable,
    branchesID,
    handleRemoveFilterTag,
    staffs,
    params,
    handlePrevNextPage,
  } = useList();

  return (
    <div className="list-by-import-serial">
      <Form
        name="basic"
        autoComplete="off"
        style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <Col span="10">
          <Form.Item>
            <Input placeholder={t("serialIE.searchSerial")} onChange={onSearch} prefix={<SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col span="3">
          <Form.Item>
            <Select options={valueFormSelectCity} onChange={(e) => onChangeBranchesId(e)} value={branchesID} />
          </Form.Item>
        </Col>
      </Form>
      <div style={{ display: "flex", gap: "10px" }}>
        <FiltersTag
          staffOptions={staffs}
          params={params}
          importNotTypeOptions={VALUE_TAGS_IMPORT_NOTE_TYPE}
          handleRemoveFilterTag={handleRemoveFilterTag}
        />
      </div>
      <CustomTable
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record?.id}
        onChange={() => handleChangeTable}
      />
      {paginationData && (
        <CustomPaginationPrevNext handlePrevNextPage={handlePrevNextPage} paginationData={paginationData} />
      )}
    </div>
  );
}

export default ListByImportSerial;

import { useCreateSellerContact } from 'hooks/seller';
import { t } from 'i18next';
import { useState } from 'react';
import { notify } from 'utils/helperFuncs';
import { Form } from 'antd';

const useModalAdd = ({ sellerID }) => {
  const [switchClose, setSwitchClose] = useState(true);
  const { handleCreateContact, loading: creating } = useCreateSellerContact();

  const [form] = Form.useForm();

  async function createContact() {
    const values = await form.validateFields();
    try {
      await handleCreateContact({
        contact: {
          ...values,
          email: values?.email || null,
          sellerID,
        },
      });
      notify.success({
        message: t('seller.componentAddressList.messageCreateAddressSuccess'),
      });
      setSwitchClose(!switchClose);
      form.resetFields();
    } catch (err) {
      notify.error({
        message: t('seller.componentAddressList.messageCreateAddressError'),
      });
    }
  }

  const initialValues = {
    isDefault: false,
  };

  return {
    createContact,
    creating,
    switchClose,
    form,
    initialValues,
  };
};

export default useModalAdd;

import CustomModal from 'components/CustomModal';
import { t } from 'i18next';
import React from 'react';

const ModalMaximumCart = ({ open, onCancel }) => {
  return (
    <CustomModal
      title={<b>{t('cart.cloneCartValidate')}</b>}
      footer={false}
      centered={true}
      visible={open}
      onCancel={onCancel}
      selfVisibleControlled={false}
      hideConfirmButton={true}
    ></CustomModal>
  );
};

export default ModalMaximumCart;

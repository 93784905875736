import React from 'react';
import CustomTable from 'components/CustomTable';
import useHistory from './useHistory';

const History = () => {
  const { columns, warrantyHistory, loading } = useHistory();
  return (
    <CustomTable
      loading={loading}
      rowKey={(record) => record?.id}
      columns={columns}
      dataSource={warrantyHistory}
    />
  );
};

export default History;

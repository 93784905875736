import { useTranslation } from 'react-i18next';
import { CHART_TYPE } from '../../constants';

const useChartSelectUser = (reportPermission) => {
  const { t } = useTranslation();

  const { canViewSale, canViewManager } = reportPermission;
  const chartTypeOptions = [
    {
      value: CHART_TYPE.PERSONAL,
      label: t('report.personalReport'),
      permission: canViewSale,
    },
    {
      value: CHART_TYPE.ENTERPRISE,
      label: t('report.enterpriseReport'),
      permission: canViewManager,
    },
  ];

  const filteredTypeOptions = chartTypeOptions
    .filter((item) => item.permission)
    .map(({ label, value }) => ({
      label,
      value,
    }));

  return { filteredTypeOptions };
};

export default useChartSelectUser;

import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

function SerialLink({ serial }) {
  return (
    <Link to={`/warranty/serial/detail/${serial?.id}`}>
      <Typography.Link strong>{serial?.serial}</Typography.Link>
    </Link>
  );
}

export default SerialLink;

import { MinusOutlined } from "@ant-design/icons";
import { Checkbox, Col, Row, Space, Typography } from "antd";
import CustomCard from "components/CustomCard";
import CustomDrawer from "components/CustomDrawer";
import { useEffect, useState } from "react";
import BadgeStatusDot from "../components/BadgeStatusDot";
import DividerCustom from "../components/DividerCustom";
import StepsCustom from "../components/StepsCustom";
import useResizeDrawer from "../helpers/useResizeDrawer";

import EllipsisText from "components/_storybooks/EllipsisText";
import CustomDescriptions from "components/CustomDescriptions";
import CustomTable from "components/CustomTable";
import { DATE_TIME_FORMAT4 } from "config/constants";
import { convertOrderStatus } from "features/Agent/constants";
import { compact } from "lodash";
import {
  renderColorTagByPaymentStatus,
  renderPaymentMethod,
  renderStringTagByPaymentStatus,
  tagColorSalesChannel,
} from "pages/App/Order/conts";
import { formatDateTime } from "utils/dateTime";
import { formatCurrency, getQuery } from "utils/helperFuncs";
import ButtonCancelOrder from "../components/ButtonCancelOrder";
import ButtonCompleteReconciliation from "../components/ButtonCompleteReconciliation";
import ButtonRejectReconciliation from "../components/ButtonRejectReconciliation";
import ButtonStartReconciliation from "../components/ButtonStartReconciliation";
import ReasonBlock from "../components/ReasonBlock";
import SpaceCustom from "../components/SpaceVertical";
import TagCustom from "../components/TagCustom";
import { PROFIT_RECONCILIATION, reconciliationStatusConverter } from "../constants";
import "./index.scss";

const defaultDrawerHeight = window.innerHeight * 0.7;
const minDrawerHeight = window.innerHeight * 0.3;
const maxDrawerHeight = window.innerHeight * 0.95;

function ProfitReconciliationDetail({
  visible,
  onClose,
  dataOrderReconciliationDetail,
  loadingOrderReconciliationDetail,
}) {
  const GET_QUERY = getQuery();
  const order = dataOrderReconciliationDetail?.order;
  const isExpiredDate =
    dataOrderReconciliationDetail?.status !== "WAITING_FOR_PAYMENT" && dataOrderReconciliationDetail?.expiredDate;
  const { drawerHeight, handleMouseDown } = useResizeDrawer({
    defaultHeight: defaultDrawerHeight,
    minHeight: minDrawerHeight,
    maxHeight: maxDrawerHeight,
    visible,
  });

  const [isHidden, setIsHidden] = useState(true);

  const columns = [
    {
      title: "STT",
      width: 60,
      align: "center",
      fixed: "left",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Thông số",
      width: 250,
      fixed: "left",
      render: (_, record) => {
        return (
          <SpaceCustom>
            <Typography.Link strong>{record?.product?.code}</Typography.Link>
            <EllipsisText title={record?.product?.name} />
          </SpaceCustom>
        );
      },
    },
    {
      title: "Xuất VAT",
      width: 100,
      align: "center",
      render: (_, record) => {
        return <Checkbox style={{ opacity: 0.5 }} value={record?.vat} disabled />;
      },
    },

    {
      title: "Số lượng",
      align: "center",
      width: 100,
      render: (_, record) => {
        return record?.quantity;
      },
    },

    {
      title: "Đơn giá đại lý",
      align: "right",
      width: 150,
      render: (_, record) => {
        return formatCurrency(record?.price);
      },
    },

    {
      title: "Thành tiền đại lý",
      align: "right",
      width: 150,
      render: (_, record) => {
        return <Typography.Link>{formatCurrency(record?.total)}</Typography.Link>;
      },
    },

    {
      title: "Đơn giá khách hàng",
      width: 170,
      align: "right",
      render: (_, record) => {
        return formatCurrency(record?.customerPrice);
      },
    },

    {
      title: "Thành tiền khách hàng",
      width: 180,
      align: "right",
      render: (_, record) => {
        return <Typography.Link strong>{formatCurrency(record?.customerTotal)}</Typography.Link>;
      },
    },

    {
      title: "Bảo hành (tháng)",
      width: 100,
      align: "center",
      render: (_, record) => {
        return record?.productWarranty;
      },
    },

    {
      title: "Được nợ (ngày)",
      width: 100,
      align: "center",
      render: (_, record) => {
        return record?.maxDaysOfDebt;
      },
    },

    {
      title: "Lợi nhuận của đại lý",
      width: 140,
      align: "right",
      fixed: "right",
      render: (_, record) => {
        return (
          <TagCustom color="#E9F9E9" style={{ color: "#0EAB10" }}>
            {formatCurrency(record?.customerTotal - record?.total)}
          </TagCustom>
        );
      },
    },
  ];

  const handleToggle = () => {
    setIsHidden(!isHidden);
  };

  const isShowViaStatus = (status, condition) => {
    switch (condition) {
      case "ButtonCancelOrder":
        return status === PROFIT_RECONCILIATION.WAITING_FOR_PAYMENT || status === PROFIT_RECONCILIATION.RECONCILING;
      case "ButtonStartReconciliation":
        return status === PROFIT_RECONCILIATION.WAITING_FOR_PAYMENT;
      case "ButtonRejectReconciliation":
      case "ButtonCompleteReconciliation":
        return status === PROFIT_RECONCILIATION.RECONCILING;
      case "ReasonBlock":
        return status === PROFIT_RECONCILIATION.REJECTED || status === PROFIT_RECONCILIATION.CANCELED;
      default:
        return false;
    }
  };

  useEffect(() => {
    setIsHidden(true);
  }, [visible]);

  return (
    <CustomDrawer
      visible={visible}
      onClose={onClose}
      closable={true}
      placement="bottom"
      height={drawerHeight}
      bodyStyle={{ background: "#F7F8FB", height: drawerHeight }}
      maskClosable={false}
      mask={false}
      customTitle={
        <>
          <MinusOutlined
            onMouseDown={handleMouseDown}
            style={{
              width: "100%",
              cursor: "ns-resize",
              position: "absolute",
              left: 0,
              zIndex: 100,
              padding: 0,
              top: "-5px",
              right: 0,
            }}
          />

          <Space style={{ paddingLeft: 30, width: "100%", justifyContent: "space-between", flexWrap: "wrap" }}>
            <Space>
              <Space style={{ width: "100%" }}>
                <Typography.Text strong style={{ paddingRight: "12px" }}>
                  Đơn hàng
                </Typography.Text>
                <Typography.Link strong>{dataOrderReconciliationDetail?.order?.number || "--"}</Typography.Link>
              </Space>
              <BadgeStatusDot
                status={reconciliationStatusConverter(dataOrderReconciliationDetail?.status)?.status}
                text={reconciliationStatusConverter(dataOrderReconciliationDetail?.status)?.text}
              />
            </Space>
            <Space>
              {isShowViaStatus(dataOrderReconciliationDetail?.status, "ButtonCancelOrder") && (
                <ButtonCancelOrder data={dataOrderReconciliationDetail} />
              )}
              {isShowViaStatus(dataOrderReconciliationDetail?.status, "ButtonStartReconciliation") && (
                <ButtonStartReconciliation data={dataOrderReconciliationDetail} />
              )}
              {isShowViaStatus(dataOrderReconciliationDetail?.status, "ButtonRejectReconciliation") && (
                <>
                  <ButtonRejectReconciliation data={dataOrderReconciliationDetail} />
                  <ButtonCompleteReconciliation data={dataOrderReconciliationDetail} />
                </>
              )}
            </Space>
          </Space>
        </>
      }
      content={
        <Row gutter={[16, 8]} className="content-profit-reconciliation">
          <Col span={24}>
            {(GET_QUERY.activeKey === "CANCELED" || GET_QUERY.activeKey === "REJECTED") && (
              <ReasonBlock
                isCanceled={dataOrderReconciliationDetail?.status === "CANCELED"}
                isRejected={dataOrderReconciliationDetail?.status === "REJECTED"}
                rejectedReason={dataOrderReconciliationDetail?.rejectedReason}
                fullEvents={order?.fullEvents}
                loading={loadingOrderReconciliationDetail}
              />
            )}
          </Col>
          <Col span={24}>
            <CustomCard
              content={
                <div>
                  <SpaceCustom>
                    <Row style={{ flexWrap: "wrap" }}>
                      <Col xl={isExpiredDate ? 18 : 24} sm={isExpiredDate ? 14 : 24}>
                        <DividerCustom fontWeight="bold" children={"Trạng thái đối soát"} margin={0} />
                      </Col>
                      {isExpiredDate && (
                        <Col xl={6} sm={10}>
                          <DividerCustom
                            colorText="#5B6673"
                            children={
                              dataOrderReconciliationDetail
                                ? `Hạn chót đối soát ${formatDateTime(
                                    dataOrderReconciliationDetail?.expiredDate,
                                    DATE_TIME_FORMAT4,
                                    0
                                  )}`
                                : DATE_TIME_FORMAT4
                            }
                            orientationMargin="0"
                            margin={0}
                          />
                        </Col>
                      )}
                    </Row>
                    <div className={`full-events ${isHidden ? "fade-out" : "fade-in"}`}>
                      <StepsCustom dataSteps={dataOrderReconciliationDetail?.fullEvents || []} />
                    </div>
                  </SpaceCustom>
                  <div style={{ textAlign: "center" }}>
                    <Typography.Link onClick={handleToggle}>
                      {isHidden ? "Xem chi tiết trạng thái đối soát" : "Thu gọn"}
                    </Typography.Link>
                  </div>
                </div>
              }
            />
          </Col>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={16} className="card-block">
                <CustomCard
                  content={
                    <SpaceCustom gap={12}>
                      <DividerCustom fontWeight="bold" children={"Thông tin đơn hàng"} margin={0} />
                      <CustomDescriptions
                        loading={loadingOrderReconciliationDetail}
                        contentMapping={[
                          {
                            label: "Trạng thái đơn hàng",
                            value: convertOrderStatus(order?.status),
                          },
                          {
                            label: "Trạng thái thanh toán",
                            value: (
                              <TagCustom color={renderColorTagByPaymentStatus(order?.paymentStatus)}>
                                {renderStringTagByPaymentStatus(order?.paymentStatus)}
                              </TagCustom>
                            ),
                          },
                          {
                            label: "Người nhận",
                            value: (
                              <SpaceCustom>
                                <div>
                                  <Typography.Text>
                                    {order?.receiverContact?.fullName}
                                    <DividerCustom type="vertical" />
                                    {order?.receiverContact?.telephone}
                                  </Typography.Text>
                                </div>
                                <EllipsisText
                                  style={{ color: "#5B6673" }}
                                  title={compact([
                                    order?.receiverContact?.address,
                                    order?.receiverContact?.ward?.name,
                                    order?.receiverContact?.district?.name,
                                    order?.receiverContact?.city?.name,
                                  ]).join(", ")}
                                />
                              </SpaceCustom>
                            ),
                          },
                          {
                            label: "Đại lý",
                            value: (
                              <SpaceCustom>
                                <Typography.Link target="_blank" strong>
                                  {order?.seller?.code}
                                </Typography.Link>
                                <EllipsisText title={order?.seller?.fullName} />
                              </SpaceCustom>
                            ),
                          },
                          {
                            label: "Tài khoản tạo đơn",
                            value: (
                              <Typography.Text>
                                {order?.createdBy?.fullname} <DividerCustom type="vertical" />
                                <span style={{ color: "#5B6673" }}> {order?.createdBy?.email}</span>
                              </Typography.Text>
                            ),
                          },
                          {
                            label: "Kho xuất hàng",
                            value: order?.exportedWarehouse?.name,
                          },
                          {
                            label: "Phương thức thanh toán",
                            value: renderPaymentMethod(order?.paymentMethod),
                          },
                          {
                            label: "Kênh bán",
                            value: (
                              <TagCustom color={tagColorSalesChannel?.[order?.saleChannel?.type]}>
                                {order?.saleChannel?.name}
                              </TagCustom>
                            ),
                          },
                        ]}
                      />
                    </SpaceCustom>
                  }
                />
              </Col>
              <Col span={8} className="card-block">
                <CustomCard
                  content={
                    <SpaceCustom gap={12}>
                      <DividerCustom fontWeight="bold" children={"Hóa đơn"} margin={0} />
                      <CustomDescriptions
                        loading={loadingOrderReconciliationDetail}
                        bordered={false}
                        colon={false}
                        labelWidth={"50%"}
                        contentWidth={"50%"}
                        labelStyle={{
                          fontWeight: "normal",
                        }}
                        contentStyle={{
                          justifyContent: "flex-end",
                        }}
                        paddingView="0 10px 0 10px"
                        title={<Typography.Text style={{ fontSize: 14 }}>Hóa đơn cho khách của đại lý</Typography.Text>}
                        contentMapping={[
                          {
                            label: "Tổng tạm tính khách hàng",
                            value: formatCurrency(order?.customerSubTotal),
                          },
                          {
                            label: "Phí vận chuyển",
                            value: formatCurrency(order?.customerShippingFee),
                          },
                          {
                            label: "Phí dịch vụ",
                            value: formatCurrency(order?.customerTotalServiceProduct),
                          },
                          {
                            label: <Typography.Link strong>Tổng thanh toán của khách</Typography.Link>,
                            value: <Typography.Link strong>{formatCurrency(order?.customerTotal)}</Typography.Link>,
                          },
                        ]}
                      />
                      <DividerCustom dashed margin={0} borderTopColor="#D8DFEA" />
                      <CustomDescriptions
                        loading={loadingOrderReconciliationDetail}
                        bordered={false}
                        colon={false}
                        labelWidth={"50%"}
                        contentWidth={"50%"}
                        labelStyle={{
                          fontWeight: "normal",
                        }}
                        contentStyle={{
                          justifyContent: "flex-end",
                        }}
                        paddingView="0 10px 0 10px"
                        title={<Typography.Text style={{ fontSize: 14 }}>Hóa đơn cho đại lý</Typography.Text>}
                        contentMapping={[
                          {
                            label: "Doanh thu bán hàng",
                            value: formatCurrency(order?.customerSubTotal),
                          },
                          {
                            label: "Tổng thành tiền đại lý",
                            value: formatCurrency(order?.subTotal),
                          },
                          {
                            label: "Giảm giá đơn hàng",
                            value: formatCurrency(order?.discount),
                          },
                          {
                            label: "Lợi nhuận trước chi phí",
                            value: (
                              <Typography.Text strong style={{ color: "#0099FF" }}>
                                {formatCurrency(order?.customerSubTotal - (order?.subTotal - order?.discount))}
                              </Typography.Text>
                            ),
                          },
                          {
                            label: "Phí vận chuyển",
                            value: formatCurrency(order?.shippingFee),
                          },
                          {
                            label: "Phí dịch vụ",
                            value: formatCurrency(order?.totalServiceProduct),
                          },
                          {
                            label: <Typography.Link strong>Lợi nhuận của đại lý</Typography.Link>,
                            value: (
                              <TagCustom color="#E9F9E9" style={{ color: "#0EAB10", margin: 0 }}>
                                {formatCurrency(order?.sellerProfitAmount)}
                              </TagCustom>
                            ),
                          },
                        ]}
                      />
                    </SpaceCustom>
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <CustomCard
              content={
                <SpaceCustom gap={12}>
                  <DividerCustom fontWeight="bold" children={"Danh sách sản phẩm"} margin={0} />
                  <CustomTable
                    loading={loadingOrderReconciliationDetail}
                    columns={columns}
                    dataSource={order?.items || []}
                  />
                </SpaceCustom>
              }
            />
          </Col>
        </Row>
      }
    />
  );
}

export default ProfitReconciliationDetail;

import { useMutation, useQuery } from "@apollo/client";
import { USER_PERMISSIONS } from "config/constants";
import { GET_CONTACTS } from "graphql/contact/query";
import {
  APPROVE,
  CREATE_CONTACT_MUTATION,
  CREATE_SELLER_GROUP,
  CREATE_SELLER_MUTATION,
  DELETE_CONTACT_MUTATION,
  INVITE_SELLERS,
  MERGE_SELLER,
  RECEIVE,
  REJECT,
  REPROCESS,
  UPDATE_CONTACT_MUTATION,
  UPDATE_IDENTITY_SELLER,
  UPDATE_SELLER_GROUP,
  UPDATE_SELLER_MUTATION,
  UPDATE_SELLER_SALE_CATEGORIES,
  UPDATE_SELLER_STATUS,
} from "graphql/seller/mutation";
import {
  GET_ACCOUNTANT_CUSTOMER,
  GET_ACTIVITY_LOGS,
  GET_CATEGORIES_SELLER,
  GET_CREATE_CART_SELLERS,
  GET_CUSTOMER_LOADMORE,
  GET_DETAIL_SELLER_CUSTOMER,
  GET_ORDERS_FOR_ACTIVITY_LOGS,
  GET_SELLER_CONTACT,
  GET_SELLER_CUSTOMER,
  GET_SELLER_DETAIL,
  GET_SELLER_GROUP_WITH_IDS,
  GET_SELLER_WITH_IDS,
  GET_SELLERS,
  GET_SELLERS_CREATE_EXPORT_SERIAL,
  GET_SELLERS_FOR_CUSTOMER,
  GET_SELLERS_LOAD_MORE,
  GET_SELLERS_WITH_IDS,
  GET_SELLERS_WITH_WALLET,
  GET_SUMMARY_SELLER,
  GET_TOTAL_BY_STATUS,
  SELLER_LEVERS,
} from "graphql/seller/query";
import { useGetUserPermissions } from "hooks/user/user";
import { isEqual } from "lodash";
import { hasPermission } from "utils/helperFuncs";
import {
  convertActivityLogs,
  convertCreateCartSeller,
  convertDataSeller,
  convertGetOrders,
  convertSellerCustomer,
} from "./converter";

export const useGetSeller = ({ filters, pagination }) => {
  const { loading, data, refetch } = useQuery(GET_SELLERS, {
    variables: {
      filters,
      pagination,
    },
  });

  return {
    loading,
    data: data?.seller?.pagination?.sellers,
    total: data?.seller?.pagination?.paginationData?.total,
    refetch,
    paginationData: data?.seller?.pagination?.paginationData,
  };
};

export const useGetSellerWithWallet = ({ filters, pagination }) => {
  const { loading, data, refetch } = useQuery(GET_SELLERS_WITH_WALLET, {
    variables: {
      filters,
      pagination,
    },
  });

  return {
    loading,
    data: data?.seller?.pagination?.sellers,
    total: data?.seller?.pagination?.paginationData?.total,
    refetch,
  };
};

export const useGetSellerDetail = ({ id }) => {
  const { loading, data, fetchMore, refetch } = useQuery(GET_SELLER_DETAIL, {
    variables: {
      id,
    },
  });

  return {
    loading,
    data: data?.seller?.get,
    fetchMore,
    refetch,
  };
};

export const useUpdateSellerStatus = () => {
  const [updateSellerStatus, { loading }] = useMutation(UPDATE_SELLER_STATUS);

  const handleUpdateSellerStatus = async (params) => {
    return await updateSellerStatus({
      variables: params,
      refetchQueries: ["GetSupplierList", "GetSellers"],
    });
  };

  return {
    handleUpdateSellerStatus,
    loading,
  };
};

export const useCreateSeller = () => {
  const [createSeller, { loading }] = useMutation(CREATE_SELLER_MUTATION);

  const handleCreateSeller = async ({ sellerInfo, warehouseIDs, saleCategories, vatInfo }) => {
    await createSeller({ variables: { sellerInfo, warehouseIDs, saleCategories, vatInfo } });
  };

  return {
    handleCreateSeller,
    loading,
  };
};

export const useUpdateSeller = () => {
  const [updateSeller, { loading }] = useMutation(UPDATE_SELLER_MUTATION);

  const handleUpdateSeller = async ({ sellerInfo, warehouseIDs, saleCategories, vatInfo, sellerID }) => {
    await updateSeller({
      variables: { sellerInfo, warehouseIDs, saleCategories, vatInfo, sellerID },
    });
  };

  return {
    handleUpdateSeller,
    loading,
  };
};

export const useUpdateSellerSaleCategories = () => {
  const [updateSellerSaleCategories, { loading }] = useMutation(UPDATE_SELLER_SALE_CATEGORIES);

  const handleUpdateSellerSaleCategories = async ({ sellerID, saleCategories }) => {
    await updateSellerSaleCategories({ variables: { sellerID, saleCategories } });
  };

  return {
    handleUpdateSellerSaleCategories,
    loading,
  };
};

export const useGetSellerLevels = () => {
  const { data, loading } = useQuery(SELLER_LEVERS);

  return {
    sellerLevels: data?.sellerLevel?.list,
    loading,
  };
};

export const useGetSellerContact = ({ sellerID, query }) => {
  const { data, loading, refetch } = useQuery(GET_SELLER_CONTACT, {
    variables: {
      sellerID,
      query,
    },
  });

  return {
    sellerContacts: data?.contact?.list,
    loading,
    refetch,
  };
};

export const useCreateSellerContact = () => {
  const [create, { loading }] = useMutation(CREATE_CONTACT_MUTATION);

  const handleCreateContact = async ({ contact }) => {
    await create({ variables: { contact }, refetchQueries: [GET_CONTACTS] });
  };

  return {
    handleCreateContact,
    loading,
  };
};

export const useUpdateSellerContact = () => {
  const [updateContact, { loading }] = useMutation(UPDATE_CONTACT_MUTATION);

  const handleUpdateContact = async ({ contact, id }) => {
    await updateContact({ variables: { contact, id } });
  };

  return {
    handleUpdateContact,
    loading,
  };
};

export const useDeleteSellerContact = () => {
  const [deleteContact, { loading }] = useMutation(DELETE_CONTACT_MUTATION);

  const handleDeleteContact = async ({ id }) => {
    await deleteContact({ variables: { id } });
  };

  return {
    handleDeleteContact,
    loading,
  };
};

export const useGetSellerCreateCart = ({ filters, pagination }) => {
  const { loading, data, fetchMore } = useQuery(GET_CREATE_CART_SELLERS, {
    variables: {
      filters,
      pagination,
    },
    fetchPolicy: "cache-first",
  });

  const sellers = convertCreateCartSeller(data?.seller?.pagination?.sellers) || [];
  const currentTotalSellers = sellers.length;
  const paginationData = data?.seller?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: currentTotalSellers, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        const isSamePaginationData = isEqual(
          pv?.seller?.pagination?.paginationData,
          fetchMoreResult?.seller?.pagination?.paginationData
        );
        if (isSamePaginationData || !fetchMoreResult?.seller?.pagination?.sellers?.length === 0) return pv;
        const newData = {
          seller: {
            pagination: {
              paginationData: { ...fetchMoreResult?.seller?.pagination?.paginationData },
              sellers: [...pv?.seller?.pagination?.sellers, ...fetchMoreResult?.seller?.pagination?.sellers],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotalSellers === paginationData?.total);

  return {
    loading,
    sellers,
    onLoadMoreData,
    hasMoreData,
    data: data?.seller?.pagination?.sellers,
  };
};

export const useSellerPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();
  return {
    canView: hasPermission(permissions, [USER_PERMISSIONS.SELLER_VIEW]),
    canCreate: hasPermission(permissions, [USER_PERMISSIONS.SELLER_CREATE]),
    canUpdate: hasPermission(permissions, [USER_PERMISSIONS.SELLER_UPDATE]),
    canDeactivate: hasPermission(permissions, [USER_PERMISSIONS.SELLER_DEACTIVE]),
    canViewContact: hasPermission(permissions, [USER_PERMISSIONS.SELLER_CONTACT_VIEW]),
    canUpdateContact: hasPermission(permissions, [USER_PERMISSIONS.SELLER_CONTACT_UPDATE]),
    canDeleteContact: hasPermission(permissions, [USER_PERMISSIONS.SELLER_CONTACT_DELETE]),
    canSellerAccountApproval: hasPermission(permissions, [USER_PERMISSIONS.SELLER_ACCOUNT_APPROVAL]),
    loading,
  };
};

export const useInviteSellers = () => {
  const [inviteSellers, { loading }] = useMutation(INVITE_SELLERS);

  const handleInviteSellers = async (params) => {
    return await inviteSellers({
      variables: params,
    });
  };

  return {
    handleInviteSellers,
    loading,
  };
};

export const useGetOrdersForActivityLogs = (params) => {
  const { data, error, loading, refetch } = useQuery(GET_ORDERS_FOR_ACTIVITY_LOGS, {
    variables: params,
  });
  return {
    data: convertGetOrders(data?.order?.pagination?.orders) || [],
    paginationData: data?.order?.pagination?.paginationData,
    error,
    loading,
    refetch,
  };
};

export const useGetSummaryOrder = ({ id }) => {
  const { data } = useQuery(GET_SUMMARY_SELLER, {
    variables: {
      sellerIDs: [id],
    },
  });

  return {
    all: data?.all?.pagination?.paginationData?.total,
    completed: data?.completed?.pagination?.paginationData?.total,
    processing: data?.processing?.pagination?.paginationData?.total,
    cancel: data?.cancel?.pagination?.paginationData?.total,
  };
};

export const useGetSellerCustomer = (params) => {
  const { data, error, refetch, loading } = useQuery(GET_SELLER_CUSTOMER, {
    variables: params,
  });
  return {
    data: convertSellerCustomer(data?.sellerGroup?.pagination?.sellerGroups),
    paginationData: data?.sellerGroup?.pagination?.paginationData,
    error,
    loading,
    refetch,
  };
};

export const useGetAccountantSelect = (params) => {
  const { data, fetchMore } = useQuery(GET_ACCOUNTANT_CUSTOMER, {
    variables: {
      filters: {
        ...params?.filters,
      },
      pagination: params?.pagination,
    },
    fetchPolicy: "cache-first",
  });
  const paginationData = data?.user?.pagination?.paginationData;
  const currentTotal = data?.user?.pagination?.users?.length;
  const accountants = data?.user?.pagination?.users;
  const options = accountants?.map((item) => {
    return { label: item?.fullname, value: item?.id };
  });
  const loadMoreDataAccountantName = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: currentTotal, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        const isSamePaginationData = isEqual(
          pv?.user?.pagination?.paginationData,
          fetchMoreResult?.user?.pagination?.paginationData
        );
        if (isSamePaginationData || fetchMoreResult?.user?.pagination?.users.length === 0) return pv;
        const newData = {
          user: {
            pagination: {
              paginationData: { ...fetchMoreResult?.user?.pagination?.paginationData },
              users: [...pv?.user?.pagination?.users, ...fetchMoreResult?.user?.pagination?.users],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotal === paginationData?.total);

  return {
    hasMoreData,
    loadMoreDataAccountantName,
    options,
  };
};

export const useGetDetailSellerCustomer = (params) => {
  const { data, error, refetch, loading } = useQuery(GET_DETAIL_SELLER_CUSTOMER, {
    variables: params,
  });
  return {
    data: data?.sellerGroup?.get,
    error,
    loading,
    refetch,
  };
};

export const useCreateSellerGroup = () => {
  const [createSellerGroup, { loading }] = useMutation(CREATE_SELLER_GROUP);

  const handleCreateSellerGroup = async (params) => {
    return await createSellerGroup({
      variables: params,
    });
  };

  return {
    handleCreateSellerGroup,
    loading,
  };
};

export const useUpdateSellerGroup = () => {
  const [updateSellerGroup, { loading }] = useMutation(UPDATE_SELLER_GROUP);

  const handleUpdateSellerGroup = async (params) => {
    return await updateSellerGroup({
      variables: params,
    });
  };

  return { handleUpdateSellerGroup, loading };
};

export const useGetCustomerLoadMore = (params) => {
  const { data, fetchMore, refetch } = useQuery(GET_CUSTOMER_LOADMORE, {
    variables: {
      filters: {
        ...params?.filters,
      },
      pagination: params?.pagination,
    },
    fetchPolicy: "cache-first",
  });

  const paginationData = data?.sellerGroup?.pagination?.paginationData;
  const currentTotal = data?.sellerGroup?.pagination?.sellerGroups?.length;
  const customers = data?.sellerGroup?.pagination?.sellerGroups;
  const options = customers?.map((item) => {
    return { label: `${item?.code} - ${item?.name}`, value: item?.id };
  });
  const customerList = customers?.map((item) => {
    return { label: item?.name, value: item?.id };
  });
  const loadMoreDataCustomers = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: currentTotal, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        const isSamePaginationData = isEqual(
          pv?.sellerGroup?.pagination?.paginationData,
          fetchMoreResult?.sellerGroup?.pagination?.paginationData
        );
        if (isSamePaginationData || fetchMoreResult?.sellerGroup?.pagination?.sellerGroups?.length === 0) return pv;
        const newData = {
          sellerGroup: {
            pagination: {
              paginationData: { ...fetchMoreResult?.sellerGroup?.pagination?.paginationData },
              sellerGroups: [
                ...pv?.sellerGroup?.pagination?.sellerGroups,
                ...fetchMoreResult?.sellerGroup?.pagination?.sellerGroups,
              ],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotal === paginationData?.total);

  return {
    hasMoreData,
    loadMoreDataCustomers,
    options,
    refetch,
    customerList,
  };
};

export const useGetCategoriesSeller = ({ id }) => {
  const { data, loading } = useQuery(GET_CATEGORIES_SELLER, {
    variables: {
      sellerIDs: id,
    },
  });
  return {
    data: data?.seller?.get?.saleCategories,
    loading,
  };
};

export const useGetSellerForCustomer = (params) => {
  // error
  const { data, error, refetch, loading } = useQuery(GET_SELLERS_FOR_CUSTOMER, {
    variables: params,
  });

  return {
    data: convertDataSeller(data?.seller?.pagination?.sellers),
    paginationData: data?.seller?.pagination?.paginationData,
    error,
    loading,
    refetch,
  };
};

export const useGetActivityLogs = (params) => {
  const { data, error, loading, refetch } = useQuery(GET_ACTIVITY_LOGS, {
    variables: params,
  });
  return {
    paginationData: data?.seller?.getActivityLogs?.paginationData,
    loading,
    error,
    refetch,
    data: convertActivityLogs(data?.seller?.getActivityLogs?.sellerActivityLogs),
  };
};

export const useGetSellersCreateExportSerial = ({ filters, pagination }) => {
  const { loading, data } = useQuery(GET_SELLERS_CREATE_EXPORT_SERIAL, {
    variables: {
      filters,
      pagination,
    },
    fetchPolicy: "cache-first",
  });

  return {
    loading,
    data: data?.seller?.pagination?.sellers,
  };
};

export const useReceive = () => {
  const [receive, { loading }] = useMutation(RECEIVE, { refetchQueries: [GET_TOTAL_BY_STATUS, GET_SELLERS] });
  const handleReceive = async (params) => {
    return await receive({
      variables: params,
    });
  };

  return {
    handleReceive,
    loading,
  };
};

export const useReject = () => {
  const [reject, { loading }] = useMutation(REJECT, { refetchQueries: [GET_TOTAL_BY_STATUS, GET_SELLERS] });
  const handleReject = async (params) => {
    return await reject({
      variables: params,
    });
  };

  return {
    handleReject,
    loading,
  };
};

export const useApprove = () => {
  const [approve, { loading }] = useMutation(APPROVE);
  const handleApprove = async (params) => {
    return await approve({
      variables: params,
    });
  };

  return {
    handleApprove,
    loading,
  };
};

export const useReprocess = () => {
  const [reprocess, { loading }] = useMutation(REPROCESS, { refetchQueries: [GET_TOTAL_BY_STATUS, GET_SELLERS] });
  const handleReprocess = async (params) => {
    return await reprocess({
      variables: params,
    });
  };

  return {
    handleReprocess,
    loading,
  };
};

export const useTotalByStatus = () => {
  const { data, loading } = useQuery(GET_TOTAL_BY_STATUS);

  return {
    loading,
    data: data?.seller?.getTotalByStatus,
  };
};

export const useUpdateIdentitySeller = () => {
  const [updateIdentitySeller, { loading }] = useMutation(UPDATE_IDENTITY_SELLER, { refetchQueries: [GET_SELLERS] });
  const handleUpdateIdentitySeller = async (params) => {
    return await updateIdentitySeller({
      variables: params,
    });
  };

  return {
    handleUpdateIdentitySeller,
    loading,
  };
};

export const useMergeSeller = () => {
  const [mergeSeller, { loading }] = useMutation(MERGE_SELLER, { refetchQueries: [GET_TOTAL_BY_STATUS, GET_SELLERS] });

  const handleMergeSeller = async (params) => {
    return await mergeSeller({
      variables: params,
    });
  };

  return {
    handleMergeSeller,
    loading,
  };
};

export const useGetSellerWithIds = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_SELLER_WITH_IDS, {
    variables: { filters: { ...params } },
    skip: params?.ids === undefined,
  });
  const options = data?.seller?.pagination?.sellers?.map((item) => ({
    value: item?.id,
    label: item?.fullName,
  }));
  return {
    loading,
    data: options || [],
    refetch,
    fetchMore,
  };
};

export const useGetSellerGroupWithIds = (params) => {
  const { loading, data } = useQuery(GET_SELLER_GROUP_WITH_IDS, {
    variables: { filters: { ...params } },
    skip: params?.ids === undefined,
  });

  return {
    loading,
    data: data?.sellerGroup?.pagination?.sellerGroups || [],
  };
};

export const useGetSellersWithIds = (params) => {
  const { data } = useQuery(GET_SELLERS_WITH_IDS, {
    variables: {
      filters: { ids: params?.ids?.filter((item) => item !== "UNCATEGORIZED") },
    },
    skip: !params?.ids,
  });

  return {
    data: data?.seller?.pagination?.sellers,
  };
};

export const useGetSellerLoadMore = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_SELLERS_LOAD_MORE, {
    variables: params,
    fetchPolicy: "cache-first",
  });

  const paginationData = data?.seller?.pagination?.paginationData;
  const currentTotal = data?.seller?.pagination?.sellers?.length;
  const sellersList = data?.seller?.pagination?.sellers;

  const sellerOptions = sellersList?.map((item) => {
    return { label: item?.fullName, value: item?.id };
  });

  const loadMoreDataSellers = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: currentTotal, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        const isSamePaginationData = isEqual(
          pv?.seller?.pagination?.paginationData,
          fetchMoreResult?.seller?.pagination?.paginationData
        );
        if (isSamePaginationData || fetchMoreResult?.seller?.pagination?.sellers?.length === 0) return pv;
        const newData = {
          seller: {
            pagination: {
              paginationData: { ...fetchMoreResult?.seller?.pagination?.paginationData },
              sellers: [...pv?.seller?.pagination?.sellers, ...fetchMoreResult?.seller?.pagination?.sellers],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotal === paginationData?.total);
  return {
    loading,
    refetch,
    sellerOptions,
    loadMoreDataSellers,
    hasMoreData,
    sellersList,
  };
};

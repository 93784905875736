import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import Card from "features/Cart/components/Card";
import { ORDER_PRIORITY } from "features/Cart/constants";
import { renderOrderPriority, renderOrderType } from "features/Order/helper";
import { t } from "i18next";
import { compact, join } from "lodash";
import DeliveryDeadline from "../DeliveryDeadline";
import DeliveryMethod from "../DeliveryMethod";
import InfoReceiverAndSender from "../InfoReceiverAndSender";
import useInfoAgent from "./useInfoAgent";

const InfoAgent = ({ dataInfoAgent, order }) => {
  const {
    seller,
    orderType,
    orderPriority,
    exportedWarehouse,
    deliveryDeadline,
    fullStockRequired,
    vat,
    vatAllOrder,
    vatTaxIDNumber,
    vatCompanyName,
    vatEmail,
    vatAddress,
  } = dataInfoAgent;

  const { infoReceiverAndSender } = useInfoAgent({ dataInfoAgent });

  const renderOrderPriorityLabel = {
    [`${ORDER_PRIORITY.LOW}`]: (
      <Typography.Text strong style={{ color: "#052987" }}>
        {renderOrderPriority[orderPriority]}
      </Typography.Text>
    ),
    [`${ORDER_PRIORITY.MEDIUM}`]: (
      <Typography.Text strong style={{ color: "#00AB78" }}>
        {renderOrderPriority[orderPriority]}
      </Typography.Text>
    ),
    [`${ORDER_PRIORITY.HIGH}`]: (
      <Typography.Text strong style={{ color: "#D2434D" }}>
        {renderOrderPriority[orderPriority]}
      </Typography.Text>
    ),
  };
  const hasNonMaterial = order?.items?.some((item) => item.product.productType !== "MATERIAL");

  return (
    <Card
      title={
        <Typography.Text strong style={{ color: "#052987" }}>
          {t("cart.infoAgent")}
        </Typography.Text>
      }
    >
      <Row gutter={16}>
        <Col span={12} style={{ borderRight: "1px dashed #9CADC4" }}>
          <Row gutter={[16, 8]}>
            <Col span={16}>
              <Typography.Text strong style={{ color: "#052987" }}>
                {join(compact([seller?.fullName, seller?.telephone]), " | ")}
              </Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text style={{ color: "#052987" }}>{renderOrderType[orderType]}</Typography.Text>
            </Col>
            {infoReceiverAndSender?.map((item, index) => (
              <Col key={index} span={24}>
                <InfoReceiverAndSender infoUser={item} />
              </Col>
            ))}
            {hasNonMaterial && (
              <Col span={24}>
                <Row>
                  <Col span={5}>
                    <Typography.Text strong>Email</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text>{order?.email || "--"}</Typography.Text>
                  </Col>
                </Row>
              </Col>
            )}
            <DeliveryMethod dataInfoAgent={dataInfoAgent} />
          </Row>
        </Col>
        <Col span={6} style={{ borderRight: "1px dashed #9CADC4" }}>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Typography.Text strong>{t("cart.exportWarehouseLabel")}</Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong style={{ color: "#052987" }}>
                {exportedWarehouse?.name}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>{t("cart.orderPriorityLabel")}</Typography.Text>
            </Col>
            <Col span={12}>{renderOrderPriorityLabel[orderPriority]}</Col>
            <Col span={12}>
              <Typography.Text strong>{t("cart.promisedDeliverTimeLabel")}</Typography.Text>
            </Col>
            <Col span={12}>
              <DeliveryDeadline deliveryDeadline={deliveryDeadline} strong />
            </Col>
            <Col span={24}>
              {fullStockRequired ? (
                <CheckCircleFilled style={{ color: "#00AB78" }} />
              ) : (
                <CloseCircleFilled style={{ color: "#D2434D" }} />
              )}
              <Typography.Text strong style={{ marginLeft: "8px" }}>
                {t("cart.fullStockRequiredLabel")}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Typography.Text strong style={{ color: "#052987" }}>
                {t("cart.vatAllOrderLabel")}
              </Typography.Text>
            </Col>

            <Col span={10}>
              {vat ? (
                <CheckCircleFilled style={{ color: "#00AB78" }} />
              ) : (
                <CloseCircleFilled style={{ color: "#D2434D" }} />
              )}
              <Typography.Text style={{ marginLeft: "8px" }}>{t("order.orderDetail.exportVat")}</Typography.Text>
            </Col>

            <Col span={14}>
              {vatAllOrder ? (
                <CheckCircleFilled style={{ color: "#00AB78" }} />
              ) : (
                <CloseCircleFilled style={{ color: "#D2434D" }} />
              )}
              <Typography.Text style={{ marginLeft: "8px" }}>
                {t("order.orderDetail.exportVatForAllOrders")}
              </Typography.Text>
            </Col>

            <Col span={10}>
              <Typography.Text strong>{t("order.orderDetail.taxCode")}</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text strong style={{ color: "#052987" }}>
                {vatTaxIDNumber}
              </Typography.Text>
            </Col>

            <Col span={10}>
              <Typography.Text strong>{t("order.orderDetail.companyName")}</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text strong style={{ color: "#052987" }}>
                {vatCompanyName}
              </Typography.Text>
            </Col>

            <Col span={10}>
              <Typography.Text strong>Email</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text strong style={{ color: "#052987" }}>
                {vatEmail}
              </Typography.Text>
            </Col>

            <Col span={10}>
              <Typography.Text strong>{t("order.orderDetail.addressInBill")}</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text strong style={{ color: "#052987" }}>
                {vatAddress}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default InfoAgent;

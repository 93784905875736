import { Typography } from "antd";
import { FilterLoadMore } from "components/CustomFilterMenu/FilterLoadMore";
import { useGetStaff } from "hooks/warranty";
import { t } from "i18next";
import { useState } from "react";
import { formatDateTime } from "utils/dateTime";
import { buildQueryString, formatCurrency, getQuery } from "utils/helperFuncs";
import file_logo from "../../../../assets/icons/file_logo.svg";
import CheckVAT from "pages/App/Product/ProductPriceLogs/List/components/CheckVAT";
import { useGetProduct, useGetProductPriceLog } from "hooks/product/product";
import { useParams } from "react-router-dom";
import { omit } from "utils/object";
import { SvgIcon } from "assets/icons";

const useList = () => {
  const GET_QUERY = getQuery();
  const { id } = useParams();
  const [params, setParams] = useState(() => {
    return {
      filters: {
        query: "",
        orderBy: GET_QUERY?.orderBy || "CREATED_AT_DESC",
        createdAtFilter: {},
        productIDs: [id],
        createdByIDs: GET_QUERY?.createdByIDs || [],
      },
      pagination: {
        offset: GET_QUERY?.offset || 0,
        limit: GET_QUERY?.limit || 10,
      },
    };
  });

  const { data, paginationData, loading } = useGetProductPriceLog(params);
  const { data: dataProductDetail } = useGetProduct({ id });
  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });
  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(paramsStaff);

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const checkNullValue = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "- -";
    } else {
      return value;
    }
  };

  const checkValueDuplicate = (oldValue, newValue) => {
    if (oldValue.toString() === newValue.toString()) {
      return oldValue;
    } else
      return (
        <span>
          {checkNullValue(oldValue)}&nbsp;&#10142;&nbsp;
          <span style={{ color: "#32BF46" }}>{checkNullValue(newValue)}</span>
        </span>
      );
  };

  const checkValueDuplicateDiscount = (oldValue, newValue) => {
    if (oldValue === newValue) {
      return oldValue || "--";
    }
    if (oldValue || newValue) {
      return (
        <span style={{ textWrap: "nowrap" }}>
          {oldValue || "--"}&nbsp;&#10142;&nbsp;
          <span style={{ color: "#32BF46" }}>{newValue || "--"}</span>
        </span>
      );
    }
    return "--";
  };

  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
      },
    });
  };

  const handleRemoveFilterTag = (key) => {
    setParams({
      pagination: { offset: 0, limit: 10 },
      filters: {
        ...omit({ ...params.filters }, [`${key}`]),
      },
      sort: params.sort,
      activeKey: params.activeKey,
    });
    buildQueryString({
      params: {
        ...omit({ ...params?.filters }, [`${key}`]),
        ...params?.pagination,
        activeKey: params.activeKey,
      },
    });
  };

  const handleSort = (by) => {
    if (params?.filters?.orderBy === "CREATED_AT_ASC") {
      const newParams = {
        ...params,
        filters: { ...params?.filters, orderBy: "CREATED_AT_DESC" },
      };
      setParams(newParams);
    } else {
      const newParams = {
        ...params,
        filters: { ...params?.filters, orderBy: "CREATED_AT_ASC" },
      };
      setParams(newParams);
    }
  };

  const handleDowloadfile = (fileURL) => {
    window.open(fileURL);
  };

  const columns = [
    {
      title: (
        <Typography.Text style={{ color: "#5b6673" }} className="title-table" onClick={() => handleSort("ID")}>
          {t("product.time")}
          <SvgIcon.SortIcon className={params?.filters?.orderBy} />
        </Typography.Text>
      ),
      align: "center",
      width: 120,
      render: (_, record, index) => checkNullValue(formatDateTime(record?.createdAt)),
    },
    {
      title: t("product.PriceLevel1"),
      align: "right",
      width: 250,
      render: (_, record) =>
        checkValueDuplicate(formatCurrency(record?.oldLevel1Price), formatCurrency(record?.newLevel1Price)),
    },
    {
      title: t("product.PriceLevel2"),
      align: "right",
      width: 250,
      render: (_, record) =>
        checkValueDuplicate(formatCurrency(record?.oldLevel2Price), formatCurrency(record?.newLevel2Price)),
    },
    {
      title: t("product.PriceLevel3"),
      align: "right",
      width: 250,
      render: (_, record) =>
        checkValueDuplicate(formatCurrency(record?.oldLevel3Price), formatCurrency(record?.newLevel3Price)),
    },
    {
      title: t("product.PriceLevel4"),
      align: "right",
      width: 250,
      render: (_, record) =>
        checkValueDuplicate(formatCurrency(record?.oldLevel4Price), formatCurrency(record?.newLevel4Price)),
    },
    {
      title: t("product.EXPORT_VAT"),
      width: 140,
      render: (_, record) =>
        record?.oldVat === record?.newVat ? (
          CheckVAT(record?.oldVat)
        ) : (
          <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {CheckVAT(record?.oldVat)}
            &nbsp;&#10142;&nbsp;
            <span className="icon-new-vat">{CheckVAT(record?.newVat)}</span>
          </span>
        ),
    },
    {
      title: t("product.%VAT"),
      width: 120,
      render: (_, record) => (
        <div>{checkValueDuplicate((record?.oldVatPercent || 0) + "%", (record?.newVatPercent || 0) + "%")}</div>
      ),
    },
    {
      title: t("product.differenceVAT"),
      width: 250,
      align: "right",
      render: (_, record) => (
        <div>{checkValueDuplicate(formatCurrency(record?.oldVatValue), formatCurrency(record?.newVatValue))}</div>
      ),
    },
    {
      title: t("product.levelQuantity", { level: "1" }),
      align: "center",
      width: 150,
      render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel1Quantity, record?.newLevel1Quantity),
    },
    {
      title: t("product.levelDiscount", { level: "1" }),
      align: "right",
      width: 250,
      render: (_, record) =>
        checkValueDuplicate(
          record?.oldLevel1Discount ? formatCurrency(record?.oldLevel1Discount) : "--",
          record?.newLevel1Discount ? formatCurrency(record?.newLevel1Discount) : "--"
        ),
    },
    {
      title: t("product.levelQuantity", { level: "2" }),
      align: "center",
      width: 150,
      render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel2Quantity, record?.newLevel2Quantity),
    },
    {
      title: t("product.levelDiscount", { level: "2" }),
      align: "right",
      width: 250,
      render: (_, record) =>
        checkValueDuplicate(
          record?.oldLevel2Discount ? formatCurrency(record?.oldLevel2Discount) : "--",
          record?.newLevel2Discount ? formatCurrency(record?.newLevel2Discount) : "--"
        ),
    },
    {
      title: t("product.levelQuantity", { level: "3" }),
      align: "center",
      width: 150,
      render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel3Quantity, record?.newLevel3Quantity),
    },
    {
      title: t("product.levelDiscount", { level: "3" }),
      align: "right",
      width: 250,
      render: (_, record) =>
        checkValueDuplicate(
          record?.oldLevel3Discount ? formatCurrency(record?.oldLevel3Discount) : "--",
          record?.newLevel3Discount ? formatCurrency(record?.newLevel3Discount) : "--"
        ),
    },
    {
      title: t("product.levelQuantity", { level: "4" }),
      align: "center",
      width: 150,
      render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel4Quantity, record?.newLevel4Quantity),
    },
    {
      title: t("product.levelDiscount", { level: "4" }),
      align: "right",
      width: 250,
      render: (_, record) =>
        checkValueDuplicate(
          record?.oldLevel4Discount ? formatCurrency(record?.oldLevel4Discount) : "--",
          record?.newLevel4Discount ? formatCurrency(record?.newLevel4Discount) : "--"
        ),
    },
    {
      title: t("product.PEOPLE_CHANGE"),
      width: 200,
      render: (_, record, index) => checkNullValue(record?.createdBy?.fullname),
      filterDropdown: (
        <FilterLoadMore
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          onFilter={(value) => handleFilter({ createdByIDs: value })}
          onSearchTextChange={handleSearchStaffs}
          hasMore={hasMoreData}
        />
      ),
    },
    {
      title: t("product.ATTACHMENT"),
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record, index) => (
        <Typography.Link>
          <img src={file_logo} alt="" />
          &nbsp;
          <div onClick={() => handleDowloadfile(record?.priceImportSession?.fileURL)}>
            {record?.priceImportSession?.fileName}
          </div>
        </Typography.Link>
      ),
    },
  ];

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      filters: {
        ...params.filters,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
      },
    });
  };

  return {
    staffs,
    columns,
    data,
    paginationData,
    loading,
    handleChangeTable,
    params,
    dataProductDetail,
    handleRemoveFilterTag,
  };
};

export default useList;

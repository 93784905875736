import React from 'react';
import { t } from 'i18next';

import { formatDateTime } from 'utils/dateTime';
import { DATE_FORMAT } from 'config/constants';
import { useCampaign } from 'features/Campaign/hooks';
import CustomTags from 'components/CustomTags';
import { paymentMethodOptions } from 'features/Agent/constants';

export function useToolbar({ params, onChangeParams, createdByOptions }) {
  const { ORDER_STATUS_FILTER } = useCampaign();

  const handleChangeDate = (value) => {
    const { from, to } = value;
    onChangeParams &&
      onChangeParams({
        timeRange: {
          from: from,
          to: to,
        },
      });
  };

  const renderDateTag = () => {
    const from = formatDateTime(params?.filters?.timeRange?.from, DATE_FORMAT);
    const to = formatDateTime(params?.filters?.timeRange?.to, DATE_FORMAT);

    return (
      params?.filters?.timeRange?.from && (
        <CustomTags
          handleOnClose={() => handleOnClose('timeRange')}
          label={
            <>
              <span className="label">{t('campaign.tags.startDate')}:</span>&nbsp;
              {from} - {to}
            </>
          }
        />
      )
    );
  };

  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(', ')}
      </div>
    );
  };
  const tags = [
    {
      title: t('seller.activitylogtabs.table.paymentMethod'),
      options: paymentMethodOptions,
      selected: params?.filters?.paymentMethods,
      key: 'paymentMethods',
    },
    {
      title: t('seller.activitylogtabs.table.createdBy'),
      options: createdByOptions,
      selected: params?.filters?.createdByIDs,
      key: 'createdByIDs',
    },
    {
      title: t('campaign.tags.status'),
      options: ORDER_STATUS_FILTER,
      selected: params?.filters?.statuses,
      key: 'statuses',
    },
  ];

  const handleOnClose = (key) => {
    switch (key) {
      case 'timeRange':
        onChangeParams && onChangeParams({ [`${key}`]: {} });
        break;
      default:
        onChangeParams && onChangeParams({ [`${key}`]: [] });
        break;
    }
  };

  const renderTags = tags
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags
          handleOnClose={() => handleOnClose(key)}
          key={key}
          label={renderTag(options, selected, title)}
        />
      );
    });

  return { handleChangeDate, renderDateTag, renderTags };
}

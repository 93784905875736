import React from 'react';
import { t } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'antd';

import { Icon } from 'assets/icons';
import './index.scss';
import CreateSellerGroup from '../../components/CreateSeller';

function Toolbar({ handleSearch, data, refetch }) {
  return (
    <div className="wrapper-customer">
      <div className="search-input">
        <Input
          className="custom-input"
          prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
          placeholder={t('customer.searchCustomer')}
          allowClear={true}
          onChange={(value) => handleSearch(value)}
        />
      </div>
      <div className="button">
        <CreateSellerGroup data={data} refetch={refetch} />
      </div>
    </div>
  );
}

export default Toolbar;

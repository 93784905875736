import React, { useState } from 'react';
import { Button, Form, Typography } from 'antd';
import CustomModal from 'components/CustomModal';
import FormInput from 'components/FormInput';
import { t } from 'i18next';
import { INPUT_TYPE } from 'config/constants';
import { useTechnicalRejectSalesReturn } from 'hooks/recommendimport';
import { notify } from 'utils/helperFuncs';

function ButtonTechnicalRejectSalesReturn({ record }) {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const { handleTechnicalRejectSalesReturn, loading } = useTechnicalRejectSalesReturn();
  const confirmReject = async () => {
    const values = await form.validateFields();
    const params = {
      id: record?.id,
      request: {
        rejectedReason: values?.rejectedReason,
        fileUrls: [''],
      },
    };
    await handleTechnicalRejectSalesReturn(params)
      .then(() => {
        notify.success({
          message: t('warehouse.recommendImport.messageReject'),
        });
        setVisible(false);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };

  return (
    <CustomModal
      {...{
        customComponent: t('warehouse.recommendImport.reject'),
        footer: false,
        centered: true,
        styleButton: 'notButton',
        hideConfirmButton: true,
        title: (
          <Typography.Text strong style={{ fontSize: '20px' }}>
            {t('warehouse.salesReturn.rejectrequestimport')}
          </Typography.Text>
        ),
        addMoreButton: (
          <Button type="primary" onClick={confirmReject} loading={loading}>
            {t('common.confirm')}
          </Button>
        ),
        visible: visible,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    >
      <Form form={form} layout="vertical">
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: (
                <Typography.Text strong>
                  {t('warehouse.recommendImport.reasonReject')}
                </Typography.Text>
              ),
              name: 'rejectedReason',
              rules: [
                { required: true, message: t('warehouse.recommendImport.pleaseEnterReject') },
              ],
            },
            inputOptions: {
              placeholder: t('warehouse.recommendImport.enterreasonReject'),
            },
          }}
        />
      </Form>
    </CustomModal>
  );
}

export default ButtonTechnicalRejectSalesReturn;

import { Space } from 'antd';
import React from 'react';

const Card = ({ title, total, backgroundColor }) => {
  return (
    <Space
      style={{
        width: '100%',
        justifyContent: 'space-between',
        backgroundColor,
        padding: '5px 10px',
        borderRadius: '5px',
        height: '100%',
      }}
    >
      {title}
      {total}
    </Space>
  );
};

export default Card;

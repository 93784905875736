import { checkObjectUndefined } from 'utils/helperFuncs';

export const convertNotification = (data) => {
  return data?.map((item) => ({
    id: item?.id,
    status: item?.status,
    title: item?.title,
    createAt: item?.createdAt,
    avatar: item?.imageURL,
    description: item?.body,
  }));
};

export const convertParams = (params) => ({
  filters: checkObjectUndefined({
    query: params?.query,
  }),
  pagination: {
    offset: params?.offset,
    limit: params?.limit,
  },
});

import { Typography } from 'antd';
import { SvgIcon } from 'assets/icons';
import FilterMenu from 'components/CustomFilterMenu/FilterMenu';
import { useGetManufacturers } from 'hooks/manufacturer/manufacturer';
import { useGetProducts } from 'hooks/product/product';
import { t } from 'i18next';
import React from 'react';
import { formatCurrency } from 'utils/helperFuncs';
import { arrayUniqueByKey } from 'utils/object';
import FilterInStock from '../FilterStock';

const useList = ({ params, onTableChange, onSelected, handleFilter, selectedProduct }) => {
  const { data: products, paginationData, loading } = useGetProducts(params);
  const { dataManufactures: manufacturersOptions } = useGetManufacturers();
  const vatOptions = [
    { label: 'Có', value: true },
    { label: 'Không', value: false },
  ];

  const onChange = (pagination) => {
    onTableChange && onTableChange(pagination);
  };

  const onFilter = (key, value) => {
    if (key === 'realStocks') {
      const realStocksFilter = arrayUniqueByKey(
        [...(params?.filters?.realStocks ? params?.filters?.realStocks : []), value],
        'warehouseID'
      ).filter((item) => item?.range?.from || item.range?.to);

      handleFilter && handleFilter(key, realStocksFilter);
    } else {
      handleFilter && handleFilter(key, value);
    }
  };

  const columns = [
    {
      title: t('product.tableTitle.code'),
      render: (text, record, index) => <Typography.Link>{record?.code}</Typography.Link>,
    },
    {
      title: t('product.tableTitle.name'),
      render: (text, record, index) => <Typography.Text>{record?.name}</Typography.Text>,
    },
    {
      title: t('product.tableTitle.producer'),
      render: (text, record, index) => (
        <Typography.Text>{record?.manufacturer?.name}</Typography.Text>
      ),
      ...(!selectedProduct?.isFilterSelected && {
        filterDropdown: (
          <FilterMenu
            onFilter={(value) => onFilter('manufacturerIDs', value)}
            type="checkbox"
            options={manufacturersOptions}
          />
        ),
      }),
    },
    {
      title: t('product.tableTitle.price'),
      align: 'end',
      render: (text, record, index) => (
        <Typography.Text>{formatCurrency(record?.priceOfSeller?.price)}</Typography.Text>
      ),
    },
    {
      title: t('product.tableTitle.inStock'),
      align: 'center',
      render: (text, record, index) => (
        <Typography.Text>{record?.stocks ? record?.stocks[0]?.quantity : ''}</Typography.Text>
      ),
      ...(!selectedProduct?.isFilterSelected && {
        filterDropdown: <FilterInStock warehouseIDs={params?.warehouseIDs} onFilter={onFilter} />,
      }),
    },
    {
      title: t('product.tableTitle.exportedVAT'),
      align: 'center',
      render: (text, record, index) =>
        record?.vat ? <SvgIcon.SuccessIcon /> : <SvgIcon.CloseIcon />,
      ...(!selectedProduct?.isFilterSelected && {
        filterDropdown: (
          <FilterMenu
            onFilter={(value) => onFilter('vat', value)}
            type="radio"
            options={vatOptions}
          />
        ),
      }),
    },
  ];

  const onSelectedRows = (selectedRowKeys, selectedRows) => {
    onSelected && onSelected(selectedRowKeys, selectedRows);
  };

  return { columns, products, paginationData, onChange, loading, onSelectedRows };
};

export default useList;

import React, { useState } from 'react';
import { useGetCustomerLoadMore } from 'hooks/seller';

function useFilter() {
  const [params, setParams] = useState({
    filters: { query: undefined },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const { hasMoreData, loadMoreDataCustomers, options } = useGetCustomerLoadMore(params);

  const onSearch = (value) => {
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  return { hasMoreData, loadMoreDataCustomers, options, onSearch };
}

export default useFilter;

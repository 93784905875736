import React from 'react';
import { t } from 'i18next';
import { TabsMenu } from 'components/CustomTabs';
import { WARRANTY_TABS } from 'features/Warranty/constant';
import History from '../History';
import InfoAgent from '../InfoAgent';

import './index.scss';

const Tabs = ({warrantyDetail}) => {
  const tabs = [
    {
      title: <span className="title"> {t('warranty.warrantyDetail.infoNCC')}</span>,
      key: WARRANTY_TABS.PROVIDER_INFO,
      children: <InfoAgent warrantyDetail={warrantyDetail} />,
    },
    {
      title: <span className="title"> {t('warranty.warrantyDetail.history')}</span>,
      key: WARRANTY_TABS.HISTORY,
      children: <History />,
    },
  ];
  return (
    <div className="warranty-tabs">
      <TabsMenu tabs={tabs}/>
    </div>
  );
};

export default Tabs;

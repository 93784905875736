import Spinner from 'components/Spinner';
import { useSellerPermissions } from 'hooks/seller';
import { useSupplierPermissions } from 'hooks/supplier';
import { useGetUserPermissions } from 'hooks/user/user';
import Page403 from 'pages/PageError/403';
import React from 'react';
import AgentDetail from '../../../../features/Agent/Detail';

const SellerDetail = () => {
  const { canView } = useSellerPermissions();
  const { canViewListSupplier } = useSupplierPermissions();
  const { loading: loadUserPermissions } = useGetUserPermissions();

  return loadUserPermissions ? (
    <Spinner loading={loadUserPermissions} />
  ) : canView || canViewListSupplier ? (
    <AgentDetail />
  ) : (
    <Page403 />
  );
};

export default SellerDetail;

import React from 'react';
import { Col, Form, Row, Typography } from 'antd';
import { t } from 'i18next';
import { DATE_FORMAT, INPUT_TYPE } from 'config/constants';
import FormInput from 'components/FormInput';
import { getFile, validateDate } from 'utils/helperFuncs';
import { optionsRequestType } from 'features/WarrantyRequest/constant';
import UploadFiles from 'components/UploadFiles';

const lableCol = { span: 24, offset: 0 };

function FormEditOwner() {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            type: INPUT_TYPE.SELECT,
            formItemOptions: {
              label: (
                <Typography.Text strong>{t('warrantyRequest.create.warrantyType')}</Typography.Text>
              ),
              name: 'warrantyRequestType',
              rules: [{ required: true, message: t('common.validateRequired') }],
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.warrantyType'),
              maxLength: 50,
              options: optionsRequestType,
              disabled: 'disabled',
            },
          }}
        />
      </Col>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            type: INPUT_TYPE.DATE_PICKER,
            formItemOptions: {
              label: (
                <Typography.Text strong>
                  {t('warrantyRequest.create.appointmentDate')}
                </Typography.Text>
              ),
              name: 'promisedReturnDate',
              rules: [
                { required: true, message: t('common.validateRequired') },
                {
                  validator: validateDate,
                },
              ],
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.date'),
              format: DATE_FORMAT,
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: (
                <Typography.Text strong>{t('warrantyRequest.create.errorDetail')}</Typography.Text>
              ),
              name: 'defectDescription',
              rules: [{ required: true, message: t('common.validateRequired') }],
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.errorDetail'),
              maxLength: 50,
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: (
                <Typography.Text strong>
                  {t('warrantyRequest.create.clientRequest')}
                </Typography.Text>
              ),
              name: 'customerRequest',
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.clientRequest'),
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: (
                <Typography.Text strong>
                  {t('warrantyRequest.create.accessoriesNote')}
                </Typography.Text>
              ),
              name: 'accessoryAttachment',
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.accessoriesNote'),
              maxLength: 50,
            },
          }}
        />
      </Col>

      <Col span={24}>
        <Form.Item
          labelCol={lableCol}
          name={'imageURLs'}
          getValueFromEvent={getFile}
          valuePropName={'fileList'}
          label={<Typography.Text strong>{t('warrantyRequest.popup.files')}</Typography.Text>}
          extra={t('warrantyRequest.create.noteAttack')}
        >
          <UploadFiles />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FormEditOwner;

import React from 'react';
import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { WarrantyReceiveMethod } from 'features/WarrantyRequest/constant';
import { useGetInfoPlatform } from 'hooks/info-platform';

const CustomCol = ({ label, value }) => {
  return (
    <>
      <Col span={24} xl={7}>
        <Typography.Text strong>{label}</Typography.Text>
      </Col>
      <Col span={24} xl={17}>
        <Typography.Text>{value || '--'}</Typography.Text>
      </Col>
    </>
  );
};

function TabRequest({ data }) {
  const { platformName } = useGetInfoPlatform();

  const warrantyRequestLabel = {
    [`${WarrantyReceiveMethod.BUS}`]: t('warrantyRequest.create.shippingType.bus'),
    [`${WarrantyReceiveMethod.SHIPPING_PROVIDER}`]: t(
      'warrantyRequest.create.shippingType.shippingProvider'
    ),
    [`${WarrantyReceiveMethod.CUSTOMER_BRING}`]: t(
      'warrantyRequest.create.shippingType.customerBring'
    ),
    [`${WarrantyReceiveMethod.SHIPPER}`]: t('warrantyRequest.create.shippingType.shipper', {
      platformName,
    }),
  };
  return (
    <Row gutter={[16, 16]}>
      <Col span={24} xl={12}>
        <Row gutter={[16, 8]} style={{ borderRight: '1px dashed #9CADC4' }}>
          <CustomCol
            label={t('serial.fullName')}
            value={data?.warrantyRequest?.warrantyCustomerName}
          />
          <CustomCol
            label={t('serial.phoneNumber')}
            value={data?.warrantyRequest?.warrantyCustomerPhone}
          />
          <CustomCol
            label={t('serial.address')}
            value={data?.warrantyRequest?.warrantyCustomerAddress}
          />
          <CustomCol
            label={t('serial.formOfReceive')}
            value={warrantyRequestLabel?.[data?.warrantyRequest?.warrantyReceiveMethod]}
          />
        </Row>
      </Col>

      <Col span={24} xl={12}>
        <Row gutter={[16, 8]}>
          <CustomCol label={t('serial.branch')} value={data?.warrantyRequest?.branch?.name} />
          <CustomCol label={t('serial.note')} value={data?.warrantyRequest?.note} />
        </Row>
      </Col>
    </Row>
  );
}

export default TabRequest;


export const firebaseConfig = {
  apiKey: "AIzaSyCanaRqCF0BIXMsezOjn2v8WRmjegEl-TM",
  authDomain: "seller-mobile-3d450.firebaseapp.com",
  projectId: "seller-mobile-3d450",
  storageBucket: "seller-mobile-3d450.appspot.com",
  messagingSenderId: "585665918013",
  appId: "1:585665918013:web:276e40ee332c28f12e2654",
  measurementId: "G-67RM6WRQ86"
};

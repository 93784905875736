import { ScanOutlined } from "@ant-design/icons";
import CreateExportSerial from "features/Serial/SerialExport/Create";
import DetailSerialExport from "features/Serial/SerialExport/Detail";
import CreateSerial from "features/Serial/SerialImport/CreateSerial";
import DetailSerialImport from "features/Serial/SerialImport/Detail";
import EditSerialImport from "features/Serial/SerialImport/Edit";
import ExportSerial from "./ExportSerial";
import ImportSerial from "./ImportSerial";
import CreateSerialPurchaseOrder from "features/Serial/SerialImport/CreateSerialPurchaseOrder";

const router = [
  {
    key: "serial",
    title: "serialIE.serial",
    icon: <ScanOutlined />,
    children: [
      {
        key: "importSerial",
        title: "serialIE.importSerial",
        path: "/import-serial",
        exact: true,
        component: ImportSerial,
      },
      {
        key: "exportSerial",
        title: "serialIE.exportSerial",
        path: "/export-serial",
        exact: true,
        component: ExportSerial,
      },
    ],
  },
  {
    key: "import-serial-create",
    path: "/import-serial/create",
    component: CreateSerial,
    exact: true,
  },
  {
    key: "detail-serial-import",
    path: "/import-serial/detail/:id",
    component: DetailSerialImport,
    exact: true,
  },
  {
    key: "edit-serial-import",
    path: "/import-serial/edit/:id",
    component: EditSerialImport,
    exact: true,
  },
  {
    key: "export-serial-create",
    path: "/export-serial/create",
    component: CreateExportSerial,
    exact: true,
  },
  {
    key: "detail-serial-export",
    path: "/serial-export/detail/:id",
    component: DetailSerialExport,
    exact: true,
  },
  {
    key: "import-serial-purchase-order-create",
    path: "/import-serial-purchase-order/create",
    component: CreateSerialPurchaseOrder,
    exact: true,
  },
];

export default router;

import FilterLoadMore from "components/_storybooks/FilterLoadMore";
import { useGetSellerLoadMore } from "hooks/seller";
import { useState } from "react";

function SellerFilter({ onFilter }) {
  const [params, setParams] = useState({
    filters: { query: undefined },
    pagination: { offset: 0, limit: 10 },
  });

  const { hasMoreData, loadMoreDataSellers, loading, sellerOptions } = useGetSellerLoadMore(params);

  const handleSearchSeller = (values) => {
    setParams({
      ...params,
      pagination: { offset: 0, limit: 10 },
      filters: { query: values || undefined },
    });
  };

  return (
    <FilterLoadMore
      onFilter={(value) => onFilter("sellerIDs", value)}
      data={sellerOptions}
      onLoadMoreData={loadMoreDataSellers}
      hasMore={hasMoreData}
      onSearch={handleSearchSeller}
      loading={loading}
    />
  );
}

export default SellerFilter;

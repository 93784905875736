import { cloneDeep } from 'lodash';

// delete object property
export const omit = (obj, property) => {
  const { [property]: unused, ...rest } = obj;
  return rest;
};

export const arrayUniqueByKey = (array, key) => [
  ...new Map(array?.map((item) => [item[key], item])).values(),
];

export const removeManyValueByKey = (tree, listRemove) => {
  const data = cloneDeep(tree);
  function removeByKey(key, arr) {
    // loop through all items of array
    for (let i = 0; i < arr.length; i++) {
      // if array item has said key, then remove it
      if (arr[i].key === key) {
        arr.splice(i, 1);
      } else if (typeof arr[i].children !== 'undefined') {
        // if object doesn't have desired key but has children, call this function
        // on the children array
        removeByKey(key, arr[i].children);
      }
    }
  }

  for (let i = 0; i < listRemove.length; i++) {
    removeByKey(listRemove[i], data);
  }
  return data;
};

export const flattenTree = (array) => {
  let result = [];
  array?.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a?.children)) {
      result = result.concat(flattenTree(a?.children));
    }
  });
  return result;
};

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {}
  );

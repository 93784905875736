/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import { filter, includes, isNull, isUndefined, pick, uniq, uniqBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { WARRANTY_STATUS } from "features/Warranty/constant";
import { useGetOrderLoadmore } from "hooks/order";
import { useGetSellerCreateCart } from "hooks/seller";
import { useCreateSerialImport } from "hooks/serial";
import { useWarehouse } from "hooks/warehouse";
import { useGetWarrantyTablePending } from "hooks/warranty";
import { notify } from "utils/helperFuncs";
import { IMPORT_TYPE, WARRANTY_ON_SALE_UNIT } from "../../constant";

function useCreateSerial() {
  const [form] = useForm();
  const formData = form.getFieldsValue();
  const history = useHistory();

  const [importType, setImportType] = useState(IMPORT_TYPE.FIRST_TIME_IMPORT);

  const [providerInfo, setProviderInfo] = useState(null);

  const [orderInfo, setOrderInfo] = useState(null);

  const [dataTableFirstTimeImport, setDataTableFirstTimeImport] = useState([]);

  const [dataTableReturn, setDataTableReturn] = useState([]);

  const [tags, setTags] = useState([]);

  const [warehouseInfo, setWarehouseInfo] = useState();

  const handleSelectWarehouse = (value, option) => {
    setWarehouseInfo(option?.warehouse);
    setDataTableFirstTimeImport([]);
    form.setFieldsValue({
      ...formData,
      warehouseID: value,
      items: [],
    });
  };

  const initialValues = {
    importType: importType,
    providerID: null,
    sellerID: null,
    warehouseID: null,
    orderID: null,
    note: null,
    fileURLs: null,
    items: [],
  };

  const [serials, setSerials] = useState([]);
  const [dataTableReImportWarranty, setDataTableReImportWarranty] = useState([]);

  const serialInfo = dataTableReImportWarranty[0];
  //
  const { data: warrantyNotes, loading: pendingWarrantyNoteLoading } = useGetWarrantyTablePending({
    filters: {
      query: null,
      status: [WARRANTY_STATUS.STILL_VALIDATED, WARRANTY_STATUS.INVALIDATED],
      serials: serials,
    },
    pagination: {
      offset: 0,
      limit: serials?.length,
    },
    sort: [],
  });

  const checkDupliSerial = useCallback(
    (serial) => {
      const message = (
        <Typography.Text>
          {t("serialIEDetail.message.serialNumber")} <Typography.Link strong>{serial}</Typography.Link>{" "}
          {t("serialIEDetail.message.already")}.{t("serialIEDetail.message.plsTryAgain")}
        </Typography.Text>
      );

      serials?.filter(function (item) {
        if (item === serial) {
          return notify.warning({
            message: message,
          });
        }
      });
    },
    [serials]
  );

  const handleSerialSelected = useCallback(
    (serial) => {
      const newSerials = uniq([...serials, serial]);
      setSerials(newSerials);
      checkDupliSerial(serial);
    },
    [serials]
  );

  const serverSerialToWarrantyNote = useMemo(() => {
    return warrantyNotes.reduce((agg, curr) => {
      agg[curr?.serialImei] = curr;
      return agg;
    }, {});
  }, [warrantyNotes]);

  useEffect(() => {
    // merge fields

    const newItems = serials?.map((serialItem) => {
      return {
        ...(serverSerialToWarrantyNote[serialItem] || {}),
      };
    });

    const hasNonEmptyElement = newItems?.every((item) => Object.keys(item).length > 0);

    if (hasNonEmptyElement) {
      const serialToItem = formData?.items?.reduce((agg, curr) => {
        agg[curr.serialItems] = curr;
        return agg;
      }, {});

      const formItems = newItems?.map((item) => {
        const currentItem = serialToItem[item?.serialImei] || {};
        const newValues = {
          productID: item?.product?.id,
          serialItems: item?.serialImei,
          warrantyOnSale: item?.warrantyOnSale,
          warrantyUnit: item?.warrantyDateUnit,
          // soldDateFromProvider: item?.soldDate,
          warrantyItem: {
            warrantyNoteID: item?.id,
            warehouseID: currentItem?.warrantyItem?.warehouseID,
          },
          quantity: 1,
        };

        return newValues;
      });

      const newArr = uniqBy([...formItems], "serialItems");

      const filteredArr = filter(newArr, (obj) => !isNull(obj.serialItems) && !isUndefined(obj.serialItems));

      form.setFieldsValue({
        ...formData,
        items: filteredArr,
      });
    }
  }, [
    JSON.stringify(serials),
    JSON.stringify(warrantyNotes),
    JSON.stringify(importType === IMPORT_TYPE.RE_IMPORT_WARRANTY ? formData : null),
  ]);

  const handleGetTags = (values) => {
    setTags(values);
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues?.importType) {
      setImportType(changedValues?.importType);
      setVisible(false);
    }
    if (changedValues?.items) {
      const newData = dataTableFirstTimeImport?.map((item, index) => {
        return {
          ...item,
          quantity: allValues.items[index].quantity,
          serialItems: allValues.items[index].serialItems,
          lack: allValues.items[index].quantity - allValues.items[index].serialItems.length,
          scanned:
            allValues.items[index].quantity -
            (allValues.items[index].quantity - allValues.items[index].serialItems.length),
        };
      });
      setDataTableFirstTimeImport(newData);
    }
  };

  const handleChangeInfo = (value, info) => {
    if (info?.provider) setProviderInfo(info?.provider);
    if (info?.order) setOrderInfo(info?.order);
  };

  const handleGetSerial = (data) => {
    checkDupliSerial(data?.serialImei);

    const sellerID = data?.sellerID;
    let newArray = [];
    newArray = uniqBy([...dataTableReImportWarranty, data], "serialImei");
    newArray = newArray.filter((obj) => Object.keys(obj).length !== 0);

    const message = (
      <Typography.Text>
        {t("serialIEDetail.message.serial")} <Typography.Link strong>{data?.serialImei}</Typography.Link>
        {t("serialIEDetail.message.itsNot")}
        <Typography.Link strong>{newArray?.[0].seller?.fullName || newArray?.[0]?.customerName}</Typography.Link>.
        {t("serialIEDetail.message.please")}
      </Typography.Text>
    );

    if (dataTableReImportWarranty?.length > 0) {
      if (includes(newArray?.[0].sellerID || newArray?.[0].seller?.id, sellerID)) {
        setSerials(uniq([...serials, data?.serialImei]));
        setDataTableReImportWarranty(uniqBy(newArray, "id"));
      } else {
        // setDataTableReImportWarranty([...dataTableReImportWarranty]);
        notify.warning({
          message: message,
        });
      }
    } else {
      setSerials(uniq([...serials, data?.serialImei]));
      setDataTableReImportWarranty(uniqBy(newArray, "id"));
    }
  };

  const handleGetProduct = (data) => {
    const formData = form.getFieldsValue();
    const newData = {
      productID: data?.id,
      warrantyOnSale: data?.warrantyOnSale,
      warrantyPeriodSupplier: 0,
      warrantyUnit: WARRANTY_ON_SALE_UNIT.MONTH,
      quantity: 0,
      serialItems: [],
    };

    form.setFieldsValue({
      ...formData,
      items: uniqBy([...formData.items, newData], "productID"),
    });
    setDataTableFirstTimeImport(uniqBy([...dataTableFirstTimeImport, data], "id"));
  };

  const handleGetProductTransfer = (data) => {
    const formData = form.getFieldsValue();
    const newData = {
      productID: data?.id,
      warrantyOnSale: data?.warrantyOnSale,
      warrantyPeriodSupplier: 0,
      warrantyUnit: WARRANTY_ON_SALE_UNIT.MONTH,
      quantity: 0,
      serialItems: [],
    };

    form.setFieldsValue({
      ...formData,
      items: uniqBy([...formData.items, newData], "productID"),
    });
    setDataTableFirstTimeImport(uniqBy([...dataTableFirstTimeImport, data], "id"));
  };

  const handleSetFieldOrder = (data) => {
    const formData = form.getFieldsValue();

    const newData = data?.items?.map((item, index) => {
      return {
        ...item,
        productID: item?.productID,
        warrantyUnit: item?.warrantyDateUnit,
        warrantyOnSale: item?.productWarranty,
        serialItems: [],
      };
    });

    form.setFieldsValue({
      ...formData,

      items: newData,
    });

    setDataTableReturn(newData);
  };

  const handleGetTableOrder = (data) => {
    setDataTableReturn(data);
  };

  const params = {
    filters: {
      query: "",
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  };

  const [paramsOrder, setParamsOrder] = useState({
    filters: {
      query: "",
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: [],
  });

  const handleChange = (value, option) => {
    handleSetFieldOrder && handleSetFieldOrder({ ...option?.order });
  };

  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null, isActiveSupplier: true, isSupplier: true },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    sellers,
    loadMoreData: loadMoreProvider,
    hasMoreData: hasMoreProvider,
  } = useGetSellerCreateCart(paramsSeller, "cache-first");

  const {
    orders,
    hasMoreData: hasMoreOrder,
    onLoadMoreData: loadMoreOrder,
    loading,
  } = useGetOrderLoadmore(paramsOrder, "cache-first");

  const { handleCreateSerialImport, loadingCreate } = useCreateSerialImport();

  const [loadingCreateBtn, setLoadingCreateBtn] = useState(loadingCreate);

  const { warehouses, loading: loadingWarehouse } = useWarehouse();

  const warehouseOptions = warehouses?.map((item) => ({
    value: item?.id,
    label: item?.name,
    warehouse: item,
  }));

  const providerOptions = sellers?.map((item) => {
    return {
      value: item?.value,
      label: item?.label,
      provider: {
        address: item?.address,
        telephone: item?.telephone,
        email: item?.email,
      },
    };
  });

  const onSearch = (value) => {
    setParamsSeller({
      filters: { ...params.filters, query: value || null, isSupplier: true, isActiveSupplier: true },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsOrder({
      filters: { ...params.filters, query: value || "" },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleRemoveSerial = (serial) => {
    const filteredSerials = serials?.filter((item) => item !== serial);
    setSerials(filteredSerials);
    const filteredWarrantyRequestItems = dataTableReImportWarranty?.filter((item) => item?.serialImei !== serial);
    setDataTableReImportWarranty(filteredWarrantyRequestItems);
  };

  const handleRemoveProduct = (id) => {
    const newArr = dataTableFirstTimeImport?.filter((item) => item?.id !== id);
    return setDataTableFirstTimeImport(newArr);
  };

  const handleClearAllSerials = (index, exportType) => {
    const formData = form.getFieldsValue();

    const newFormItems = formData.items.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          serialItems: [],
        };
      }
      return item;
    });
    const newDataTable = dataTableFirstTimeImport.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          serialItems: [],
        };
      }
      return item;
    });

    form.setFieldsValue({
      ...formData,
      items: newFormItems,
    });
    setDataTableFirstTimeImport(newDataTable);
  };

  const onCancelCreate = () => {
    history.push("/import-serial");
  };

  const paramItems = (importType, values) => {
    switch (importType) {
      case IMPORT_TYPE.FIRST_TIME_IMPORT:
        return uniqBy(values?.items, "productID");
      case IMPORT_TYPE.RETURN:
        return values.items.map((item) =>
          pick(item, ["serialItems", "quantity", "productID", "warrantyUnit", "warrantyOnSale"])
        );
      case IMPORT_TYPE.RE_IMPORT_WARRANTY:
        return uniqBy(values?.items, "serialItems");
      case IMPORT_TYPE.TRANSFER_SERIAL:
        return uniqBy(values?.items, "productID");
      case IMPORT_TYPE.OTHER:
        return uniqBy(values?.items, "productID");
      default:
        return;
    }
  };

  const [visible, setVisible] = useState(false);

  const showOnModalConfirmCreate = () => {
    setVisible(true);
  };
  const showOffModalConfirmCreate = () => {
    setVisible(false);
  };

  const handleFinish = async () => {
    await form.validateFields().then((values) => {
      showOffModalConfirmCreate();
      values?.items?.length
        ? showOnModalConfirmCreate()
        : notify.warning({ message: t("serialIE.notify.warningCreate") });
    });
  };

  const handleCreate = async () => {
    const values = await form.getFieldsValue();
    setLoadingCreateBtn(true);
    try {
      const params = {
        ...values,
        sellerID: orderInfo?.seller?.id || serialInfo?.seller?.id || serialInfo?.sellerID,
        fileURLs: values?.fileURLs,
        items: paramItems(values?.importType, values),
      };
      if (values?.items?.length) {
        await form.validateFields();
        await handleCreateSerialImport({ request: params })
          .then((response) => {
            const idParams = response?.data?.serialImportNotes?.create?.id;
            notify.success({ message: t("serialIE.notify.successCreate") });
            history.push(`/import-serial/detail/${idParams}`);
          })
          .catch((err) => {
            notify.error({ message: err.message });
          });
      }
    } catch (error) {
      console.info(error);
    } finally {
      setLoadingCreateBtn(false);
      showOffModalConfirmCreate();
    }
  };

  useEffect(() => {
    setDataTableFirstTimeImport([]);
    setDataTableReturn([]);
    setProviderInfo(null);
    setOrderInfo(null);
    setWarehouseInfo();
    form.setFieldsValue({ ...initialValues, importType: importType });
  }, [importType]);

  return {
    form,
    hasMoreProvider,
    warehouseOptions,
    providerOptions,
    hasMoreOrder,
    loading,
    orders,
    initialValues,
    providerInfo,
    importType,
    orderInfo,
    serialInfo,
    dataTableFirstTimeImport,
    dataTableReturn,
    loadingCreateBtn,
    loadingCreate,
    tags,
    visible,
    dataTableReImportWarranty,
    pendingWarrantyNoteLoading,
    loadMoreProvider,
    onSearch,
    loadMoreOrder,
    handleRemoveSerial,
    handleRemoveProduct,
    handleValuesChange,
    handleChangeInfo,
    handleGetSerial,
    handleGetProduct,
    handleFinish,
    handleGetTags,
    onCancelCreate,
    handleChange,
    handleCreate,
    showOffModalConfirmCreate,
    handleSerialSelected,
    handleGetTableOrder,
    loadingWarehouse,
    warehouseInfo,
    handleSelectWarehouse,
    handleClearAllSerials,
    handleGetProductTransfer,
  };
}

export default useCreateSerial;

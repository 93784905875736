import React, { useState } from 'react';
import { isNil, omit } from 'lodash';

const useFilterInStock = ({ warehouseIDs, onFilter }) => {
  const [realStocksFilter, setRealStocksFilter] = useState({
    warehouseID: warehouseIDs,
    range: {
      from: null,
      to: null,
    },
  });

  const handleChangeFrom = (value) => {
    setRealStocksFilter({
      ...realStocksFilter,
      range: { ...realStocksFilter?.range, from: value },
    });
  };
  const handleChangeTo = (value) => {
    setRealStocksFilter({
      ...realStocksFilter,
      range: { ...realStocksFilter?.range, to: value },
    });
  };

  const handleApplyFilter = () => {
    onFilter && onFilter('realStocks', realStocksFilter);
  };

  const handleResetFilter = () => {
    setRealStocksFilter({
      ...realStocksFilter,
      range: {
        from: null,
        to: null,
      },
    });
  };

  return {
    handleChangeFrom,
    handleChangeTo,
    handleApplyFilter,
    handleResetFilter,
    range: realStocksFilter?.range,
  };
};

export default useFilterInStock;

import React from 'react';
import { Button, Typography } from 'antd';
import { t } from 'i18next';
import { SvgIcon } from 'assets/icons';

function PopoverPrint({ handlePrint }) {
  return (
    <Button type="text" className="button" onClick={handlePrint}>
      <SvgIcon.PrinterBlueIcon />
      <Typography.Text>{t('warrantyRequest.detail.print')}</Typography.Text>
    </Button>
  );
}

export default PopoverPrint;

import React from 'react';
import useFormEditNotOwner from 'features/WarrantyRequest/components/FormEditNotOwner/useFormEditNotOwner';
import { Button, Form, Tooltip, Typography } from 'antd';
import { SvgIcon } from 'assets/icons';
import CustomModal from 'components/CustomModal';
import FormEditNotOwner from 'features/WarrantyRequest/components/FormEditNotOwner';
import { t } from 'i18next';
import useModalEditNotOwner from './useModalEditNotOwner';

function ModalEditNotOwner({ record, onSubmit }) {
  const {
    options,
    hasMoreData,
    dataAllCategories,
    handleSearchTreeSelect,
    onLoadMoreData,
    onSearch,
    handleChange,
    product,
    category,
    handleChangeTreeSelect,
  } = useFormEditNotOwner({ onSubmit });

  const { form, initialValues, handleEdit, switchClose, loading, handleOpen, handleCancel } =
    useModalEditNotOwner({ record, onSubmit, product, category });

  return (
    <CustomModal
      {...{
        title: (
          <Typography.Text strong style={{ fontSize: '20px' }}>
            {t('warrantyRequest.detail.updateSerial')}
          </Typography.Text>
        ),
        customComponent: (
          <Tooltip title={t('warrantyRequest.detail.edit')}>
            <SvgIcon.EditIcon />
          </Tooltip>
        ),
        width: '50%',
        footer: false,
        centered: true,
        hideConfirmButton: true,
        visible: switchClose,
        onOpen: handleOpen,
        onCancel: handleCancel,
        addMoreButton: (
          <Button type="primary" onClick={handleEdit} loading={loading}>
            {t('common.save')}
          </Button>
        ),
      }}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <FormEditNotOwner
          options={options}
          hasMoreData={hasMoreData}
          dataAllCategories={dataAllCategories}
          handleSearchTreeSelect={handleSearchTreeSelect}
          onLoadMoreData={onLoadMoreData}
          onSearch={onSearch}
          handleChange={handleChange}
          handleChangeTreeSelect={handleChangeTreeSelect}
        />
      </Form>
    </CustomModal>
  );
}

export default ModalEditNotOwner;

import React, { useState } from 'react';
import { Tag, Typography } from 'antd';
import CustomModal from 'components/CustomModal';
import { t } from 'i18next';
import styles from './index.module.scss';

function PopupViewAllSerial({ listSerial, components }) {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <CustomModal
      {...{
        customComponent: components,
        footer: false,
        title: (
          <Typography.Text strong style={{ fontSize: '18px', textTransform: 'uppercase' }}>
            {t('serialIE.productInfo.detailSerial')}
          </Typography.Text>
        ),
        width: '40%',
        centered: true,
        cancelButtonLabel: t('common.close'),
        hideConfirmButton: true,
        styleButton: styles['inline'],
        visible: visible,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    >
      <div className={styles['body-popup-view-all-serial']}>
        {listSerial?.map((item, index) => {
          return (
            <Tag className={styles['custom-tag']} key={index}>
              {item?.code}
            </Tag>
          );
        })}
      </div>
    </CustomModal>
  );
}

export default PopupViewAllSerial;

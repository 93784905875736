import { Col, Row } from "antd";
import CustomCard from "components/CustomCard";
import CustomTable from "components/CustomTable";
import PageHeader from "components/PageHeader";
import ProfitReconciliationDetail from "../Detail";
import { PROFIT_RECONCILIATION } from "../constants";
import TablesList from "./Tables/TablesList";
import Toolbar from "./Toolbar";
import useProfitReconciliationList from "./useProfitReconciliationList";
import { useGetCountOrderReconciliation } from "hooks/orderReconciliation";

function ProfitReconciliationList() {
  const {
    params,
    restTableList,
    activeKeyMain,
    visibleDrawerDetail,
    dataOrderReconciliationDetail,
    loadingOrderReconciliationDetail,
    handleChangeTab,
    handleVisibleDrawerDetail,
    handleCloseVisibleDrawerDetail,
    handleSearch,
    handleChangeParams,
    onFilter,
  } = useProfitReconciliationList();

  const {
    COLUMN_ALL,
    COLUMN_WAITING_FOR_PAYMENT,
    COLUMN_RECONCILING,
    COLUMN_COMPLETED,
    COLUMN_REJECTED,
    COLUMN_CANCELED,
  } = TablesList({ handleVisibleDrawerDetail, onFilter });

  const { all, canceled, completed, reconciling, rejected, waiting_for_payment } = useGetCountOrderReconciliation();

  const tabList = [
    {
      key: PROFIT_RECONCILIATION.ALL,
      tab: `Tất cả (${all})`,
    },
    {
      key: PROFIT_RECONCILIATION.WAITING_FOR_PAYMENT,
      tab: `Chờ thanh toán (${waiting_for_payment})`,
    },
    {
      key: PROFIT_RECONCILIATION.RECONCILING,
      tab: `Đang đối soát (${reconciling})`,
    },
    {
      key: PROFIT_RECONCILIATION.COMPLETED,
      tab: `Hoàn thành (${completed})`,
    },
    {
      key: PROFIT_RECONCILIATION.REJECTED,
      tab: `Đã từ chối (${rejected})`,
    },
    {
      key: PROFIT_RECONCILIATION.CANCELED,
      tab: `Đã hủy (${canceled})`,
    },
  ];

  const contentList = {
    [PROFIT_RECONCILIATION.ALL]: <CustomTable columns={COLUMN_ALL} {...restTableList} />,
    [PROFIT_RECONCILIATION.WAITING_FOR_PAYMENT]: (
      <CustomTable columns={COLUMN_WAITING_FOR_PAYMENT} {...restTableList} />
    ),
    [PROFIT_RECONCILIATION.RECONCILING]: <CustomTable columns={COLUMN_RECONCILING} {...restTableList} />,
    [PROFIT_RECONCILIATION.COMPLETED]: <CustomTable columns={COLUMN_COMPLETED} {...restTableList} />,
    [PROFIT_RECONCILIATION.REJECTED]: <CustomTable columns={COLUMN_REJECTED} {...restTableList} />,
    [PROFIT_RECONCILIATION.CANCELED]: <CustomTable columns={COLUMN_CANCELED} {...restTableList} />,
  };

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          pageTitle={"Danh sách đơn hàng đặt cho khách của đại lý"}
          routes={[
            {
              path: "/profit-reconciliation",
              name: "Kế toán",
            },
            {
              path: "",
              name: "Danh sách đơn hàng đặt cho khách của đại lý",
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <CustomCard
          tabList={tabList}
          onTabChange={handleChangeTab}
          defaultActiveTabKey={activeKeyMain}
          contentList={contentList}
          activeTabKey={activeKeyMain}
          content={<Toolbar onSearch={handleSearch} params={params} onChangeParams={handleChangeParams} />}
        />
        <ProfitReconciliationDetail
          visible={visibleDrawerDetail}
          onClose={handleCloseVisibleDrawerDetail}
          dataOrderReconciliationDetail={dataOrderReconciliationDetail}
          loadingOrderReconciliationDetail={loadingOrderReconciliationDetail}
        />
      </Col>
    </Row>
  );
}

export default ProfitReconciliationList;

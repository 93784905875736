import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Typography } from 'antd';
import CreateButton from 'components/CreateButton';
import CustomModal from 'components/CustomModal';
import FormInput from 'components/FormInput';
import { INPUT_TYPE } from 'config/constants';
import { t } from 'i18next';
import React from 'react';
import { getFile } from 'utils/helperFuncs';
import usePopupConfirmDeliComplete from './usePopupConfirmDeliComplete';

function PopupConfirmDeliComplete({ loading, complete, disabled, itemID }) {
  const { handleClick, form, isModalOpen, showModal, handleCancel, loadingBtn } =
    usePopupConfirmDeliComplete({
      complete,
      itemID,
    });

  return (
    <CustomModal
      {...{
        message: (
          <Typography.Title level={5} strong>
            {t('serial.notiImage')}
          </Typography.Title>
        ),
        customComponent: (
          <CreateButton
            disabled={disabled}
            title={t('warrantyRequest.detail.btnGroup.completed')}
          />
        ),
        addMoreButton: (
          <Button type="primary" onClick={handleClick} loading={loadingBtn}>
            {t('common.confirm')}
          </Button>
        ),
        footer: false,
        hideConfirmButton: true,
        visible: isModalOpen,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    >
      <Form form={form} layout="vertical">
        <FormInput
          type={INPUT_TYPE.IMAGE_UPLOAD}
          formItemOptions={{
            getValueFromEvent: getFile,
            name: 'deliverFileURLs',
            valuePropName: 'fileList',
            // label: <Typography.Title level={5} strong>{t('serial.notiImage')}</Typography.Title>,
            extra: t('common.noteAttack'),
            rules: [{ required: true, message: t('common.validateRequired') }],
          }}
          inputOptions={{
            showUploadList: {
              showPreviewIcon: false,
              multiple: true,
            },
          }}
          inputChildren={<PlusOutlined />}
        />
      </Form>
    </CustomModal>
  );
}

export default PopupConfirmDeliComplete;

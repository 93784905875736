import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, Select, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import { FilterLoadMore } from "components/CustomFilterMenu";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import { CustomPaginationPrevNext } from "components/CustomPaginationPrevNext";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { FiltersTag } from "../../../components/FiltersTag";
import { optionExportRadio, typeExport } from "../../../constant";
import "./index.scss";
import useList from "./useList";

function ListByExportSerial() {
  const {
    valueFormSelectCity,
    loading,
    data,
    branchesID,
    onChangeBranchesId,
    paginationData,
    onSearch,
    handleChangeTable,
    params,
    staffs,
    warehouseOptions,
    handleRemoveFilterTag,
    checkNullValue,
    handleSort,
    handleFilter,
    paramsStaff,
    onLoadMoreData,
    hasMoreData,
    handleSearchStaffs,
    sellersOptions,
    handleSearchSellers,
    onLoadMoreDataSeller,
    hasMoreDataSeller,
    handlePrevNextPage,
  } = useList();
  const columns = [
    {
      title: t("serialIE.stt"),
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: (
        <Typography.Text style={{ color: "#5b6673" }} className="title-table" onClick={() => handleSort("ID")}>
          {t("serialIE.createdAtExport")}
          <SvgIcon.SortIcon className={params?.sort && params?.sort[0]?.sortType} />
        </Typography.Text>
      ),
      width: 150,
      render: (_, record) => checkNullValue(formatDateTime(record?.createdAt)),
    },
    {
      title: t("serialIE.codeExport"),
      render: (_, record) =>
        checkNullValue(
          <Typography.Link style={{ fontWeight: "bold" }} className="text-left">
            <Link to={`/serial-export/detail/${record?.id}`}>{record.code}</Link>
          </Typography.Link>
        ),
    },
    {
      title: t("serialIE.codeOrder"),
      render: (_, record) =>
        checkNullValue(
          <Typography.Link strong style={{ fontWeight: "bold" }} className="text-left">
            <Link to={`/order/${record?.order?.id}`}>{record?.order?.number || "- -"}</Link>
          </Typography.Link>
        ),
    },
    {
      title: t("serialIE.agent"),
      render: (_, record) => {
        return checkNullValue(
          <CustomTextHiddenLine text={record?.seller?.fullName || record?.provider?.fullName || "- -"} line={3} />
        );
      },
      align: "left",
      filterDropdown: (
        <FilterLoadMore
          params={params}
          data={sellersOptions}
          onLoadMoreData={onLoadMoreDataSeller}
          onFilter={(value) => handleFilter({ sellerIDs: value })}
          onSearchTextChange={handleSearchSellers}
          hasMore={hasMoreDataSeller}
        />
      ),
    },
    {
      title: t("serialIE.warehouse"),
      width: 120,
      align: "left",
      render: (_, record) =>
        checkNullValue(
          record?.order?.exportedWarehouse?.name ||
            (record?.items &&
              record?.items[0]?.exportItems &&
              record?.items[0]?.exportItems[0]?.exportedWarehouse?.name) ||
            "- -"
        ),
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter({ exportWarehouseIDs: value })}
          type="checkbox"
          options={warehouseOptions}
        />
      ),
    },
    {
      title: t("serialIE.totalCategoryProduct"),
      render: (_, record) => checkNullValue(record?.quantityKindOfProduct),
    },
    {
      title: t("serialIE.quantitySerial"),
      render: (_, record, index) => checkNullValue(record?.quantitySerial),
    },
    {
      title: t("serialIE.staff"),
      render: (_, record, index) => checkNullValue(record?.createdBy?.fullname),
      filterDropdown: (
        <FilterLoadMore
          params={paramsStaff}
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          hasMore={hasMoreData}
          onFilter={(value) => handleFilter({ createdByIDs: value })}
          onSearchTextChange={handleSearchStaffs}
        />
      ),
    },
    {
      title: t("serialIE.typeExport"),
      filterDropdown: (
        <FilterMenu onFilter={(value) => handleFilter({ type: value })} type="checkbox" options={optionExportRadio} />
      ),
      render: (_, record, index) => checkNullValue(typeExport?.[record?.type]),
    },
  ];
  return (
    <div className="list-by-export-serial">
      <Form
        name="basic"
        autoComplete="off"
        style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <Col span="10">
          <Form.Item>
            <Input placeholder={t("serialIE.searchByExportSerial")} prefix={<SearchOutlined />} onChange={onSearch} />
          </Form.Item>
        </Col>
        <Col span="3">
          <Form.Item>
            <Select options={valueFormSelectCity} onChange={(e) => onChangeBranchesId(e)} value={branchesID} />
          </Form.Item>
        </Col>
      </Form>
      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
        <FiltersTag
          staffOptions={staffs}
          params={params}
          optionExportRadio={optionExportRadio}
          warehouseOptions={warehouseOptions}
          handleRemoveFilterTag={handleRemoveFilterTag}
          sellersOptions={sellersOptions}
        />
      </div>
      <CustomTable
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record?.id}
        onChange={handleChangeTable}
      />
      {paginationData && (
        <CustomPaginationPrevNext handlePrevNextPage={handlePrevNextPage} paginationData={paginationData} />
      )}
    </div>
  );
}

export default ListByExportSerial;

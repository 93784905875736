import React, { useState } from 'react';
import { Form } from 'antd';
import { t } from 'i18next';
import { notify } from 'utils/helperFuncs';
import { useCreateSellerGroup } from 'hooks/seller';
import { trim } from 'lodash';

function useCreate({ formCreateSeller, refetch }) {
  const [switchClose, setSwitchClose] = useState(true);
  const { handleCreateSellerGroup, loading } = useCreateSellerGroup();
  const [form] = Form.useForm();

  const handleOpen = () => {
    form.setFieldsValue();
  };

  async function createSellerGroup() {
    const values = await form.validateFields();
    try {
      await handleCreateSellerGroup({
        sellerGroupInfo: {
          ...values,
          name: trim(values?.name),
          description: trim(values?.description),
          isActive: true,
        },
      }).then((res) => {
        formCreateSeller?.setFieldsValue({
          ...formCreateSeller?.getFieldsValue(),
          sellerGroupID: res?.data?.sellerGroup?.create?.id,
        });
      });

      notify.success({
        message: t('customer.createdSuccess'),
      });
      refetch();
      setSwitchClose(!switchClose);
      form.resetFields();
    } catch (err) {
      notify.error({
        message: err?.message,
      });
    }
  }
  const initialValues = {
    code: null,
    name: null,
    managerID: null,
    description: null,
    isActive: true,
  };

  return {
    handleOpen,
    form,
    createSellerGroup,
    switchClose,
    loading,
    initialValues,
  };
}

export default useCreate;

import CustomTags from 'components/CustomTags';
import {
  optionsItemStatus,
  optionsRequestType,
  optionsSolution,
} from 'features/WarrantyRequest/constant';
import { t } from 'i18next';
import React from 'react';

function useToolbar({ params, onChangeParams }) {
  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(', ')}
      </div>
    );
  };

  const tags = [
    {
      title: t('warrantyRequest.detail.table.wResolve'),
      options: optionsSolution,
      selected: params?.filters?.itemSolutions,
      key: 'itemSolutions',
    },
    {
      title: t('warrantyRequest.detail.table.requestType'),
      options: optionsRequestType,
      selected: [params?.filters?.warrantyRequestTypes],
      key: 'warrantyRequestTypes',
    },
    {
      title: t('warrantyRequest.detail.table.status'),
      options: optionsItemStatus,
      selected: params?.filters?.itemStatuses,
      key: 'itemStatuses',
    },
  ];

  const handleOnClose = (key) => {
    onChangeParams && onChangeParams({ [`${key}`]: [] });
  };

  const renderTags = tags
    ?.filter(
      ({ selected }) => selected.filter((item) => Object.keys(item).length !== 0)?.length > 0
    )
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags
          handleOnClose={() => handleOnClose(key)}
          key={key}
          label={renderTag(options, selected, title)}
        />
      );
    });
  return { renderTags };
}

export default useToolbar;

import PageHeader from 'components/PageHeader';
import {
  useGetAllAttributes,
  useGetAllCategory,
  useUpdateStatusCategory,
} from 'hooks/category/category';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getQuery, buildQueryString, notify } from 'utils/helperFuncs';
import FilterBox from './components/FilterBox';
import TableProductCategory from './components/TableProductCategory';
import { cloneDeep, xor } from 'lodash';
import { flattenTree } from 'utils/object';
import CustomModalUpdateStatusCategory from './components/CustomModalUpdateStatusCategory';

const ProductCategoryList = () => {
  const GET_QUERY = getQuery();
  const { t } = useTranslation();
  const [params, setParams] = useState({
    levels: GET_QUERY.levels || [1],
    attributeIDs: GET_QUERY.attributeIDs || [],
  });
  const [attributeIDs, setAttributeIDs] = useState(GET_QUERY.attributeIDs || []);
  const { data: categories, loading: loadingCategories } = useGetAllCategory(params);
  const { data: attributes, loading: loadingAttributes } = useGetAllAttributes();
  const [attributeOptions, setAttributeOptions] = useState([]);
  const { handleUpdateStatusCategory, loading: loadingUpdateStatusCategory } =
    useUpdateStatusCategory();
  const [listCategories, setListCategories] = useState();
  const [idsCategories, setIdsCategories] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [modalAlert, setModalAlert] = useState({
    switchClose: false,
    title: '',
    hideConfirmButton: false,
  });

  useEffect(() => {
    if (!loadingCategories) {
      setListCategories(categories);
      setAttributeOptions(cloneDeep(attributes));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCategories]);

  useEffect(() => {
    if (!loadingUpdateStatusCategory) {
      setListCategories(categories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUpdateStatusCategory, JSON.stringify(categories)]);

  useEffect(() => {
    if (!loadingAttributes) {
      setAttributeOptions(cloneDeep(attributes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAttributes]);

  //search category
  const getEachItem = (data, searchTerm) =>
    flattenTree(data)
      ?.filter((item) => item?.name.toLowerCase().includes(searchTerm.toLowerCase()))
      ?.map((item) => item?.id);

  const filterByName = (e) => {
    const newExpandedRowKeys = flattenTree(listCategories)
      .filter((item) => item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
      .map((item) => {
        if (item.parentID) return item.parentID;
        else return item.id;
      });
    setExpandedRowKeys(newExpandedRowKeys);
    e.target.value
      ? setIdsCategories(getEachItem(listCategories, e.target.value))
      : setIdsCategories([]);
  };

  const onExpand = (newExpandedRowKeys, record) => {
    setExpandedRowKeys(xor([...expandedRowKeys], [record?.id]));
  };

  //search attributes
  const handleSearchAttributes = (e) => {
    const filterAttributesOptionByText = attributes.filter((attribute) =>
      attribute?.label.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setAttributeOptions([...filterAttributesOptionByText]);
  };

  //filter category by attributes
  const handleFilterAttributes = (checkedValue) => {
    setAttributeIDs([...checkedValue]);
  };

  const applyFilter = () => {
    const newParams = { ...params, attributeIDs: [...attributeIDs] };
    buildQueryString({ params: newParams });
    setParams({ ...newParams });
  };

  const resetFilter = () => {
    setAttributeIDs([]);
  };

  const onCancel = () => {
    setModalAlert({ ...modalAlert, switchClose: false });
  };
  const updateStatusCategory = async (id, checked) => {
    try {
      await handleUpdateStatusCategory({ ids: id, isActive: checked }).then(() => {
        notify.success({ message: t('product.updateStatusSuccess') });
        setModalAlert({ ...modalAlert, switchClose: false });
      });
    } catch (err) {
      notify.error({ message: t('product.updateStatusError'), description: err.message });
      setModalAlert({ ...modalAlert, switchClose: false });
    }
  };

  //change active category
  const onChange = (id, parentId, checked) => {
    const flattenCategory = flattenTree(listCategories);

    if (checked) {
      //turn on
      const idCategory = parentId || id;
      const categorySelected = flattenCategory?.find((item) => item?.id === idCategory);
      if (categorySelected?.id !== id) {
        if (categorySelected?.isActive) {
          updateStatusCategory(id, checked);
        } else {
          setModalAlert({
            ...modalAlert,
            switchClose: true,
            title: t('product.categoryTableTitle.alertTurnOnChildButParentOff'),
            onOk: () => updateStatusCategory(id, checked),
            onCancel: () => onCancel(),
          });
        }
      } else {
        updateStatusCategory(id, checked);
      }
    } else {
      //turn off
      const categorySelected = flattenCategory?.find((item) => item?.id === id);
      if (!categorySelected?.totalActiveProduct) {
        setModalAlert({
          ...modalAlert,
          switchClose: true,
          title: t('product.categoryTableTitle.alertTurnOffCategoryButChildOn'),
          onOk: () => updateStatusCategory(id, checked),
          onCancel: () => onCancel(),
        });
      } else {
        setModalAlert({
          ...modalAlert,
          switchClose: true,
          title: t('product.categoryTableTitle.alertTurnOffCategoryButHaveActiveProduct'),
          hideConfirmButton: true,
          onCancel: () => onCancel(),
        });
      }
    }
  };

  return (
    <>
      <PageHeader pageTitle={t('product.productCategory')} />
      {/* filter and add */}
      <FilterBox
        attributeOptions={attributeOptions}
        filterByName={filterByName}
        handleSearchAttributes={handleSearchAttributes}
        handleFilterAttributes={handleFilterAttributes}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        attributeIDs={attributeIDs}
        loadingCategories={loadingCategories}
      />
      {/* Table */}
      <TableProductCategory
        category={listCategories}
        loadingCategories={loadingCategories}
        onChange={onChange}
        idsCategories={idsCategories}
        expandedRowKeys={expandedRowKeys}
        onExpand={onExpand}
        loadingUpdateStatusCategory={loadingUpdateStatusCategory}
      />
      <CustomModalUpdateStatusCategory modalAlert={modalAlert} />
    </>
  );
};

export default ProductCategoryList;

/* eslint-disable react-hooks/exhaustive-deps */
import { SORT_TYPES } from "features/Serial/constant";
import { useGetBranchQuery } from "hooks/branch";
import { useGetSellerCreateCart } from "hooks/seller";
import { useGetExportNotesSerial } from "hooks/serial/query";
import useDebounce from "hooks/useDebounce";
import { useGetUserBranch } from "hooks/user/user";
import { useWarehouse } from "hooks/warehouse";
import { useGetStaff } from "hooks/warranty";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import { omit } from "utils/object";

const useList = () => {
  const GET_QUERY = getQuery();
  const { data: valueFormSelectCity } = useGetBranchQuery();
  const { branchIdByUser } = useGetUserBranch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [branchIdUser, setBranchIdUser] = useState("");
  const branchesIDParam = searchParams.get("branchesID");
  const [branchesID, setBranchesId] = useState("");

  const onChangeBranchesId = (e) => {
    setBranchesId(e);
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        branchesID: e,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: newParams?.activeKey,
        key: newParams?.key,
        branchesID: newParams?.branchesID,
      },
    });
  };
  const [params, setParams] = useState(() => {
    return {
      filters: {
        branchesID: branchesIDParam || branchIdUser || "",
        query: "",
        sellerIDs: [],
        providerIDs: [],
        exportWarehouseIDs: [],
        createdByIDs: [],
        type: [],
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
      sort: [],
    };
  });
  const { data, paginationData, loading } = useGetExportNotesSerial(omit(params, ["activeKey", "key"]));
  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(paramsStaff);
  const { warehouses } = useWarehouse(paramsStaff);

  const warehouseOptions = warehouses?.map(({ id, name }) => ({ value: id, label: name }));
  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        branchesID: branchesID,
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: newParams?.activeKey,
        key: newParams?.key,
      },
    });
  };

  const checkNullValue = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "- -";
    } else {
      return value;
    }
  };

  const handleSort = (by) => {
    if (params.sort[0]?.sortType === "ASC") {
      const newParams = {
        ...params,
        sort: [{ by: by, sortType: "DESC" }],
      };
      setParams(newParams);
    } else {
      const newParams = {
        ...params,
        sort: [{ by: by, sortType: "ASC" }],
      };
      setParams(newParams);
    }
  };
  const handleRemoveFilterTag = (key) => {
    setParams({
      pagination: { offset: 0, limit: 10 },
      filters: {
        ...omit({ ...params.filters }, [`${key}`]),
      },
      sort: params.sort || [],
      activeKey: params.activeKey,
    });
    buildQueryString({
      params: {
        ...omit({ ...params?.filters }, [`${key}`]),
        ...params?.pagination,
        activeKey: params.activeKey,
      },
    });
  };

  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedValue = useDebounce(searchTerm);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    data: sellers,
    onLoadMoreData: onLoadMoreDataSeller,
    hasMoreData: hasMoreDataSeller,
  } = useGetSellerCreateCart(paramsSeller);
  const sellersOptions = sellers?.map(({ id, fullName }) => ({ value: id, label: fullName }));
  const handleSearchSellers = (value) => {
    setParamsSeller({
      ...paramsSeller,
      pagination: { offset: 0, limit: 10 },
      filters: { query: value === "" ? null : value },
    });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      filters: {
        ...params.filters,
      },
      sort: sorter?.order ? [{ by: "ID", sortType: SORT_TYPES[`${sorter?.order}`] }] : [],
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: params.activeKey,
        key: params.key,
      },
    });
  };

  const handlePrevNextPage = (offset) => {
    const newParams = {
      ...params,
      pagination: {
        offset: offset,
        limit: 10,
      },
      filters: {
        ...params.filters,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: params.activeKey,
        key: params.key,
      },
    });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      buildQueryString({
        params: {
          ...newParams?.filters,
          ...newParams?.pagination,
          activeKey: GET_QUERY.activeKey,
          key: GET_QUERY.key,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
  useEffect(() => {
    setParams(() => {
      return {
        filters: {
          branchesID: branchIdUser || "",
          query: "",
          sellerIDs: [],
          providerIDs: [],
          exportWarehouseIDs: [],
          createdByIDs: [],
          type: [],
        },
        pagination: {
          offset: 0,
          limit: 10,
        },
        sorts: [],
      };
    });
    buildQueryString({
      params: { activeKey: "listByExportSerial", key: "listByExportSerial" },
    });
  }, []);
  useEffect(() => {
    setBranchIdUser(branchIdByUser);
    setParams({
      ...params,
      filters: {
        ...params.filters,
        branchesID: branchIdByUser || "",
      },
    });
  }, [branchIdByUser]);

  useEffect(() => {
    setBranchesId(branchesIDParam || branchIdByUser);
  }, [branchesIDParam, branchIdByUser]);

  return {
    data,
    valueFormSelectCity,
    branchesID,
    onChangeBranchesId,
    paginationData,
    loading,
    onSearch,
    handleChangeTable,
    params,
    staffs,
    warehouseOptions,
    handleRemoveFilterTag,
    checkNullValue,
    handleSort,
    handleFilter,
    paramsStaff,
    onLoadMoreData,
    hasMoreData,
    handleSearchStaffs,
    sellersOptions,
    handleSearchSellers,
    onLoadMoreDataSeller,
    hasMoreDataSeller,
    handlePrevNextPage,
  };
};

export default useList;

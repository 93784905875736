import CustomModal from 'components/CustomModal';
import React from 'react';
import './index.scss';

const TableSelection = ({
  modalProps,
  selectedNumber,
  totalSelectedNumber,
  name,
  onSelectAll,
  toggleSelectAll,
  isSelectAll,
}) => {
  return (
    <div className="table-selection">
      <div className="group-btn"></div>
      <div className="selected-info">
        <CustomModal {...modalProps} />
      </div>
    </div>
  );
};

export default TableSelection;

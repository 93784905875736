import React from 'react';
import { Button, Col, Form, Row, Typography } from 'antd';
import CustomModal from 'components/CustomModal';
import FormInput from 'components/FormInput';
import { INPUT_TYPE } from 'config/constants';
import { t } from 'i18next';
import useFormEditAddInfo from './useFormEditAddInfo';
import EditIcon from '../EditIcon';
import { checkIcon } from 'features/WarrantyRequest/constant';

function FormEditAddInfo({ data }) {
  const {
    form,
    branchQuery,
    handleOk,
    loading,
    isModalOpen,
    showModal,
    handleCancel,
    initialValues,
    isCompleted,
    isCanceled,
    isDelivering,
  } = useFormEditAddInfo({ data });

  return (
    <CustomModal
      {...{
        okeButtonLabel: t('common.save'),
        customComponent: <EditIcon status={checkIcon(isCompleted || isCanceled || isDelivering)} /> ,
        title: (
          <Typography.Text strong style={{ fontSize: '20px' }}>
            {t('warrantyRequest.create.addInfomartion')}
          </Typography.Text>
        ),
        addMoreButton: (
          <Button type="primary" onClick={handleOk} loading={loading}>
            {t('common.save')}
          </Button>
        ),
        hideConfirmButton: true,
        width: 'fit-content',
        centered: true,
        footer: false,
        visible: isModalOpen,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={16}>
          <Col span={24}>
            <FormInput
              {...{
                type: INPUT_TYPE.SELECT,
                formItemOptions: {
                  label: (
                    <Typography.Text strong>{t('warrantyRequest.create.branch')}</Typography.Text>
                  ),
                  name: 'branchID',
                  rules: [{ required: true, message: t('common.validateRequired') }],
                },
                inputOptions: {
                  placeholder: t('warrantyRequest.create.placeHolder.branch'),
                  options: branchQuery,
                },
              }}
            />
          </Col>
          <Col span={24}>
            <FormInput
              {...{
                type: INPUT_TYPE.TEXT_AREA,
                formItemOptions: {
                  label: (
                    <Typography.Text strong>{t('warrantyRequest.create.note')}</Typography.Text>
                  ),
                  name: 'note',
                },
                inputOptions: {
                  placeholder: t('warrantyRequest.create.placeHolder.note'),
                },
              }}
            />
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
}

export default FormEditAddInfo;

import React from 'react';
import useReportChart from './useReportChart';
import styles from './index.module.scss';
import { Empty } from 'antd';

const ReportChart = ({ chartData, loadingChart, fetchChartToken }) => {
  const elementId = 'report-charts';
  useReportChart(elementId, chartData, fetchChartToken);

  return !!chartData?.dashboardID ? (
    <div id={elementId} className={styles['charts']}></div>
  ) : (
    <Empty />
  );
};

export default ReportChart;

import { gql } from "@apollo/client";

export const GET_SELLER_WITH_IDS = gql`
  query GetSellerWithIds($filters: SellersFilters, $pagination: PaginationRequest) {
    seller {
      pagination(filters: $filters, pagination: $pagination) {
        sellers {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_SELLER_GROUP_WITH_IDS = gql`
  query GetSellerGroupWithIds($filters: SellerGroupFilters, $pagination: PaginationRequest) {
    sellerGroup {
      pagination(filters: $filters, pagination: $pagination) {
        sellerGroups {
          id
          name
        }
      }
    }
  }
`;

export const GET_WAREHOUSES_WITH_IDS = gql`
  query GetWarehouseWithIds($request: WarehouseRequest, $pagination: PaginationRequest) {
    warehouse {
      list(request: $request, pagination: $pagination) {
        warehouses {
          name
          id
        }
      }
    }
  }
`;

export const GET_USER_WITH_IDS = gql`
  query GetUserWithIds($filters: UsersFilters, $pagination: PaginationRequest) {
    user {
      pagination(filters: $filters, pagination: $pagination) {
        users {
          id
          email
          fullname
        }
      }
    }
  }
`;

export const GET_PRODUCT_WITH_IDS = gql`
  query GetProductWithIds($filters: ProductsFilters, $pagination: PaginationRequest) {
    product {
      pagination(filters: $filters, pagination: $pagination) {
        products {
          id
          name
          code
        }
      }
    }
  }
`;

export const GET_SALES_CHANNEL_WITH_IDS = gql`
  query GetSalesChannelWithIds($filters: SaleChannelFilters, $pagination: PaginationRequest) {
    saleChannel {
      listSalesChannel(filters: $filters, pagination: $pagination) {
        id
        name
      }
    }
  }
`;

import React from 'react';
import { Col, Row, Typography } from 'antd';
import { formatDate } from 'utils/helperFuncs';
import { useGetInfoPlatform } from 'hooks/info-platform';
import { t } from 'i18next';
import { SHIPPING_TYPE } from 'config/constants';

function PaymentInfo({ data }) {
  const { platformName } = useGetInfoPlatform();

  const optionReceiveMethod = {
    [`${SHIPPING_TYPE.URBAN_COD}`]: t('warrantyRequest.detail.btnGroup.urbanCOD', { platformName }),
    [`${SHIPPING_TYPE.BUS}`]: t('warrantyRequest.detail.btnGroup.bus'),
    [`${SHIPPING_TYPE.DELIVER_3PARTY}`]: t('warrantyRequest.detail.btnGroup.deliver3Party'),
    [`${SHIPPING_TYPE.PICK_UP_COUNTER}`]: t('warrantyRequest.detail.btnGroup.pickUpCounter'),
  };

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Typography.Text className="sub-title">
              {t('warehouse.salesReturn.processingTime')}
            </Typography.Text>
          </Col>
          <Col span={24}>{formatDate(data?.processDeadline)}</Col>
        </Row>
        <Row gutter={[16, 8]} style={{ marginTop: '10px' }}>
          <Col span={24}>
            <Typography.Text className="sub-title">
              {t('warehouse.salesReturn.receptionForm')}
            </Typography.Text>
          </Col>
          <Col span={24}>{optionReceiveMethod[data?.receiveMethod]}</Col>
        </Row>
        <Row gutter={[16, 8]} style={{ marginTop: '10px' }}>
          <Col span={24}>
            <Typography.Text className="sub-title">
              {t('warehouse.salesReturn.deliveryAdrress')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text>{data?.receivingAddress || '---'}</Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PaymentInfo;

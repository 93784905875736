import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import CustomModal from 'components/CustomModal';
import { useGetInfoPlatform } from 'hooks/info-platform';
import { t } from 'i18next';
import React from 'react';
import FormEditNotOwner from '../FormEditNotOwner';
import useFormEditNotOwner from '../FormEditNotOwner/useFormEditNotOwner';

function ModalAddProduct({ onSubmit, supportAddMore, selectedRows }) {
  const {
    onCreateProduct,
    form,
    options,
    loading,
    hasMoreData,
    dataAllCategories,
    handleSearchTreeSelect,
    initialValues,
    addProduct,
    switchClose,
    handleChange,
    onLoadMoreData,
    handleChangeTreeSelect,
    onSearch,
    loadingAdd,
    handleOpen,
    handleCancel,
  } = useFormEditNotOwner({
    onSubmit,
    selectedRows,
  });

  const { platformName } = useGetInfoPlatform();

  return (
    <CustomModal
      {...{
        customComponent: (
          <Row gutter={10} style={{ color: '#2246DC', padding: '10px', cursor: 'pointer' }}>
            <Col>
              <PlusCircleFilled />
            </Col>
            <Col>{t('warrantyRequest.create.addProductNotHA', { platformName })}</Col>
          </Row>
        ),
        title: (
          <span className="add-customer-title">
            {t('warrantyRequest.create.addProductNotHA', { platformName })}
          </span>
        ),
        centered: true,
        footer: false,
        width: '50%',
        visible: switchClose,
        hideConfirmButton: true,
        onOpen: handleOpen,
        onCancel: handleCancel,
        addMoreButton: (
          <>
            <Button onClick={addProduct} loading={loadingAdd}>
              {t('common.add')}
            </Button>
            {supportAddMore && (
              <Button type="primary" onClick={() => addProduct({ isClose: true })}>
                {t('common.saveAndAdd')}
              </Button>
            )}
          </>
        ),
      }}
    >
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        style={{ padding: '0 10px' }}
      >
        <FormEditNotOwner
          onCreateProduct={onCreateProduct}
          options={options}
          loading={loading}
          hasMoreData={hasMoreData}
          dataAllCategories={dataAllCategories}
          handleSearchTreeSelect={handleSearchTreeSelect}
          onLoadMoreData={onLoadMoreData}
          handleChangeTreeSelect={handleChangeTreeSelect}
          onSearch={onSearch}
          handleChange={handleChange}
        />
      </Form>
    </CustomModal>
  );
}

export default ModalAddProduct;

import { gql } from '@apollo/client';

export const GET_CHANGE_ACTIVE_STATUS = gql`
  mutation ChangeStatusCampaign($promotionID: ID!, $isActive: Boolean!) {
    promotionCampaign {
      changeActiveStatus(promotionID: $promotionID, isActive: $isActive) {
        result

        listPromotionDuplicateProducts {
          promotionCampaignID
          promotionCampaign {
            name
          }
          overlappingProducts {
            id
            name
            code
          }
        }
      }
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation CreatePromotionCampaign($promotionCampaignInput: PromotionCampaignInput) {
    promotionCampaign {
      create(promotionCampaignInput: $promotionCampaignInput) {
        promotionID
        errorMessage
        duplicatedPromotionProducts {
          productID
          promotionCampaignID
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($id: ID!, $promotionCampaignInput: PromotionCampaignInput) {
    promotionCampaign {
      update(id: $id, promotionCampaignInput: $promotionCampaignInput) {
        promotionID
        errorMessage
        duplicatedPromotionProducts {
          productID
          promotionCampaignID
        }
      }
    }
  }
`;

export const CLONE_CAMPAIGN = gql`
  mutation CloneCampaign($promotionCampaignID: ID!) {
    promotionCampaign {
      clone(promotionCampaignID: $promotionCampaignID) {
        name
        campaignType
        startDate
        endDate
        banner {
          imageURL
          clickURL
        }
        description
        maxOrderCompletedDate
        minTotalOrderValue
        appliedCategories {
          id
        }
        appliedBrands {
          id
        }
        saleChannels {
          id
        }
      }
    }
  }
`;

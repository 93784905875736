import React from 'react';
import useFiltersTag from './useFiltersTag';

export const FiltersTag = ({ params, handleRemoveFilterTag, VALUE_OPTION_STATUS_BRAND }) => {
  const { renderTagsIsActive } = useFiltersTag({
    params,
    handleRemoveFilterTag,
    VALUE_OPTION_STATUS_BRAND,
  });
  return (
    <div style={{ marginBottom: '16px' }}>
      {params?.filters?.isActive !== undefined ? renderTagsIsActive : ''}
    </div>
  );
};

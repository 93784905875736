import { Radio } from 'antd';
import React from 'react';

function RadioGroup({ filterItemsTypeOptions, handleChange, isUpdate }) {
  return (
    <Radio.Group
      options={filterItemsTypeOptions}
      defaultValue={isUpdate ? true : false}
      onChange={handleChange}
      style={{
        display: 'flex',
        justifyContent: 'end',
      }}
      optionType="button"
    />
  );
}

export default RadioGroup;

import { Form, message, Radio, Space, Tooltip } from 'antd';
import CustomModal from 'components/CustomModal';
import React, { useEffect, useState } from 'react';
import './index.scss';
import { SvgIcon } from 'assets/icons';
import { t } from 'i18next';
import { useGetImportFileProduct } from 'hooks/product/product';

const CustomModalDownloadFileUpdatePrice = ({ params, selectedRowKeys }) => {
  const [value, setValue] = useState(t('product.ALL_PRODUCT'));
  const [paramsExport, setParamsExport] = useState({ filters: {} });
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    setParamsExport();
    setValue(e.target.value);
    if (e?.target?.value === t('product.ALL_PRODUCT')) {
      setParamsExport({ filters: {} });
    } else if (e?.target.value === t('product.PRODUCT_CHECKED')) {
      setParamsExport({ filters: { ids: Object.assign(selectedRowKeys) } });
    } else {
      setParamsExport({ filters: params?.filters });
    }
  };

  const { handleExportFileProduct, loading } = useGetImportFileProduct();
  const handleSubmit = async () => {
    const data = await handleExportFileProduct(paramsExport);
    if (value === t('product.PRODUCT_CHECKED') && Object.assign(selectedRowKeys)?.length === 0) {
      message.error(t('product.pleaseChooseProduct'));
    } else if (
      value === t('product.BY_FILTER_CURRENT') &&
      Object.keys(paramsExport?.filters)?.every((item) => paramsExport?.filters[item] === undefined)
    ) {
      message.error(t('product.pleaseChooseProduct'));
    } else {
      window.open(data?.data?.product?.getImportFile?.fileURL);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    setValue(t('product.ALL_PRODUCT'));
    setParamsExport({ filters: {} });
  }, [visible]);
  return (
    <CustomModal
      className="modal-download-file-update-price"
      closable={false}
      centered={true}
      footer={false}
      title={<span className="title">{t('product.exportFileProduct')}</span>}
      onOke={handleSubmit}
      visible={visible}
      onCancel={handleCancel}
      onOpen={handleOpen}
      isBlockCloseOnOke
      buttonLoading={loading}
      customComponent={
        <Tooltip title={t('product.downloadFilePriceLogs')} color={'#5B6673'} placement="bottom">
          <SvgIcon.Xlsx style={{ width: '22px', cursor: 'pointer' }} />
        </Tooltip>
      }
    >
      <Form layout="vertical">
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            <Radio value={t('product.ALL_PRODUCT')}>{t('product.ALL_PRODUCT')}</Radio>
            <Radio value={t('product.PRODUCT_CHECKED')}>{t('product.PRODUCT_CHECKED')}</Radio>
            <Radio value={t('product.BY_FILTER_CURRENT')}>{t('product.BY_FILTER_CURRENT')}</Radio>
          </Space>
        </Radio.Group>
      </Form>
    </CustomModal>
  );
};

export default CustomModalDownloadFileUpdatePrice;

import { useQuery } from "@apollo/client";
import {
  GET_SELLER_GROUP_WITH_IDS,
  GET_SELLER_WITH_IDS,
  GET_PRODUCT_WITH_IDS,
  GET_USER_WITH_IDS,
  GET_WAREHOUSES_WITH_IDS,
  GET_SALES_CHANNEL_WITH_IDS,
} from "../../graphql/valueWithIds";

export const useGetSellerWithIds = (value) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_SELLER_WITH_IDS, {
    variables: {
      filters: { ids: value ?? undefined },
      pagination: { limit: value?.length ?? 10 },
    },
    skip: value === undefined,
  });
  const options = data?.seller?.pagination?.sellers?.map((item) => ({
    value: item?.id,
    label: item?.fullName,
  }));
  return {
    loading,
    data: options || [],
    refetch,
    fetchMore,
  };
};

export const useGetSellerGroupWithIds = (value) => {
  const { loading, data } = useQuery(GET_SELLER_GROUP_WITH_IDS, {
    variables: {
      filters: { ids: value ?? undefined },
      pagination: { limit: value?.length ?? 10 },
    },
    skip: value === undefined,
  });

  return {
    loading,
    data: data?.sellerGroup?.pagination?.sellerGroups || [],
  };
};

export const useGetCreatedByWithIds = (value) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_USER_WITH_IDS, {
    variables: {
      filters: { ids: value ?? undefined },
      pagination: { limit: value?.length ?? 10 },
    },
    skip: value === undefined,
  });
  const convertGetCreatedBy = (data) =>
    data?.map((item) => ({
      value: item?.id,
      label: item?.fullname,
    }));
  return {
    loading,
    data: convertGetCreatedBy(data?.user?.pagination?.users) || [],
    refetch,
    fetchMore,
  };
};

export const useSalesChannelWithIds = (value) => {
  const { data, error, loading } = useQuery(GET_SALES_CHANNEL_WITH_IDS, {
    variables: {
      filters: { ids: value ?? undefined },
      pagination: { limit: value?.length ?? 10 },
    },
    skip: value === undefined,
  });

  const convertOptions = (data) => {
    return data?.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };

  return {
    data: data?.saleChannel?.listSalesChannel,
    error,
    loading,
    convertedData: convertOptions(data?.saleChannel?.listSalesChannel),
  };
};

import React from 'react';
import './index.scss';
import useList from './useList';
import { TabsMenu } from 'components/CustomTabs';
import { t } from 'i18next';

const Profile = () => {
  const { tabsMain, handleChangeTab, activeKeyMain, GET_QUERY } = useList();
  return (
    <div className="profile-container">
      <p className="page-title">{t('account.setupusers')}</p>
      <div className="page-content">
        <TabsMenu
          tabs={tabsMain}
          tabPosition={'left'}
          tabBarStyle={{ background: '#ffffff', padding: '0 20px 0 0px', borderRadius: '8px' }}
          onChange={handleChangeTab}
          activeKey={activeKeyMain}
          defaultActiveKey={GET_QUERY?.activeKey}
          destroyInactiveTabPane={true}
        />
      </div>
    </div>
  );
};

export default Profile;

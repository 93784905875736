import React from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { warrantyHistoryStatus } from 'features/Warranty/util';
import { Typography } from 'antd';
import { useGetWarrantyHistory } from 'hooks/warranty';
import { formatDateTime } from 'utils/dateTime';
import { renderSolutionLabel, warrantyResultLabel } from 'features/WarrantyRequest/constant';
import moment from 'moment';
import WarrantyRequestDetailLink from 'components/CustomRedirectLink/WarrantyRequestDetailLink';
import { useGetInfoPlatform } from 'hooks/info-platform';

function useHistory() {
  const { id } = useParams();
  const { data: warrantyHistory, loading } = useGetWarrantyHistory({ warrantyNoteID: id });
  const { platformName } = useGetInfoPlatform();

  const columns = [
    {
      title: <Typography.Text strong>{t('common.index')}</Typography.Text>,
      align: 'center',
      dataIndex: 'id',
      width: '10px',
      render: (_, _record, index) => index + 1,
    },
    {
      title: <Typography.Text strong>{t('warranty.warrantyDetail.date&Title')}</Typography.Text>,
      align: 'center',
      dataIndex: 'createdAt',
      width: '7%',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix(),
      render: (_, record) => {
        return (
          <>
            <WarrantyRequestDetailLink warrantyRequest={record} />
            <br />
            <Typography.Text>{formatDateTime(record.createdAt)}</Typography.Text>
          </>
        );
      },
    },
    {
      title: <Typography.Text strong>{t('warranty.warrantyDetail.customer')}</Typography.Text>,
      dataIndex: 'customerName',
    },
    {
      title: <Typography.Text strong>{t('warranty.warrantyDetail.phoneNumber')}</Typography.Text>,
      align: 'center',
      dataIndex: 'customerPhone',
    },
    {
      title: <Typography.Text strong>{t('warranty.warrantyDetail.errorDetail')}</Typography.Text>,
      dataIndex: 'errorDescription',
    },
    {
      title: (
        <Typography.Text strong>
          {t('warranty.warrantyRequestList.solutionAccordingSerial')}
        </Typography.Text>
      ),
      align: 'center',
      render: (_, record) => {
        const technicalStaffName =
          record?.technicalStaff && `NVKT: ${record?.technicalStaff?.fullname}`;
        return (
          <>
            <Typography.Text>{renderSolutionLabel(record?.solution, platformName)}</Typography.Text>
            <br />
            <Typography.Link italic>
              {record?.receivePartyName || technicalStaffName}
            </Typography.Link>
          </>
        );
      },
    },
    {
      title: (
        <Typography.Text strong>{t('warranty.warrantyDetail.warrantyResult')}</Typography.Text>
      ),
      align: 'center',
      render: (_, record) => {
        const reImportSerial = `Serial: ${record?.alterProductSerial}`;
        return record?.oldSerial ? (
          <>
            <Typography.Text>{t('warranty.warrantyList.replaceSerial')}</Typography.Text>
            <br />
            <Typography.Link italic>Serial: {record?.oldSerial}</Typography.Link>
          </>
        ) : (
          <>
            <Typography.Text>{warrantyResultLabel?.[record?.warrantyResult]}</Typography.Text>
            <br />
            {record?.alterProductSerial && (
              <Typography.Link italic>{reImportSerial}</Typography.Link>
            )}
          </>
        );
      },
    },
    {
      title: <Typography.Text strong>{t('warranty.warrantyDetail.status')}</Typography.Text>,
      align: 'center',
      dataIndex: 'status',
      render: (_, record) => {
        return (
          <Typography.Text style={{ color: warrantyHistoryStatus?.[record?.status]?.color }}>
            {warrantyHistoryStatus?.[record?.status]?.text}
          </Typography.Text>
        );
      },
    },
  ];
  return { columns, warrantyHistory, loading };
}

export default useHistory;

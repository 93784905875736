import React from 'react';
import { WarningTwoTone } from '@ant-design/icons';
import { Space, Typography } from 'antd';

function ModalWarning({ title }) {
  return (
    <Space direction="vertical">
      <WarningTwoTone twoToneColor="orange" style={{ fontSize: 50 }} />
      <Typography.Text>{title}</Typography.Text>
    </Space>
  );
}

export default ModalWarning;

/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { SHIPPING_PROVIDER_TYPE } from "features/Cart/constants";
import { convertGetCart } from "features/Cart/utils";
import { isMaterial } from "features/Products/helper";
import { useFlags } from "flagsmith/react";
import {
  useCartPermissions,
  useGetAllCarts,
  useGetCart,
  useGetShippingProvider,
  useGetShippingProviderOld,
  useUpdateCartItemV2,
  useUpdateCartSeller,
  useUpdateCartV2,
} from "hooks/cart";
import { useGetInfoPlatform } from "hooks/info-platform";
import { t } from "i18next";
import { isEmpty, isNil, omit, pick } from "lodash";
import { VAT_TYPE } from "pages/App/Product/constants";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getQuery, notify, parseIsoDateStringToMoment } from "utils/helperFuncs";
import { renameKeys } from "utils/object";

const useList = ({ useInOrder }) => {
  const [form] = Form.useForm();

  const { id } = useParams();
  const GET_QUERY = getQuery();

  const [cartID, setCartID] = useState(id);

  const flags = useFlags(["enable_new_shipping_provider"]);
  const idEnableNewShippingProvider = flags.enable_new_shipping_provider.enabled;

  const { loadingCartPermissions, cartPermissions } = useCartPermissions();
  const { data, loading: loadingGetCart, refetch: refetchCart } = useGetCart({ id: cartID || GET_QUERY?.id });
  const [isEmptyCart, setIsEmptyCart] = useState(true);
  const { handleUpdateCartV2, loading: loadingUpdateCart } = useUpdateCartV2();
  const { data: carts } = useGetAllCarts();
  const { handleUpdateCartSeller, loading: loadingUpdateCartSeller } = useUpdateCartSeller();
  const { handleUpdateCartItemV2, loading: loadingUpdateCartItem } = useUpdateCartItemV2();
  const onChangeTab = (value) => setCartID(value);

  const [isChangeShippingType, setIsChangeShippingType] = useState(false);

  const cartConverter = convertGetCart(data);
  const {
    data: dataShippingProvider,
    loading: loadingShippingProvider,
    refetch: refetchShippingProvider,
  } = useGetShippingProvider({
    shippingType: data?.shippingType,
    serviceFeeInput: { cartID: cartID },
  });

  const { data: dataShippingProviderOld } = useGetShippingProviderOld();

  const convertItemsForForm = (items) => {
    return items?.map((item) => ({
      id: item.id,
      quantity: item?.quantity,
      vat: item?.vat,
      maxDaysOfDebt: item?.maxDaysOfDebt,
      customerPrice: item?.customerPrice,
      customSellerPrice: item?.price,
      productWarranty: item?.productWarranty,
      productType: item?.product?.productType,
    }));
  };

  const renderShippingProvider = (carrierMethodShipping, providerIDShipping) => {
    const getShippingMethods = dataShippingProvider?.find(
      (item) => item?.method === carrierMethodShipping && item?.carrierMinFee !== null && item?.carrierMaxFee !== null
    );

    const getShippingProvider = getShippingMethods?.providers?.find(
      (item) => item.id === `${providerIDShipping} - ${carrierMethodShipping}`
    );

    const checkShipping = () => {
      if (carrierMethodShipping !== null && providerIDShipping !== null) {
        return getShippingProvider;
      } else if (carrierMethodShipping !== null && providerIDShipping === null) {
        return getShippingMethods;
      }
    };
    if (idEnableNewShippingProvider) {
      if (
        !dataShippingProvider ||
        !checkShipping() ||
        checkShipping() === null ||
        !data?.items ||
        !data?.items?.length === 0
      ) {
        return null;
      } else {
        if (!isChangeShippingType) {
          if (providerIDShipping === null) {
            return carrierMethodShipping;
          } else {
            return `${providerIDShipping} - ${carrierMethodShipping}`;
          }
        } else {
          return undefined;
        }
      }
    } else {
      return providerIDShipping;
    }
  };

  const [cartUpdateSeller, setCartUpdateSeller] = useState([]);
  const [isUpdateSeller, setIsUpdateSeller] = useState(false);

  useEffect(() => {
    refetchCart().then(async (res) => {
      const cart = await res?.data?.cart?.get;
      const carrierMethodShipping = cart?.shippingConfiguration?.deliver3PartyConfig?.carrierMethod;
      const providerIDShipping = cart?.shippingConfiguration?.deliver3PartyConfig?.providerID;
      setCartUpdateSeller(cart?.seller?.warehouses);
      form.setFieldsValue({
        ...cart,
        shippingConfiguration: {
          ...cart?.shippingConfiguration,
          deliver3PartyConfig: {
            ...cart?.shippingConfiguration?.deliver3PartyConfig,
            providerID: renderShippingProvider(carrierMethodShipping, providerIDShipping),
          },
        },
        sellerID: [cart?.seller?.code, cart?.seller?.fullName, cart?.seller?.telephone].join(" - "),
        items: convertItemsForForm(cart?.items),
        deliveryDeadline: cart?.deliveryDeadline && parseIsoDateStringToMoment(cart?.deliveryDeadline),
      });
    });
  }, [
    GET_QUERY?.id,
    data,
    loadingGetCart,
    JSON.stringify(idEnableNewShippingProvider ? dataShippingProvider : dataShippingProviderOld),
  ]);

  const converterRequestCartItem = (item) =>
    omit(
      {
        ...item,
        customerPrice: isMaterial(item?.productType) ? item?.customerPrice : item?.customSellerPrice,
        quantity: isNil(item?.quantity) ? 1 : item?.quantity,
      },
      ["productType"]
    );

  const dataInfoAgent = pick(cartConverter, [
    "shippingType",
    "vatAllOrder",
    "sender",
    "sellerID",
    "allSenders",
    "shippingContact",
    "shippingConfiguration",
    "seller",
    "exportedWarehouse",
    "orderPriority",
    "deliveryDeadline",
    "warehouses",
    "listProducts",
  ]);

  const dataInfoProductAndBill = {
    ...pick(cartConverter, [
      "id",
      "listProducts",
      "paymentMethod",
      "customerPaymentMethod",
      "temporaryTotal",
      "customerSubTotal",
      "shippingFee",
      "totalServiceProduct",
      "discountAmount",
      "total",
      "customerTotal",
      "customerSubTotal",
      "orderType",
      "seller",
      "total",
      "paymentByDebit",
      "paymentByCash",
      "sellerID",
      "exportedWarehouseID",
      "vatAllOrder",
      "shippingType",
      "shippingConfiguration",
    ]),
    id: cartID,
  };
  const handleUpdate = async (value, minPrice, maxPrice, fee, parentMethodValue) => {
    if (value && "shippingType" in value) {
      setIsChangeShippingType(true);
    } else {
      setIsChangeShippingType(false);
    }

    const formData = form.getFieldsValue();

    const {
      senderAddress,
      senderName,
      senderPhone,
      shippingContact,
      vatAllOrder,
      shippingConfiguration,
      exportedWarehouse,
    } = data;

    const getShippingMethods = dataShippingProvider?.find(
      (item) => item?.method === shippingConfiguration?.deliver3PartyConfig?.carrierMethod
    );
    let deliver3PartyConfig;
    const isProviderIDValid = Object.values(SHIPPING_PROVIDER_TYPE).includes(
      SHIPPING_PROVIDER_TYPE[
        formData?.shippingConfiguration?.deliver3PartyConfig?.providerID
          ? formData?.shippingConfiguration?.deliver3PartyConfig?.providerID
          : shippingConfiguration?.deliver3PartyConfig?.providerID
      ]
    );

    if (idEnableNewShippingProvider) {
      if (isProviderIDValid) {
        deliver3PartyConfig = {
          carrierMethod: formData?.shippingConfiguration?.deliver3PartyConfig?.providerID
            ? formData?.shippingConfiguration?.deliver3PartyConfig?.providerID
            : shippingConfiguration?.deliver3PartyConfig?.providerID,
          carrierMaxFee: maxPrice >= 0 ? maxPrice : getShippingMethods?.carrierMaxFee || 0,
          carrierMinFee: minPrice >= 0 ? minPrice : getShippingMethods?.carrierMinFee || 0,
          fee: minPrice,
        };
      } else {
        deliver3PartyConfig = {
          providerID:
            formData?.shippingConfiguration?.deliver3PartyConfig?.providerID?.split(" - ")[0] ||
            shippingConfiguration?.deliver3PartyConfig?.providerID,
          fee: fee >= 0 ? fee : shippingConfiguration?.deliver3PartyConfig?.fee,
          carrierMaxFee: fee >= 0 ? fee : shippingConfiguration?.deliver3PartyConfig?.carrierMaxFee || 0,
          carrierMinFee: fee >= 0 ? fee : shippingConfiguration?.deliver3PartyConfig?.carrierMinFee || 0,
          carrierMethod: parentMethodValue || shippingConfiguration?.deliver3PartyConfig?.carrierMethod,
        };
      }
    } else {
      deliver3PartyConfig = { providerID: formData?.shippingConfiguration?.deliver3PartyConfig?.providerID };
    }

    const shippingConfigurationRename = renameKeys(
      { urbanConfig: "urbanCODConfig" },
      {
        busConfig: {
          busName: formData?.shippingConfiguration?.busConfig?.busName,
          busStation: formData?.shippingConfiguration?.busConfig?.busStation,
          telephone: formData?.shippingConfiguration?.busConfig?.telephone,
          location: !idEnableNewShippingProvider ? formData?.shippingConfiguration?.busConfig?.location : undefined,
        },
        deliver3PartyConfig: deliver3PartyConfig,
        urbanConfig: {
          fee: formData?.shippingConfiguration?.urbanConfig?.fee
            ? formData?.shippingConfiguration?.urbanConfig?.fee
            : shippingConfiguration?.urbanConfig?.fee,
        },
      }
    );

    const renameFieldsForm = renameKeys(
      { shippingConfiguration: "shippingConfig" },
      {
        ...formData,
        senderAddress,
        senderName,
        senderPhone,
        exportedWarehouseID:
          cartUpdateSeller?.length > 0 && isUpdateSeller ? cartUpdateSeller[0].id : exportedWarehouse?.id,
        shippingContactID: shippingContact?.id,
        shippingConfiguration: { ...shippingConfigurationRename },
        vatAllOrder,
        discountAmount: isNil(formData?.discountAmount) ? 0 : formData?.discountAmount,
      }
    );
    const dataMissingUseInEdit = isEmpty(useInOrder)
      ? {}
      : {
          ...pick(data, ["orderPriority", "fullStockRequired"]),
          exportedWarehouseID:
            cartUpdateSeller?.length > 0 && isUpdateSeller ? cartUpdateSeller[0].id : data?.exportedWarehouse?.id,
        };
    const omitFields = omit(renameFieldsForm, ["sellerID", "items", "exportedWarehouse", "vat"]);
    const params = { cartID, request: { ...omitFields, ...value, ...dataMissingUseInEdit } };
    await handleUpdateCartV2(params);
    setCartUpdateSeller([]);
    setIsUpdateSeller(false);
  };

  const handleUpdateSeller = (value) => {
    const resultUpdateSeller = handleUpdateCartSeller({ cartID, ...value });
    setIsUpdateSeller(true);
    if (
      resultUpdateSeller?.data?.cart?.updateCartSeller &&
      !loadingGetCart &&
      !loadingShippingProvider &&
      cartUpdateSeller?.length > 0
    ) {
      refetchShippingProvider().then((res) => {
        const servicesFee = res?.data?.shippingProvider?.servicesFee;

        if (data?.shippingType === "DELIVER_3PARTY" && idEnableNewShippingProvider) {
          const carrierMethodShipping = data?.shippingConfiguration?.deliver3PartyConfig?.carrierMethod;
          const providerIDShipping = data?.shippingConfiguration?.deliver3PartyConfig?.providerID;
          const getShippingMethods = servicesFee?.find((item) => item?.method === carrierMethodShipping);
          if (carrierMethodShipping !== null && providerIDShipping === null && servicesFee) {
            handleUpdate(
              undefined,
              getShippingMethods?.carrierMinFee,
              getShippingMethods?.carrierMaxFee,
              undefined,
              undefined
            );
          }
          const getShippingProvider = getShippingMethods?.providers?.find(
            (item) => item.id === `${providerIDShipping} - ${carrierMethodShipping}`
          );
          if (carrierMethodShipping !== null && providerIDShipping !== null && servicesFee) {
            handleUpdate(undefined, undefined, undefined, getShippingProvider?.fee, carrierMethodShipping);
          }
        }
      });
    }
  };

  const handleUpdateItem = async () => {
    const formData = await form.getFieldsValue();
    const { items } = formData;

    await handleUpdateCartItemV2({
      cartID,
      request: {
        cartItems: items?.map((item) => converterRequestCartItem(item)),
      },
    }).then((value) => {
      refetchCart().then((res) => {
        const { vat, vatAllOrder } = res?.data?.cart?.get;
        form.setFieldsValue(omit({ ...formData, vat, vatAllOrder }, ["items"]));
      });
    });
  };

  const { platformCode } = useGetInfoPlatform();

  const platformCodes = {
    HA: "HA",
    C_GROUP: "C_GROUP",
  };

  const handleAddNewItems = async (value) => {
    const newItems = value?.map((item, index) => ({
      productId: item.id,
      quantity: 1,
      // vat: value?.map((item) => item.vat)?.[index]
      vat:
        item.vatType === VAT_TYPE.REQUIRED ||
        (item.vatType === VAT_TYPE.OPTIONAL && cartConverter?.seller?.vatInfo?.defaultExportVat),
      customerPrice: isMaterial(item?.productType) ? 0 : item?.priceOfSeller?.price,
    }));

    const formData = form.getFieldsValue();
    try {
      await handleUpdateCartItemV2({
        cartID: cartID,
        request: {
          newItems,
        },
      }).then(() => {
        refetchCart().then((res) => {
          const items = res?.data?.cart?.get?.items;
          form.setFieldsValue({
            ...formData,
            vat: platformCode === platformCodes.HA ? false : true,
            items: convertItemsForForm(items),
          });
        });
      });
    } catch (error) {
      notify.error({ message: t("common.error") });
    }
  };

  const handleRemoveCartItem = async (id) => {
    await handleUpdateCartItemV2({ cartID, request: { removedItemIDs: [id] } }).then(() =>
      refetchCart().then((res) => {
        const items = res?.data?.cart?.get?.items;
        const formData = form.getFieldsValue();
        form.setFieldsValue({ ...formData, items: convertItemsForForm(items) });
      })
    );
  };

  const handleVatAllItems = async (value) => {
    const formData = await form.getFieldsValue();

    const items = data?.items?.map((item) => {
      if (item?.product?.vat) return { ...item, vat: value };

      return {
        ...item,
        vat: value,
      };
    });
    // ?.filter((item) => item?.product?.vat === true);

    await handleUpdateCartItemV2({
      cartID,
      request: {
        cartItems: convertItemsForForm(items)?.map((item) => converterRequestCartItem(item)),
      },
    }).then(() => {
      refetchCart().then((res) => {
        const { vat, items, vatAllOrder } = res?.data?.cart?.get;
        form.setFieldsValue({ ...formData, vat, items: convertItemsForForm(items), vatAllOrder });
      });
    });
  };

  const hasCart = (value) => setIsEmptyCart(value);
  return {
    cartPermissions,
    onChangeTab,
    data,
    loading:
      loadingGetCart || loadingCartPermissions || loadingUpdateCart || loadingUpdateCartItem || loadingUpdateCartSeller,
    form,
    dataInfoAgent,
    dataInfoProductAndBill,
    handleUpdate,
    handleUpdateSeller,
    refetchCart,
    handleUpdateItem,
    handleAddNewItems,
    handleRemoveCartItem,
    hasCart,
    isEmptyCart,
    handleVatAllItems,
    carts,
    dataShippingProvider,
    loadingShippingProvider,
  };
};

export default useList;

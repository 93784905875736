import { Button } from 'antd';
import LoadingComponent from 'components/LoadingComponent';
import { STOCK_STATUS } from 'config/constants';
import { useApproveStock, useDenyStock, useOrderPermissions } from 'hooks/order';
import { WARNING_MODAL_ACTION } from 'pages/App/Order/conts';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'utils/helperFuncs';
import CustomModalCancelOrder from '../CustomModalCancelOrder';
import CustomModalWarning from '../CustomModalWarning';
import './index.scss';

const WaitingStocksButtons = ({ checkedKeyList, selectedList, resetCheckedList }) => {
  const { t } = useTranslation();
  const [openModalWarningApproval, setOpenModalWarningApproval] = useState(false);
  const [openModalWarningWaitingStock, setOpenModalWarningWaitingStock] = useState(false);

  const { handleApproveStock, loading: loadingApproveStock } = useApproveStock();
  const { handleDenyStock, loading: loadingDenyStock } = useDenyStock();
  const [openModalCancelOrder, setOpenModalCancelOrder] = useState(false);
  const { canApproveStock, canDenyStock, canCancelOrder, canOrderCancelBeforePayView } =
    useOrderPermissions();

  const handleDenyOrder = async () => {
    if (selectedList?.length === 0) {
      notify.error({
        message: t('order.orderList.selectOrderValidate'),
      });
    } else {
      if (selectedList.some((item) => item.inStockStatus === STOCK_STATUS.FULL)) {
        setOpenModalWarningWaitingStock(true);
      } else {
        await handleDenyStock({
          orderIDs: checkedKeyList,
        })
          .then(() => {
            notify.success({
              message: t('order.orderList.denyStockSuccess'),
            });
          })
          .catch((error) => {
            notify.error({
              message: t('order.orderList.denyStockError'),
              description: error.message,
            });
          })
          .finally(() => {
            resetCheckedList();
          });
      }
    }
  };

  const handleApproval = async () => {
    if (selectedList?.length === 0) {
      notify.error({
        message: t('order.orderList.selectOrderValidate'),
      });
    } else {
      if (selectedList.some((item) => item.inStockStatus !== STOCK_STATUS.FULL)) {
        setOpenModalWarningApproval(true);
      } else {
        await handleApproveStock({
          orderIDs: checkedKeyList,
        })
          .then(() => {
            notify.success({
              message: t('order.orderList.confirmStockSuccess'),
            });
          })
          .catch((error) => {
            notify.error({
              message: t('order.orderList.confirmStockError'),
              description: error.message,
            });
          })
          .finally(() => {
            resetCheckedList();
          });
      }
    }
  };

  const handleCancelOrder = () => {
    if (selectedList?.length === 0) {
      notify.error({
        message: t('order.orderList.selectOrderValidate'),
      });
    } else {
      setOpenModalCancelOrder(true);
    }
  };

  const isLoading = loadingApproveStock || loadingDenyStock;

  return (
    <LoadingComponent loading={isLoading}>
      <div className={`button-group-status`}>
        {canOrderCancelBeforePayView && canCancelOrder && (
          <>
            <Button onClick={handleCancelOrder} className="secondary-button">
              {t('order.orderList.cancelOrder')}
            </Button>
            {openModalCancelOrder && (
              <CustomModalCancelOrder
                setOpenModalCancelOrder={setOpenModalCancelOrder}
                orderIDs={checkedKeyList}
                resetCheckedList={resetCheckedList}
              />
            )}
          </>
        )}

        {canDenyStock && (
          <>
            <Button onClick={handleDenyOrder} className="secondary-button">
              {t('order.orderDetail.unableToImport')}
            </Button>
            {openModalWarningWaitingStock && (
              <CustomModalWarning
                openModalWarning={openModalWarningWaitingStock}
                setOpenModalWarning={setOpenModalWarningWaitingStock}
                orderIDs={checkedKeyList}
                action={WARNING_MODAL_ACTION.DENY_STOCK_LIST}
                resetCheckedList={resetCheckedList}
              />
            )}
          </>
        )}

        {canApproveStock && (
          <>
            <Button className="btn-main-action" onClick={handleApproval}>
              {t('order.orderDetail.sufficientConfirmation')}
            </Button>
            {openModalWarningApproval && (
              <CustomModalWarning
                openModalWarning={openModalWarningApproval}
                setOpenModalWarning={setOpenModalWarningApproval}
                orderIDs={checkedKeyList}
                action={WARNING_MODAL_ACTION.CONFIRM_STOCK_LIST}
                resetCheckedList={resetCheckedList}
              />
            )}
          </>
        )}
      </div>
    </LoadingComponent>
  );
};

export default WaitingStocksButtons;

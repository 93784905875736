import React from 'react';
import useFiltersTag from './useFiltersTag';
import { PROMOTION_PRICE_BID_STATUS } from 'features/Products/constants';
export const FiltersTag = ({
  params,
  handleRemoveFilterTag,
  rangeDateTimeFilter,
  statusTabs,
  warehouseOptions,
  optionImportRequestType,
  staffs,
  sellersOptions,
  canImportSalesReuturnView,
}) => {
  const {
    renderTagsStatusRequestImport,
    renderTagsWarehouseIDs,
    renderTagsTypes,
    renderTagsCreatedByIDs,
    renderTagsSupplierIDs,
    renderTagsupdatedByIDs,
    renderTagTimeRangeFilter,
  } = useFiltersTag({
    params,
    handleRemoveFilterTag,
    rangeDateTimeFilter,
    warehouseOptions,
    optionImportRequestType,
    staffs,
    sellersOptions,
  });
  return (
    <div style={{ marginBottom: '12px' }}>
      {params?.filters?.statuses?.length > 0 && statusTabs === PROMOTION_PRICE_BID_STATUS.ALL
        ? renderTagsStatusRequestImport
        : null}
      {params?.filters?.warehouseIDs?.length > 0 ? renderTagsWarehouseIDs : ''}
      {params?.filters?.types?.length > 0 && params?.filters?.types?.length < 2
        ? renderTagsTypes
        : ''}
      {params?.filters?.supplierIDs?.length > 0 ? renderTagsSupplierIDs : ''}
      {params?.filters?.updatedByIDs?.length > 0 ? renderTagsupdatedByIDs : ''}
      {canImportSalesReuturnView === true && params?.filters?.createdByIDs?.length > 0
        ? renderTagsCreatedByIDs
        : ''}
      {rangeDateTimeFilter?.from !== undefined ? renderTagTimeRangeFilter : ''}
    </div>
  );
};

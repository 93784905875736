import { AutoComplete } from 'antd';
import useDebounce from 'hooks/useDebounce';
import React, { useState, useEffect } from 'react';

const CustomSelectLoadMore = ({
  options,
  onSearch,
  placeholder,
  onLoadMore,
  hasMoreData,
  onSelect,
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debounceValue = useDebounce(searchTerm);

  const handlePopupScroll = (e) => {
    const target = e.target;
    const scrollTop = target.scrollTop;
    const offsetHeight = target.offsetHeight;
    const scrollHeight = target.scrollHeight;
    if (hasMoreData && scrollTop + offsetHeight === scrollHeight) {
      onLoadMore && onLoadMore();
    }
  };

  const handleSearch = (value) => setSearchTerm(value);

  const handleSelect = (_, option) => {
    onSelect && onSelect(option?.key);
  };
  useEffect(() => {
    onSearch && onSearch(debounceValue);
  }, [debounceValue]);

  return (
    <AutoComplete
      {...rest}
      showSearch
      allowClear
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      virtual={false}
      onPopupScroll={handlePopupScroll}
      options={options}
    />
  );
};

export default CustomSelectLoadMore;

import { DATE_FORMAT } from "config/constants";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { formatDateTime } from "utils/dateTime";
import { getQuery } from "utils/helperFuncs";
import SpaceCustom from "../SpaceCustom";
import TagCustom from "../TagCustom";
import Tooltip from "../Tooltip";
import SkeletonCustom from "../Skeleton";

function TagFilterCustom({ arrTags = [], arrValue, title, loading, onClose, isTimeRange }) {
  const GET_QUERY = getQuery();
  let result = [];
  const from = formatDateTime(GET_QUERY?.from, DATE_FORMAT);
  const to = formatDateTime(GET_QUERY?.to, DATE_FORMAT);

  for (const element of arrValue) {
    const item = arrTags?.find(({ value }) => element === value);
    result.push(item);
  }
  const label = result.map((item) => item?.label).join(", ");

  return (
    <TagCustom onClose={onClose} closable>
      <SpaceCustom direction="horizontal" gap={4}>
        <span className="label">{title}: </span>
        {loading ? (
          <SkeletonCustom oneLine />
        ) : (
          <Tooltip title={label} limit={100}>
            {result?.length >= 5 ? truncateAddDots(label, 100) : label}
          </Tooltip>
        )}
      </SpaceCustom>
    </TagCustom>
  );
}

export default TagFilterCustom;

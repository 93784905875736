import { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { notification } from 'antd';

export const useFirebase = (firebaseConfig) => {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  async function getMessagingToken() {
    return Notification.requestPermission().then(() => {
      if (Notification.permission === 'granted') {
        return getToken(messaging);
      }
      return Promise.resolve();
    });
  }

  useEffect(() => {
    onMessage(
      messaging,
      (payload) => {
        if (payload.notification) {
          // todo pass notification level to right method
          notification.info({
            message: payload.notification.title,
            description: payload.notification.body,
          });
        }
      },
      []
    );
  });

  return {
    messaging,
    getMessagingToken,
  };
};

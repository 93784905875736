import LoadingComponent from 'components/LoadingComponent';
import { useGetCategory } from 'hooks/category/category';
import { useParams } from 'react-router-dom';
import FormProductCategory from '../components/FormProductCategory';
import { ACTION } from '../const';

const EditProductCategory = () => {
  const { id } = useParams();

  const { data, loading } = useGetCategory({ id });

  return (
    <LoadingComponent loading={loading}>
      <FormProductCategory action={ACTION.EDIT} category={data} idCategory={id} />
    </LoadingComponent>
  );
};

export default EditProductCategory;

import React from 'react';
import { Col, Row, Typography } from 'antd';
import { SelectLoadMore } from 'components/CustomSelect';
import { t } from 'i18next';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { join } from 'lodash';

function InfoProvider({
  sellers,
  loadMoreSeller,
  hasMoreSeller,
  sellerInfo,
  handleChangeInfo,
  onSearchSeller,
}) {
  const sellerOptions = sellers?.map((item) => ({
    value: item?.value,
    label: (
      <Row gutter={[16]}>
        <Col
          span={5}
          className={styles['item']}
          style={{ padding: '10px 0 0 20px', textAlign: 'left' }}
        >
          <Typography.Link strong>{item?.code}</Typography.Link>
        </Col>
        <Col span={7} className={styles['item']} style={{ paddingTop: '10px', textAlign: 'left' }}>
          <Typography.Text ellipsis>{item?.sellername}</Typography.Text>
        </Col>
        <Col span={4} style={{ paddingTop: '10px', textAlign: 'center' }}>
          <Typography.Text>{item?.telephone}</Typography.Text>
        </Col>
        <Col span={8} style={{ paddingTop: '10px', textAlign: 'left' }}>
          <Typography.Text ellipsis>{item?.address}</Typography.Text>
        </Col>
      </Row>
    ),
    seller: item,
    labelSeller: item?.sellername,
  }));
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <div>
          <SelectLoadMore
            formItemOptions={{
              className: 'form-seller-select',
              name: 'supplierID',
              rules: [{ required: true, message: t('warehouse.create.relueschosseSeller') }],
            }}
            inputOptions={{
              allowClear: false,
              placeholder: t('warehouse.create.searchProvider'),
              options: sellerOptions,
              optionLabelProp: 'labelSeller',
              onSelect: (value, options) => handleChangeInfo(value, options),
              dropdownRender: (originNode) => (
                <>
                  <Row gutter={16} className={styles['custom-dropdown']}>
                    <Col
                      span={4}
                      className={styles['item']}
                      style={{ padding: '10px 0 0 20px', textAlign: 'center' }}
                    >
                      <Typography.Text>{t('supplier.table.supplierCode')}</Typography.Text>
                    </Col>
                    <Col
                      span={7}
                      className={styles['item']}
                      style={{ paddingTop: '10px', textAlign: 'center' }}
                    >
                      <Typography.Text>{t('supplier.table.supplierName')}</Typography.Text>
                    </Col>
                    <Col
                      span={5}
                      className={styles['item']}
                      style={{ paddingTop: '10px', textAlign: 'center' }}
                    >
                      <Typography.Text>{t('supplier.table.phoneNumber')}</Typography.Text>
                    </Col>
                    <Col
                      span={8}
                      className={styles['item']}
                      style={{ paddingTop: '10px', textAlign: 'center' }}
                    >
                      <Typography.Text>{t('supplier.table.address')}</Typography.Text>
                    </Col>
                  </Row>
                  {originNode}
                </>
              ),
            }}
            onLoadMore={loadMoreSeller}
            hasMoreData={hasMoreSeller}
            onSearch={onSearchSeller}
          />
        </div>
      </Col>
      <Col span={24} className="form-info-provider">
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">
              {t('warehouse.create.providerrecommend')}
            </Typography.Text>
          </Col>
          <Col span={18}>
            <Link to={`/supplier/detail/${sellerInfo?.value}`}>
              <Typography.Link strong>{sellerInfo?.code || '---'}</Typography.Link>
            </Link>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}></Col>
          <Col span={18}>
            <Typography.Text>{sellerInfo?.label || sellerInfo?.fullName || '---'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{'SĐT'}</Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>{sellerInfo?.telephone || '---'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{t('common.address')}</Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>{sellerInfo?.address || '---'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">
              {t('warehouse.create.infoAccountBank')}
            </Typography.Text>
          </Col>
          <Col span={18}>
            {sellerInfo?.bankAccounts?.map((item) => (
              <div>
                <Typography.Text>
                  {`${item?.bank?.name} - ${item?.bankAccountNumber} - ${item?.name} - chi nhánh ${item?.bankBranch}` ||
                    '---'}
                </Typography.Text>
              </div>
            ))}
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24} style={{ textAlign: 'center', width: '100%' }}>
            {!sellerInfo && (
              <Typography.Link strong>{t('warehouse.create.PleasesearchProvider')}</Typography.Link>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default InfoProvider;

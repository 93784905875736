/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { useGetDetailProductBrand, useUpdateProductBrand } from 'hooks/product/product';
import { notify } from 'utils/helperFuncs';
import { t } from 'i18next';

function useList() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();

  const { data } = useGetDetailProductBrand({ id });

  const filesCatalogs = data?.catalogURLs?.map((url) => {
    return {
      url: url,
    };
  });

  const initialValues = {
    ...data,
    name: data?.name,
    description: data?.description,
    logo: data?.logoURL !== '' ? [{ url: data?.logoURL }] : [],
    catalogs: filesCatalogs?.length > 0 && filesCatalogs[0].url?.length > 0 ? filesCatalogs : [],
    isActive: data?.isActive,
    id: data?.id,
  };

  const onCancelCreate = () => {
    history.push('/product-brand');
  };
  const { handleUpdateProductBrand, loading } = useUpdateProductBrand();
  const [loadingUpdate, setloadingUpdate] = useState(loading);

  const handleSubmitCreate = async () => {
    setloadingUpdate(true);
    const values = await form.getFieldsValue();
    try {
      const params = {
        name: values.name,
        logo: values?.logo || '',
        catalogs: values?.catalogs,
        description: values.description,
        isActive: data?.isActive,
        id: data?.id,
      };
      if (values?.name) {
        await form.validateFields();
        await handleUpdateProductBrand({ request: params })
          .then(() => {
            notify.success({ message: t('productBrand.updateBrandSuccess') });
            history.push('/product-brand');
          })
          .catch((error) => {
            notify.error({
              message: error.message,
            });
          });
      }
    } catch (error) {
      console.info(error);
    } finally {
      setloadingUpdate(false);
    }
  };
  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
  }, [data]);

  return {
    loading,
    form,
    initialValues,
    data,
    onCancelCreate,
    handleSubmitCreate,
    loadingUpdate,
  };
}

export default useList;

import { gql } from "@apollo/client";

export const GET_PRODUCTS_LOAD_MORE = gql`
  query GetProductLoadMore($filters: ProductsFilters, $pagination: PaginationRequest) {
    product {
      pagination(filters: $filters, pagination: $pagination) {
        products {
          id
          name
          code
          uom {
            id
            name
          }
          inStock
          vatPercent
        }
      }
    }
  }
`;

export const GET_LIST_IMPORT_SERIAL = gql`
  query GetListImportSerial(
    $branchesID: ID!
    $filters: SerialImportNotesFilters
    $pagination: PaginationRequest! = { offset: 0, limit: 10 }
    $sort: [SerialImportNotesSort!]
  ) {
    serialImportNotes {
      pagination(branchesID: $branchesID, filters: $filters, pagination: $pagination, sort: $sort) {
        paginationData {
          total
          offset
          limit
          cursorHasNext
        }

        SerialImportNotes {
          id
          code
          type
          createdBy {
            fullname
          }
          quantityKindOfProduct
          quantitySerial
          createdAt
          seller {
            fullName
            telephone
          }
          provider {
            fullName
            telephone
          }
        }
      }
    }
  }
`;

export const GET_LIST_SERIAL = gql`
  query PaginationSerialBySerial(
    $filters: SerialImportNotesFilters
    $pagination: PaginationRequest! = { offset: 0, limit: 10 }
    $sort: [SerialImportNotesSort!]
  ) {
    serialImportNotes {
      paginationSerial(filters: $filters, pagination: $pagination, sort: $sort) {
        paginationData {
          total
          offset
          limit
          cursorHasNext
        }
        importSerialItems {
          serialID
          serial {
            code
            createdAt
            product {
              name
              id
              code
            }
          }
          warehouse {
            name
          }
          serialImportNoteItemID
          serialImportNoteItem {
            warrantyType
            warrantyUnit
            warrantyOnSale
            warrantyPeriodProvider
            serialImportNote {
              type
              code
              id
              createdByID
              createdAt
              createdBy {
                fullname
              }
              provider {
                fullName
              }
              seller {
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DETAIL_SERIAL_IMPORT = gql`
  query GET_DETAIL_IMPORT_SERIAL($id: ID!) {
    serialImportNotes {
      get(id: $id) {
        id
        code
        type
        note
        providerID
        warehouseID
        warehouse {
          name
          address
          ward {
            name
          }
          district {
            name
          }
          city {
            name
          }
          branch {
            name
          }
        }
        order {
          id
          number
        }
        provider {
          id
          fullName
          telephone
          email
          address
        }
        sellerID
        warehouse {
          name
        }
        seller {
          id
          fullName
          telephone
          email
          address
        }
        createdByID
        createdBy {
          id
          fullname
        }
        createdAt
        updatedAt
        branchID
        branch {
          id
          name
        }
        soldDateFromProvider
        quantityKindOfProduct
        quantitySerial
        fileURLs
        items {
          id
          serialImportNoteID
          productID
          product {
            id
            name
            code
            uom {
              name
            }
          }
          warrantyType
          warrantyOnSale
          warrantyPeriodProvider
          warrantyUnit
          serialItems {
            warrantyNoteID
            warrantyNote {
              code
              id
              orderID
              order {
                number
                id
              }
            }
            serial {
              status
              code
            }
            warehouse {
              name
            }
          }
          quantity
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_EXPORT_NOTES_SERIAL = gql`
  query GetExportNotesSerial(
    $filters: SerialExportNotesFilters!
    $pagination: PaginationRequest! = { offset: 0, limit: 10 }
    $sort: [SerialExportNoteSort!]
  ) {
    serialExportNotes {
      pagination(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
          cursorHasNext
        }
        SerialExportNotes {
          createdAt
          id
          code
          order {
            id
            number
          }
          seller {
            fullName
          }
          provider {
            fullName
          }
          quantityKindOfProduct
          quantitySerial
          createdBy {
            fullname
          }
          items {
            exportItems {
              exportedWarehouse {
                name
              }
            }
          }
          type
        }
      }
    }
  }
`;

export const GET_EXPORT_NOTES_BY_SERIAL = gql`
  query GetExportNotesBySerial(
    $filters: SerialExportNotesFilters!
    $pagination: PaginationRequest! = { offset: 0, limit: 10 }
    $sort: [SerialExportNoteSort!]
  ) {
    serialExportNotes {
      paginationSerial(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
          cursorHasNext
        }
        exportSerials {
          serialExportNoteItem {
            serialExportNote {
              createdAt
              id
              code
              order {
                id
                number
              }
              seller {
                fullName
              }
              provider {
                fullName
              }
              createdBy {
                fullname
              }
              type
            }
          }
          serial {
            id
            code
            product {
              id
              code
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_SERIAL = gql`
  query GetSerialExportNoteResponse(
    $filter: SerialFilters
    $pagination: PaginationRequest! = { offset: 0, limit: 10 }
  ) {
    serial {
      pagination(filters: $filter, pagination: $pagination) {
        Serials {
          id
          code
          product {
            id
            code
            name
            category {
              id
              name
            }
          }
          history {
            serialImportNoteItem {
              serialImportNote {
                seller {
                  fullName
                }
                provider {
                  fullName
                }
              }
            }
          }
        }
        paginationData {
          total
          limit
          offset
        }
      }
    }
  }
`;

export const GET_DETAIL_SERIAL_EXPORT = gql`
  query GetDetailSerialExport($id: ID!) {
    serialExportNotes {
      get(id: $id) {
        id
        code
        type
        orderID
        order {
          id
          number
          fullEvents {
            state
            name
            createdAt
            createdByID
            createdBy {
              id
              fullname
            }
          }
          status
          items {
            productID
            quantity
          }
          seller {
            fullName
            telephone
            email
            address
          }
          exportedWarehouse {
            name
          }
        }
        sellerID
        seller {
          id
          code
          fullName
          telephone
          email
          address
          ward {
            name
          }
          district {
            name
          }
          city {
            name
          }
        }
        provider {
          id
          code
          fullName
          telephone
          address
          ward {
            name
          }
          district {
            name
          }
          city {
            name
          }
        }
        note
        fileURLs
        createdAt
        updatedAt
        createdBy {
          id
          fullname
        }
        items {
          id
          product {
            id
            code
            name
            inStock
            uom {
              name
            }
          }
          warrantyOnSale
          warrantyOnSaleUnit
          quantity
          expectedQuantity
          serialExportNote {
            code
            id
            provider {
              id
              fullName
            }
          }
          soldDate
          exportItems {
            warrantyNote {
              code
              id
              provider {
                id
                fullName
              }
            }
            serial {
              code
              id
              provider {
                fullName
              }
            }
            replaceSerial {
              provider {
                fullName
              }
              id
              code
            }
            exportedWarehouse {
              name
              id
              branch {
                name
              }
              city {
                name
              }
              district {
                name
              }
              ward {
                name
              }
              address
            }
          }
        }
      }
    }
  }
`;

export const GET_DETAIL_SERIAL = gql`
  query getDetailSerial($id: ID!) {
    serial {
      get(id: $id) {
        id
        createdAt
        code
        status
        isMigrated
        migratedSerialData {
          id
          serial
          name
          supplier
          dateOfImport
          warrantyOnImportType
          warrantyOnImportPeriod
          warrantyOnImportPeriodUnit
          dateOfSale
          customerName
          customerEmail
          customerPhone
          warrantyOnSaleType
          warrantyOnSalePeriod
          warrantyOnSalePeriodUnit
          branchName
        }
        provider {
          id
          fullName
        }
        product {
          id
          name
          code
        }
        warrantyUnit
        warrantyPeriodProvider

        history {
          serialExportNoteItem {
            createdAt
            warrantyOnSale
            warrantyOnSaleUnit
            createdAt
            exportItems {
              exportedWarehouse {
                name
              }
            }
            serialExportNote {
              type
              seller {
                id
                fullName
              }
            }
          }
          serialImportNoteItem {
            createdAt
            serialItems {
              serial {
                provider {
                  fullName
                }
              }
              serialImportNoteItem {
                warrantyUnit
                warrantyPeriodProvider
                serialImportNote {
                  type
                  provider {
                    fullName
                  }
                  seller {
                    fullName
                  }
                }
              }
              serialID
              warehouse {
                name
              }
            }
            serialImportNote {
              code
            }
          }
        }
      }
    }
  }
`;

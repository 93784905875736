import React from 'react';
import { t } from 'i18next';
import CustomTags from 'components/CustomTags';
import { formatDateTime } from 'utils/dateTime';
import { DATE_FORMAT } from 'config/constants';

const useFiltersTag = ({ params, handleRemoveFilterTag, staffOptions, rangeDateTimeFilter }) => {
  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    if (
      typeof arrValue !== 'undefined' &&
      arrValue !== null &&
      typeof arrValue[Symbol.iterator] === 'function'
    ) {
      for (const element of arrValue) {
        const item = arrTags?.find(({ value }) => element === value);
        result.push(item);
      }
    }
    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(', ')}
      </div>
    );
  };
  const valueTagsImportNoteType = [
    {
      label: t('serialIE.FIRST_TIME_IMPORT'),
      value: 'FIRST_TIME_IMPORT',
    },
    {
      label: t('serialIE.RE_IMPORT_WARRANTY'),
      value: 'RE_IMPORT_WARRANTY',
    },
    {
      label: t('serialIE.RETURN'),
      value: 'RETURN',
    },
  ];
  const tagsCreatedByID = [
    {
      title: 'NHÂN VIÊN PHỤ TRÁCH',
      options: staffOptions,
      selected: params?.filters?.createdByIDs,
      key: 'createdByIDs',
    },
  ];
  const tagsCreatedByIDPriceLogs = [
    {
      title: 'NHÂN VIÊN PHỤ TRÁCH',
      options: staffOptions,
      selected: params?.createdByIDs,
      key: 'createdByIDs',
    },
  ];
  const tagsImportNoteType = [
    {
      title: 'HÌNH THỨC NHẬP',
      options: valueTagsImportNoteType,
      selected: params?.filters?.serialImportNoteType,
      key: 'serialImportNoteType',
    },
  ];
  const tagsCreatedAtFilter = [
    {
      title: 'Thời gian',
      // options: valueTagsImportNoteType,
      selected: rangeDateTimeFilter,
      key: 'createdAtFilter',
    },
  ];

  const renderTagsCreatedByID = tagsCreatedByID.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsCreatedByIDPriceLogs = tagsCreatedByIDPriceLogs.map(
    ({ options, selected, title, key }) => {
      return (
        <CustomTags
          handleOnClose={() => handleRemoveFilterTag(key)}
          key={key}
          label={renderTag(options, selected, title)}
        />
      );
    }
  );
  const renderTagsImportNoteType = tagsImportNoteType.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagCreatedAtFilter = tagsCreatedAtFilter.map(({ options, selected, title, key }) => {
    const getDate = new Date(selected?.to);
    const getDateTo = `${String(getDate.getUTCDate()).padStart(2, '0')}/${String(
      getDate.getUTCMonth() + 1
    ).padStart(2, '0')}/${getDate.getUTCFullYear()}`;
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={`${title} : ${t('order.orderTag.from')} ${formatDateTime(
          selected?.from,
          DATE_FORMAT
        )} ${t('order.orderTag.to')} ${getDateTo}`}
      />
    );
  });

  return {
    renderTagsCreatedByID,
    renderTagsImportNoteType,
    renderTagsCreatedByIDPriceLogs,
    renderTagCreatedAtFilter,
  };
};

export default useFiltersTag;

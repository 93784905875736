import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, Select, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import { FilterLoadMore } from "components/CustomFilterMenu";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import { CustomPaginationPrevNext } from "components/CustomPaginationPrevNext";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import PopupDetailSerial from "features/Serial/components/PopupDetailSerial";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { FiltersTag } from "../../../components/FiltersTag";
import { typeExport } from "../../../constant";
import "./index.scss";
import useList from "./useList";

function ListBySerial() {
  const {
    valueFormSelectCity,
    loading,
    data,
    branchesID,
    paginationData,
    handleChangeTable,
    onSearch,
    onChangeBranchesId,
    handleRemoveFilterTag,
    params,
    staffs,
    optionExportRadio,
    checkNullValue,
    handleSort,
    paramsStaff,
    handleFilter,
    hasMoreData,
    handleSearchStaffs,
    onLoadMoreData,
    handlePrevNextPage,
  } = useList();

  const columns = [
    {
      title: t("serialIE.stt"),
      render: (_, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      title: (
        <Typography.Text className="title-table" onClick={() => handleSort("ID")}>
          {t("serialIE.createdAtExport")}
          <SvgIcon.SortIcon className={params.sort[0]?.sortType} />
        </Typography.Text>
      ),
      width: 150,
      render: (_, record) => checkNullValue(formatDateTime(record?.serialExportNoteItem?.serialExportNote?.createdAt)),
      fixed: "left",
    },
    {
      title: t("serialIE.CODE_SERIAL/IMEI"),
      render: (_, record) => <PopupDetailSerial button={record?.serial?.code} id={record?.serial?.id} />,
      fixed: "left",
    },
    {
      title: t("serialIE.PRODUCT_CODE"),
      render: (_, record) => (
        <>
          <Typography.Link style={{ fontWeight: "bold" }} className="text-left">
            {checkNullValue(<ProductLink product={record?.serial} />)}
          </Typography.Link>
          <Typography.Text style={{ fontWeight: "bold" }} className="text-left">
            {checkNullValue(record?.serial?.product?.name)}
          </Typography.Text>
        </>
      ),
      fixed: "left",
      width: 300,
    },
    {
      title: t("serialIE.EXPORT_SERIAL_CODE"),
      render: (_, record) => (
        <Typography.Link style={{ fontWeight: "bold" }} className="text-left">
          <Link to={`/serial-export/detail/${record?.serialExportNoteItem?.serialExportNote?.id}`}>
            {record.code}
            {checkNullValue(record?.serialExportNoteItem?.serialExportNote?.code)}
          </Link>
        </Typography.Link>
      ),
    },
    {
      title: t("serialIE.ORDER_NUMBER"),
      render: (_, record) => (
        <Typography.Link style={{ fontWeight: "bold" }} className="text-left">
          <Link to={`/order/${record?.serialExportNoteItem?.serialExportNote?.order?.id}`}>
            {checkNullValue(record?.serialExportNoteItem?.serialExportNote?.order?.number)}
          </Link>
        </Typography.Link>
      ),
    },
    {
      title: t("serialIE.agent"),
      render: (_, record) => (
        <Typography.Text className="text-left">
          {checkNullValue(
            <CustomTextHiddenLine
              text={
                record?.serialExportNoteItem?.serialExportNote?.seller?.fullName ||
                record?.serialExportNoteItem?.serialExportNote?.provider?.fullName
              }
              line={3}
            />
          )}
        </Typography.Text>
      ),
    },
    {
      title: t("serialIE.staff"),
      render: (_, record, index) =>
        checkNullValue(checkNullValue(record?.serialExportNoteItem?.serialExportNote?.createdBy?.fullname)),
      filterDropdown: (
        <FilterLoadMore
          params={paramsStaff}
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          hasMore={hasMoreData}
          onFilter={(value) => handleFilter({ createdByIDs: value })}
          onSearchTextChange={handleSearchStaffs}
        />
      ),
    },
    {
      title: t("serialIE.typeExport"),
      align: "left",
      filterDropdown: (
        <FilterMenu onFilter={(value) => handleFilter({ type: value })} type="checkbox" options={optionExportRadio} />
      ),
      render: (_, record, index) => checkNullValue(typeExport?.[record?.serialExportNoteItem?.serialExportNote?.type]),
    },
  ];
  return (
    <div className="list-by-serial-export-notes-page">
      <Form
        name="basic"
        autoComplete="off"
        style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <Col span="10">
          <Form.Item>
            <Input placeholder={t("serialIE.searchBySerial")} prefix={<SearchOutlined />} onChange={onSearch} />
          </Form.Item>
        </Col>
        <Col span="3">
          <Form.Item>
            <Select options={valueFormSelectCity} value={branchesID} onChange={(e) => onChangeBranchesId(e)} />
          </Form.Item>
        </Col>
      </Form>
      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
        <FiltersTag
          staffOptions={staffs}
          params={params}
          optionExportRadio={optionExportRadio}
          handleRemoveFilterTag={handleRemoveFilterTag}
        />
      </div>
      <CustomTable
        loading={loading}
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1800,
        }}
        rowKey={(record) => record?.id}
        onChange={handleChangeTable}
      />
      {paginationData && (
        <CustomPaginationPrevNext handlePrevNextPage={handlePrevNextPage} paginationData={paginationData} />
      )}
    </div>
  );
}

export default ListBySerial;

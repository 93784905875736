import React from 'react';

const InfoReceiverAndSender = ({ infoUser = {} }) => {
  const { title, name, phoneNumber, address } = infoUser;
  return (
    <div className="info-receiver-and-sender">
      <b>{title}</b>
      <div className="info-address">
        <div>
          <span>
            <b>{name}</b>
            {phoneNumber ? `| ${phoneNumber}` : null}
          </span>
        </div>
        <span>{address}</span>
      </div>
    </div>
  );
};

export default InfoReceiverAndSender;

import FilterLoadMore from "components/_storybooks/FilterLoadMore";
import { useGetStaff } from "hooks/warranty";
import { useState } from "react";

function UserFilter({ onFilter }) {
  const [params, setParams] = useState({
    filters: { query: undefined },
    pagination: { offset: 0, limit: 10 },
  });

  const { hasMoreData, onLoadMoreData, loading, data } = useGetStaff(params);

  const handleSearchSeller = (values) => {
    setParams({
      ...params,
      pagination: { offset: 0, limit: 10 },
      filters: { query: values || undefined },
    });
  };

  return (
    <FilterLoadMore
      onFilter={(value) => onFilter("staffInChargeIDs", value)}
      data={data}
      onLoadMoreData={onLoadMoreData}
      hasMore={hasMoreData}
      onSearch={handleSearchSeller}
      loading={loading}
    />
  );
}

export default UserFilter;

import { Form } from "antd";
import { CAMPAIGN_TYPE, COMBO_CAMPAIGN, DISCOUNT_TYPE, PRODUCT_TYPE, TYPE_LIMIT } from "features/Campaign/constants";
import { useCheckDuplicateCodes } from "hooks/campaign";
import { useCreateCampaign } from "hooks/campaign/campaign";
import { useUploadDocument } from "hooks/upload-document";
import { t } from "i18next";
import { cloneDeep, forEach, pick, unset } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { endOfDayMomentToISOString, startOfDayMomentToISOString } from "utils/dateTime";
import { notify } from "utils/helperFuncs";

const useCreateForm = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [promotionCampaignTierGroups, setPromotionCampaignTierGroups] = useState([]);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);
  const [dataConfirmModal, setDataConfirmModal] = useState(null);
  const [indexErrorCombo, setIndexErrorCombo] = useState([]);
  const [isApplyWithPromotion, setIsApplyWithPromotion] = useState(false);
  const [promoType, setPromoType] = useState();
  const [hasChanges, setHasChanges] = useState(false);

  const initialValues = {
    bannerDesktop: [],
    bannerMobile: [],
    buyChannel: [],
    description: null,
    highlight: null,
    link: null,
    period: null,
    promoName: null,
    promoType: null,
    promotionCampaignTierGroups: [],
    promotionCampaignTierGroupsDiscountMoney: null,
    promotionCampaignTierGroupsFixedPrice: null,
    promotionCampaignTierGroupsGift: null,
    promotionCampaignTierGroupsProductPromo: null,
  };

  const { handleUpload } = useUploadDocument();

  const getCampaignType = () => {
    switch (location.hash.replace(/#/g, "")) {
      case CAMPAIGN_TYPE.COMBO_PROMO:
        return CAMPAIGN_TYPE.COMBO_PROMO;
      case CAMPAIGN_TYPE.PRODUCT_PROMO:
        return CAMPAIGN_TYPE.PRODUCT_PROMO;
      default:
        return null;
    }
  };

  const validateConditionProducts = (conditionProducts) => {
    return conditionProducts?.some((itemProductGroup) => itemProductGroup.conditionPromotionProducts.length === 0);
  };

  function mergeCampaignTierGroups(values) {
    const discountMoneyIterable = Array.isArray(values?.promotionCampaignTierGroupsDiscountMoney)
      ? values?.promotionCampaignTierGroupsDiscountMoney
      : [];
    const fixedPriceIterable = Array.isArray(values?.promotionCampaignTierGroupsFixedPrice)
      ? values?.promotionCampaignTierGroupsFixedPrice
      : [];
    const giftIterable = Array.isArray(values?.promotionCampaignTierGroupsGift)
      ? values?.promotionCampaignTierGroupsGift
      : [];
    const productPromoIterable = Array.isArray(values?.promotionCampaignTierGroupsProductPromo)
      ? values?.promotionCampaignTierGroupsProductPromo
      : [];
    const mergePromotionCampaignTierGroups = [
      ...discountMoneyIterable,
      ...fixedPriceIterable,
      ...giftIterable,
      ...productPromoIterable,
    ];

    return mergePromotionCampaignTierGroups;
  }

  const handleOpenConfirmModal = async () => {
    const values = await form.validateFields();

    let allConditionsMet = true;
    const result = mergeCampaignTierGroups(values);
    if (!result?.length) {
      allConditionsMet = false;
      setVisibleConfirmModal(false);
      notify.error({
        message:
          getCampaignType() === CAMPAIGN_TYPE.PRODUCT_PROMO
            ? t("campaign.error.productNotYet")
            : t("campaign.error.promotionalCombosYet"),
      });
    } else {
      result?.forEach((itemGroups, indexGroups) => {
        itemGroups?.promotionCampaignTiers?.forEach((itemTiers, indexTiers) => {
          if (
            validateConditionProducts(itemTiers.conditionProductGroups) ||
            validateConditionProducts(itemTiers.conditionProducts) ||
            validateConditionProducts(itemTiers.conditionGifts)
          ) {
            allConditionsMet = false;
            notify.error({
              message: t("campaign.error.checkSelectItems"),
            });
          }

          if (itemTiers.discountType === DISCOUNT_TYPE.DISCOUNT_PERCENTAGE && itemTiers.discountAmount > 99) {
            allConditionsMet = false;
            notify.error({
              message: t("campaign.error.errorValidPercent"),
            });
          }
        });
      });
    }

    if (allConditionsMet) {
      const newDataConfirmModal = {
        name: values?.promoName,
        applyWithOtherPromoTypes: values?.applyWithOtherPromoTypes,
        startDate: startOfDayMomentToISOString(values?.period?.[0]),
        endDate: endOfDayMomentToISOString(values?.period?.at(1)),
        promotionCampaignTierGroups,
      };
      setVisibleConfirmModal(true);
      setDataConfirmModal(newDataConfirmModal);
    }
  };

  const handleCancelConfirmModal = () => {
    setVisibleConfirmModal(false);
  };

  const handleValuesChange = (changeValue, allValue) => {
    const values = form.getFieldsValue();
    const result = mergeCampaignTierGroups(values);
    if (changeValue?.promoType) {
      setPromoType(changeValue?.promoType);
    }
    if (changeValue?.applyWithOtherPromo === false) {
      const newValues = {
        ...values,
        applyWithOtherPromoTypes: [],
      };
      form.setFieldsValue(newValues);
    }
    if (
      changeValue?.promotionCampaignTierGroupsDiscountMoney ||
      changeValue?.promotionCampaignTierGroupsFixedPrice ||
      changeValue?.promotionCampaignTierGroupsGift ||
      changeValue?.promotionCampaignTierGroupsProductPromo
    ) {
      const newPromotionTierGroups = result?.map((itemTiers, index) => {
        return { ...itemTiers };
      });
      setPromotionCampaignTierGroups(newPromotionTierGroups);
    }
    setHasChanges(true);
  };

  const handleCheckIsApplyWithPromo = (value) => {
    setIsApplyWithPromotion(value);
  };

  const uploadImage = async (values) => {
    const handleImageUpload = async (item) => {
      try {
        const result = await handleUpload({ files: [item?.originFileObj] });
        return result?.[0]?.url;
      } catch (error) {
        notify.error({
          message: t("uploadDocument.uploadImageError"),
          description: error?.message,
        });
        return null;
      }
    };
    const newImagesDesktopURLs = await Promise.all(
      (values?.bannerDesktop || []).map(async (item) => await handleImageUpload(item))
    );
    const newImagesMobileURLs = await Promise.all(
      (values?.bannerMobile || []).map(async (item) => await handleImageUpload(item))
    );

    return {
      imageURL: newImagesDesktopURLs?.[0],
      mobileURL: newImagesMobileURLs?.[0],
    };
  };

  const { loading: loadingCheckDuplicateCodes } = useCheckDuplicateCodes();
  const { handleCreateCampaign: handleCreateCampaignAPI } = useCreateCampaign();
  const [loadingCreateCampaign, setLoadingCreateCampaign] = useState(false);

  function convertData(input) {
    return input.map((item) => {
      if (item.promotionCampaignTiers) {
        item.promotionCampaignTiers = item.promotionCampaignTiers.map((tier) => {
          if (tier.conditionProducts) {
            tier.conditionProducts = tier.conditionProducts.map((product) => ({
              conditionPromotionProducts: product.conditionPromotionProducts?.map((item) => ({
                productID: item.id,
                type: PRODUCT_TYPE.CONDITION_PRODUCT,
              })),
              quantity: product.quantity,
              type: product.type,
            }));
          }

          if (tier.conditionGifts) {
            tier.conditionGifts = tier.conditionGifts.map((gift) => {
              const giftConditionPromotionProducts = Object.keys(gift.conditionPromotionProducts)
                .filter((key) => !isNaN(key))
                .map((key) => {
                  const convertProductWarranty = () => {
                    if (gift?.[key]?.productWarranty === 0) {
                      return 0;
                    } else {
                      return gift?.[key]?.productWarranty || gift.conditionPromotionProducts?.[key]?.warranty;
                    }
                  };

                  return {
                    productID: gift.conditionPromotionProducts?.[key]?.id,
                    budgetQuantity: gift?.[key]?.budgetQuantity,
                    productWarranty: convertProductWarranty(),
                    type: PRODUCT_TYPE.GIFT_PRODUCT,
                  };
                });

              return {
                conditionPromotionProducts: giftConditionPromotionProducts,
                quantity: gift.quantity,
                type: gift.type,
              };
            });
          }

          return {
            ...tier,
          };
        });
      }

      const conditionProductGroups = item.promotionCampaignTiers.map((item) => {
        const maxQuantityPerSeller = item?.limitType === TYPE_LIMIT.NO_LIMIT ? null : item.maxQuantityPerSeller;

        return {
          type: item.type,
          maxQuantityPerSeller: maxQuantityPerSeller,
          limitType: item.limitType,
          conditionProductGroups: [...item.conditionProducts, ...item.conditionGifts],
        };
      });

      const newData = {
        type: item.type,
        promotionCampaignTiers: conditionProductGroups,
      };
      return newData;
    });
  }

  const handleCreateCampaign = async () => {
    try {
      const values = await form.getFieldsValue();
      const discountMoneyIterable = Array.isArray(values?.promotionCampaignTierGroupsDiscountMoney)
        ? values?.promotionCampaignTierGroupsDiscountMoney
        : [];
      const fixedPriceIterable = Array.isArray(values?.promotionCampaignTierGroupsFixedPrice)
        ? values?.promotionCampaignTierGroupsFixedPrice
        : [];
      const giftIterable = Array.isArray(values?.promotionCampaignTierGroupsGift)
        ? values?.promotionCampaignTierGroupsGift
        : [];
      const productPromoIterable = Array.isArray(values?.promotionCampaignTierGroupsProductPromo)
        ? [
            {
              type: COMBO_CAMPAIGN.DISCOUNT_PRODUCT,
              promotionCampaignTiers: values?.promotionCampaignTierGroupsProductPromo?.[0]?.promotionCampaignTiers,
            },
          ]
        : [];

      const startDate = startOfDayMomentToISOString(values?.period?.[0]);
      const endDate = endOfDayMomentToISOString(values?.period?.at(1));

      const mergePromotionCampaignTierGroups = [
        ...discountMoneyIterable,
        ...fixedPriceIterable,
        ...productPromoIterable,
      ];
      setLoadingCreateCampaign(true);
      const imageURLs = await uploadImage(values);

      const params = {
        name: values?.promoName,
        campaignType: getCampaignType(),
        link: values?.link,
        applyWithOtherPromoTypes: values?.applyWithOtherPromoTypes,
        startDate: startDate,
        endDate: endDate,
        banner: imageURLs,
        highlight: values?.highlight,
        description: values?.description,
        saleChannelIDs: values?.buyChannels,
        promotionCampaignTierGroups: mergePromotionCampaignTierGroups,
      };

      const updatedData = cloneDeep(params);

      // filters productID and unset smallestValueInStock
      const promotionCampaignTierGroups = convertData(giftIterable);

      forEach(updatedData?.promotionCampaignTierGroups, (tierGroups) => {
        forEach(tierGroups?.promotionCampaignTiers, (tier) => {
          if (tier?.limitType === TYPE_LIMIT.NO_LIMIT) {
            tier.maxQuantityPerSeller = null;
          }
          forEach(tier?.conditionProductGroups, (productGroup) => {
            unset(productGroup, "smallestValueInStock");
            if (tierGroups?.type === COMBO_CAMPAIGN.FIXED_PRICE) {
              productGroup.quantity = tier.quantity;
              delete tier?.quantity;
            }
            if (getCampaignType() === CAMPAIGN_TYPE.PRODUCT_PROMO) {
              productGroup.conditionPromotionProducts = {
                productID: productGroup.conditionPromotionProducts?.id,
              };
            }
            if (getCampaignType() === CAMPAIGN_TYPE.COMBO_PROMO) {
              productGroup.conditionPromotionProducts = productGroup.conditionPromotionProducts.map((product) => {
                const productID = pick(product, ["id"]);
                return {
                  productID: productID.id,
                };
              });
            }
          });
        });
      });
      const paramsRequest = {
        ...updatedData,
        promotionCampaignTierGroups: [...promotionCampaignTierGroups, ...updatedData?.promotionCampaignTierGroups],
      };

      await handleCreateCampaignAPI(paramsRequest).then((res) => {
        const data = res?.data?.promotionCampaign?.create;
        if (!data?.promotionID) {
          notify.error({ message: data?.errorMessage });
        } else {
          notify.success({ message: t("campaign.form.createCampaignSuccess") });
          history.push("/campaign");
        }
        if (data?.indexErrorCombo) {
          setIndexErrorCombo(data?.indexErrorCombo);
        }
        setLoadingCreateCampaign(false);
      });
    } catch (error) {
      console.error("Error handling select product:", error);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);

  return {
    form,
    handleValuesChange,
    isApplyWithPromotion,
    promoType,
    initialValues,
    handleCheckIsApplyWithPromo,
    handleCreateCampaign,
    loadingCheckDuplicateCodes,
    promotionCampaignTierGroups,
    dataConfirmModal,
    visibleConfirmModal,
    handleOpenConfirmModal,
    handleCancelConfirmModal,
    createLoading: loadingCreateCampaign,
    indexErrorCombo,
  };
};

export default useCreateForm;

import React from 'react';
import { Col, Form, Row, Typography } from 'antd';
import FormInput from 'components/FormInput';
import { DATE_FORMAT, INPUT_TYPE } from 'config/constants';
import { t } from 'i18next';
import { getFile } from 'utils/helperFuncs';
import {
  optionsRequestType,
  optionsSolution,
  TOTAL_ITEMS_STATUS,
} from 'features/WarrantyRequest/constant';
import { SelectLoadMore } from 'components/CustomSelect';
import useNotOwner from './useNotOwner';
import UploadFiles from 'components/UploadFiles';

const lableCol = { span: 24, offset: 0 };

function FormUpdateNotOwner({ record, status }) {
  const {
    dataAllCategories,
    handleChange,
    handleChangeTreeSelect,
    handleSearchTreeSelect,
    hasMoreData,
    onLoadMoreData,
    onSearch,
    options,
  } = useNotOwner({ record });

  return (
    <Row gutter={16}>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            formItemOptions: {
              label: (
                <Typography.Text strong>{t('warrantyRequest.create.serialIMEI')}</Typography.Text>
              ),
              name: 'serialImei',
              rules: [{ required: true, message: t('common.validateRequired') }],
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.serialIMEI'),
            },
          }}
        />
      </Col>
      <Col span={24} xl={12}>
        <SelectLoadMore
          type={INPUT_TYPE.SELECT_LOAD_MORE}
          {...{
            formItemOptions: {
              label: (
                <Typography.Text strong>{t('warrantyRequest.create.productCode')}</Typography.Text>
              ),
              name: 'productID',
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.productCode'),
              options: options,
              allowClear: false,
            },
          }}
          onLoadMore={onLoadMoreData}
          hasMoreData={hasMoreData}
          onSearch={onSearch}
          onChange={handleChange}
        />
      </Col>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            formItemOptions: {
              label: (
                <Typography.Text strong>{t('warrantyRequest.create.productName')}</Typography.Text>
              ),
              name: 'productName',
              rules: [{ required: true, message: t('common.validateRequired') }],
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.productName'),
            },
          }}
        />
      </Col>
      <Col span={24} xl={12}>
        <FormInput
          type={INPUT_TYPE.TREE_SELECT}
          formItemOptions={{
            placeholder: t('warrantyRequest.create.placeHolder.attributeLV3'),
            label: (
              <Typography.Text strong>{t('warrantyRequest.create.attributeLV3')}</Typography.Text>
            ),
            name: 'categoryID',
          }}
          inputOptions={{
            placeholder: t('warrantyRequest.create.placeHolder.attributeLV3'),
            treeData: dataAllCategories,
            showSearch: true,
            onChange: handleChangeTreeSelect,
            filterTreeNode: (search, item) => handleSearchTreeSelect(search, item),
          }}
        />
      </Col>

      <Col span={24} xl={12}>
        <FormInput
          {...{
            type: INPUT_TYPE.SELECT,
            formItemOptions: {
              label: (
                <Typography.Text strong>{t('warrantyRequest.create.warrantyType')}</Typography.Text>
              ),
              name: 'warrantyRequestType',
              rules: [{ required: true, message: t('common.validateRequired') }],
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.warrantyType'),
              options: optionsRequestType,
              disabled: 'disabled',
            },
          }}
        />
      </Col>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            type: INPUT_TYPE.DATE_PICKER,
            formItemOptions: {
              label: (
                <Typography.Text strong>
                  {t('warrantyRequest.create.appointmentDate')}
                </Typography.Text>
              ),
              name: 'promisedReturnDate',
              rules: [{ required: true, message: t('common.validateRequired') }],
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.date'),
              format: DATE_FORMAT,
            },
          }}
        />
      </Col>
      {status === TOTAL_ITEMS_STATUS.WAITING_FOR_DISTRIBUTE && (
        <Col span={12}>
          <FormInput
            {...{
              type: INPUT_TYPE.SELECT,
              formItemOptions: {
                label: (
                  <Typography.Text strong>{t('warrantyRequest.popup.solution')}</Typography.Text>
                ),
                name: 'solution',
                rules: [{ required: true, message: t('common.validateRequired') }],
              },
              inputOptions: {
                options: optionsSolution,
                disabled: true, //todo namtc07 check update serial
              },
            }}
          />
        </Col>
      )}
      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: (
                <Typography.Text strong>{t('warrantyRequest.create.errorDetail')}</Typography.Text>
              ),
              name: 'defectDescription',
              rules: [{ required: true, message: t('common.validateRequired') }],
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.errorDetail'),
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: (
                <Typography.Text strong>
                  {t('warrantyRequest.create.clientRequest')}
                </Typography.Text>
              ),
              name: 'customerRequest',
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.clientRequest'),
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: (
                <Typography.Text strong>
                  {t('warrantyRequest.create.accessoriesNote')}
                </Typography.Text>
              ),
              name: 'accessoryAttachment',
            },
            inputOptions: {
              placeholder: t('warrantyRequest.create.placeHolder.accessoriesNote'),
            },
          }}
        />
      </Col>
      <Col span={24}>
        <Form.Item
          labelCol={lableCol}
          name={'imageURLs'}
          getValueFromEvent={getFile}
          valuePropName={'fileList'}
          label={<Typography.Text strong>{t('warrantyRequest.popup.files')}</Typography.Text>}
          extra={t('warrantyRequest.create.noteAttack')}
        >
          <UploadFiles />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FormUpdateNotOwner;

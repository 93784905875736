import React, { useState } from 'react';
import { Form, Tooltip, Typography } from 'antd';
import { t } from 'i18next';
import { notify } from 'utils/helperFuncs';
import { DATE_FORMAT, SHIPPING_TYPE } from 'config/constants';
import { unset } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { useExportFile, useGetWarrantyRequestPopup } from 'hooks/warranty';
import {
  EXPORT_WARRANTY_TYPE,
  NOTE_TYPE,
  renderItemsStatus,
  solutionLabel,
  TOTAL_ITEMS_STATUS,
} from 'features/WarrantyRequest/constant';
import SerialLink from 'components/CustomRedirectLink/SerialLink';
import { truncateAddDots } from 'features/WarrantyRequest/helperFuncs';
import { warrantyRequestTypeLabel } from 'features/Warranty/constant';
import { formatDateTime } from 'utils/dateTime';
import { RenderRemainingDeliverTime } from 'features/Warranty/util';
import printJS from 'print-js';

function usePopupShippingType({ confirmReturn }) {
  const [form] = Form.useForm();
  const { id } = useParams();

  const [selectedRows, setSelectedRows] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [isFilterSelected, setIsFilterSelected] = useState(false);

  const { handleExportFile, loading: loadingPrint } = useExportFile();

  const {
    data: dataPopup,
    loading: loadingData,
    refetch,
    totalItemStatus,
  } = useGetWarrantyRequestPopup({
    id: id || undefined,
    filters: {
      itemStatuses: TOTAL_ITEMS_STATUS.WAITING_FOR_RETURN,
    },
    sort: [],
  });

  const all = {
    serial: {
      title: <Typography.Text strong>{t('warrantyRequest.detail.table.serial')}</Typography.Text>,
      fixed: 'left',
      width: 150,
      align: 'center',
      render: (_, record) => <SerialLink serial={record} />,
    },
    parameter: {
      title: (
        <Typography.Text strong>{t('warrantyRequest.detail.table.parameter')}</Typography.Text>
      ),
      dataIndex: 'parameter',
      fixed: 'left',
      width: 200,
      align: 'center',
      render: (_, record) => {
        const productName =
          record?.product?.name || record?.productName || record?.warrantyNote?.productName;
        return (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography.Text style={{ textAlign: 'start', margin: 0 }}>
              <Link to={`/product/detail/${record?.productID}`}>{record?.product?.code}</Link>
            </Typography.Text>
            <Typography.Text style={{ textAlign: 'start', margin: 0 }} strong>
              <Tooltip title={productName}>
                <span>{truncateAddDots(productName)}</span>
              </Tooltip>
            </Typography.Text>
          </div>
        );
      },
    },

    errorDetail: {
      title: (
        <Typography.Text strong>{t('warrantyRequest.detail.table.errorDetail')}</Typography.Text>
      ),
      dataIndex: 'defectDescription',
      width: 250,
      align: 'center',
      render: (_, record) => (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography.Text style={{ textAlign: 'start', margin: 0 }}>
            <Tooltip title={record?.defectDescription || '--'}>
              <span>{truncateAddDots(record?.defectDescription)}</span>
            </Tooltip>
          </Typography.Text>
        </div>
      ),
    },
    solution: {
      title: <Typography.Text strong>{t('warrantyRequest.detail.table.wResolve')}</Typography.Text>,
      dataIndex: 'solution', //Hướng xử lý
      width: 250,
      render: (_, record) => {
        return <Typography.Text>{solutionLabel?.[record?.solution]}</Typography.Text>;
      },
    },

    warrantyRequest: {
      title: (
        <Typography.Text strong>{t('warrantyRequest.detail.table.requestType')}</Typography.Text>
      ),
      dataIndex: 'warrantyRequestType',
      width: 150,
      align: 'center',
      render: (_, record) => warrantyRequestTypeLabel?.[record?.warrantyRequestType],
    },
    promisedReturnDate: {
      title: (
        <Typography.Text strong>{t('warrantyRequest.detail.table.dateReCustomer')}</Typography.Text>
      ),
      dataIndex: 'promisedReturnDate',
      width: 200,
      align: 'center',
      render: (_, record) => {
        return (
          <div>
            <Typography.Text>
              {formatDateTime(record?.promisedReturnDate, DATE_FORMAT)}
            </Typography.Text>
            <br />
            <Typography.Text strong style={{ color: '#00AB78' }}>
              {/* {`(${renderRemainingDeliverTime()})`} */}
            </Typography.Text>
          </div>
        );
      },
    },
    remainingWarrantyDays: {
      title: (
        <Typography.Text strong>{t('warrantyRequest.detail.table.remainingDate')}</Typography.Text>
      ),
      width: 200,
      align: 'center',
      dataIndex: 'resultReceivedDate',
      render: (_, record) => (
        <div>
          <Typography.Text>
            {formatDateTime(record?.warrantyNote?.warrantyDate, DATE_FORMAT)}
          </Typography.Text>
          <br />
          <RenderRemainingDeliverTime time={record?.warrantyNote?.remainingWarrantyDays} />
        </div>
      ),
    },

    statusWaitingForCheck: {
      title: <Typography.Text strong>{t('warrantyRequest.detail.table.status')}</Typography.Text>,
      dataIndex: 'status',
      fixed: 'right',
      width: 120,
      align: 'center',
      render: (_, record) => renderItemsStatus(record?.status),
    },
  };

  const {
    serial,
    parameter,
    errorDetail,
    solution,
    warrantyRequest,
    promisedReturnDate,
    remainingWarrantyDays,
    statusWaitingForCheck,
  } = all;

  const columnsPopup = [
    serial,
    parameter,
    errorDetail,
    solution,
    warrantyRequest,
    promisedReturnDate,
    remainingWarrantyDays,
    statusWaitingForCheck,
  ];

  const filterItemsTypeOptions = [
    { label: t('common.all'), value: false },
    {
      label: `${t('cart.selected')} (${selectedRowKeys?.length ? selectedRowKeys?.length : 0})`,
      value: true,
    },
  ];
  const [shippingType, setShippingType] = useState(SHIPPING_TYPE.URBAN_COD);
  const handleChange = (e) => {
    setIsFilterSelected && setIsFilterSelected(e.target.value);
  };

  const initialValues = {
    shippingType: shippingType,
  };

  const handleOk = async ({ isPrint }) => {
    const values = await form.getFieldsValue();
    unset(values, 'shippingType');
    const params = {
      warrantyRequestItemIDs: selectedRowKeys,
      shippingType: shippingType,
      shippingConfig: values,
    };

    form
      .validateFields()
      .then(() => {
        if (selectedRowKeys?.length) {
          confirmReturn(params).then(() => {
            form.resetFields();
            refetch();
            setSelectedRowKeys([]);
          });
        } else {
          notify.warning({ message: t('serial.selectSerialPls') });
        }
      })
      .catch((info) => {
        console.info('Validate Failed:', info);
      });

    isPrint &&
      handleExportFile({
        warrantyRequestID: id,
        warrantyRequestItemIDs: selectedRowKeys,
        noteType: NOTE_TYPE.RETURN_NOTE,
        fileType: EXPORT_WARRANTY_TYPE.PDF,
      })
        .then((response) => {
          const fileUrl = response.data.warrantyRequest.exportFile.url;
          printJS({
            printable: fileUrl,
            type: EXPORT_WARRANTY_TYPE.PDF.toLowerCase(),
            showModal: true,
            modalMessage: t('common.loadingData'),
          });
          form.resetFields();
          // setIsModalOpen(false);
        })
        .catch(() => {
          notify.error({ message: t('common.error') });
        });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRowKeys,
  };

  // useEffect(() => {
  //   // form.setFieldsValue({ ...initialValues });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return {
    form,
    initialValues,
    handleOk,
    filterItemsTypeOptions,
    handleChange,
    shippingType,
    setShippingType,
    columnsPopup,
    dataPopup,
    loadingData,
    refetch,
    totalItemStatus,
    selectedProduct: { isFilterSelected, selectedRows, selectedRowKeys },
    rowSelection,
    loadingPrint,
  };
}

export default usePopupShippingType;

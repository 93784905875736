import { gql } from '@apollo/client';

export const GET_INFO_PLATFORM = gql`
  query GetInfoPlatform {
    platform {
      get {
        id
        name
        code
        logoURL
        bannerURL
      }
    }
  }
`;

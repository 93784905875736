import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import Card from 'features/Cart/components/Card';
import { t } from 'i18next';
import ProductLink from 'components/CustomRedirectLink/ProductLink';
import styles from './index.module.scss';
import { RenderRemainingDeliverTime, warrantyStatus } from 'features/Warranty/util';
import OrderLink from 'components/CustomRedirectLink/OrderLink';
import SellerLink from 'components/CustomRedirectLink/SellerLink';
import { formatDateTime } from 'utils/dateTime';
import WarrantyDetailLink from 'components/CustomRedirectLink/WarrantyDetailLink';
import { DATE_FORMAT } from 'config/constants';
import { convertUnit } from 'hooks/warranty/converter';

const CustomCol = ({ label, value }) => {
  return (
    <>
      <Col span={12} xl={7}>
        <Typography.Text strong>{label}</Typography.Text>
      </Col>
      <Col span={12} xl={17}>
        <Typography.Text>{value}</Typography.Text>
      </Col>
    </>
  );
};

function SerialInfo({ data }) {
  const warrantyNote = data?.warrantyNote;
  const categoryNameFromWarrantyNotes = warrantyNote?.category?.name;
  const productName = warrantyNote?.productName ?? data?.product?.name ?? data?.productName;
  const categoryName = categoryNameFromWarrantyNotes ?? data?.category?.name;

  return (
    <Card>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <Typography.Title level={5} strong style={{ color: '#052987' }}>
            {t('serial.serialInfo')}
          </Typography.Title>
        </Col>
        <Col xl={12} span={24}>
          <Row gutter={[16, 8]} style={{ borderRight: '1px dashed #9CADC4' }}>
            <Col span={7}>
              <Typography.Text strong>{t('warranty.warrantyDetail.serial/imei')}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>{data?.serial}</Typography.Text>
            </Col>

            <Col span={7}>
              <Typography.Text strong>{t('warranty.warrantyDetail.productCode')}</Typography.Text>
            </Col>
            <Col span={17}>
              <ProductLink product={warrantyNote ? warrantyNote : data} />
            </Col>

            <Col span={7}>
              <Typography.Text strong>{t('warranty.warrantyDetail.productName')}</Typography.Text>
            </Col>
            <Col span={17}>
              {/* nullish coalescing */}
              <Typography.Text>{productName}</Typography.Text>
              {/*  */}
            </Col>

            <Col span={7}>
              <Typography.Text strong>{t('warranty.warrantyDetail.attributeLV3')}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>{categoryName || '--'}</Typography.Text>
            </Col>

            <CustomCol
              label={t('serial.warrantyNote')}
              value={
                <Space>
                  <div>
                    <span className={styles['color-content']}> # </span>
                    <WarrantyDetailLink warranty={warrantyNote} />
                  </div>
                  <div>
                    <Typography.Text style={warrantyStatus[warrantyNote?.status]}>
                      {warrantyStatus[warrantyNote?.status]?.text}
                    </Typography.Text>
                  </div>
                </Space>
              }
            />
          </Row>
        </Col>
        <Col xl={12} span={24}>
          <Row gutter={[16, 8]}>
            <Col span={7}>
              <Typography.Text strong>{t('warranty.warrantyDetail.orderCode')}</Typography.Text>
            </Col>
            <Col span={17}>
              <OrderLink order={warrantyNote} />
            </Col>

            <Col span={7}>
              <Typography.Text strong>{t('serial.orderClient')}</Typography.Text>
            </Col>
            <Col span={17}>
              {warrantyNote?.seller === null ? (
                <Typography.Link className={styles['color-content']}>
                  {warrantyNote?.customerName }
                </Typography.Link>
              ) : (
                <SellerLink seller={warrantyNote} />
              )}
            </Col>

            <Col span={7}>
              <Typography.Text strong>{t('warranty.warrantyDetail.soldDate')}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>
                {formatDateTime(warrantyNote?.soldDate, DATE_FORMAT)}
              </Typography.Text>
            </Col>

            <Col span={7}>
              <Typography.Text strong>{t('warranty.warrantyDetail.totalWarranty')}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>
                {warrantyNote?.warrantyOnSale || '--'} {convertUnit(warrantyNote?.warrantyDateUnit)}
              </Typography.Text>
            </Col>

            <Col span={7}>
              <Typography.Text strong>{t('warranty.warrantyDetail.dateWarranty')}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>
                {formatDateTime(warrantyNote?.warrantyEndDate, DATE_FORMAT)}
              </Typography.Text>
              <br />
              <RenderRemainingDeliverTime time={warrantyNote?.remainingWarrantyDays} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default SerialInfo;

import React from 'react';
import { Input } from 'antd';

import { Icon, FontAwesomeIcon } from 'assets/icons';
import { useToolbar } from './useToolbar';
import FilterDate from 'components/CustomFilterMenu/FilterDate';

import './index.scss';
import { t } from 'i18next';

function Toolbar({
  params,
  onChangeParams,
  children,
  isSellerIDs = true,
  createdByOptions,
  handleSearch,
}) {
  const { handleChangeDate, renderDateTag, renderTags } = useToolbar({
    params,
    onChangeParams,
    isSellerIDs,
    createdByOptions,
  });

  return (
    <>
      <div className="filter-history-container">
        <div className="search-input">
          {<FilterDate onChangeDate={handleChangeDate} title={t('campaign.ranking.createdAt')} />}
          {
            <Input
              className="custom-input input-seller"
              prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
              placeholder={t('campaign.detail.orderCodeSearch')}
              allowClear={true}
              onChange={(value) => handleSearch(value)}
            />
          }
        </div>
        {children}
      </div>
      <div className="tags-list">
        {renderDateTag()}
        {renderTags}
      </div>
    </>
  );
}

export default Toolbar;

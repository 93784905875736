import { CUSTOM_PAYMENT_METHOD, ORDER_TYPE, PAYMENT_METHOD } from 'features/Cart/constants';
import { t } from 'i18next';

const usePaymentMethods = ({ data, handleUpdate }) => {
  const { paymentMethod, seller, total, orderType } = data || {};
  const customerPaymentMethodOptions = [
    { label: t('cart.customPaymentMethods.cod'), value: CUSTOM_PAYMENT_METHOD.COD },
    { label: t('cart.customPaymentMethods.cash'), value: CUSTOM_PAYMENT_METHOD.CASH },
  ];

  const isPaymentMethodByCash = paymentMethod === PAYMENT_METHOD.CASH;

  const isAlertPaymentByCash = total > seller?.currentAvailable;

  const isAlertPaymentByDebt = total > seller?.availabilityLimit;

  const isSeller = orderType === ORDER_TYPE.SELLER;

  const onChange = (value) => handleUpdate(value);

  return {
    customerPaymentMethodOptions,
    isPaymentMethodByCash,
    isAlertPaymentByCash,
    isAlertPaymentByDebt,
    isSeller,
    onChange,
  };
};

export default usePaymentMethods;

import { Col, Row } from 'antd';
import { TabsMenu } from 'components/CustomTabs';
import PageHeader from 'components/PageHeader';
import { t } from 'i18next';
import React from 'react';
import { getQuery } from 'utils/helperFuncs';
import ButtonGroup from './ButtonGroup';

import useList from './useList';

const List = () => {
  const {
    tabsMain,
    handleChangeTab,
    activeKeyMain,
    activeKeyStatus,
    selectedRowKeys,
    selectedRows,
    setSelectedRowKeys,
  } = useList();
  const GET_QUERY = getQuery();
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <PageHeader
          pageTitle={t('warranty.warrantyRequestList.title')}
          routes={[
            { path: '/warranty-request', name: t('common.warranty') },
            { path: '/warranty-request', name: t('warranty.warrantyRequestList.title') },
          ]}
        />
      </Col>
      <Col span={24}>
        <TabsMenu
          tabs={tabsMain}
          tabBarExtraContent={
            <ButtonGroup
              activeKeyMain={activeKeyMain}
              activeKeyStatus={activeKeyStatus}
              itemIDs={selectedRowKeys}
              itemID={selectedRowKeys?.[0]}
              selectedRows={selectedRows}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          }
          tabBarStyle={{ background: '#ffffff', padding: '0 10px', borderRadius: '8px' }}
          onChange={handleChangeTab}
          activeKey={activeKeyMain}
          defaultActiveKey={GET_QUERY.activeKey}
          destroyInactiveTabPane={true}
        />
      </Col>
    </Row>
  );
};

export default List;

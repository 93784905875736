/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Spin, Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { INPUT_TYPE } from "config/constants";
import { useEffect, useState } from "react";

import { useGetProducts } from "hooks/product/product";
import { t } from "i18next";
import styles from "./index.module.scss";

function SelectProductTransferSerial({ handleSetFieldTransferSerial }) {
  const handleChange = (value, option) => {
    handleSetFieldTransferSerial && handleSetFieldTransferSerial({ ...option?.product });
  };

  const [params, setParams] = useState();
  const { data: productData, loading } = useGetProducts(params);
  useEffect(() => {
    setParams({
      filters: {
        query: "",
        isActive: true,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    });
  }, []);

  const formatDataProduct = productData?.map((item) => ({
    productCode: item?.code,
    productID: item?.id,
    productName: item?.name,
    productUom: item?.uom,
    stocks: item?.stocks,
  }));
  const handleSearchProduct = (value) => {
    setParams({
      filters: {
        query: value?.trim(),
        isActive: true,
      },
      ...params?.pagination,
    });
  };

  const options = formatDataProduct?.map((item) => ({
    value: item.productID,
    label: (
      <Row gutter={24}>
        <Col span={6}>
          <Typography.Link ellipsis strong style={{ padding: "5px 18px", fontSize: "13px" }}>
            {item?.productCode}
          </Typography.Link>
        </Col>
        <Col span={14}>
          <Typography.Text ellipsis style={{ padding: "5px 18px", fontSize: "13px" }}>
            {item?.productName}
          </Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text ellipsis style={{ padding: "5px 18px", fontSize: "13px" }}>
            {(item?.stocks && item?.stocks[0].quantity) || 0}
          </Typography.Text>
        </Col>
      </Row>
    ),
    product: item,
  }));
  return (
    <SelectLoadMore
      type={INPUT_TYPE.SELECT_LOAD_MORE}
      {...{
        inputOptions: {
          placeholder: t("warehouse.salesReturn.searchCodeProduct"),
          options: options,
          value: null,
          allowClear: false,
          dropdownMatchSelectWidth: 1000,
          dropdownRender: (originNode) => (
            <>
              <Row gutter={24} className={styles["custom-select"]}>
                <Col span={6}>
                  <Typography strong className={styles["header-select"]} style={{ padding: "8px 30px" }}>
                    {t("serialIE.productInfo.productCodeB")}
                  </Typography>
                </Col>
                <Col span={14}>
                  <Typography strong className={styles["header-select"]} style={{ padding: "8px 30px" }}>
                    {t("serialIE.productInfo.productNameB")}
                  </Typography>
                </Col>
                <Col span={4}>
                  <Typography strong className={styles["header-select"]} style={{ padding: "8px 0px" }}>
                    TỒN KHO
                  </Typography>
                </Col>
              </Row>
              <Spin spinning={loading}>{originNode}</Spin>
            </>
          ),
          dropdownStyle: { zIndex: "1000" },
        },
      }}
      onSearch={(event) => handleSearchProduct(event)}
      onChange={handleChange}
    />
  );
}

export default SelectProductTransferSerial;

import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

function WarrantyDetailLink({ warranty }) {
  if (warranty) {
    return (
      <Link to={`/warranty-detail/${warranty?.id}`}>
        <Typography.Link ellipsis={{ expandable: true, tooltip: true }} strong>
          {warranty?.code}
        </Typography.Link>
      </Link>
    );
  }

  return <Typography.Text>--</Typography.Text>;
}

export default WarrantyDetailLink;

import React from 'react';
import { Col, Form, Row } from 'antd';
import { FORM_LAYOUT, INPUT_TYPE, REGEX } from 'config/constants';
import { t } from 'i18next';
import FormInput from 'components/FormInput';
import { SelectLoadMore } from 'components/CustomSelect';
import useForm from './useForm';

function FormSellerGroup({ form, initialValues, hasIsActiveInput = false }) {
  const { loadMoreDataAccountantName, options, hasMoreData, onSearch } = useForm();

  return (
    <Form form={form} {...FORM_LAYOUT} initialValues={initialValues}>
      <Row gutter={16}>
        {hasIsActiveInput && (
          <Col span={24}>
            <FormInput
              type={INPUT_TYPE.SWITCH}
              formItemOptions={{
                name: 'isActive',
                label: t('customer.detail.groupStatus'),
                valuePropName: 'checked',
              }}
            />
          </Col>
        )}

        <Col span={8}>
          <FormInput
            formItemOptions={{
              name: 'code',
              label: <span className="sub-title">{t('customer.modal.customerCode')}</span>,
              rules: [
                {
                  required: true,
                  message: t('customer.modal.validate.customerCode'),
                },
                {
                  pattern: REGEX.CODE,
                  message: t('product.validateMessage.shortCode.wrongFormat'),
                },
              ],
            }}
            inputOptions={{
              placeholder: t('customer.modal.placeholder.customerCode'),
              maxLength: 50,
            }}
          />
        </Col>
        <Col span={8}>
          <FormInput
            formItemOptions={{
              name: 'name',
              label: <span className="sub-title">{t('customer.modal.customerName')}</span>,
              rules: [
                {
                  required: true,
                  message: t('customer.modal.validate.customerName'),
                },
                {
                  whitespace: true,
                  message: t('supplier.placeholderInput.whiteSpace'),
                },
              ],
            }}
            inputOptions={{
              placeholder: t('customer.modal.placeholder.customerName'),
              maxLength: 255,
            }}
          />
        </Col>
        <Col span={8}>
          <SelectLoadMore
            formItemOptions={{
              name: 'managerID',
              label: <span className="sub-title">{t('customer.modal.accountant')}</span>,
              rules: [
                {
                  required: true,
                  message: t('customer.modal.validate.accountant'),
                },
              ],
            }}
            inputOptions={{
              placeholder: t('customer.modal.placeholder.accountant'),
              options: options,
            }}
            onLoadMore={loadMoreDataAccountantName}
            hasMoreData={hasMoreData}
            onSearch={onSearch}
          />
        </Col>
        <Col span={24}>
          <FormInput
            type={INPUT_TYPE.TEXT_AREA}
            formItemOptions={{
              name: 'description',
              label: <span className="sub-title">{t('customer.modal.description')}</span>,
              rules: [
                {
                  whitespace: true,
                  message: t('supplier.placeholderInput.whiteSpace'),
                },
              ],
            }}
            inputOptions={{
              placeholder: t('customer.modal.placeholder.description'),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default FormSellerGroup;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Input, Typography } from 'antd';
import { Icon } from 'assets/icons';
import FilterDate from 'components/CustomFilterMenu/FilterDate';
import { t } from 'i18next';
import React from 'react';
import { formatCurrency } from 'utils/helperFuncs';
import Card from '../Card';

const Toolbar = () => {
  const cards = [
    {
      title: <Typography.Text strong>{t('accountant.totalCollected')}</Typography.Text>,
      total: (
        <Typography.Text style={{ fontSize: '24px' }}>{formatCurrency(15000000)}</Typography.Text>
      ),
      backgroundColor: 'rgba(246, 196, 148, 1)',
    },
    {
      title: <Typography.Text strong>{t('accountant.totalRealCollected')}</Typography.Text>,
      total: (
        <Typography.Text style={{ fontSize: '24px' }}>{formatCurrency(15000000)}</Typography.Text>
      ),
      backgroundColor: 'rgba(0, 100, 240, 0.47)',
    },
    {
      title: <Typography.Text strong>{t('accountant.different')}</Typography.Text>,
      total: (
        <Typography.Text style={{ fontSize: '24px' }}>{formatCurrency(15000000)}</Typography.Text>
      ),
      backgroundColor: 'rgba(85, 174, 88, 0.51)',
    },
  ];

  return (
    <>
      {cards?.map((item, index) => (
        <Col span={8} key={index}>
          <Card {...item} />
        </Col>
      ))}
      <Col xxl={3} xl={4}>
        <FilterDate title={t('accountant.createdAt')} />
      </Col>
      <Col xxl={9} xl={14}>
        <Input
          prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
          placeholder={t('accountant.placeholderSearchCollectManagement')}
        />
      </Col>
    </>
  );
};

export default Toolbar;

import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useEffect } from 'react';

const useReportChart = (elId, chartData, fetchChartToken) => {
  useEffect(() => {
    if (!!chartData?.dashboardID) {
      embedDashboard({
        id: chartData?.dashboardID, // given by the Superset embedding UI
        supersetDomain: chartData?.domainUrl,
        mountPoint: document.getElementById(elId), // any html element that can contain an iframe
        fetchGuestToken: fetchChartToken,
        dashboardUiConfig: {
          // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
          hideTitle: true,
          hideTab: true,
          hideChartControls: true,
        },
      });
    }
  }, [chartData]);
};

export default useReportChart;

import { Card, Col, Form, Row, Typography } from 'antd';
import PageHeader from 'components/PageHeader';
import { t } from 'i18next';
import React from 'react';
import './index.scss';
import CustomButton from 'components/CustomButton';
import FormInput from 'components/FormInput';
import { INPUT_TYPE } from 'config/constants';
import UploadBannerButton from './components/UploadBannerButton';
import useList from './useList';
import UploadLogo from 'components/UploadLogo';
import CustomModal from 'components/CustomModal';
import { getFile } from 'utils/helperFuncs';

function CreateProductBrand() {
  const {
    form,
    initialValues,
    handleValuesChange,
    handleFinish,
    handleSubmitCreate,
    onCancelCreate,
    handleSubmitCreateSave,
    loadingCreateSave,
    loadingCreate,
  } = useList();
  return (
    <div className="form-create-product-brand">
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <PageHeader
            pageTitle={t('productBrand.createProductBrand')}
            routes={[
              { path: '/', name: t('productBrand.product') },
              { path: '/product-brand', name: t('productBrand.listBrand') },
              { path: '#', name: t('productBrand.createProductBrand') },
            ]}
          />
        </Col>
        <Col span={24}>
          <Form
            layout="horizontal"
            initialValues={initialValues}
            form={form}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
          >
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Card>
                  <Row gutter={[16, 100]}>
                    <Col span={12} style={{ paddingRight: '30px' }}>
                      <Form.Item
                        labelCol={{ span: 24, offset: 0 }}
                        name={'logo'}
                        getValueFromEvent={getFile}
                        valuePropName={'fileList'}
                        label={
                          <Typography.Text strong>{t('productBrand.logoBrand')}</Typography.Text>
                        }
                        extra={t('common.noteAttack')}
                      >
                        <UploadLogo maxCount={1} form={form} />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 24, offset: 0 }}
                        name={'catalogs'}
                        getValueFromEvent={getFile}
                        valuePropName={'fileList'}
                        label={
                          <Typography.Text strong>{t('productBrand.catalog')}</Typography.Text>
                        }
                        extra={t('warrantyRequest.create.noteAttack')}
                      >
                        <UploadBannerButton />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingLeft: '30px' }}>
                      <FormInput
                        type={INPUT_TYPE.INPUT_TYPE}
                        formItemOptions={{
                          label: <b>{t('productBrand.nameBrand')}</b>,
                          labelCol: { span: 24, offset: 0 },
                          name: 'name',
                          rules: [
                            {
                              required: true,
                              message: t('productBrand.enterNameBrand'),
                            },
                            {
                              whitespace: true,
                              message: t('supplier.placeholderInput.whiteSpace'),
                            },
                          ],
                        }}
                        inputOptions={{
                          maxLength: 255,
                          placeholder: t('productBrand.enterNameBrand'),
                        }}
                      />
                      <FormInput
                        type={INPUT_TYPE.TEXT_AREA}
                        formItemOptions={{
                          label: <b>{t('productBrand.description')}</b>,
                          labelCol: { span: 24, offset: 0 },
                          name: 'description',
                        }}
                        inputOptions={{
                          rows: 5,
                          placeholder: t('productBrand.enterDescription'),
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card className="form-footer-product-brand">
                  <CustomButton />
                  <CustomModal
                    {...{
                      customComponent: <CustomButton title={t('productBrand.cancel')} />,
                      footer: false,
                      message: t('productBrand.messageCancel'),
                      centered: true,
                      onOke: onCancelCreate,
                    }}
                  />
                  <CustomButton
                    title={t('productBrand.create')}
                    htmlType={'submit'}
                    onClick={handleSubmitCreate}
                    loading={loadingCreate}
                  />
                  <CustomButton
                    type={'primary'}
                    title={t('productBrand.saveAndCreate')}
                    htmlType={'submit'}
                    onClick={handleSubmitCreateSave}
                    loading={loadingCreateSave}
                  />
                </Card>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
export default CreateProductBrand;

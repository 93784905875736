import React from 'react';
import useFiltersTag from './useFiltersTag';

export const FiltersTag = ({
  params,
  handleRemoveFilterTag,
  dataBranch,
  dataRoles,
  statusOptions,
}) => {
  const { renderTagsBranchIDs, renderTagsRoleIDs, renderTagsStatus } = useFiltersTag({
    params,
    handleRemoveFilterTag,
    dataBranch,
    dataRoles,
    statusOptions,
  });
  return (
    <div style={{ marginBottom: '16px' }}>
      {params?.filters?.status?.length > 0 ? renderTagsStatus : ''}
      {params?.filters?.roleIDs?.length > 0 ? renderTagsRoleIDs : ''}
      {params?.filters?.branchIDs?.length > 0 ? renderTagsBranchIDs : ''}
    </div>
  );
};

import { Button, Col, Row } from "antd";
import { TabsMenu } from "components/CustomTabs";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import { getQuery } from "utils/helperFuncs";
import useList from "./useList";

function ListExportSerial() {
  const { tabsMain, handleChangeTab, activeKeyMain } = useList();
  const GET_QUERY = getQuery();
  const history = useHistory();
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <PageHeader
          pageTitle={t("serialIE.listExportSerial")}
          routes={[
            { path: "/import-serial", name: t("serialIE.serial") },
            { path: "/", name: t("serialIE.exportBySerial") },
          ]}
        />
      </Col>
      <Col span={24}>
        <TabsMenu
          tabs={tabsMain}
          tabBarExtraContent={
            <Button
              onClick={() => {
                history.push({
                  pathname: "/export-serial/create",
                });
              }}
              type="primary"
            >
              {t("serialIE.create")}
            </Button>
          }
          tabBarStyle={{ background: "#ffffff", padding: "0 10px", borderRadius: "8px" }}
          onChange={handleChangeTab}
          activeKey={activeKeyMain}
          defaultActiveKey={GET_QUERY.activeKey}
          destroyInactiveTabPane={true}
        />
      </Col>
    </Row>
  );
}

export default ListExportSerial;

import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_DETAIL_SERIAL,
  GET_DETAIL_SERIAL_EXPORT,
  GET_DETAIL_SERIAL_IMPORT,
  GET_EXPORT_NOTES_BY_SERIAL,
  GET_EXPORT_NOTES_SERIAL,
  GET_LIST_IMPORT_SERIAL,
  GET_LIST_SERIAL,
  GET_SERIAL,
} from "graphql/serial/query";

export const useGetListImportSerial = (params) => {
  const { data, error, loading, refetch } = useQuery(GET_LIST_IMPORT_SERIAL, {
    variables: params,
    skip: params?.branchesID?.length === 0 || !params?.branchesID,
  });

  return {
    data: data?.serialImportNotes?.pagination?.SerialImportNotes,
    paginationData: data?.serialImportNotes?.pagination?.paginationData,
    error,
    loading,
    refetch,
  };
};

//handle get list serial from graphql
export const useGetListSerial = (params) => {
  const { data, error, loading, refetch } = useQuery(GET_LIST_SERIAL, {
    variables: params,
    skip: params?.filters?.branchIDs?.length === 0 || !params?.filters?.branchIDs,
  });
  return {
    data: data?.serialImportNotes?.paginationSerial?.importSerialItems,
    paginationData: data?.serialImportNotes?.paginationSerial?.paginationData,
    error,
    loading,
    refetch,
  };
};

export const useGetDetailSerialImport = (params) => {
  const { data, loading, error, refetch } = useQuery(GET_DETAIL_SERIAL_IMPORT, {
    variables: params,
  });

  return {
    data: data?.serialImportNotes?.get,
    loading,
    error,
    refetch,
  };
};

export const useGetExportNotesSerial = (params) => {
  const { data, error, loading, refetch } = useQuery(GET_EXPORT_NOTES_SERIAL, {
    variables: params,
    skip: params?.filters?.branchesID?.length === 0 || !params?.filters?.branchesID,
  });
  return {
    data: data?.serialExportNotes?.pagination?.SerialExportNotes,
    paginationData: data?.serialExportNotes?.pagination?.paginationData,
    error,
    loading,
    refetch,
  };
};

export const useGetExportNotesBySerial = (params) => {
  const { data, error, loading, refetch } = useQuery(GET_EXPORT_NOTES_BY_SERIAL, {
    variables: params,
    skip: params?.filters?.branchesID?.length === 0 || !params?.filters?.branchesID,
  });
  return {
    data: data?.serialExportNotes?.paginationSerial?.exportSerials,
    paginationData: data?.serialExportNotes?.paginationSerial?.paginationData,
    error,
    loading,
    refetch,
  };
};

export const useGetSerial = (params) => {
  const { data, error, loading, refetch } = useQuery(GET_SERIAL, {
    variables: params,
    fetchPolicy: params.fetchPolicy ?? "cache-first",
    skip: params.skip ?? params?.filters?.branchesID?.length === 0 ?? !params?.filters?.branchesID,
  });

  return {
    data: data?.serial?.pagination?.Serials,
    paginationData: data?.serial?.pagination?.paginationData,
    error,
    loading,
    refetch,
  };
};

export const useGetSerialExistWarehouse = (params) => {
  const { data, error, loading, refetch } = useQuery(GET_SERIAL, {
    variables: params,
  });
  return {
    data: data?.serial?.pagination?.Serials,
    paginationData: data?.serial?.pagination?.paginationData,
    error,
    loading,
    refetch,
  };
};

export const useGetDetailSerialExport = (params) => {
  const { data, loading, error, refetch } = useQuery(GET_DETAIL_SERIAL_EXPORT, {
    variables: params,
  });

  return {
    data: data?.serialExportNotes?.get,
    loading,
    error,
    refetch,
  };
};

export const useGetSerialExportNotesWarranty = () => {
  const [data, { loading, refetch }] = useLazyQuery(GET_SERIAL);
  const handleGetSerialExportNotesWarranty = async (params) => {
    return await data({
      variables: params,
    });
  };
  return {
    handleGetSerialExportNotesWarranty,
    loading,
    refetch,
  };
};

export const useGetSerialDetail = (id) => {
  const [getSerialDetail, { loading, data }] = useLazyQuery(GET_DETAIL_SERIAL, {
    variables: id,
  });

  return {
    data: data?.serial?.get,
    loading,
    getSerialDetail,
  };
};

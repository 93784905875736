import React from 'react';
import { TabsMenu } from 'components/CustomTabs';
import useTabsMenu from './useTabsMenu';

function Tabs({ data, warrantyNote }) {
  const { tabs, defaultActiveKey, handleChange } = useTabsMenu({ data, warrantyNote });

  return (
    <div className="warranty-tabs">
      <TabsMenu tabs={tabs} activeKey={defaultActiveKey} onChange={handleChange} />
    </div>
  );
}

export default Tabs;

import React from 'react';
import { Col, Form, Row, Typography } from 'antd';
import FormInput from 'components/FormInput';
import { INPUT_TYPE } from 'config/constants';
import UploadBannerButton from 'features/Products/ProductBrand/CreateProductBrand/components/UploadBannerButton';
import { t } from 'i18next';
import { getFile } from 'utils/helperFuncs';
import UploadFiles from 'components/UploadFiles';

function AddInfo() {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24} style={{ marginBottom: '-24px' }}>
        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          name={'fileURLs'}
          getValueFromEvent={getFile}
          valuePropName={'fileList'}
          label={<Typography.Text strong>{t('warrantyRequest.popup.files')}</Typography.Text>}
          extra={t('warrantyRequest.create.noteAttack')}
        >
          <UploadFiles />
        </Form.Item>
      </Col>
      <Col span={24}>
        <FormInput
          type={INPUT_TYPE.TEXT_AREA}
          formItemOptions={{
            label: <b>{t('common.description')}</b>,
            labelCol: { span: 24, offset: 0 },
            name: 'note',
          }}
          inputOptions={{
            rows: 5,
            placeholder: t('warrantyRequest.create.note'),
          }}
        />
      </Col>
    </Row>
  );
}

export default AddInfo;

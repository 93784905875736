import Detail from 'features/SellerGroup/CustomerDetail'
import React from 'react'

function SellerGroupDetail() {
  return (
    <Detail/>
  )
}

export default SellerGroupDetail

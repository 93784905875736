import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, Select } from "antd";
import { CustomPaginationPrevNext } from "components/CustomPaginationPrevNext";
import CustomTable from "components/CustomTable";
import { VALUE_TAGS_IMPORT_NOTE_TYPE } from "features/Serial/constant";
import { t } from "i18next";
import { FiltersTag } from "../../../components/FiltersTag";
import "./index.scss";
import useList from "./useList";

function ListBySerial() {
  const {
    onChangeBranchIDs,
    valueFormSelectCity,
    columns,
    loading,
    paginationData,
    data,
    onSearchBySerial,
    handleChangeTable,
    branchIDs,
    handleRemoveFilterTag,
    staffs,
    paramsListBySerial,
    warehouseOptions,
    handlePrevNextPage,
  } = useList();
  return (
    <div className="list-by-serial">
      <Form
        name="basic"
        autoComplete="off"
        style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <Col span="10">
          <Form.Item>
            <Input
              placeholder={t("serialIE.searchBySerialNumberIMEIOrBySerialNumber")}
              onChange={onSearchBySerial}
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span="3">
          <Form.Item>
            <Select options={valueFormSelectCity} onChange={(e) => onChangeBranchIDs(e)} value={branchIDs} />
          </Form.Item>
        </Col>
      </Form>
      <div style={{ display: "flex", gap: "10px" }}>
        <FiltersTag
          staffOptions={staffs}
          params={paramsListBySerial}
          importNotTypeOptions={VALUE_TAGS_IMPORT_NOTE_TYPE}
          handleRemoveFilterTag={handleRemoveFilterTag}
          warehouseOptions={warehouseOptions}
        />
      </div>
      <CustomTable
        loading={loading}
        columns={columns}
        dataSource={data}
        scroll={{ x: 2000 }}
        rowKey={(record) => record?.id}
        onChange={handleChangeTable}
      />
      {paginationData && (
        <CustomPaginationPrevNext handlePrevNextPage={handlePrevNextPage} paginationData={paginationData} />
      )}
    </div>
  );
}

export default ListBySerial;

export const CHARGE_LIMIT = 5000000000;

export const WALLET_TYPE = {
  WAREHOUSE_ACCOUNTANT: 'WAREHOUSE_ACCOUNTANT',
  BANK_ACCOUNT: 'BANK_ACCOUNT',
};

export const TARGET_WALLET = {
  TO_DEBT: 'TO_DEBT',
  TO_VIRTUAL_WALLET: 'TO_VIRTUAL_WALLET',
  TO_WAREHOUSE_ACCOUNTANT: 'TO_WAREHOUSE_ACCOUNTANT',
  TO_BANK_ACCOUNT: 'TO_BANK_ACCOUNT',
};

export const SELLER_PAYMENT_METHOD = {
  WALLET: 'WALLET',
  DEBT: 'DEBT',
};

export const ACTIVE_WALLET = {
  SELLER_PERSONAL: 'SELLER_PERSONAL',
  SELLER_COMPANY: 'SELLER_COMPANY',
  SELLER_DEBT: 'SELLER_DEBT',
  WAREHOUSE_ACCOUNTANT: 'WAREHOUSE_ACCOUNTANT',
  BANK_ACCOUNT: 'BANK_ACCOUNT',
};

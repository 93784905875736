import React from 'react';
import { Col, Row, Typography } from 'antd';
import { DATE_FORMAT, INPUT_TYPE, SHIPPING_TYPE } from 'config/constants';
import FormInput from 'components/FormInput';
import { t } from 'i18next';
import { useGetInfoPlatform } from 'hooks/info-platform';

function PaymentInfo() {
  const { platformName } = useGetInfoPlatform();

  const optionReceiveMethod = [
    {
      label: t('warrantyRequest.detail.btnGroup.urbanCOD', { platformName }),
      value: SHIPPING_TYPE.URBAN_COD,
    },
    {
      label: t('warrantyRequest.detail.btnGroup.bus'),
      value: SHIPPING_TYPE.BUS,
    },
    {
      label: t('warrantyRequest.detail.btnGroup.deliver3Party'),
      value: SHIPPING_TYPE.DELIVER_3PARTY,
    },
    {
      label: t('warrantyRequest.detail.btnGroup.pickUpCounter'),
      value: SHIPPING_TYPE.PICK_UP_COUNTER,
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Typography.Text className="sub-title">
              {t('warehouse.salesReturn.processingTime')}
            </Typography.Text>
          </Col>
          <Col span={24} className="form-tags">
            <FormInput
              type={INPUT_TYPE.DATE_PICKER}
              {...{
                formItemOptions: {
                  name: 'processDeadline',
                },
                inputOptions: {
                  placeholder: 'DD/MM/YYYY',
                  optionFilterProp: 'label',
                  placement: 'topLeft',
                  style: {
                    width: '100%',
                  },
                  format: DATE_FORMAT,
                },
              }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Typography.Text className="sub-title">
              {t('warehouse.salesReturn.receptionForm')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <FormInput
              type={INPUT_TYPE.SELECT}
              {...{
                formItemOptions: {
                  name: 'receiveMethod',
                },
                inputOptions: {
                  placeholder: t('warehouse.salesReturn.chooseRecept'),
                  allowClear: true,
                  showSearch: true,
                  filterOption: false,
                  optionFilterProp: 'label',
                  placement: 'topLeft',
                  options: optionReceiveMethod,
                },
              }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Typography.Text className="sub-title">
              {t('warehouse.salesReturn.deliveryAdrress')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <FormInput
              type={INPUT_TYPE.TEXT_AREA}
              {...{
                formItemOptions: {
                  name: 'receivingAddress',
                },
                inputOptions: {
                  placeholder: t('warehouse.salesReturn.placeholderdeliveryAdrress'),
                },
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PaymentInfo;

import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import CustomTable from 'components/CustomTable';
import { t } from 'i18next';
import styles from '../../index.module.scss';

function DetailTable({ columns, data, dataTable, loading, onTableChange, rowSelection }) {
  return (
    <CustomTable
      columns={columns}
      dataSource={dataTable?.filter((item) => !item?.oldSerial)}
      rowKey={(record) => record?.id}
      loading={loading}
      scroll={{
        x: 900,
        // y: 600,
      }}
      onChange={onTableChange}
      rowSelection={rowSelection}
      footer={() => {
        return (
          <Space className={styles['space']} style={{ background: 'transparent' }}>
            <Row gutter={[10]}>
              <Col>
                <Typography.Text strong className={styles['color-content']}>
                  {t('warrantyRequest.create.totalItem')}:
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text strong className={styles['color-content']}>
                  {data?.totalItems < 10 ? `0${data?.totalItems}` : data?.totalItems}
                </Typography.Text>
              </Col>
            </Row>
          </Space>
        );
      }}
      pagination
      isLeftFooter={false}
    />
  );
}

export default DetailTable;

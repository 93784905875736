import { Button, Col, Image, Row, Space, Typography } from 'antd';
import CommonCard from 'components/CommonCard';
import PageHeader from 'components/PageHeader';
import Card from 'features/Cart/components/Card';
import { t } from 'i18next';
import React, { useRef } from 'react';
import { formatCurrency } from 'utils/helperFuncs';

const Detail = () => {
  const libraryImageRef = useRef(null);

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <PageHeader
          // pageTitle={t('common.collectManagement')}
          routes={[
            {
              path: '',
              name: t('common.accountant'),
            },
            {
              path: '/collect-management',
              name: t('common.collectManagement'),
            },
            {
              path: '',
              name: t('common.collectManagementDetail'),
            },
          ]}
        />
      </Col>

      <Col span={6} offset={12}>
        <Typography style={{ fontSize: '12px' }}>
          <Typography.Text style={{ color: 'rgba(120, 120, 120, 1)' }}>
            {t('accountant.createBy')}: Phan Thành&nbsp;
          </Typography.Text>
          <Typography.Text italic style={{ color: 'rgba(38, 47, 59, 0.44)' }}>
            3 ngày trước
          </Typography.Text>
        </Typography>
      </Col>

      <Col span={6}>
        <Typography style={{ fontSize: '12px' }}>
          <Typography.Text style={{ color: 'rgba(120, 120, 120, 1)' }}>
            {t('accountant.updateBy')}: Phan Thành&nbsp;
          </Typography.Text>
          <Typography.Text italic style={{ color: 'rgba(38, 47, 59, 0.44)' }}>
            19 giờ trước
          </Typography.Text>
        </Typography>
      </Col>

      <Col span={24}>
        <Card
          title={
            <Typography.Text strong style={{ color: '#2246DC' }}>
              {t('accountant.infoBill')}
            </Typography.Text>
          }
        >
          <Row gutter={[16, 16]}>
            <Col span={8} style={{ borderRight: '0.5px dashed #9cadc4' }}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Typography.Text>{t('accountant.paymentDate')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>25/11/2022</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{t('accountant.receiptsCode')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>PTT1234567</Typography.Text>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Row gutter={[16, 16]} style={{ borderRight: '0.5px dashed #9cadc4' }}>
                <Col span={12}>
                  <Typography.Text>{t('accountant.orderCode')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong style={{ color: '#2246DC' }}>
                    BH20221123
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{t('accountant.statusOrder')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>Hoàn thành</Typography.Text>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Typography.Text>{t('accountant.agentCode')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>MST11111111</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{t('accountant.agentName')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>Đại lý thanh thảo</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card
          title={
            <Typography.Text strong style={{ color: '#2246DC' }}>
              {t('accountant.collectionAmountInformation')}
            </Typography.Text>
          }
        >
          <Row gutter={[16, 16]}>
            <Col span={12} style={{ borderRight: '0.5px dashed #9cadc4' }}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Typography.Text>{t('accountant.accountReceivingMoney')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>123456789</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{t('accountant.bank')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>Vietcombank</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{t('accountant.receiveMoneyMethod')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>Chuyển khoản</Typography.Text>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Typography.Text>{t('accountant.amountToBeCollected')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>{formatCurrency(150000000)}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{t('accountant.actualAmount')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text style={{ color: '#FF0C5C' }}>
                    {formatCurrency(150000000)}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{t('accountant.transactionDetails')}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Image.PreviewGroup>
                    <Row gutter={[16, 16]}>
                      <Col span={6} ref={libraryImageRef}>
                        <Image
                          width="100%"
                          height={libraryImageRef?.current?.offsetWidth - 16}
                          src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                        />
                      </Col>
                      <Col span={6}>
                        <Image
                          width="100%"
                          height={libraryImageRef?.current?.offsetWidth - 16}
                          src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                        />
                      </Col>
                      <Col span={6}>
                        <Image
                          width="100%"
                          height={libraryImageRef?.current?.offsetWidth - 16}
                          src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                        />
                      </Col>
                      <Col span={6}>
                        <Image
                          width="100%"
                          height={libraryImageRef?.current?.offsetWidth - 16}
                          src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                        />
                      </Col>
                      <Col span={6}>
                        <Image
                          width="100%"
                          height={libraryImageRef?.current?.offsetWidth - 16}
                          src="https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp"
                        />
                      </Col>
                      <Col span={6}>
                        <Image
                          width="100%"
                          height={libraryImageRef?.current?.offsetWidth - 16}
                          src="https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp"
                        />
                      </Col>
                      <Col span={6}>
                        <Image
                          width="100%"
                          height={libraryImageRef?.current?.offsetWidth - 16}
                          src="https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp"
                        />
                      </Col>
                      <Col span={6}>
                        <Image
                          width="100%"
                          height={libraryImageRef?.current?.offsetWidth - 16}
                          src="https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp"
                        />
                      </Col>
                    </Row>
                  </Image.PreviewGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <CommonCard>
          <Row gutter={16} style={{ padding: '12px' }}>
            <Col span={6}>{t('accountant.reasonForCollectingMoney')}</Col>
            <Col span={18}>Nhận tiền thu hộ của đơn hàng BH20221123#1</Col>
          </Row>
        </CommonCard>
      </Col>

      <Col span={24}>
        <Space
          style={{
            width: '100%',
            justifyContent: 'end',
            background: '#ffffff',
            borderRadius: '8px',
          }}
        >
          <Button size="large" type="primary">
            {t('accountant.edit')}
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default Detail;

import CustomTags from "components/CustomTags";
import { IMPORT_TYPE } from "features/Serial/constant";
import { t } from "i18next";

const useFiltersTag = ({
  params,
  handleRemoveFilterTag,
  staffOptions,
  optionExportRadio,
  warehouseOptions,
  sellersOptions,
}) => {
  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    if (typeof arrValue !== "undefined" && arrValue !== null && typeof arrValue[Symbol.iterator] === "function") {
      for (const element of arrValue) {
        const item = arrTags?.find(({ value }) => element === value);
        result.push(item);
      }
    }
    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(", ")}
      </div>
    );
  };
  const valueTagsImportNoteType = [
    {
      label: t("serialIE.FIRST_TIME_IMPORT"),
      value: IMPORT_TYPE.FIRST_TIME_IMPORT,
    },
    {
      label: t("serialIE.RETURN"),
      value: IMPORT_TYPE.RETURN,
    },
    {
      label: t("serialIE.RE_IMPORT_WARRANTY"),
      value: IMPORT_TYPE.RE_IMPORT_WARRANTY,
    },
    {
      label: t("serialIE.transfer_serial"),
      value: IMPORT_TYPE.TRANSFER_SERIAL,
    },
    {
      label: t("serialIE.other"),
      value: IMPORT_TYPE.OTHER,
    },
  ];
  const tagsCreatedByID = [
    {
      title: t("serialIE.staff"),
      options: staffOptions,
      selected: params?.filters?.createdByIDs,
      key: "createdByIDs",
    },
  ];
  const tagsImportNoteType = [
    {
      title: t("serialIE.operation"),
      options: valueTagsImportNoteType,
      selected: params?.filters?.serialImportNoteType,
      key: "serialImportNoteType",
    },
  ];

  const tagsExportNoteType = [
    {
      title: t("serialIE.typeExport"),
      options: optionExportRadio,
      selected: params?.filters?.type,
      key: "type",
    },
  ];
  const tagsExportWarranty = [
    {
      title: t("serialIE.EXPORTWAREHOUSE"),
      options: warehouseOptions,
      selected: params?.filters?.exportWarehouseIDs,
      key: "exportWarehouseIDs",
    },
  ];
  const tagsWarehouse = [
    {
      title: t("serialIE.ENTERPRISE_SERIAL"),
      options: warehouseOptions,
      selected: params?.filters?.warehouseIDs,
      key: "warehouseIDs",
    },
  ];
  const tagsSellers = [
    {
      title: t("serialIE.SUPPLIER_AGENT"),
      options: sellersOptions,
      selected: params?.filters?.sellerIDs,
      key: "sellerIDs",
    },
  ];

  const renderTagsCreatedByID = tagsCreatedByID.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsImportNoteType = tagsImportNoteType.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsExportNoteType = tagsExportNoteType.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsExportWarranty = tagsExportWarranty.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });

  const renderTagswarehouseIDs = tagsWarehouse.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsSellerIDs = tagsSellers.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });

  return {
    renderTagsCreatedByID,
    renderTagsImportNoteType,
    renderTagsExportNoteType,
    renderTagsExportWarranty,
    renderTagswarehouseIDs,
    renderTagsSellerIDs,
  };
};

export default useFiltersTag;

import React from 'react';
import { t } from 'i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import { TabsMenu } from 'components/CustomTabs';
import useCustomerDetail from './useCustomerDetail';
import CreateButton from 'components/CreateButton';

import './index.scss';
import Card from 'features/Cart/components/Card';

function Detail() {
  const { tabs, activeTabKey, handleChangeTab, handleSave, loading } = useCustomerDetail();

  return (
    <>
      <PageHeader
        pageTitle={t('customer.customerList')}
        routes={[
          {
            path: '/seller',
            name: t('common.seller'),
          },
          {
            path: `/seller-group`,
            name: t('customer.manageCustomer'),
          },
          {
            path: `/seller-group`,
            name: t('customer.updateCustomer'),
          },
        ]}
      />

      <div className="wrapper">
        <div className="button-group">
          <Button>
            <Link to={'/seller-group'}>{t('common.cancel')}</Link>
          </Button>
          <CreateButton
            onClick={handleSave}
            loading={loading}
            title={t('common.save')}
            className="btn-create"
          />
        </div>
        <Card
          children={
            <TabsMenu
              tabs={tabs}
              defaultActiveKey={activeTabKey}
              activeKey={activeTabKey}
              onChange={handleChangeTab}
            />
          }
        />
      </div>
    </>
  );
}

export default Detail;

import { gql } from '@apollo/client';

export const GET_SELLER_WALLETS_TRANSACTION = gql`
  query GetWalletsTransaction(
    $filters: WalletTransactionFilters
    $pagination: PaginationRequest
  ) {
    walletTransaction {
      pagination(filters: $filters, pagination: $pagination) {
        transactions {
          createdAt # thời gian giao dịch
          id # mã giao dịch
          amount # số tiền
          wallet {
            type # Tài khoản thực hiện
            seller {
              id
              code # mã đại lý
              fullName # tên đại lý
            }
          }
          walletTransfer {
            sourceWallet {
              type # nguồn tiền nạp
            }
            createdBy {
              email # người thực hiện
            }
            orderID # mã đơn hàng
            description # ghi chú
            fileURLs # file
            order {
              formattedNumber #mã đơn hàng
            }
          }
          walletTransferID
          walletID #mã tài khoản
          id # mã giao dịch
          amount # số tiền
          sourceType
          transferType #loại giao dịch
        }
        paginationData {
          total
        }
      }
    }
  }
`;

export const GET_WAREHOUSE_ACCOUNTANT_WALLETS_TRANSACTION = gql`
  query GetWalletsTransaction(
    $filters: WalletTransactionFilters
    $pagination: PaginationRequest
  ) {
    walletTransaction {
      pagination(filters: $filters, pagination: $pagination) {
        transactions {
          createdAt #thời gian giao dịch
          walletID #mã tài khoản
          walletTransfer {
            createdByID
            createdBy {
              fullname #Tên kế toán kho
              email #Người thực hiện
            }
            description #Ghi chú
            fileURLs #list url
            targetWallet {
              type
              seller {
                id
                code
                shortName
              }
              user {
                id
                fullname
              }
            }
            sourceWallet {
              type
              seller {
                id
                code
                shortName
              }
              user {
                id
                fullname
              }
            }
          }
          id #Mã giao dịch
          sourceType #Nguồn tiền
          amount #Số tiền
        }
        paginationData {
          total
        }
      }
    }
  }
`;

import React, {useEffect, useRef, useState} from 'react';
import {Input, Tag} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import './index.scss';

const InputTagNewSerial = ({title, onOke}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    onOke && onOke(inputValue);
    setInputVisible(false);
    setInputValue("")
  };
  return (
    <>
      {inputVisible && (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          className="tag-input"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {inputVisible === false && (
        <Tag className="site-tag-plus" onClick={showInput}>
          <PlusOutlined/> {title}
        </Tag>
      )}
    </>
  );
};
export default InputTagNewSerial;

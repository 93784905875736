import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Radio, Row } from 'antd';
import { t } from 'i18next';
import React from 'react';
import useToolbar from './useToolbar';

const ToolBar = ({ onChange, onSearch, countSelectedRowKeys }) => {
  const { filterItemsTypeOptions, handleChange, handleSearch, isFilterItems } = useToolbar({
    onChange,
    onSearch,
    countSelectedRowKeys,
  });
  return (
    <Row gutter={16} style={{ marginBottom: '16px' }}>
      <Col span={12}>
        {!isFilterItems && (
          <Input
            prefix={<SearchOutlined />}
            placeholder={t('cart.placeholderSearchProduct')}
            style={{ height: '40px' }}
            onChange={handleSearch}
          />
        )}
      </Col>
      <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
        <Radio.Group
          options={filterItemsTypeOptions}
          defaultValue={false}
          onChange={handleChange}
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
          optionType="button"
        />
      </Col>
    </Row>
  );
};

export default ToolBar;

import React, { useState } from 'react';
import { Modal, Upload } from 'antd';
import { ACCEPT_FILE_TYPES } from 'config/constants';
import { dummyRequest, notify, validateFile } from 'utils/helperFuncs';
import { PlusOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import './index.scss';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function UploadFiles({
  onUploadSuccess,
  switchClear,
  multiple,
  accept,
  listType,
  loading,
  ...rest
}) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  return (
    <>
      <Upload
        accept={accept || ACCEPT_FILE_TYPES.join(',')}
        multiple={multiple || true}
        listType={listType || 'picture-card'}
        onPreview={handlePreview}
        customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
        beforeUpload={(file) => {
          if (!validateFile(file)) {
            notify.error({
              message: t('uploadDocument.limitErrorMessage'),
              description: file?.name,
            });
            return Upload.LIST_IGNORE;
          }
          return validateFile(file);
        }}
        {...rest}
      >
        <PlusOutlined />
      </Upload>
      <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt={previewTitle}
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
}

export default React.memo(UploadFiles);

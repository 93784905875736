import React from 'react';
import { Button, Menu } from 'antd';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import './index.scss';
import { useTranslation } from 'react-i18next';
import NotiList from '../NotiList';
import { useGetNotifyPaging, useUpdateNotifyStatus } from 'hooks/notification';
import { NOTIFICATION_STATUS } from 'config/constants';
import { notify } from 'utils/helperFuncs';
import MarkAllReadButton from '../MarkAllReadButton';

const NotificationMenu = () => {
  const { t } = useTranslation();

  const { convertData, loading } = useGetNotifyPaging({
    pagination: {
      offset: 0,
      limit: 3,
    },
  });

  const { handleUpdateNotifyStatus } = useUpdateNotifyStatus();

  const handleUpdateStatus = async (ids = [], isUnread) => {
    if (isUnread) {
      const checkIsMarkAllNotify = (ids, handleMultiple, handleSingle) => {
        if (ids.length > 1) {
          handleMultiple && handleMultiple();
        } else {
          handleSingle && handleSingle();
        }
      };

      await handleUpdateNotifyStatus({
        ids: ids,
        status: NOTIFICATION_STATUS.READ,
      })
        .then(() => {
          checkIsMarkAllNotify(
            ids,
            () => {
              notify.success({
                message: t('notification.markAllReadSuccess'),
                placement: 'top',
              });
            },
            () => {
              notify.success({
                message: t('notification.changeStatusSuccess'),
                placement: 'top',
              });
            }
          );
        })
        .catch((error) => {
          checkIsMarkAllNotify(
            ids,
            () => {
              notify.error({
                message: t('notification.markAllReadError'),
                placement: 'top',
                description: error.message,
              });
            },
            () => {
              notify.error({
                message: t('notification.changeStatusError'),
                placement: 'top',
                description: error.message,
              });
            }
          );
        });
    }
    return;
  };

  const checkUnread = (data) => {
    return data?.some((item) => item.status === NOTIFICATION_STATUS.UNREAD);
  };

  return (
    <Menu className="noti-menu">
      <div className={styles['notification-menu']}>
        <div className={styles['noti-header']}>
          <span className={`${styles['header-title']}`}>{t('notification.newNotifications')}</span>
          <MarkAllReadButton />
        </div>
        <div className={styles['list']}>
          <NotiList
            dataSource={convertData}
            loading={loading}
            onClickCard={(id, notifyObj) => {
              handleUpdateStatus(id ? [id] : [], checkUnread([notifyObj]));
            }}
          />
        </div>
        <Link to="/notification">
          <Button className={styles['noti-watch-all']} type="text">
            {t('notification.watchAllNotifications')}
          </Button>
        </Link>
      </div>
    </Menu>
  );
};

export default NotificationMenu;

import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Result } from 'antd';
import { useGetInfoPlatform } from 'hooks/info-platform';

const Home = () => {
  const { t } = useTranslation();

  const { platformURL, platformName, platformCode } = useGetInfoPlatform();

  return (
    <div className={styles['home']}>
      <div className={styles['container']}>
        <Result
          icon={<img alt={`logo-${platformCode}`} className={styles['icon']} src={platformURL} />}
          title={
            <div className={styles['title']}>
              <span>{t('welcome.message1')}</span>&nbsp;
              <span className={styles['ha']}>{t('welcome.HA', { platformName })}</span>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Home;

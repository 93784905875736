export const USER_ME_PERMISSION = [
  "ORDER_VIEW",
  "TRANSACTION_ALL",
  "ORDER_ALL",
  "ORDER_CREATE",
  "ORDER_APPROVE_STOCK",
  "ORDER_CONFIRM_PAYMENT",
  "SELLER_ALL",
  "SELLER_VIEW",
  "SELLER_DEACTIVE",
  "SELLER_CREATE",
  "SELLER_UPDATE",
  "SELLER_CONTACT_VIEW",
  "SELLER_CONTACT_UPDATE",
  "SELLER_CONTACT_DELETE",
  "SYSTEM_ALL",
  "STAFF_ALL",
  "ROLE_ALL",
  "PRODUCT_ALL",
  "PRODUCT_VIEW",
  "PRODUCT_UPDATE",
  "PRODUCT_UPDATE_PRICE",
  "PRODUCT_VIEW_STOCK",
  "ACCOUNTANT_ALL",
  "ACCOUNTANT_BANK",
  "WAREHOUSE_ACCOUNTANT",
  "ORDER_WAIT_STOCK",
  "ORDER_DENY_STOCK",
  "ORDER_EXPORT_STOCK",
  "ORDER_ASSIGN_SHIPPER",
  "ORDER_COMPLETE",
  "ORDER_CANCEL",
  "ORDER_RECREATE_CART",
  "ORDER_COMPLETE_ASSEMBLY",
  "CART_ALL",
  "CART_CREATE",
  "CART_VIEW",
  "ORDER_SHIPPER_DELIVER",
  "ORDER_VIEW_ALL",
  "REPORT-ALL",
  "REPORT_SALE_VIEW",
  "REPORT_SALE_MANAGER_VIEW",
  "REPORT_POTENTIAL_CUSTOMER_GENERAL_VIEW",
  "REPORT_POTENTIAL_CUSTOMER_PERSONAL_VIEW",
  "ORDER_VIEW",
  "TRANSACTION_ALL",
  "ORDER_ALL",
  "ORDER_CREATE",
  "ORDER_APPROVE_STOCK",
  "ORDER_CONFIRM_PAYMENT",
  "SELLER_ALL",
  "SELLER_VIEW",
  "SELLER_DEACTIVE",
  "SELLER_CREATE",
  "SELLER_UPDATE",
  "SELLER_CONTACT_VIEW",
  "SELLER_CONTACT_UPDATE",
  "SELLER_CONTACT_DELETE",
  "SYSTEM_ALL",
  "STAFF_ALL",
  "STAFF_CREATE",
  "STAFF_UPDATE",
  "ROLE_ALL",
  "ROLE_VIEW",
  "ROLE_CREATE",
  "ROLE_UPDATE",
  "ROLE_DELETE",
  "PRODUCT_ALL",
  "PRODUCT_VIEW",
  "PRODUCT_UPDATE",
  "PRODUCT_UPDATE_PRICE",
  "PRODUCT_VIEW_STOCK",
  "ACCOUNTANT_ALL",
  "ACCOUNTANT_BANK",
  "WAREHOUSE_ACCOUNTANT",
  "STAFF_ASSIGN_ROLE",
  "STAFF_VIEW",
  "STAFF_DEACTIVE",
  "DEPARTMENT_ALL",
  "DEPARTMENT_VIEW",
  "DEPARTMENT_CREATE",
  "DEPARTMENT_UPDATE",
  "DEPARTMENT_DELETE",
  "ORDER_WAIT_STOCK",
  "ORDER_DENY_STOCK",
  "ORDER_EXPORT_STOCK",
  "ORDER_ASSIGN_SHIPPER",
  "ORDER_COMPLETE",
  "ORDER_CANCEL",
  "ORDER_RECREATE_CART",
  "ACCOUNTANT_VIEW_SELLER_LIST",
  "ACCOUNTANT_VIEW_ACCOUNTANT_LIST",
  "ACCOUNTANT_VIEW_SELLER_TX_HISTORY",
  "ACCOUNTANT_VIEW_ACCOUNTANT_TX_HISTORY",
  "ACCOUNTANT_RECHARGE_WAREHOUSE_ACCOUNTANT",
  "ORDER_COMPLETE_ASSEMBLY",
  "CART_ALL",
  "CART_CREATE",
  "CART_VIEW",
  "ORDER_SHIPPER_DELIVER",
  "ORDER_VIEW",
  "ORDER_ALL",
  "ORDER_CREATE",
  "ORDER_APPROVE_STOCK",
  "ORDER_CONFIRM_PAYMENT",
  "SELLER_VIEW",
  "SELLER_DEACTIVE",
  "SELLER_CREATE",
  "SELLER_UPDATE",
  "SELLER_CONTACT_VIEW",
  "SELLER_CONTACT_UPDATE",
  "SELLER_CONTACT_DELETE",
  "STAFF_ALL",
  "STAFF_CREATE",
  "STAFF_UPDATE",
  "ROLE_ALL",
  "ROLE_VIEW",
  "ROLE_CREATE",
  "ROLE_UPDATE",
  "ROLE_DELETE",
  "PRODUCT_VIEW",
  "PRODUCT_UPDATE",
  "PRODUCT_UPDATE_PRICE",
  "PRODUCT_VIEW_STOCK",
  "ACCOUNTANT_BANK",
  "WAREHOUSE_ACCOUNTANT",
  "STAFF_ASSIGN_ROLE",
  "STAFF_VIEW",
  "STAFF_DEACTIVE",
  "DEPARTMENT_ALL",
  "DEPARTMENT_VIEW",
  "DEPARTMENT_CREATE",
  "DEPARTMENT_UPDATE",
  "DEPARTMENT_DELETE",
  "ORDER_WAIT_STOCK",
  "ORDER_DENY_STOCK",
  "ORDER_EXPORT_STOCK",
  "ORDER_ASSIGN_SHIPPER",
  "ORDER_COMPLETE",
  "ORDER_CANCEL",
  "ORDER_RECREATE_CART",
  "ACCOUNTANT_VIEW_SELLER_LIST",
  "ACCOUNTANT_VIEW_ACCOUNTANT_LIST",
  "ACCOUNTANT_VIEW_SELLER_TX_HISTORY",
  "ACCOUNTANT_VIEW_ACCOUNTANT_TX_HISTORY",
  "ACCOUNTANT_RECHARGE_WAREHOUSE_ACCOUNTANT",
  "ORDER_COMPLETE_ASSEMBLY",
  "CART_ALL",
  "CART_CREATE",
  "CART_VIEW",
  "ORDER_SHIPPER_DELIVER",
  "ORDER_VIEW_ALL",
  "REPORT-ALL",
  "REPORT_SALE_VIEW",
  "REPORT_SALE_MANAGER_VIEW",
  "ORDER_VIEW",
  "ORDER_ALL",
  "ORDER_CREATE",
  "ORDER_APPROVE_STOCK",
  "ORDER_CONFIRM_PAYMENT",
  "SELLER_VIEW",
  "SELLER_DEACTIVE",
  "SELLER_CREATE",
  "SELLER_UPDATE",
  "SELLER_CONTACT_VIEW",
  "SELLER_CONTACT_UPDATE",
  "SELLER_CONTACT_DELETE",
  "STAFF_ALL",
  "STAFF_CREATE",
  "STAFF_UPDATE",
  "ROLE_ALL",
  "ROLE_VIEW",
  "ROLE_CREATE",
  "ROLE_UPDATE",
  "ROLE_DELETE",
  "PRODUCT_VIEW",
  "PRODUCT_UPDATE",
  "PRODUCT_UPDATE_PRICE",
  "PRODUCT_VIEW_STOCK",
  "ACCOUNTANT_BANK",
  "WAREHOUSE_ACCOUNTANT",
  "STAFF_ASSIGN_ROLE",
  "STAFF_VIEW",
  "STAFF_DEACTIVE",
  "DEPARTMENT_ALL",
  "DEPARTMENT_VIEW",
  "DEPARTMENT_CREATE",
  "DEPARTMENT_UPDATE",
  "DEPARTMENT_DELETE",
  "ORDER_WAIT_STOCK",
  "ORDER_DENY_STOCK",
  "ORDER_EXPORT_STOCK",
  "ORDER_ASSIGN_SHIPPER",
  "ORDER_COMPLETE",
  "ORDER_CANCEL",
  "ORDER_RECREATE_CART",
  "ACCOUNTANT_VIEW_SELLER_LIST",
  "ACCOUNTANT_VIEW_ACCOUNTANT_LIST",
  "ACCOUNTANT_VIEW_SELLER_TX_HISTORY",
  "ACCOUNTANT_VIEW_ACCOUNTANT_TX_HISTORY",
  "ACCOUNTANT_RECHARGE_WAREHOUSE_ACCOUNTANT",
  "ORDER_COMPLETE_ASSEMBLY",
  "CART_ALL",
  "CART_CREATE",
  "CART_VIEW",
  "ORDER_SHIPPER_DELIVER",
  "ORDER_VIEW_ALL",
  "REPORT-ALL",
  "REPORT_SALE_VIEW",
  "REPORT_SALE_MANAGER_VIEW",
  "IMPORT_REQUEST_VIEW",
  "IMPORT_REQUEST_CREATE",
  "IMPORT_REQUEST_UPDATE",
  "IMPORT_REQUEST_APPROVE",
  "IMPORT_REQUEST_REJECT",
  "IMPORT_REQUEST_CANCEL",
  "IMPORT_REQUEST_COMPLETE",
  "IMPORT_REQUEST_VIEW_CREATED_BY_ME",
  "PROMOTION_CREATE",
  "PROMOTION_UPDATE",
  "PROMOTION_VIEW",
  "PROMOTION_DETAIL_VIEW",
  "PROMOTION_ORDER_VIEW",
  "ORDER_CANCEL_BEFORE_PAY_CONFIRM",
  "ORDER_CANCEL_AFTER_PAY_CONFIRM",
  "IMPORT_SALES_RETURN_VIEW",
  "IMPORT_SALES_RETURN_CREATE",
  "IMPORT_SALES_RETURN_UPDATE",
  "IMPORT_SALES_RETURN_MANAGER_APPROVE",
  "IMPORT_SALES_RETURN_TECHNICAL_APPROVE",
  "IMPORT_SALES_RETURN_APPROVE_AFTER_CHECKING_QUALITY",
  "IMPORT_SALES_RETURN_MANAGER_REJECT",
  "IMPORT_SALES_RETURN_TECHNICAL_REJECT",
  "IMPORT_SALES_RETURN_COMPLETE",
  "IMPORT_SALES_RETURN_BACK_TO_ENTRY",
  "IMPORT_SALES_RETURN_CANCEL",
  "IMPORT_SALES_RETURN_VIEW_CREATED_BY_ME",
  "SUPPLIER_VIEW",
  "SUPPLIER_DEACTIVATE",
  "SUPPLIER_CREATE",
  "SUPPLIER_UPDATE",
  "SUPPLIER_CONTACT_VIEW",
  "SUPPLIER_CONTACT_UPDATE",
  "SUPPLIER_CONTACT_DELETE",
  "APPROVE_ORDER",
  "SELLER_ACCOUNT_APPROVAL",
  "CUSTOMERS_VIEW",
  "CUSTOMER_CREATE",
  "CUSTOMERS_UPDATE",
  "CUSTOMER_REPORT_STAFF_VIEW",
  "CUSTOMER_REPORT_MANAGER_VIEW",
  "KEY_LICENSE_ACCOUNT_ALL",
  "KEY_LICENSE_ACCOUNT_VIEW_ALL",
  "KEY_LICENSE_ACCOUNT_VIEW_PART",
  "KEY_LICENSE_ACCOUNT_CREATE",
  "KEY_LICENSE_ACCOUNT_DETAIL",
  "KEY_LICENSE_ACCOUNT_LOCK",
  "KEY_LICENSE_ACCOUNT_UNLOCK",
  "SUGGEST_KEY_LICENSE_ACCOUNT_ALL",
  "SUGGEST_KEY_LICENSE_ACCOUNT_VIEW",
  "SUGGEST_KEY_LICENSE_ACCOUNT_CONFIRM",
  "SUGGEST_KEY_LICENSE_ACCOUNT_CANCEL",
];

export const PERMISSION_ARRAY = [...new Set(USER_ME_PERMISSION)];

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'assets/icons';
import { t } from 'i18next';
import WarrantyList from './WarrantyList';
import WarrantyRequestCreate from 'features/WarrantyRequest/Create';
import Detail from './Detail';
import WarrantyRequest from './WarrantyRequest';
import WarrantyRequestDetail from 'features/WarrantyRequest/Detail';
import SerialDetail from './SerialDetail';

const router = [
  {
    key: 'warranty',
    title: 'common.warranty',
    icon: <FontAwesomeIcon icon={Icon.faShieldAlt} />,
    children: [
      {
        key: 'warrantyList',
        title: 'common.warrantyList',
        path: '/warranty-list',
        exact: true,
        component: WarrantyList,
      },
      {
        key: 'warrantyRequest',
        title: 'common.warrantyRequest',
        path: '/warranty-request',
        exact: true,
        component: WarrantyRequest,
      },
    ],
  },
  {
    key: 'warrantyDetail',
    path: '/warranty-detail/:id',
    exact: true,
    component: Detail,
  },
  {
    key: 'warranty-request-create',
    path: '/warranty-request/create',
    component: WarrantyRequestCreate,
    exact: true,
  },
  {
    key: 'warranty-request-detail',
    path: '/warranty-request/detail/:id',
    component: WarrantyRequestDetail,
    exact: true,
  },
  {
    key: 'warrantyRequestDetail',
    path: '/warranty/serial/detail/:id',
    exact: true,
    component: SerialDetail,
  },
];

export default router;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ChatwootWidget = ({ locale, token, url }) => {

  const { t } = useTranslation();

  useEffect(() => {
    // Add Chatwoot Settings
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'left', // This can be left or right
      locale, // Language to be set
      useBrowserLanguage: true,
      type: 'expanded_bubble', // [standard, expanded_bubble]
      launcherTitle: t('common.needSupport')
    };

    // Paste the script from inbox settings except the <script> tag
    (function (d, t) {
      var BASE_URL=url;
      var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
      g.src=BASE_URL+"/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g,s);
      g.onload=function(){
        window.chatwootSDK.run({
          websiteToken: token,
          baseUrl: BASE_URL
        })
      }
    })(document, 'script');
  }, []);

  return null;
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Input } from 'antd';
import { Icon, SvgIcon } from 'assets/icons';
import CustomTable from 'components/CustomTable';
import React, { useState, useEffect } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { formatCurrency, getQuery, getTimeStamp, buildQueryString } from 'utils/helperFuncs';
import useSearchTextDebounce from 'hooks/useSearchTextDebounce/index';
import { useGetWalletWarehouseAccountantTransaction } from 'hooks/walletTransaction';
import { DATE_TIME_FORMAT } from 'config/constants';
import moment from 'moment';
import FilterBox from '../FilterBox';
import useDownloadFiles from 'hooks/useDownloadFiles/index';
import { useTranslation } from 'react-i18next';
import { parseWalletType } from 'hooks/walletTransaction/converter';
import PeriodSelect from 'components/PeriodSelect';

const AMOUNT_STATUS = (number) =>
  number > 0 ? (
    <div className="amount-increase">{formatCurrency(number)}</div>
  ) : (
    <div className="amount-decrease">{formatCurrency(number)}</div>
  );

const HYPER_LINK_STATUS = (isSeller, id) => {
  if (isSeller) {
    return `/seller/detail/${id}`;
  } else {
    return `/account/detail/${id}`;
  }
};
const CODE_STATUS = (isSeller, id, code) => {
  if (isSeller) {
    return code;
  } else {
    return id;
  }
};

const WarehouseAccountant = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    type: [],
    source: [],
    walletType: [],
    sellerTransactionOnly: false,
    timeRange: null,
    warehouseTransactionSearch: getQuery().warehouseTransactionSearch || null,
  });

  const getParams = () => ({
    filters: {
      type: params.type,
      source: params.source,
      walletType: params.walletType,
      sellerTransactionOnly: params.sellerTransactionOnly,
      timeRange: params.timeRange,
      query: params.warehouseTransactionSearch,
    },
    pagination: {
      offset: params.offset,
      limit: params.limit,
    },
  });

  const search = useSearchTextDebounce(params, setParams, 'warehouseTransactionSearch');

  const { onChangeFile } = useDownloadFiles();

  const { loading, transactions, total, refetch } = useGetWalletWarehouseAccountantTransaction(
    getParams()
  );

  const columns = [
    {
      title: 'THỜI GIAN',
      dataIndex: 'createdAt',
      render: (_, record) => moment(record?.createdAt).format(DATE_TIME_FORMAT),
    },
    {
      title: 'MÃ GIAO DỊCH',
      dataIndex: 'id',
      render: (_, record) => <b>{record.id}</b>,
    },
    {
      title: 'LOẠI GIAO DỊCH',
      dataIndex: 'transferType',
      render: (_, record) => <div>{t(record.transferType)}</div>,
    },
    {
      title: 'KẾ TOÁN KHO',
      dataIndex: 'nameAccountantWarehouse',
      render: (_, record) => {
        if (record?.amount > 0) {
          return (
            <span>
              <Link to={HYPER_LINK_STATUS(false, record.idAgent)}>
                {`${record.accountantName} (${CODE_STATUS(false, record.idAgent)})`}
              </Link>
            </span>
          );
        } else {
          return (
            <span>
              <Link to={HYPER_LINK_STATUS(false, record.createdByID)}>
                {`${record.nameAccountantWarehouse} (${CODE_STATUS(false, record.createdByID)})`}
              </Link>
            </span>
          );
        }
      },
    },
    {
      title: 'NGUỒN/VÍ NHẬN',
      render: (_, record) => {
        const wallet = record?.targetWallet;
        switch (wallet?.type) {
          case 'BANK_ACCOUNT':
            return <div>{t(parseWalletType(wallet.type))}</div>;
          case 'WAREHOUSE_ACCOUNTANT':
            return (
              <Link to={HYPER_LINK_STATUS(false, wallet?.user?.id)}>
                {`${t(parseWalletType(wallet.type))} (${CODE_STATUS(
                  false,
                  wallet?.user?.id || ''
                )})`}
              </Link>
            );
          case 'SELLER_PERSONAL':
          case 'SELLER_COMPANY':
            return (
              <Link to={HYPER_LINK_STATUS(true, wallet?.seller?.id)}>
                {`${t(parseWalletType(wallet.type))} (${CODE_STATUS(
                  true,
                  wallet?.seller?.id || '',
                  wallet?.seller?.code || ''
                )})`}
              </Link>
            );
          case 'SELLER_DEBT':
            return (
              <Link to={HYPER_LINK_STATUS(true, wallet?.seller?.id)}>
                {`${t(parseWalletType(wallet.type))} (${CODE_STATUS(
                  true,
                  wallet?.seller?.id || '',
                  wallet?.seller?.code || ''
                )})`}
              </Link>
            );
          default:
            return '';
        }
      },
    },
    {
      align: 'right',
      title: 'SỐ TIỀN',
      dataIndex: 'amount',
      render: (_, record) => AMOUNT_STATUS(record.amount),
    },
    {
      title: 'NGƯỜI THỰC HIỆN',
      dataIndex: 'executor',
    },
    {
      title: 'GHI CHÚ',
      dataIndex: 'description',
    },
    {
      align: 'center',
      title: 'FILE',
      dataIndex: 'fileURLs',
      render: (_, record) =>
        record.fileURLs.length > 0 ? (
          <SvgIcon.CloudDownloadIcon
            style={{ cursor: 'pointer' }}
            onClick={() => onChangeFile(record.fileURLs)}
          />
        ) : (
          ''
        ),
    },
  ];

  useEffect(() => {
    fetchWalletWarehouseAccountantTransaction();
  }, [params]);

  function filterByTimeRange(value) {
    setParams({
      ...params,
      timeRange: getTimeStamp(value),
    });
  }

  const fetchWalletWarehouseAccountantTransaction = async () => {
    await refetch(getParams);
  };

  function onTableChange(pagination) {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      offset: (current - 1) * pageSize,
    };
    setParams(newParams);
    buildQueryString({
      params: newParams,
    });
  }

  function filterExtra(values) {
    setParams({
      ...params,
      ...values,
      offset: 0,
    });
  }

  return (
    <div className="history-container">
      <div className="filter-box">
        <div className="search-input">
          <Input
            className="custom-input"
            allowClear={true}
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder="Tìm kiếm mã hoặc tên kế toán kho"
            defaultValue={getQuery().warehouseTransactionSearch || ''}
            onChange={search.onChange}
          />
        </div>

        <PeriodSelect onChange={filterByTimeRange} />

        <Dropdown
          overlayClassName="dropdown-overlay"
          trigger="click"
          overlay={<FilterBox onFilter={filterExtra} />}
          placement="bottomRight"
        >
          <Button
            icon={<FontAwesomeIcon icon={Icon.faFilter} className="filter-icon" />}
            className="filter-btn"
          >
            Bộ lọc
          </Button>
        </Dropdown>
      </div>
      <CustomTable
        loading={loading}
        pagination={{
          total: total,
          pageSize: params.limit,
          current: params.offset / params.limit + 1,
          showSizeChanger: false,
        }}
        onChange={onTableChange}
        columns={columns}
        dataSource={transactions}
        scroll={{ x: 800, y: null }}
        rowKey={(obj) => obj?.id}
      />
    </div>
  );
};

export default WarehouseAccountant;

import { Icon, FontAwesomeIcon } from 'assets/icons';
import ListCampaign from '../../../features/Campaign/components/List';
import CampaignCreateForm from './CampaignCreateForm';
import CampaignDetail from './CampaignDetail';
import CampaignEditForm from './CampaignEditForm';

const router = [
  {
    key: 'campaign',
    title: 'common.campaign',
    icon: <FontAwesomeIcon icon={Icon.faMedal} />,
    children: [
      {
        key: 'campaign-list',
        title: 'common.campaignList',
        path: '/campaign',
        component: ListCampaign,
        exact: true,
      },
    ],
  },
  { key: 'campaign-create', path: '/campaign/create', component: CampaignCreateForm, exact: true },
  // {
  //   key: 'campaign-create-confirm',
  //   path: '/campaign/create/confirm-information',
  //   component: ConfirmInformation,
  //   exact: true,
  // },
  { key: 'campaign-detail', path: '/campaign/detail/:id', component: CampaignDetail, exact: true },
  { key: 'campaign-edit', path: '/campaign/edit/:id', component: CampaignEditForm, exact: true },
];

export default router;

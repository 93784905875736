/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-prototype-builtins */
import { Typography } from 'antd';
import { SvgIcon } from 'assets/icons';
import FilterMenu from 'components/CustomFilterMenu/FilterMenu';
import StateSwitch from 'components/StateSwitch';
import TextAlignLeft from 'components/TextAlignLeft';
import { useGetProdAttribute } from 'hooks/attribute';
import { useGetProducts } from 'hooks/product/product';
import { useWarehouse } from 'hooks/warehouse';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'utils/helperFuncs';
import { isNil, omit } from 'lodash';

import {
  FilterAttributes,
  FilterLevelPrice,
  FilterManufacture,
  FilterRealStocks,
} from '../FilterProduct';
import { useGetManufacturers } from 'hooks/manufacturer/manufacturer';
import useDebounce from 'hooks/useDebounce';
import CustomTextHiddenLine from 'components/CustomTextHiddenLine';

const usePopupShowAllProduct = ({ data, index }) => {
  const listProductIds = data?.conditionPromotionProducts?.map((item) => item?.product?.id);
  const { warehouses: listWarehouse, loadingWarehouses } = useWarehouse();
  const listCate = data?.conditionPromotionProducts?.map((item) => item?.product?.categoryID);
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  function filterRealStocks(values) {
    //first make it unique array then filter it has own property range
    const realStocks = [
      ...new Map(
        [...(params.filters?.realStocks ? params.filters.realStocks : []), values].map((item) => [
          item.warehouseID,
          item,
        ])
      ).values(),
    ].filter((item) => item.hasOwnProperty('range') && item.range);

    setParams({
      ...params,
      filters: {
        ...params.filters,
        realStocks: realStocks.length > 0 ? realStocks : undefined,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    });
  }

  const renderChildrenInStock = (warehouses) => {
    const findDefaultValue = (realStocks, value) => {
      if (realStocks && value) {
        return realStocks.find((item) => item.warehouseID === value);
      } else return undefined;
    };
    return warehouses?.map((warehouse, index) => ({
      title: (
        <div className="title-table" style={{ textTransform: 'uppercase' }}>
          {warehouse.name}
        </div>
      ),
      key: index,
      width: 100,
      align: 'center',
      editable: 'false',
      render: (_, record) =>
        record?.stocks?.filter((stock) => stock?.warehouse?.id === warehouse?.id)[0]?.quantity ||
        '- -',
      filterDropdown: (
        <FilterRealStocks
          onFilter={filterRealStocks}
          warehouseID={warehouse.id}
          defaultValue={findDefaultValue(params.filters.realStocks, warehouse.id)}
        />
      ),
    }));
  };

  const { data: prodAtt } = useGetProdAttribute(listCate);
  const { dataManufactures, loadingManufactures } = useGetManufacturers();

  const filterProductAttributes = (values) => {
    const attributeValues = [
      ...new Map(
        [...(params.filters?.attributeValues ? params.filters.attributeValues : []), values].map(
          (item) => [item.attributeID, item]
        )
      ).values(),
    ].filter((item) => item.valueIDs?.length > 0);

    setParams({
      ...params,
      filters: {
        ...params.filters,
        attributeValues: attributeValues.length > 0 ? attributeValues : undefined,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    });
  };

  const renderProductAttributes = (attributes = []) => {
    const findDefaultValue = (attributes, value) => {
      if (attributes && value) {
        return attributes.find((item) => item.attributeID === value);
      } else return undefined;
    };
    if (attributes?.length > 0)
      return attributes?.map((attribute) => ({
        title: <div style={{ textTransform: ' uppercase' }}>{attribute.name}</div>,
        key: attribute.id,
        width: 125,
        align: 'center',
        editable: 'false',
        filterDropdown: (
          <FilterAttributes
            attributeId={attribute.id}
            attributeList={attribute.values}
            defaultValue={findDefaultValue(params.filters.attributeValues, attribute.id)?.valueIDs}
            onFilter={filterProductAttributes}
          />
        ),
        render: (_, record) => {
          const prodAtt = record?.fullAttributeValues?.find(
            (item) => item.attributeID === attribute.id
          );
          if (prodAtt) {
            return (
              <span>
                {prodAtt?.attributeValues
                  ?.map((attributeValue) => attributeValue.value)
                  .join(', ') || '- -'}
              </span>
            );
          }
          return '- -';
        },
      }));
    return [];
  };

  function filterProductPrices(values, key) {
    const newValue = values?.isSpecified === undefined ? null : values;

    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        [`${key}`]: newValue,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    };

    setParams(newParams);
  }

  const defaultParams = {
    filters: {
      query: '',
      categoryIDs: undefined,
      manufacturerIDs: undefined,
      priceLevel1: undefined,
      priceLevel2: undefined,
      priceLevel3: undefined,
      priceLevel4: undefined,
      realStocks: undefined,
      vat: undefined,
      vatPercent: undefined,
      attributeValues: undefined,
      productType: undefined,
      ids: listProductIds,
    },
    pagination: {
      limit: 10,
      offset: 0,
    },
  };

  const [params, setParams] = useState(defaultParams);
  const { loading, data: productData, paginationData } = useGetProducts(params);

  const optionVAT = [
    { label: t('product.vatCheckbox.yes'), value: true },
    { label: t('product.vatCheckbox.no'), value: false },
  ];

  function filterProductExtra(values) {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...values,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    };

    setParams(newParams);
  }

  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
  };

  const columns = [
    {
      title: t('product.productId'),
      render: (_, record, index) => (
        <TextAlignLeft
          value={
            <>
              <Link to={`/product/detail/${record?.id}`} target="_blank">
                <Typography.Link strong>{record?.code}</Typography.Link>
              </Link>
              <div>
                <CustomTextHiddenLine text={record?.name} line={1} />
              </div>
            </>
          }
        />
      ),
      fixed: 'center',
      width: 200,
    },
    {
      title: t('product.tableTitle.brand'),
      render: (_, record) => <Typography.Text>{record?.manufacturer?.name}</Typography.Text>,
      fixed: 'center',
      filterDropdown: (
        <FilterManufacture
          dataManufactures={dataManufactures}
          onFilter={filterProductExtra}
          defaultValue={params?.filters?.manufacturerIDs}
        />
      ),
    },
    {
      title: t('product.tableTitle.warranty'),
      render: (_, record) => (
        <Typography.Text>
          <div>{record?.warranty ? `${record?.warranty} ${t('common.month')}` : '- -'}</div>
        </Typography.Text>
      ),
      fixed: 'center',
    },
    ...renderProductAttributes(prodAtt),
    {
      title: t('product.tableTitle.priceLevel1'),
      render: (_, record) => (
        <Typography.Text>{formatCurrency(record?.productPrices?.level1Price)}</Typography.Text>
      ),
      fixed: 'center',
      filterDropdown: (
        <FilterLevelPrice
          onFilter={(priceLevel) => {
            filterProductPrices(priceLevel, 'priceLevel1');
          }}
          defaultValue={params.filters.priceLevel1}
        />
      ),
    },
    {
      title: t('cart.inStockColumn'),
      children: renderChildrenInStock(listWarehouse),
    },
    {
      title: t('product.EXPORT_VAT'),
      render: (_, record) => (record?.vat ? <SvgIcon.SuccessIcon /> : <SvgIcon.CloseIcon />),
      fixed: 'center',
      filterDropdown: (
        <FilterMenu
          type="radio"
          options={optionVAT}
          onFilter={(value) => handleFilter({ vat: value })}
        />
      ),
    },
    {
      title: t('product.tableTitle.status'),
      render: (_, record) => <StateSwitch checked={record?.isActive} />,
      fixed: 'center',
    },
  ];

  const handleChangeTable = (pagination) => {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      pagination: { ...params?.pagination, offset: (current - 1) * pageSize },
      sorts: params?.sorts,
    };
    setParams(newParams);
  };

  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedValue = useDebounce(searchTerm);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRemoveFilterTag = (key) => {
    setParams({
      pagination: { offset: 0, limit: 10 },
      filters: {
        ...omit({ ...params.filters }, [`${key}`]),
      },
      sort: params.sort,
      activeKey: params.activeKey,
    });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    setParams(defaultParams);
  }, [JSON.stringify(visible)]);

  return {
    loading,
    columns,
    productData,
    paginationData,
    handleChangeTable,
    onSearch,
    params,
    handleRemoveFilterTag,
    setParams,
    prodAtt,
    listWarehouse,
    dataManufactures,
    loadingManufactures,
    loadingWarehouses,
    showModal,
    handleCancel,
    visible,
  };
};

export default usePopupShowAllProduct;

import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { DATE_FORMAT } from 'config/constants';
import { t } from 'i18next';
import Card from 'features/Cart/components/Card';
import {
  renderWarrantyRequestType,
  WARRANTY_REQUEST_TYPE,
} from 'features/WarrantyRequest/constant';
import { formatDateTime } from 'utils/dateTime';
import { RenderRemainingDeliverTime, warrantyStatus } from 'features/Warranty/util';

import styles from '../../index.module.scss';
import OrderLink from 'components/CustomRedirectLink/OrderLink';
import ProductLink from 'components/CustomRedirectLink/ProductLink';
import SellerLink from 'components/CustomRedirectLink/SellerLink';
import { WARRANTY_STATUS } from 'features/Warranty/constant';
import CustomViewDocumentFiles from 'components/CustomViewDocumentFiles';
import { formatDate } from 'utils/helperFuncs';

function ViewProduct({ dataRow }) {
  return (
    <span>
      {dataRow ? (
        dataRow?.code ? (
          <Col span={24}>
            <Card style={{ background: '#F7F8FB' }}>
              <Row gutter={[16, 16]}>
                {dataRow?.order?.number && (
                  <>
                    <Col span={24} xl={8}>
                      <Typography.Text strong>
                        {t('warrantyRequest.create.orderCode')}
                      </Typography.Text>
                    </Col>
                    <Col span={24} xl={16} className={styles['color-content']}>
                      <OrderLink order={dataRow} />
                    </Col>
                  </>
                )}
                <Col span={24} xl={8}>
                  <Typography.Text strong>{t('warrantyRequest.create.warranty')}</Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  <Row gutter={[16]}>
                    <Col className={styles['color-content']}>#{dataRow?.code}</Col>
                    <Col>
                      <Typography.Text style={warrantyStatus[dataRow?.status]}>
                        {warrantyStatus[dataRow?.status]?.text}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>{t('warrantyRequest.create.serialIMEI')}</Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  {dataRow?.serialImei}
                </Col>
                {dataRow?.product?.code && (
                  <>
                    <Col span={24} xl={8}>
                      <Typography.Text strong>
                        {t('warrantyRequest.create.productCode')}
                      </Typography.Text>
                    </Col>
                    <Col span={24} xl={16} className={styles['color-content']}>
                      <ProductLink product={dataRow} />
                    </Col>
                  </>
                )}

                {(dataRow?.product?.name || dataRow?.productName) && (
                  <>
                    <Col span={24} xl={8}>
                      <Typography.Text strong>
                        {t('warrantyRequest.create.productName')}
                      </Typography.Text>
                    </Col>
                    <Col span={24} xl={16}>
                      {dataRow?.product?.name || dataRow?.productName}
                    </Col>
                  </>
                )}
                {dataRow?.category?.name && (
                  <>
                    <Col span={24} xl={8}>
                      <Typography.Text strong>
                        {t('warrantyRequest.create.attributeLV3')}
                      </Typography.Text>
                    </Col>
                    <Col span={24} xl={16}>
                      {dataRow?.category?.name}
                    </Col>
                  </>
                )}
                <Col span={24} xl={8}>
                  <Typography.Text strong>{t('warrantyRequest.create.soldDate')}</Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  {formatDate(dataRow?.soldDate)}
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.expiredDate')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  <Row gutter={[16]}>
                    <Col>
                      {formatDate(dataRow?.warrantyDate) || formatDate(dataRow?.warrantyEndDate)}
                    </Col>
                    <RenderRemainingDeliverTime time={dataRow?.remainingWarrantyDays} />
                  </Row>
                </Col>
                {dataRow?.seller?.fullName && (
                  <>
                    <Col span={24} xl={8}>
                      <Typography.Text strong>{t('warrantyRequest.create.client')}</Typography.Text>
                    </Col>
                    <Col span={24} xl={16} className={styles['color-content']}>
                      <SellerLink seller={dataRow} />
                    </Col>
                  </>
                )}
                {/*  */}
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.warrantyType')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  {renderWarrantyRequestType(
                    dataRow?.status === WARRANTY_STATUS.STILL_VALIDATED
                      ? WARRANTY_REQUEST_TYPE.SELF
                      : WARRANTY_REQUEST_TYPE.BEHALF
                  )}
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.appointmentDate')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  {dataRow?.promisedReturnDate
                    ? formatDateTime(dataRow?.promisedReturnDate, DATE_FORMAT)
                    : '- -'}
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.accessoriesNote')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  <Typography.Text> {dataRow?.accessoryAttachment}</Typography.Text>
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.errorDetail')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  <Typography.Text>{dataRow?.defectDescription}</Typography.Text>
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.clientRequest')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  <Typography.Text>{dataRow?.customerRequest}</Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text strong>{t('warrantyRequest.popup.files')}</Typography.Text>
                </Col>
                <Col span={24}>
                  <Space style={{ flexWrap: 'wrap' }}>
                    {dataRow?.imageURLs?.map((fileURL, index) => {
                      return <CustomViewDocumentFiles file={fileURL} key={index} />;
                    }) || <Typography.Text italic>{t('common.noFiles')}</Typography.Text>}
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        ) : (
          <Col span={24}>
            <Card style={{ background: '#F7F8FB' }}>
              <Row gutter={[16, 16]}>
                <Col span={24} xl={8}>
                  <Typography.Text strong>{t('warrantyRequest.create.serialIMEI')}</Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  {dataRow?.serialImei}
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.productCode')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16} className={styles['color-content']}>
                  <ProductLink product={dataRow} />
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.productName')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  {dataRow?.productName}
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.attributeLV3')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  {dataRow?.categoryName}
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.warrantyType')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  {renderWarrantyRequestType(dataRow?.warrantyRequestType)}
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.accessoriesNote')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  <Typography.Text> {dataRow?.accessoryAttachment}</Typography.Text>
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.appointmentDate')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  {formatDateTime(dataRow?.promisedReturnDate, DATE_FORMAT)}
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.errorDetail')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  <Typography.Text>{dataRow?.defectDescription}</Typography.Text>
                </Col>
                <Col span={24} xl={8}>
                  <Typography.Text strong>
                    {t('warrantyRequest.create.clientRequest')}
                  </Typography.Text>
                </Col>
                <Col span={24} xl={16}>
                  <Typography.Text>{dataRow?.customerRequest}</Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text strong>{t('warrantyRequest.popup.files')}</Typography.Text>
                </Col>
                <Col span={24}>
                  <Space style={{ flexWrap: 'wrap' }}>
                    {dataRow?.imageURLs?.map((fileURL, index) => {
                      return <CustomViewDocumentFiles file={fileURL} key={index} />;
                    }) || <Typography.Text italic>{t('common.noFiles')}</Typography.Text>}
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        )
      ) : (
        <Typography.Text strong>{t('warrantyRequest.create.notSelect')}</Typography.Text>
      )}
    </span>
  );
}

export default ViewProduct;

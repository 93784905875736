import React from 'react';
import { t } from 'i18next';
import CustomTags from 'components/CustomTags';

const useFiltersTag = ({ params, handleRemoveFilterTag, dataBranch, dataRoles, statusOptions }) => {
  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    if (
      typeof arrValue !== 'undefined' &&
      arrValue !== null &&
      typeof arrValue[Symbol.iterator] === 'function'
    ) {
      for (const element of arrValue) {
        const item = arrTags?.find(({ value }) => element === value);
        result.push(item);
      }
    }
    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(', ')}
      </div>
    );
  };
  const tagsBranchIDs = [
    {
      title: t('account.branch'),
      options: dataBranch,
      selected: params?.filters?.branchIDs,
      key: 'branchIDs',
    },
  ];
  const tagsRoleIDs = [
    {
      title: t('common.role'),
      options: dataRoles,
      selected: params?.filters?.roleIDs,
      key: 'roleIDs',
    },
  ];

  const tagsStatus = [
    {
      title: t('account.status'),
      options: statusOptions,
      selected: [params?.filters?.status],
      key: 'status',
    },
  ];

  const renderTagsBranchIDs = tagsBranchIDs.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsRoleIDs = tagsRoleIDs.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsStatus = tagsStatus.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });

  return {
    renderTagsBranchIDs,
    renderTagsRoleIDs,
    renderTagsStatus,
  };
};

export default useFiltersTag;

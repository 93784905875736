import React, { useState } from 'react';
import { Form, Tooltip, Typography } from 'antd';
import { t } from 'i18next';
import { notify } from 'utils/helperFuncs';
import {
  EXPORT_WARRANTY_TYPE,
  NOTE_TYPE,
  renderItemsStatus,
  TOTAL_ITEMS_STATUS,
} from 'features/WarrantyRequest/constant';
import { Link, useParams } from 'react-router-dom';
import { useGetWarrantyRequestPopup } from 'hooks/warranty';
import { RenderRemainingDeliverTime } from 'features/Warranty/util';
import { formatDateTime } from 'utils/dateTime';
import { DATE_FORMAT } from 'config/constants';
import { truncateAddDots } from 'features/WarrantyRequest/helperFuncs';
import SerialLink from 'components/CustomRedirectLink/SerialLink';
import printJS from 'print-js';

function usePopupExportFile({ handleExportFile, isPrint }) {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [isFilterSelected, setIsFilterSelected] = useState(false);

  const filterItemsTypeOptions = [
    { label: t('common.all'), value: false },
    {
      label: `${t('cart.selected')} (${selectedRowKeys?.length ? selectedRowKeys?.length : 0})`,
      value: true,
    },
  ];

  const { data: dataPopup } = useGetWarrantyRequestPopup({
    id: id || undefined,
    filters: {
      itemStatuses: [
        TOTAL_ITEMS_STATUS.WAITING_FOR_RETURN,
        TOTAL_ITEMS_STATUS.DELIVERING,
        TOTAL_ITEMS_STATUS.COMPLETED,
        TOTAL_ITEMS_STATUS.CANCELED,
      ],
    },
    sort: [],
  });

  const columns = [
    {
      title: <Typography.Text strong>{t('warrantyRequest.detail.table.serial')}</Typography.Text>,
      fixed: 'left',
      width: 150,
      align: 'center',
      render: (_, record) => <SerialLink serial={record} />,
    },
    {
      title: (
        <Typography.Text strong>{t('warrantyRequest.detail.table.parameter')}</Typography.Text>
      ),
      dataIndex: 'parameter',
      fixed: 'left',
      width: 200,
      align: 'center',
      render: (_, record) => {
        const productName =
          record?.product?.name || record?.productName || record?.warrantyNote?.productName;
        return (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography.Text style={{ textAlign: 'start', margin: 0 }}>
              <Link to={`/product/detail/${record?.productID}`}>{record?.product?.code}</Link>
            </Typography.Text>
            <Typography.Text style={{ textAlign: 'start', margin: 0 }} strong>
              <Tooltip title={productName}>
                <span>{truncateAddDots(productName)}</span>
              </Tooltip>
            </Typography.Text>
          </div>
        );
      },
    },

    {
      title: (
        <Typography.Text strong>{t('warrantyRequest.detail.table.dateReCustomer')}</Typography.Text>
      ),
      dataIndex: 'promisedReturnDate',
      width: 200,
      align: 'center',
      sorter: true,
      render: (_, record) => {
        return (
          <div>
            <Typography.Text>
              {formatDateTime(record?.promisedReturnDate, DATE_FORMAT)}
            </Typography.Text>
            <br />
            <Typography.Text strong style={{ color: '#00AB78' }}>
              {/* {`(${renderRemainingDeliverTime()})`} */}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: (
        <Typography.Text strong>{t('warrantyRequest.detail.table.remainingDate')}</Typography.Text>
      ),
      width: 200,
      align: 'center',
      dataIndex: 'resultReceivedDate',
      render: (_, record) => (
        <div>
          <Typography.Text>
            {formatDateTime(record?.warrantyNote?.warrantyDate, DATE_FORMAT)}
          </Typography.Text>
          <br />
          <RenderRemainingDeliverTime time={record?.warrantyNote?.remainingWarrantyDays} />
        </div>
      ),
    },
    {
      title: <Typography.Text strong>{t('warrantyRequest.detail.table.status')}</Typography.Text>,
      dataIndex: 'status',
      fixed: 'right',
      width: 120,
      align: 'center',
      render: (_, record) => renderItemsStatus(record?.status),
    },
  ];

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e) => {
    setIsFilterSelected && setIsFilterSelected(e.target.value);
  };

  const handleOk = async () => {
    if (selectedRowKeys !== undefined) {
      return await handleExportFile({
        warrantyRequestID: id,
        warrantyRequestItemIDs: selectedRowKeys,
        noteType: NOTE_TYPE.RETURN_NOTE,
        fileType: isPrint ? EXPORT_WARRANTY_TYPE.PDF : EXPORT_WARRANTY_TYPE.XLSX,
      })
        .then((response) => {
          const fileUrl = response.data.warrantyRequest.exportFile.url;
          const exportFile = () => {
            var win = window.open(fileUrl, '_blank');
            win.opener = null;
            win.blur();
            window.focus();
            window.close();
          };
          isPrint
            ? printJS({
                printable: fileUrl,
                type: EXPORT_WARRANTY_TYPE.PDF.toLowerCase(),
                showModal: true,
                modalMessage: t('common.loadingData'),
              })
            : exportFile();
        })
        .catch(() => {
          notify.error({ message: t('common.error') });
        });
    } else {
      return notify.warning({ message: t('serial.notSelect') });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRowKeys,
  };

  return {
    form,
    handleOk,
    filterItemsTypeOptions,
    handleChange,
    showModal,
    handleCancel,
    isModalOpen,
    selectedProduct: { isFilterSelected, selectedRows, selectedRowKeys },
    rowSelection,
    dataPopup,
    columns,
  };
}

export default usePopupExportFile;

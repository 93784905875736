import { gql } from '@apollo/client';

export const UPDATE_NOTIFY_STATUS = gql`
  mutation UpdateNotifyStatus($ids: [ID!], $status: NotificationStatus!) {
    notification {
      updateStatus(ids: $ids, status: $status)
    }
  }
`;

export const MARK_ALL_READ = gql`
  mutation {
    notification {
      markAsReadAll
    }
  }
`;

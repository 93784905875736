import { TreeSelect, Typography } from 'antd';
import { DATE_FORMAT, INPUT_TYPE } from 'config/constants';
import { useGetManufacturers } from 'hooks/manufacturer/manufacturer';
import { t } from 'i18next';
import { useGetCategoriesOptions } from 'hooks/campaign/campaign';
import { useSalesChannelInputOptions } from 'hooks/salesChannel';

const { SHOW_PARENT } = TreeSelect;

const useConditionInfo = () => {
  const { dataManufactures } = useGetManufacturers();
  const { data: dataAllCategories } = useGetCategoriesOptions();
  const { convertedData: dataSalesChannel } = useSalesChannelInputOptions();

  const conditionFields = [
    {
      type: INPUT_TYPE.DATE_PICKER,
      formItemOptions: {
        label: <Typography.Text strong>{t('campaign.form.orderDueDate')}</Typography.Text>,
        name: 'dueDate',
        rules: [{ required: true, message: t('common.validateRequired') }],
      },
      inputOptions: {
        placeholder: t('campaign.form.orderDueDatePlaceholder'),
        style: { width: '100%' },
        format: DATE_FORMAT,
      },
    },
    {
      type: INPUT_TYPE.CURRENCY_INPUT,
      formItemOptions: {
        label: (
          <Typography.Text strong>{t('campaign.form.minimumOrderPricePoint')}</Typography.Text>
        ),
        name: 'minTotal',
        rules: [{ required: true, message: t('common.validateRequired') }],
      },
      inputOptions: {
        placeholder: t('campaign.form.minimumOrderPricePointPlaceholder'),
        min: 0,
      },
    },

    {
      type: INPUT_TYPE.TREE_SELECT,
      formItemOptions: {
        label: <Typography.Text strong>{t('campaign.form.category')}</Typography.Text>,
        name: 'categories',
      },
      inputOptions: {
        placeholder: t('campaign.form.categoryPlaceholder'),
        treeData: dataAllCategories,
        multiple: true,
        treeCheckable: true,
        filterTreeNode: true,
        treeNodeFilterProp: 'label',
        treeLine: { showLeafIcon: false },
        showCheckedStrategy: SHOW_PARENT,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: <Typography.Text strong>{t('campaign.form.brand')}</Typography.Text>,
        name: 'brands',
      },
      inputOptions: {
        placeholder: t('campaign.form.brandPlaceholder'),
        options: dataManufactures,
        mode: 'multiple',
        filterOption: true,
        optionFilterProp: 'label',
      },
    },
  ];

  return {
    conditionFields,
  };
};

export default useConditionInfo;

import { gql } from "@apollo/client";

export const ORDER_RECONCILIATION_LIST_QUERY = gql`
  query GetOrderReconcilationQuery(
    $filters: OrderReconciliationFilter! = { query: "" }
    $sort: [OrderReconcilationSort!]
    $pagination: PaginationRequest
  ) {
    orderReconciliation {
      pagination(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        orderReconcilations {
          id
          orderID
          order {
            number
            status
            createdAt
            sellerID
            seller {
              fullName
              code
              idNumber
            }
            paymentMethod
            createdByID
            sellerProfitAmount
            createdBy {
              fullname
              email
              id
            }
            cancelReason
            saleChannelID
            saleChannel {
              name
              type
            }
            total
            fullEvents {
              state
              name
              createdAt
              createdByID
              createdBy {
                fullname
              }
            }
          }
          rejectedReason {
            reason
            causes
          }
          status
          expiredDate
          completedDate
          updatedByID
          updatedBy {
            fullname
            telephone
            id
          }
        }
      }
    }
  }
`;

export const ORDER_RECONCILIATION_DETAIL = gql`
  query GetOrderReconciliationByID($id: ID!) {
    orderReconciliation {
      get(id: $id) {
        id
        status
        rejectedReason {
          reason
          causes
        }
        expiredDate
        fullEvents {
          state
          name
          createdAt
          createdByID
          createdBy {
            id
            fullname
          }
        }
        orderID
        order {
          number
          status
          paymentStatus
          cancelReason
          fullEvents {
            state
            name
            createdAt
            createdByID
            createdBy {
              fullname
              email
              type
            }
          }
          receiverContact {
            fullName
            telephone
            address
            ward {
              name
            }
            district {
              name
            }
            city {
              name
            }
          }
          seller {
            idNumber
            code
            fullName
          }
          createdBy {
            fullname
            email
          }
          exportedWarehouse {
            name
          }
          paymentMethod
          saleChannel {
            name
            type
          }
          customerTotalServiceProduct #phi dich vu
          customerShippingFee #phi van chuyen
          customerSubTotal #tong tam tinh
          customerTotal #tong thanh toan
          total
          subTotal
          discount
          shippingFee
          totalServiceProduct
          sellerProfitAmount
          items {
            product {
              id
              name
              code
            }
            vat
            quantity
            price
            total
            customerPrice
            customerTotal
            productWarranty
            maxDaysOfDebt
          }
        }
      }
    }
  }
`;

export const COUNT_ORDER_RECONCILIATION = gql`
  query CountOrderReconciliation {
    all: orderReconciliation {
      count(filters: { reconciliationStatuses: [] })
    }
    waiting_for_payment: orderReconciliation {
      count(filters: { reconciliationStatuses: [WAITING_FOR_PAYMENT] })
    }
    reconciling: orderReconciliation {
      count(filters: { reconciliationStatuses: [RECONCILING] })
    }
    completed: orderReconciliation {
      count(filters: { reconciliationStatuses: [COMPLETED] })
    }
    rejected: orderReconciliation {
      count(filters: { reconciliationStatuses: [REJECTED] })
    }
    canceled: orderReconciliation {
      count(filters: { reconciliationStatuses: [CANCELED] })
    }
  }
`;

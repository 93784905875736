import { Input, Space } from "antd";
import { FontAwesomeIcon, Icon } from "assets/icons";
import FilterDate from "components/CustomFilterMenu/FilterDate";
import { useToolbar } from "./useToolbar";

import "./index.scss";

function Toolbar({ onSearch, onChangeParams, params }) {
  const { renderTags, handleChangeDate, renderDateTag } = useToolbar({
    params,
    onChangeParams,
  });

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div className="filter-profit-reconciliation-container">
        <FilterDate title={"Ngày tạo"} onChangeDate={handleChangeDate} />
        <Input
          className="custom-input"
          prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
          placeholder={"Tìm kiếm theo mã đơn hàng"}
          allowClear={true}
          defaultValue={String(params?.filters?.query)}
          onChange={(value) => onSearch(value)}
        />
      </div>
      <div className="tags-list">
        {renderTags}
        {renderDateTag()}
      </div>
    </Space>
  );
}

export default Toolbar;

import React from "react";
import { Table } from "antd";
import "./index.scss";

const CustomTable = ({
  pagination,
  dataSource,
  isLeftFooter = true,
  scroll = { x: 1500, y: "calc(80vh - 250px)" },
  ...rest
}) => {
  return (
    <div className="custom-table-component">
      <Table
        dataSource={dataSource}
        pagination={pagination ? { ...pagination, responsive: true } : false}
        size="middle"
        scroll={scroll}
        {...rest}
      />
      {pagination && dataSource?.length > 0 && isLeftFooter && (
        <div className="left-footer">
          Hiển thị {(pagination?.current - 1) * 10 + 1} - {(pagination?.current - 1) * 10 + dataSource?.length} của{" "}
          {pagination?.total} ({Math.ceil(pagination?.total / pagination?.pageSize)} trang)
        </div>
      )}
    </div>
  );
};

export default CustomTable;
